import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/services/global';

@Component({
  selector: 'app-contenido',
  templateUrl: './contenido.component.html',
  styleUrls: ['./contenido.component.scss']
})
export class ContenidoBackview implements OnInit {

  idContenido;

  constructor(
    public globalService: GlobalService
    ) { }

  async ngOnInit() {
    this.idContenido = await this.globalService.getIdContenido();
  }

}
