import { Component, OnInit, ɵConsole, Injectable } from '@angular/core';
/* SERVICES */
import { HttpClientEnersolService } from 'src/services/http-client-enersol.service';
import { GlobalService } from 'src/services/global';
/* REST MODEL */
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
/* MODELS */
import { OrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/OrdenDeCompra';
import { AuthenticationRequest } from 'src/models/com.enersol.directshopping.ws.rest.security.model/AuthenticationRequest';

@Injectable()
export class LoginController {

  constructor(
    private httpClientEnersolService: HttpClientEnersolService,
    private globalEnersolService: GlobalService
  ) { 
  }

  /**
  * Función que hace la petición del servicio para obtener una orden de compra en estado borrador
  * @returns ListResponse<OrdenDeCompra>
  * @author msemeraro
  */
  async getOrdenDeCompraImportacionBorrador(): Promise<ListResponse<OrdenDeCompra>> {
    return this.globalEnersolService.getNuevaOrdenDeCompraBorrador();
  }

  /**
  * Función que cierra la sesión
  * @returns 
  * @author msemeraro
  */
  async cerrarSesion() {
    return this.globalEnersolService.cerrarSesion();
  }

  /**
  * Función que hace guarda la orden de compra en estado borrador obtenida del servicio
  * @returns ordenDeCompra OrdenDeCompra
  * @author msemeraro
  */
  async setOrdenDeCompraImportacion(ordenDeCompra: OrdenDeCompra) {
    await this.globalEnersolService.setOrdenDeCompraImportacionBorrador(ordenDeCompra);
    await this.globalEnersolService.getCantidadProductosCarrito();
  }


  /**
  * Función que hace guarda la orden de compra en estado borrador obtenida del servicio
  * @returns ordenDeCompra OrdenDeCompra
  * @author msemeraro
  */
   async setOrdenDeCompraInventario(ordenDeCompra: OrdenDeCompra) {
    await this.globalEnersolService.setOrdenDeCompraInventarioBorrador(ordenDeCompra);
    await this.globalEnersolService.getCantidadProductosCarrito();
  }



  async login(authenticationRequest: AuthenticationRequest): Promise<Response<string>> {
    let respuesta: Response<string>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost<Response<string>>('security/auth', authenticationRequest);
      respuesta = <Response<string>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<string>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que guarda las variables para mostrar un mensaje
  * @param tituloMensaje
  * @param contenidoMensaje
  * @param esError
  * @author msemeraro
  */
 setMensaje(tituloMensaje: String, contenidoMensaje: String, esError: Boolean): void {
  this.globalEnersolService.setTituloMensaje(tituloMensaje);
  this.globalEnersolService.setContenidoMensaje(contenidoMensaje);
  this.globalEnersolService.setEsError(esError);
}

/**
* Función que obtiene el titulo de un mensaje
* @return tituloMensaje 
* @author msemeraro
*/
getTituloMensaje(): String {
  return this.globalEnersolService.getTituloMensaje();
}

/**
* Función que obtiene el contenido de un mensaje
* @return contenidoMensaje 
* @author msemeraro
*/
getContenidoMensaje(): String {
  return this.globalEnersolService.getContenidoMensaje();
}

/**
* Función que obtiene si un mensaje es error o no
* @return esError 
* @author msemeraro
*/
getEsError(): Boolean {
  return this.globalEnersolService.getEsError();
}
}
