import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

/* SERVICES */
import { LoginController } from './login.component.controller';
import { ErrorService } from 'src/services/error.service';
import { AuthenticationRequest } from 'src/models/com.enersol.directshopping.ws.rest.security.model/AuthenticationRequest';
import { LogService } from 'src/services/log.service';
import { GlobalService } from 'src/services/global';
import { EnumEstadoOrdenInventario } from 'src/models/com.enersol.directshopping.dal.enums/EnumEstadoOrdenInventario';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LoginController]
})
export class LoginBackview implements OnInit {

  authenticationRequest: AuthenticationRequest;

  /* Variable para los mensajes */
  contenidoMensaje: String;
  tituloMensaje: String;
  esError: Boolean;

  constructor(
    private router: Router,
    private loginController: LoginController,
    private errorService: ErrorService,
    private logService: LogService,
    private globalService: GlobalService,
    private spinner: NgxSpinnerService
  ) {

    this.authenticationRequest = new AuthenticationRequest();

    this.authenticationRequest.codigoRecope = '';
    this.authenticationRequest.username = '';
    this.authenticationRequest.password = '';
    // Este no deberia cambiar, es para diferenciarlo y saber que es la versión web
    this.authenticationRequest.appVersion = 'N/A';
  }

  ngOnInit() {
    this.loginController.cerrarSesion();
    this.globalService.setShowClientHeader(true);
  }

  /**
    * Función que solicita la estacion al ingresar y navega a la pagina principal
    * @author msemeraro
    */
  async ingresar() {
    this.spinnerShow();
    let respuesta = await this.loginController.login(this.authenticationRequest);
    if (respuesta.sucess) {
      this.logService.log('accionLogin ok');
      this.globalService.saveToken(respuesta.data);
      this.globalService.setUsername(this.authenticationRequest.username);

      await this.globalService.setFormaInventario();
      // Consulta si es Administrador de enersol
      if ((await this.globalService.isAdminEnersol()
        ||
        await this.globalService.isCreacionOrdenesEnersol()
      )
        && (this.authenticationRequest.codigoRecope != '000000')) {
        await this.getOrdenDeCompraImportacionBorrador();
        
        this.spinnerHide();
        await this.globalService.setCodigoEstacion(this.authenticationRequest.codigoRecope);
        await this.globalService.setShowClientHeader(true);
        await this.router.navigate(['principal']);
        return;
      }
      if (await this.globalService.isAdminEnersol()) {
        await this.globalService.setShowClientHeader(false);
        // Pregunta si es el encargado de Pricing
        if (await this.globalService.isPricingEnersol()) {
          this.spinnerHide();
          // Muestra la vista de Pricing
          await this.globalService.setCodigoEstacion(this.authenticationRequest.codigoRecope);
          await this.router.navigate(['admin-revision-precios']);
        }
        // Pregunta si es el encargado de algún mantenimiento
        else if (await this.globalService.isMantenimientoProductosEnersol() || await this.globalService.isMantenimientoGeneralEnersol()) {
          this.spinnerHide();
          await this.globalService.setShowClientHeader(false);
          // Muestra la vista de mantenimiento de productos
          await this.globalService.setCodigoEstacion(this.authenticationRequest.codigoRecope);
          await this.router.navigate(['admin-mantenimiento-productos']);
        }
        // Muestra la vista de administración de órdenes
        else {
          await this.globalService.setShowClientHeader(false);
          this.spinnerHide();
          await this.globalService.setCodigoEstacion(this.authenticationRequest.codigoRecope);
          await this.router.navigate(['admin-ordenes']);
        }
      }
      // Si no es administrador de enersol, muestra la vista principal de productos
      else {
        await this.globalService.setShowClientHeader(true);
        await this.getOrdenDeCompraImportacionBorrador();
        this.spinnerHide();
        await this.globalService.setCodigoEstacion(this.authenticationRequest.codigoRecope);
        await this.router.navigate(['principal']);
      }
    }
    else {
      this.spinnerHide();
      await this.globalService.setCodigoEstacion(this.authenticationRequest.codigoRecope);
      this.logService.log('accionLogin fallo');
      this.loginController.setMensaje('Error al iniciar sesión', "Credenciales incorrectos, verifique los datos", true);
      this.verMensaje();
      return;
    }
  }

  /**
   * Función que carga una orden de compra para el carrito
   * @author msemeraro
   */
  async getOrdenDeCompraImportacionBorrador() {
    let ordenDeCompra = await this.loginController.getOrdenDeCompraImportacionBorrador();
    if (!ordenDeCompra.sucess) {
      this.errorService.displayError(ordenDeCompra.errors);
    }
    else {
      if(ordenDeCompra.data[0].estadoOrdenInventario.estado == EnumEstadoOrdenInventario.IMPORTACION.nombre) {
        this.loginController.setOrdenDeCompraImportacion(ordenDeCompra.data[0]);
        this.loginController.setOrdenDeCompraInventario(ordenDeCompra.data[1]);
      } else {
        this.loginController.setOrdenDeCompraInventario(ordenDeCompra.data[0]);
        this.loginController.setOrdenDeCompraImportacion(ordenDeCompra.data[1]);
      }
    }
  }

  enviarContrasena() {
    // TODO Revisar produccion
    window.open("https://admin.enersolcr.com/web/guest/home?p_p_id=com_liferay_login_web_portlet_LoginPortlet&p_p_lifecycle=0&p_p_state=maximized&p_p_mode=view&_com_liferay_login_web_portlet_LoginPortlet_mvcRenderCommandName=%2Flogin%2Fforgot_password", "_blank");
    //window.open("http://localhost:8080/web/guest/home?p_p_id=com_liferay_login_web_portlet_LoginPortlet&p_p_lifecycle=0&p_p_state=maximized&p_p_mode=view&_com_liferay_login_web_portlet_LoginPortlet_mvcRenderCommandName=%2Flogin%2Fforgot_password", "_blank");
  }

  spinnerShow() {
    this.spinner.show();
  }

  spinnerHide() {
    this.spinner.hide();
  }

  /**
  * Función que muestra un mensaje
  * @author msemeraro
  */
  verMensaje() {
    this.tituloMensaje = this.loginController.getTituloMensaje();
    this.contenidoMensaje = this.loginController.getContenidoMensaje();
    this.esError = this.loginController.getEsError();
    var element = document.getElementById("btnAbrirMensaje") as any;
    element.click();
  }

  clickModalMensaje() { }
}
