import { HistorialRevisionPreciosComponent } from './historial-revision-precios.component';

import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
import { HistoricoProductoPricing } from 'src/models/com.enersol.directshopping.dal.entidad/HistoricoProductoPricing'


export interface historicoPricingData {
  'fecha': Date;
  'item': number;
  'producto' : string;
  'presentacion': string;
  'unidad': number;
  'cantidad' : number;
  'precio': number; 
  'total': number;
}


@Component({
  selector: 'app-historial-revision-precios',
  templateUrl: './historial-revision-precios.component.html',
  styleUrls: ['./historial-revision-precios.component.scss'],
  providers: [HistorialRevisionPreciosComponent]
})
export class HistorialRevisionPreciosBackview implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  
  displayedColumns: string[] = ['fecha', 'item', 'producto', 'presentacion', 'unidad',
                                'cantidad', 'precio', 'total'];
  dataSource: MatTableDataSource<historicoPricingData>;


  constructor(
    private historicoPricingController: HistorialRevisionPreciosComponent
  ) {   

  }

  async ngOnInit() {
    let response = await this.historicoPricingController.getHistoricoProductosPricing();
    await this.asignarInterfaceDataSource(response.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  asignarInterfaceDataSource(listaHistorico) {
    let listaProductos = [];
    for (let producto of listaHistorico) {
      let productoData = {
        'fecha' : (new Date(producto.id.fechaPricing)).toLocaleDateString(), 
        'item' : producto.productoPresentacion.id.codigoProductoPresentacion,
        'producto' : producto.productoPresentacion.producto.nombre,
        'presentacion': producto.productoPresentacion.presentacion.descripcion,
        'unidad': producto.productoPresentacion.unidadEmpaque,
        'cantidad' : producto.cantidad,
        'precio': producto.precioUnitarioEstimado,
        'total': producto.totalEstimado
      }
      listaProductos.push(productoData);
    }
    this.dataSource = new MatTableDataSource(listaProductos);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

