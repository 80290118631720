import { Injectable } from '@angular/core';

/**
 * Provedor de constantes de la aplicacion
 */
@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  /**
   * Define el modo de debug
   */
  private debugMode: boolean;


  //###########################################################################

  /**
  * Indica el host del servicio web rest 
  */

  // HOST PARA DEPLOY DESARROLLO 

  //LIFERAY
  // TODO Revisar produccion
  // TOMCAT
  //private hostEnersolRS: string = 'http://localhost:7080';

  // HOST PARA DEPLOY EN PRUEBAS
  // private hostEnersolRS: string = 'https://pruebas.enersolcr.com';

  // HOST PARA DEPLOY EN PRODUCCION
  private hostEnersolRS: string = 'https://admin.enersolcr.com';

  //##########################################################################

  /**
  * Indica el root del api del servicio web rest
  */
  private apiRootEnersolRS: string = '/Enersol_WS/api/directshopping';

  /**
  * Indica la url del servicio web rest
  */
  private urlEnersolRS: string;

  /**
   * Nombre del header donde se transmite el token
   */
  private headerAuth: string;

  constructor() {

    this.debugMode = false;

    this.urlEnersolRS = this.hostEnersolRS + this.apiRootEnersolRS + '/';

    this.headerAuth = 'Authorization';

  }

  getDebugMode() {
    return this.debugMode;
  }

  getUrlEnersolRS() {
    return this.urlEnersolRS;
  }

  getUrlEnersol() {
    return this.hostEnersolRS;
  }

  getHeaderAuth() {
    return this.headerAuth;
  }

}