import { Component, OnInit, ɵConsole, Injectable } from '@angular/core';
/* SERVICES */
import { HttpClientEnersolService } from 'src/services/http-client-enersol.service';
import { GlobalService } from 'src/services/global';
import { ValidationsService } from 'src/services/validations.service';
import { ErrorService } from 'src/services/error.service';
/* REST MODEL */
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
/* MODELS */
import { ProductoPricing } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoPricing';
import { Parametro } from 'src/models/com.enersol.directshopping.dal.entidad/Parametro';
import { DatePipe } from '@angular/common';
import { RequestPricing } from 'src/models/com.enersol.directshopping.dal.entidad/RequestPricing';

@Injectable()
export class AdminRevisionPreciosController {

  constructor(
    private httpClientEnersolService: HttpClientEnersolService,
    private globalEnersolService: GlobalService,
    private validationEnersolService: ValidationsService,
    private errorService: ErrorService,
    private datePipe : DatePipe 
  ) { }

  /* FUNCIONES QUE HACEN PETICIONES A LOS SERVICIOS */

  /**
  * Función que hace la petición del servicio de todos los productos en pricing
  * @returns ListResponse<ProductoPricing>
  * @author msemeraro
  */
  async getProductosPricing(): Promise<ListResponse<ProductoPricing>> {
    let respuesta: ListResponse<ProductoPricing>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('Pricing/Fecha/all');
      respuesta = <ListResponse<ProductoPricing>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<ProductoPricing>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que hace la petición del servicio para aprobar un producto en pricing
  * @returns ListResponse<ProductoPricing>
  * @author msemeraro
  */
  async aprobarProductoPricing(producto: ProductoPricing): Promise<ListResponse<ProductoPricing>> {
    let respuesta: ListResponse<ProductoPricing>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('Pricing/AprobarProducto', this.getDatosAprobarProducto(producto));
      respuesta = <ListResponse<ProductoPricing>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<ProductoPricing>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
 * Función que actualiza los Parámetros fecha de pricing, fecha limite pago y tipo de cambio
 * @returns void
 * @author msemeraro
 */
  async actualizarParametrosPricing(tipoCambio: String, fechaPago: Date, fechaPricing: Date) {
    // Actualiza los Parámetros
    let fechaLimitePago : string = this.datePipe.transform(fechaPago,"dd-MM-yyyy HH:mm:ss");
    await this.actualizarParametro("fechaLimitePago", fechaLimitePago);
    let fechaCotizacion = this.datePipe.transform(fechaPago,"dd-MM-yyyy HH:mm:ss");
    await this.actualizarParametro("fechaAprobacionParaPricing", fechaCotizacion);
    await this.actualizarParametro("tipoCambio", tipoCambio);
    return true;
  }

  /**
 * Función que finaliza el pricing
 * @returns Response<Boolean>
 * @author msemeraro
 */
  async realizarPricing(tipoCambio: number, fechaPago: Date, fechaPricing: Date): Promise<Response<Boolean>> {
    let respuesta: Response<Boolean>;
    try {
        let requestPricing : RequestPricing = new RequestPricing();
        requestPricing.tipoCambio = tipoCambio;
        requestPricing.fechaPago = fechaPago;
        requestPricing.fechaPricing = fechaPricing;

        let promiseResponse = await this.httpClientEnersolService.httpPost('Pricing/ConfirmarPricing', requestPricing);
        respuesta = <Response<Boolean>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Boolean>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que obtiene el tipo de cambio
  * @return tipoCambio 
  * @author msemeraro
  */
  async getTipoCambio() {
    await this.globalEnersolService.setTipoCambio();
    let tipoCambio: Parametro = this.globalEnersolService.getTipoCambio();
    return Number(tipoCambio.valor);
  }

  /**
   * Función que obtiene el impuesto
   * @returns Response<Parametro>
   * @author msemeraro
   */
  async getImpuesto(): Promise<Response<Parametro>> {
    let respuesta: Response<Parametro>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('Parametro/id?nombre=Impuesto');
      respuesta = <Response<Parametro>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Parametro>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que hace la petición del servicio para actualizar el precio de un producto presentacion
  * @param productoId id del producto a actualizar
  * @param precio nuevo precio del producto
  * @returns Response<Boolean>
  * @author msemeraro
  */
  async actualizarPrecioProducto(productoId, precio): Promise<Response<Boolean>> {
    let respuesta: Response<Boolean>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('ProductoPresentacion/Update/Precio', this.getDatosActualizarPrecioProducto(productoId, precio));
      respuesta = <Response<Boolean>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Boolean>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que hace la petición del servicio para actualizar un Parametro
  * @param nombre nombre del parametro
  * @param valor nuevo valor
  * @returns Response<Parametro>
  * @author msemeraro
  */
  async actualizarParametro(nombre, valor): Promise<Response<Parametro>> {
    let respuesta: Response<Parametro>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('Parametro/update', this.getDatosActualizarParametro(nombre, valor));
      respuesta = <Response<Parametro>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Parametro>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }


  /* FUNCIONES QUE HACEN PETICIONES AL GLOBAL SERVICE */

  /**
  * Función que guarda las variables para mostrar un mensaje
  * @param tituloMensaje
  * @param contenidoMensaje
  * @param esError
  * @author msemeraro
  */
  setMensaje(tituloMensaje: String, contenidoMensaje: String, esError: Boolean): void {
    this.globalEnersolService.setTituloMensaje(tituloMensaje);
    this.globalEnersolService.setContenidoMensaje(contenidoMensaje);
    this.globalEnersolService.setEsError(esError);
  }

  /**
  * Función que obtiene el titulo de un mensaje
  * @return tituloMensaje 
  * @author msemeraro
  */
  getTituloMensaje(): String {
    return this.globalEnersolService.getTituloMensaje();
  }

  /**
  * Función que obtiene el contenido de un mensaje
  * @return contenidoMensaje 
  * @author msemeraro
  */
  getContenidoMensaje(): String {
    return this.globalEnersolService.getContenidoMensaje();
  }

  /**
  * Función que obtiene si un mensaje es error o no
  * @return esError 
  * @author msemeraro
  */
  getEsError(): Boolean {
    return this.globalEnersolService.getEsError();
  }

  /**
  * Función que obtiene si el usuario logueado es administrador de enersol
  * @return boolean 
  * @author msemeraro
  */
  isAdminEnersol() {
    return this.globalEnersolService.isAdminEnersol();
  }

  /**
  * Función que retorna si el nuevo precio de producto es valida
  * @returns boolean
  * @author msemeraro
  */
  cambioCantidadProducto(precio: string): boolean {
    return true;
    //return this.validationEnersolService.validateNumeric(precio);
  }


  /* FUNCIONES QUE HACEN ORDENAMIENTOS O FILTROS */




  /* OTRAS FUNCIONES */

  /**
  * Función que llama a aprobar los productos en pricing según lista de productos selccionados
  * @param productosPricing 
  * @param productosSeleccionados productos por aprobar
  * @author msemeraro
  */
  async aprobarProductos(productosPricing: Array<ProductoPricing>, productosSeleccionados, aprobar: boolean) {
    for (let productoSeleccionado of productosSeleccionados) {
      for (let productoPricing of productosPricing) {
        if (productoPricing.productoPresentacion.id.codigoProductoPresentacion == productoSeleccionado && !productoPricing.aprobado == aprobar) {
          await this.aprobarProductoPricing(productoPricing);
        }
      }
    }
  }

  /**
  * Función que formatea un número
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
  getFormatNumber(numero: number) {
    return this.validationEnersolService.getNumber(numero);
  }

  /**
  * Función que obtiene la suma de los totales de los productos en pricing
  * @param productosPricing
  * @returns number
  * @author msemeraro
  */
  getSubTotalPricing(productosPricing: Array<ProductoPricing>): number {
    let subTotal: number = 0;
    for (let producto of productosPricing) {
      if (producto.aprobado) {
        subTotal = subTotal + (producto.totalEstimado);
      }
    }
    return subTotal;
  }

    /**
    * Función que valida si una fecha es mayor a la actual
    * @param fecha
    * @returns boolean
    * @author msemeraro
    */
    validarFecha(fecha: Date) {
      let fechaActual = Date.now();
      return (fecha != null && fechaActual < fecha.getTime());
    }

    /**
      * Función que retorna el json con los datos para actualizar un Parametro
      * @param nombre
      * @param valor
      * @returns Json con los datos del producto a actualizar
      * @author msemeraro
      */
    getDatosActualizarParametro(nombre, valor) {
      return {
        "nombre": nombre,
        "valor": valor
      }
    }

    /**
    * Función que retorna el json con los datos para actualizar el precio de un producto presentacion
    * @param productoId
    * @returns Json con los datos del producto a actualizar
    * @author msemeraro
    */
    getDatosActualizarPrecioProducto(productoId, precio) {
      return {
        "id": {
          "codigoProducto": productoId.codigoProducto,
          "codigoPresentacion": productoId.codigoPresentacion,
          "codigoMarca": productoId.codigoMarca,
          "codigoProductoPresentacion": productoId.codigoProductoPresentacion
        },
        "precio": precio
      }
    }

    /**
    * Función que retorna el json con los datos para actualizar el estado
    * @param productoId
    * @returns Json con los datos del producto a aprobar
    * @author msemeraro
    */
    getDatosAprobarProducto(producto: ProductoPricing) {
      return producto;
    }

  }
