import { Component, OnInit, AfterViewInit, DoCheck, AfterViewChecked } from '@angular/core';
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
import { HeaderController } from './header.component.controller';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorService } from 'src/services/error.service';
import { GlobalService } from 'src/services/global';
import { Observable } from 'rxjs';
import { ConstantsService } from 'src/services/constants.service';
import { Parametro } from 'src/models/com.enersol.directshopping.dal.entidad/Parametro';
import { EnumFormaInventario } from 'src/models/com.enersol.directshopping.dal.enums/EnumFormaInventario';

import etiquetas from '../../assets/i18n/es.json';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [HeaderController]
})
export class HeaderBackview implements OnInit {

  /* Variables para controlar los roles del usuario */
  isAdmin: Boolean;
  isCreadorOrdenes: Boolean;
  codigoRecope: string;

  /* Variables para cantidad en el carrito */
  cantidadProductosCarrito: Response<number>;

  /* Variable para los mensajes */
  contenidoMensaje: String;
  tituloMensaje: String;
  esError: Boolean;
  showClientHeader: boolean;
  tabSelected: number = 0;
  username: string;

  formaInventario: Parametro;

  //isAdmin: boolean = false;

  constructor(
    private router: Router,
    public headerComponent: HeaderController,
    private route: ActivatedRoute,
    private errorService: ErrorService,
    private constantesService: ConstantsService,
    public globalService: GlobalService
  ) {
    this.getCantidadProductosCarrito();
    // this.globalService.isShowClientHeader().then(
    //   response => {
    //     response.subscribe(flag => {
    //       this.showClientHeader = flag;
    //     });
    //   });
  }

  async ngOnInit() {
    this.headerComponent.isAdminEnersol().then(response => {
      this.isAdmin = response;
    });
    
    this.getCantidadProductosCarrito();
    await this.globalService.isShowClientHeader();
    await this.globalService.getusername();
    this.showClientHeader = this.globalService.showHeader;
    this.username = this.globalService.username;
    this.formaInventario = await this.globalService.getFormaInventario();
    // this.isAdmin = await this.headerComponent.isAdminEnersol();
    // this.isCreadorOrdenes = await this.globalService.isCreacionOrdenesEnersol();
    await this.globalService.getCodigoEstacion().then(response => {
      this.codigoRecope = response;
    });
  }

  // // REVISAR Y CORREGIR LUEGO
  // // SE EJECUTA POR CADA ACCION QUE DETECTE LA PAGINA
  ngDoCheck() {
    // this.headerComponent.isAdminEnersol().then(response => {
    //   this.isAdmin = response;
    // });

    // this.showClientHeader = this.globalService.showHeader;

    // this.username = this.globalService.username;

  }

  async getCantidadProductosCarrito() {
    this.cantidadProductosCarrito = await this.headerComponent.getCantidadProductosCarrito();
    if (this.cantidadProductosCarrito != null && !this.cantidadProductosCarrito.sucess) {
      this.errorService.displayError(this.cantidadProductosCarrito.errors);
    }
  }

  /**
  * Función que navega a otra pagina
  * @author msemeraro
  */
  irPagina(nombre: string) {
    this.router.navigate([nombre]);
  }

  async irPricing(nombre: string) {
    this.tabSelected = 2;
    if (await this.headerComponent.isPricingEnersol())
      this.router.navigate([nombre]);
    else {
      this.headerComponent.setMensaje('Error al cargar la página', "No posee con los permisos necesarios para navegar a la página", true);
      this.verMensaje();
    }
  }

  async irMantenimientoGeneral(nombre: string) {
    this.tabSelected = 3;
    if (await this.headerComponent.isMantenimientoGeneralEnersol())
      this.router.navigate([nombre]);
    else {
      this.headerComponent.setMensaje('Error al cargar la página', "No posee con los permisos necesarios para navegar a la página", true);
      this.verMensaje();
    }
  }

  async irMantenimientoProductos(nombre: string) {
    this.tabSelected = 3;
    if (await this.headerComponent.isMantenimientoGeneralEnersol() || await this.headerComponent.isMantenimientoProductosEnersol())
      this.router.navigate([nombre]);
    else {
      this.headerComponent.setMensaje('Error al cargar la página', "No posee con los permisos necesarios para navegar a la página", true);
      this.verMensaje();
    }
  }

  async irAdministracionOrdenes(nombre: string) {
    this.tabSelected = 1;
    if (await this.headerComponent.isConsultaMantenimientoOrdenesEnersol())
      this.router.navigate([nombre]);
    else {
      this.headerComponent.setMensaje('Error al cargar la página', "No posee con los permisos necesarios para navegar a la página", true);
      this.verMensaje();
    }
  }

  verCarrito() {
    this.router.navigate(['carrito']);
  }

  verPrincipal() {
    this.router.navigate(['principal']);
  }

  verMisOrdenes() {
    this.router.navigate(['mis-ordenes']);
  }

  async verReportes() {
    if (await this.headerComponent.isReportesEnersol()) {
      this.router.navigate(['reporte-orden']);
    }
    else {
      this.headerComponent.setMensaje('Error al cargar la página', "No posee con los permisos necesarios para navegar a la página", true);
      this.verMensaje();
    }
  }

  async administrarUsuarios() {
    if (await this.headerComponent.isCrearUsuariosEnersol() || await this.headerComponent.isCrearUsuariosEstacion()) {
      window.open(this.constantesService.getUrlEnersol() + "/es/group/guest/usuarios", "_blank");
    }
    else {
      this.headerComponent.setMensaje('Error al cargar la página', "No posee con los permisos necesarios para navegar a la página", true);
      this.verMensaje();
    }
  }

  async cambiarContrasena() {
    window.open(this.constantesService.getUrlEnersol() + "/es/group/control_panel/manage?p_p_id=com_liferay_my_account_web_portlet_MyAccountPortlet&p_p_lifecycle=0&p_p_auth=yOHokLYs", "_blank");
  }

  cerrarSesion() {
    this.headerComponent.cerrarSesion();
    this.router.navigate(['login']);
  }

  buscar() {
    this.headerComponent.verListadoProductos('todosProductos', 1, 'Productos');
    this.router.navigate(['busqueda']);
  }

  getToken() {
    return this.headerComponent.getToken();
  }

  /**
  * Función que muestra un mensaje
  * @author msemeraro
  */
  verMensaje() {
    this.tituloMensaje = this.headerComponent.getTituloMensaje();
    this.contenidoMensaje = this.headerComponent.getContenidoMensaje();
    this.esError = this.headerComponent.getEsError();
    var element = document.getElementById("btnAbrirMensajeHeader") as any;
    element.click();
  }

  clickModalMensaje() { }


  verBitacora() {
    this.router.navigate(['bitacora']);
  }

  /**
   * Indica si el parametro está colocado para la forma de Inventario
   */
  isParametroInventario(){
    return this.formaInventario.valor === EnumFormaInventario.INVENTARIO.nombre;
  }

  /**
   * Indica si el parametro está colocado para la forma de Importacion
   */
  isParametroImportacion(){
    return this.formaInventario.valor === EnumFormaInventario.IMPORTACION.nombre;
  }

  /**
   * Indica si el parametro está colocado para la forma Mixta
   */
  isParametroMixto(){
    return this.formaInventario.valor === EnumFormaInventario.MIXTO.nombre;
  }

  /**
   * Obtiene la explicación del parámetro de forma de inventario, según su tipo
   * @returns 
   */
  getEtiquetaExplicacion(): string{
    let explicacion:string = "";
    if(this.isParametroInventario()){
      explicacion = etiquetas["pagHeaderExplicacionParametroInventario"];
    }
    else if(this.isParametroImportacion()){
      explicacion = etiquetas["pagHeaderExplicacionParametroImportacion"];
    }
    else if(this.isParametroMixto()){
      explicacion = etiquetas["pagHeaderExplicacionParametroMixto"];
    }
    return explicacion;
  }

}
