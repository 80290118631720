import { Component, OnInit, ɵConsole, Injectable } from '@angular/core';
import { HttpClientEnersolService } from 'src/services/http-client-enersol.service';
import { GlobalService } from 'src/services/global';

@Injectable()
export class HeaderController {

  constructor(
    public globalEnersolService: GlobalService
  ) { }

  async getCantidadProductosCarrito() {
    return this.globalEnersolService.getCantidadProductosCarrito();
  }

  setCantidadProductosCarrito(cantidad) {
    this.globalEnersolService.setCantidadProductosCarrito(cantidad);
  }

  cerrarSesion() {
    this.globalEnersolService.cerrarSesion();
  }

  verListadoProductos(nombreLista: string, codigo: number, titulo: string) {
    this.globalEnersolService.setCodigoFamiliaProductos(codigo);
    this.globalEnersolService.setNombreListaProductos(nombreLista);
    this.globalEnersolService.setNombreProductos(titulo);
  }

  getToken(){
    return this.globalEnersolService.getToken();
  }

  /**
    * Función que obtiene si el usuario tiene permisos de Pricing
    * @return boolean 
    * @author msemeraro
    */
  async isPricingEnersol() {
    return await this.globalEnersolService.isPricingEnersol();
  }

  /**
    * Función que obtiene si el usuario tiene permisos de mantener ordenes
    * @return boolean 
    * @author msemeraro
    */
   async isConsultaMantenimientoOrdenesEnersol() {
    return await this.globalEnersolService.isConsultaMantenimientoOrdenesEnersol();
  }

  /**
    * Función que obtiene si el usuario tiene permisos de mantener todos los catalagos
    * @return boolean 
    * @author msemeraro
    */
  async isMantenimientoGeneralEnersol() {
    return await this.globalEnersolService.isMantenimientoGeneralEnersol();
  }

  /**
    * Función que obtiene si el usuario tiene permisos de mantener los productos
    * @return boolean 
    * @author msemeraro
    */
   async isMantenimientoProductosEnersol() {
    return await this.globalEnersolService.isMantenimientoProductosEnersol();
  }


  /**
    * Función que obtiene si el usuario logueado es administrador de enersol
    * @return boolean 
    * @author msemeraro
    */
 async isAdminEnersol() {
  return await this.globalEnersolService.isAdminEnersol();
  }

  /**
    * Función que obtiene si el usuario tiene permisos de reportes
    * @return boolean 
    * @author msemeraro
    */
   async isReportesEnersol() {
    return await this.globalEnersolService.isReportesEnersol();
  }

  /**
    * Función que obtiene si el usuario tiene permisos de crear usuarios
    * @return boolean 
    * @author msemeraro
    */
   async isCrearUsuariosEnersol() {
    return await this.globalEnersolService.isCrearUsuariosEnersol();
  }

  /**
    * Función que obtiene si el usuario tiene permisos de crear usuarios
    * @return boolean 
    * @author msemeraro
    */
   async isCrearUsuariosEstacion() {
    return await this.globalEnersolService.isCrearUsuariosEstacion();
  }

  /**
  * Función que guarda las variables para mostrar un mensaje
  * @param tituloMensaje
  * @param contenidoMensaje
  * @param esError
  * @author msemeraro
  */
  setMensaje(tituloMensaje: String, contenidoMensaje: String, esError: Boolean): void {
    this.globalEnersolService.setTituloMensaje(tituloMensaje);
    this.globalEnersolService.setContenidoMensaje(contenidoMensaje);
    this.globalEnersolService.setEsError(esError);
  }

  /**
  * Función que obtiene el titulo de un mensaje
  * @return tituloMensaje 
  * @author msemeraro
  */
  getTituloMensaje(): String {
    return this.globalEnersolService.getTituloMensaje();
  }

  /**
  * Función que obtiene el contenido de un mensaje
  * @return contenidoMensaje 
  * @author msemeraro
  */
  getContenidoMensaje(): String {
    return this.globalEnersolService.getContenidoMensaje();
  }

  /**
  * Función que obtiene si un mensaje es error o no
  * @return esError 
  * @author msemeraro
  */
  getEsError(): Boolean {
    return this.globalEnersolService.getEsError();
  }

}
