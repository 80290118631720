import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ErrorService } from 'src/services/error.service';
import { PageEvent } from '@angular/material/paginator';
import { AdminMantenimientoParametrosController } from './admin-mantenimiento-parametros.component.controller';
import { Parametro } from 'src/models/com.enersol.directshopping.dal.entidad/Parametro';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { EnumFormaInventario } from 'src/models/com.enersol.directshopping.dal.enums/EnumFormaInventario';

@Component({
  selector: 'app-admin-mantenimiento-parametros',
  templateUrl: './admin-mantenimiento-parametros.component.html',
  styleUrls: ['./admin-mantenimiento-parametros.component.scss'],
  providers: [AdminMantenimientoParametrosController]
})
export class AdminMantenimientoParametrosBackview implements OnInit {

  /* Variables para controlar los roles del usuario */
  isAdmin: Boolean;

  /* Variables con las fechas */
  fechaPago: Date;
  fechaPricing: Date;

  /* Variable con el tipo de cambio */
  tipoCambio: number = 0;

  /* Variable con el impuesto */
  impuesto: number = 0;

  /* Variable con la cantidad de días para el pago de una orden */
  cantidadDiasPagoOrden: number = 0;
  /* Variable con la cantidad de días para notificar el vencimiento de orden */
  cantidadDiasVencimientoOrden: number = 0;

  /* Variable para los mensajes */
  contenidoMensaje: String;
  tituloMensaje: String;
  esError: Boolean = false;

  /* Variables para la paginacion */
  page_size: number = 10;
  page_number: number = 1;
  pageSizeOptions = [5, 10, 20, 50, 100];

  /* Variable con la forma del inventario */
  formaInventario: string = "";
  constructor(
    private router: Router,
    private adminMantenimientosComponent: AdminMantenimientoParametrosController,
    private errorService: ErrorService,
    private spinner: NgxSpinnerService
  ) {
  }

  async ngOnInit() {
    this.isAdmin = await this.adminMantenimientosComponent.isAdminEnersol();
    await this.getParametros();
  }


  /**
  * Función que obtiene los parametros registradas
  * @author msemeraro
  */
  async getParametros() {
    this.spinnerShow();
    this.tipoCambio = await this.adminMantenimientosComponent.getTipoCambio();
    await this.getImpuesto();
    await this.getFechaLimite();
    await this.getFechaPricing();
    await this.getFormaInventario();
    await this.getCantidadDiasPagoOrden();
    await this.getCantidadDiasVencimientoOrden();
    this.spinnerHide();
  }

  async getImpuesto() {
    let respuesta = await this.adminMantenimientosComponent.getParametro('Impuesto');
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.impuesto = Number(respuesta.data.valor);
    }
  }



  async getFormaInventario() {
    let respuesta = await this.adminMantenimientosComponent.getParametro('formaInventario');
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.formaInventario = respuesta.data.valor;
    }
  }

  async getFechaPricing() {
    let respuesta = await this.adminMantenimientosComponent.getParametro('fechaAprobacionParaPricing');
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      let fecha = this.adminMantenimientosComponent.actualizarStringFecha(respuesta.data.valor);
      // Separa el dia, mes y a;o
      let splitFecha = fecha.split('/');
      // cambia el dia y el mes
      fecha = splitFecha[1] + '/' + splitFecha[0] + '/' + splitFecha[2];

      this.fechaPricing = new Date(fecha);
    }
  }

  async getFechaLimite() {
    let respuesta = await this.adminMantenimientosComponent.getParametro('fechaLimitePago');
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      let fecha = this.adminMantenimientosComponent.actualizarStringFecha(respuesta.data.valor);
      // Separa el dia, mes y a;o
      let splitFecha = fecha.split('/');
      // cambia el dia y el mes
      fecha = splitFecha[1] + '/' + splitFecha[0] + '/' + splitFecha[2];
      this.fechaPago = new Date(fecha);
    }
  }

  /**
   * Consulta el parametro para dias de pago de orden
   * @author gberrocal
   */
  async getCantidadDiasPagoOrden() {
    let respuesta = await this.adminMantenimientosComponent.getParametro('cantidadDiasPagoOrden');
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.cantidadDiasPagoOrden = Number(respuesta.data.valor);
    }
  }
  
  /**
   * Consulta el parametro para dias de notificación para vencimiento
   * @author gberrocal
   */
  async getCantidadDiasVencimientoOrden() {
    let respuesta = await this.adminMantenimientosComponent.getParametro('cantidadDiasVencimientoOrden');
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.cantidadDiasVencimientoOrden = Number(respuesta.data.valor);
    }
  }

  async actualizarFechaPricing() {
    if (!this.validarFecha(this.fechaPricing)) {
      this.adminMantenimientosComponent.setMensaje('Fecha incorrecta', 'Error, debe de ingresar una fecha próxima de Cotización Internacional válida', true);
      this.verMensaje();
      return;
    }
    this.spinnerShow();
    let respuesta = await this.adminMantenimientosComponent.actualizarParametroFecha('fechaAprobacionParaPricing', this.fechaPricing);
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al actualizar el parametro', mensaje, true);
      this.verMensaje();
    }
    else {
      await this.getParametros();
    }
    this.spinnerHide();
  }

  async actualizarFechaPago() {
    if (!this.validarFecha(this.fechaPago)) {
      this.adminMantenimientosComponent.setMensaje('Fecha incorrecta', 'Error, debe de ingresar una fecha límite de pago válida', true);
      this.verMensaje();
      return;
    }
    this.spinnerShow();
    let respuesta = await this.adminMantenimientosComponent.actualizarParametroFecha('fechaLimitePago', this.fechaPago);
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al actualizar el parametro', mensaje, true);
      this.verMensaje();
    }
    else {
      await this.getParametros();
    }
    this.spinnerHide();
  }

  async actualizarTipoCambio() {
    let parametro: Parametro = new Parametro();
    parametro.nombre = 'tipoCambio';
    parametro.valor = this.tipoCambio.toString();
    await this.actualizarParametro(parametro);
  }

  async actualizarImpuesto() {
    let parametro: Parametro = new Parametro();
    parametro.nombre = 'Impuesto';
    parametro.valor = this.impuesto.toString();
    await this.actualizarParametro(parametro);
  }


  /**
  * Función que controla el cambio de estado
  * @param codigoEstado
  * @author gberrocal
  */

   onChangeFormaInventario(formaDeInventario:string) {
    this.formaInventario = formaDeInventario;
  }

  async actualizarFormaInventario() {
    this.spinnerShow();
    if(this.formaInventario.toString() == EnumFormaInventario.IMPORTACION.nombre 
    || this.formaInventario.toString() == EnumFormaInventario.MIXTO.nombre
    || this.formaInventario.toString() == EnumFormaInventario.INVENTARIO.nombre) {
      let parametro: Parametro = new Parametro();
      parametro.nombre = 'formaInventario';
      parametro.valor = this.formaInventario.toString();
      await this.actualizarParametro(parametro);
    } else {
      let mensaje = "Favor utilizar una de los siguientes 3 formas de importación: "
        + EnumFormaInventario.INVENTARIO.nombre + ", " +
        + EnumFormaInventario.IMPORTACION.nombre + " o "
        + EnumFormaInventario.MIXTO.nombre;
      this.adminMantenimientosComponent.setMensaje('Error al actualizar el parametro', mensaje, true);
      this.verMensaje();
    }
    this.spinnerHide();
  }

  async actualizarCantidadDiasPagoOrden() {
    let parametro: Parametro = new Parametro();
    parametro.nombre = 'cantidadDiasPagoOrden';
    parametro.valor = this.cantidadDiasPagoOrden.toString();
    await this.actualizarParametro(parametro);
  }

  async actualizarCantidadDiasVencimientoOrden() {
    let parametro: Parametro = new Parametro();
    parametro.nombre = 'cantidadDiasVencimientoOrden';
    parametro.valor = this.cantidadDiasVencimientoOrden.toString();
    await this.actualizarParametro(parametro);
  }

  async actualizarParametro(parametro: Parametro) {
    this.spinnerShow();
    let respuesta = await this.adminMantenimientosComponent.actualizarParametro(parametro);
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al actualizar el parametro', mensaje, true);
      this.verMensaje();
    }
    else {
      await this.getParametros();
    }
    this.spinnerHide();
  }

  /**
  * Función que valida el cambio en el tipo de cambio
  * @author msemeraro
  */
  cambioTipoCambio() {
    console.log(this.tipoCambio);
    /*if (!this.adminRevisionPreciosComponent.cambioCantidadProducto(this.tipoCambio.toString())) {
      this.adminRevisionPreciosComponent.setMensaje('Modificación Tipo de Cambio', 'Error, debe de ingresar un tipo de cambio válido', true);
      this.verMensaje();
    }*/
  }

  validarFecha(fecha: Date) {
    return this.adminMantenimientosComponent.validarFecha(fecha);
  }

  /**
  * Función que navega a otra pagina
  * @author msemeraro
  */
  irPagina(nombre: string) {
    this.router.navigate([nombre]);
  }

  spinnerShow() {
    this.spinner.show();
  }

  spinnerHide() {
    this.spinner.hide();
  }

  /**
  * Función que muestra un mensaje
  * @author msemeraro
  */
  verMensaje() {
    this.tituloMensaje = this.adminMantenimientosComponent.getTituloMensaje();
    this.contenidoMensaje = this.adminMantenimientosComponent.getContenidoMensaje();
    this.esError = this.adminMantenimientosComponent.getEsError();
    var element = document.getElementById("btnAbrirMensaje") as any;
    element.click();
  }

  clickModalMensaje() { }

  clickModalCambioEstado() { }

  handlePage(e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }
}
