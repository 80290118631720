import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BusquedaBackview } from 'src/components/busqueda/busqueda.component.backview';
import { PrincipalBackview } from 'src/components/principal/principal.component.backview';
import { DetalleProductoBackview } from 'src/components/detalle-producto/detalle-producto.component.backview';
import { CarritoBackview } from 'src/components/carrito/carrito.component.backview';
import { MisOrdenesBackview } from 'src/components/mis-ordenes/mis-ordenes.component.backview';
import { LoginBackview } from 'src/components/login/login.component.backview';
import { AdminOrdenesBackview } from 'src/components/admin-ordenes/admin-ordenes.component.backview';
import { AdminDetalleOrdenBackview } from 'src/components/admin-detalle-orden/admin-detalle-orden.component.backview';
import { AdminRevisionPreciosBackview } from 'src/components/admin-revision-precios/admin-revision-precios.component.backview';
import { DetalleOrdenBackview } from 'src/components/detalle-orden/detalle-orden.component.backview';
import { OrdenSugeridaBackview } from 'src/components/orden-sugerida/orden-sugerida.component.backview';
import { AdminMantenimientoMarcasBackview } from 'src/components/admin-mantenimiento-marcas/admin-mantenimiento-marcas.component.backview';
import { AdminMantenimientoFamiliasBackview } from 'src/components/admin-mantenimiento-familias/admin-mantenimiento-familias.component.backview';
import { AdminMantenimientoPresentacionesBackview } from 'src/components/admin-mantenimiento-presentaciones/admin-mantenimiento-presentaciones.component.backview';
import { AdminMantenimientoParametrosBackview } from 'src/components/admin-mantenimiento-parametros/admin-mantenimiento-parametros.component.backview';
import { AdminMantenimientoEstacionesBackview } from 'src/components/admin-mantenimiento-estacion/admin-mantenimiento-estaciones.component.backview';
import { AdminMantenimientoMembresiasBackview } from 'src/components/admin-mantenimiento-membresia/admin-mantenimiento-membresias.component.backview';
import { AdminMantenimientoProductosBackview } from 'src/components/admin-mantenimiento-productos/admin-mantenimiento-productos.component.backview';
import { AdminMantenimientoProductoPresentacionesBackview } from 'src/components/admin-mantenimiento-productos-presentaciones/admin-mantenimiento-productos-presentaciones.component.backview';
import { ReporteMarcasBackview } from 'src/components/reporte-marcas/reporte-marcas.component.backview';
import { ReporteOrdenBackview } from 'src/components/reporte-orden/reporte-orden.component.backview';
import { ContenidoBackview } from 'src/components/contenido/contenido.component.backview';
import { HistorialRevisionPreciosBackview } from 'src/components/historial-revision-precios/historial-revision-precios.backview';
import { BitacoraBackview } from 'src/components/bitacora/bitacora.component.backview';

const routes: Routes = [
  //{ path: '', loadChildren: () => import('../components/principal/principal.module').then(m => m.PrincipalModule) },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  { path: 'principal', component: PrincipalBackview },
  { path: 'busqueda', component: BusquedaBackview },
  { path: 'detalle-producto', component: DetalleProductoBackview },
  { path: 'carrito', component: CarritoBackview },
  { path: 'mis-ordenes', component: MisOrdenesBackview },
  { path: 'login', component: LoginBackview },
  { path: 'admin-ordenes', component: AdminOrdenesBackview },
  { path: 'admin-detalle-orden', component: AdminDetalleOrdenBackview },
  { path: 'admin-revision-precios', component: AdminRevisionPreciosBackview },
  { path: 'admin-mantenimiento-marcas', component: AdminMantenimientoMarcasBackview },
  { path: 'admin-mantenimiento-familias', component: AdminMantenimientoFamiliasBackview },
  { path: 'admin-mantenimiento-presentaciones', component: AdminMantenimientoPresentacionesBackview },
  { path: 'admin-mantenimiento-parametros', component: AdminMantenimientoParametrosBackview },
  { path: 'admin-mantenimiento-estaciones', component: AdminMantenimientoEstacionesBackview },
  { path: 'admin-mantenimiento-membresias', component: AdminMantenimientoMembresiasBackview },
  { path: 'admin-mantenimiento-productos', component: AdminMantenimientoProductosBackview },
  { path: 'admin-mantenimiento-productos-presentacion', component: AdminMantenimientoProductoPresentacionesBackview },
  { path: 'detalle-orden', component: DetalleOrdenBackview },
  { path: 'orden-sugerida', component: OrdenSugeridaBackview },
  { path: 'reporte-marcas', component: ReporteMarcasBackview },
  { path: 'reporte-orden', component: ReporteOrdenBackview },
  { path: 'contenido', component: ContenidoBackview },
  { path: 'historial-revision-precios', component: HistorialRevisionPreciosBackview },
  { path: 'bitacora', component: BitacoraBackview }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
