/**
 * Crea y exporta una constante equivalente al EnumEstadoOrden del DAL
 */
export const EnumEstadoOrden = {

  BORRADOR: {codigoEstado: 1, nombre: "Borrador"},
  PRICING: {codigoEstado: 2, nombre: "Pricing"},
  ESPERA_APROBACION_SOCIO: {codigoEstado: 3, nombre: "En espera de aprobación por el socio"},
  VERIFICANDO_PAGO: {codigoEstado: 4, nombre: "Verificando pago"},
  PROCESANDO: {codigoEstado: 5, nombre: "Procesando"},
  ENTREGADA_ESTACION: {codigoEstado: 6, nombre: "Entregada en la estación"},
  CANCELADA_ESPECIAL: {codigoEstado: 7, nombre: "Cancelada por situación especial"},
  CANCELADA: {codigoEstado: 8, nombre: "Cancelada"},
  ENTREGADA_PLANTA: {codigoEstado: 9, nombre: "Entregada por la planta"},
  ENVIADA: {codigoEstado: 10, nombre: "Enviada (shipped)"},
  DESALMACENAJE_FISCAL: {codigoEstado: 11, nombre: "En desalmacenaje fiscal"},
  TRANSITO_ESTACION: {codigoEstado: 12, nombre: "En tránsito a la estación"},
}