import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderBackview } from './header.component.backview';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [HeaderBackview],
  imports: [
    CommonModule,
    MatMenuModule,
    MatTooltipModule
  ],
  exports: [
    HeaderBackview
  ]
})
export class HeaderModule { }
