import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { HistorialRevisionPreciosBackview } from './historial-revision-precios.backview';
import { MatFormFieldModule, MatTableModule, MatPaginatorModule, MatInputModule } from '@angular/material';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../footer/footer.module';


@NgModule({
  declarations: [HistorialRevisionPreciosBackview],
  imports: [
    CommonModule,
    FormsModule,
    HeaderModule,
    FooterModule,
    NgxSpinnerModule,
    MatFormFieldModule,
    MatTableModule,
    MatInputModule,
    MatPaginatorModule
  ],
  exports: [
    HistorialRevisionPreciosBackview
  ]
})
export class HistorialRevisionPreciosModule { }
