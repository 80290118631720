import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterBackview } from './footer.component.backview';

@NgModule({
  declarations: [FooterBackview],
  imports: [
    CommonModule
  ],
  exports: [
    FooterBackview
  ]
})
export class FooterModule { }
