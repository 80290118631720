import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ErrorService } from 'src/services/error.service';
import { PageEvent } from '@angular/material/paginator';
import { AdminMantenimientoFamiliasController } from './admin-mantenimiento-familias.component.controller';
import { Familia } from 'src/models/com.enersol.directshopping.dal.entidad/Familia';
import { SubFamilia } from 'src/models/com.enersol.directshopping.dal.entidad/SubFamilia';
import { SubFamiliaId } from 'src/models/com.enersol.directshopping.dal.entidad/SubFamiliaId';

@Component({
  selector: 'app-admin-mantenimiento-familias',
  templateUrl: './admin-mantenimiento-familias.component.html',
  styleUrls: ['./admin-mantenimiento-familias.component.scss'],
  providers: [AdminMantenimientoFamiliasController]
})
export class AdminMantenimientoFamiliasBackview implements OnInit {

  /* Variables para controlar los roles del usuario */
  isAdmin: Boolean;

  /* Variables para controlar las familias */
  listaFamilias: Familia[] = null;
  nuevaFamilia: Familia = new Familia();

  /* Variables para controlar las subfamilias */
  listaSubFamilias: SubFamilia[] = null;
  nuevaSubFamilia: SubFamilia = new SubFamilia();

  /* Variable para los mensajes */
  contenidoMensaje: String;
  tituloMensaje: String;
  esError: Boolean = false;

  /* Variables para la paginacion */
  page_size: number = 10;
  page_number: number = 1;
  pageSizeOptions = [5, 10, 20, 50, 100];


  constructor(
    private router: Router,
    private adminMantenimientosComponent: AdminMantenimientoFamiliasController,
    private errorService: ErrorService,
    private spinner: NgxSpinnerService
  ) {
  }

  async ngOnInit() {
    this.isAdmin = await this.adminMantenimientosComponent.isAdminEnersol();
    await this.getFamilias();
    await this.getSubFamilias();
  }


  /**
  * Función que obtiene las familias registradas
  * @author msemeraro
  */
  async getFamilias() {
    let familias = await this.adminMantenimientosComponent.getFamilias();
    if (!familias.sucess) {
      let mensaje = this.errorService.displayError(familias.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.listaFamilias = familias.data;
    }
  }

  /**
  * Función que obtiene las subfamilias registradas
  * @author msemeraro
  */
  async getSubFamilias() {
    let familias = await this.adminMantenimientosComponent.getSubFamilias();
    if (!familias.sucess) {
      let mensaje = this.errorService.displayError(familias.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.listaSubFamilias = familias.data;
    }
  }

  async eliminarFamilia(familia: Familia) {
    var confirmacion = confirm("¿Desea eliminar la familia " + familia.nombre + "?");
    if (confirmacion) {
      this.spinnerShow();
      let respuesta = await this.adminMantenimientosComponent.eliminarFamilia(familia);
      if (!respuesta.sucess) {
        let mensaje = this.errorService.displayError(respuesta.errors);
        this.adminMantenimientosComponent.setMensaje('Error al eliminar la familia', mensaje, true);
        this.verMensaje();
      }
      else {
        await this.getFamilias();
      }
      this.spinnerHide();
    }
  }

  async actualizarFamilia(familia: Familia) {
    this.spinnerShow();
    let respuesta = await this.adminMantenimientosComponent.actualizarFamilia(familia);
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al actualizar la familia', mensaje, true);
      this.verMensaje();
    }
    else {
      await this.getFamilias();
    }
    this.spinnerHide();
  }

  async agregarFamilia() {
    if (!this.adminMantenimientosComponent.validarNombre(this.nuevaFamilia.nombre)) {
      this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar un nombre válido", true);
      this.verMensaje();
      return;
    }
    if (!this.adminMantenimientosComponent.validarNombre(this.nuevaFamilia.descripcion)) {
      this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar una descripción válida", true);
      this.verMensaje();
      return;
    }
    this.spinnerShow();
    let respuesta = await this.adminMantenimientosComponent.agregarFamilia(this.nuevaFamilia);
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al agregar la familia', mensaje, true);
      this.verMensaje();
    }
    else {
      $(".close").click();
      this.nuevaFamilia.nombre = "";
      await this.getFamilias();
    }
    this.spinnerHide();
  }

  async eliminarSubFamilia(subFamilia: SubFamilia) {
    var confirmacion = confirm("¿Desea eliminar la subFamilia " + subFamilia.nombre + "?");
    if (confirmacion) {
      this.spinnerShow();
      let respuesta = await this.adminMantenimientosComponent.eliminarSubFamilia(subFamilia);
      if (!respuesta.sucess) {
        let mensaje = this.errorService.displayError(respuesta.errors);
        this.adminMantenimientosComponent.setMensaje('Error al eliminar la subFamilia', mensaje, true);
        this.verMensaje();
      }
      else {
        await this.getSubFamilias();
      }
      this.spinnerHide();
    }
  }

  async actualizarSubFamilia(subFamilia: SubFamilia) {
    this.spinnerShow();
    let respuesta = await this.adminMantenimientosComponent.actualizarSubFamilia(subFamilia);
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al actualizar la subFamilia', mensaje, true);
      this.verMensaje();
    }
    else {
      await this.getSubFamilias();
    }
    this.spinnerHide();
  }

  async agregarSubFamilia() {
    if (!this.adminMantenimientosComponent.validarNombre(this.nuevaSubFamilia.nombre)) {
      this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar un nombre válido", true);
      this.verMensaje();
      return;
    }
    if (!this.adminMantenimientosComponent.validarNombre(this.nuevaSubFamilia.descripcion)) {
      this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar una descripción válida", true);
      this.verMensaje();
      return;
    }
    let subFamiliaId = new SubFamiliaId();
    subFamiliaId.codigoFamilia = this.nuevaSubFamilia.familia.codigoFamilia
    this.nuevaSubFamilia.id = subFamiliaId;

    this.spinnerShow();
    let respuesta = await this.adminMantenimientosComponent.agregarSubFamilia(this.nuevaSubFamilia);
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al agregar la subFamilia', mensaje, true);
      this.verMensaje();
    }
    else {
      $(".close").click();
      this.nuevaSubFamilia.nombre = "";
      await this.getSubFamilias();
    }
    this.spinnerHide();
  }

  changeFamilia(codFamilia: number, subFamilia: SubFamilia) {
    for (let familia of this.listaFamilias) {
      if (familia.codigoFamilia == codFamilia) {
        let posicion: number = 0;
        for (let subFamiliaActualizar of this.listaSubFamilias) {
          if (subFamiliaActualizar.id.codigoSubFamilia == subFamilia.id.codigoSubFamilia) {
            this.listaSubFamilias[posicion].familia = familia;
            break;
          }
          posicion++;
        }
      }
    }
  }

  changeFamiliaNueva(codFamilia: number) {
    for (let familia of this.listaFamilias) {
      if (familia.codigoFamilia == codFamilia) {
        this.nuevaSubFamilia.familia = familia;
        break;
      }
    }
  }

  /**
  * Función que navega a otra pagina
  * @author msemeraro
  */
  irPagina(nombre: string) {
    this.router.navigate([nombre]);
  }

  spinnerShow() {
    this.spinner.show();
  }

  spinnerHide() {
    this.spinner.hide();
  }

  /**
  * Función que muestra un mensaje
  * @author msemeraro
  */
  verMensaje() {
    this.tituloMensaje = this.adminMantenimientosComponent.getTituloMensaje();
    this.contenidoMensaje = this.adminMantenimientosComponent.getContenidoMensaje();
    this.esError = this.adminMantenimientosComponent.getEsError();
    var element = document.getElementById("btnAbrirMensaje") as any;
    element.click();
  }

  clickModalMensaje() { }

  clickModalCambioEstado() { }

  handlePage(e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }
}
