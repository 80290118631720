import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ErrorService } from 'src/services/error.service';
import { PageEvent } from '@angular/material/paginator';
import { AdminMantenimientoPresentacionesController } from './admin-mantenimiento-presentaciones.component.controller';
import { Presentacion } from 'src/models/com.enersol.directshopping.dal.entidad/Presentacion';

@Component({
  selector: 'app-admin-mantenimiento-presentaciones',
  templateUrl: './admin-mantenimiento-presentaciones.component.html',
  styleUrls: ['./admin-mantenimiento-presentaciones.component.scss'],
  providers: [AdminMantenimientoPresentacionesController]
})
export class AdminMantenimientoPresentacionesBackview implements OnInit {

  /* Variables para controlar los roles del usuario */
  isAdmin: Boolean;

  listaPresentaciones: Presentacion[] = null;
  nuevaPresentacion: Presentacion = new Presentacion();

  /* Variable para los mensajes */
  contenidoMensaje: String;
  tituloMensaje: String;
  esError: Boolean = false;

  /* Variables para la paginacion */
  page_size: number = 10;
  page_number: number = 1;
  pageSizeOptions = [5, 10, 20, 50, 100];


  constructor(
    private router: Router,
    private adminMantenimientosComponent: AdminMantenimientoPresentacionesController,
    private errorService: ErrorService,
    private spinner: NgxSpinnerService
  ) {
  }

  async ngOnInit() {
    this.isAdmin = await this.adminMantenimientosComponent.isAdminEnersol();
    await this.getPresentaciones();
  }


  /**
  * Función que obtiene las presentaciones registradas
  * @author msemeraro
  */
  async getPresentaciones() {
    let presentaciones = await this.adminMantenimientosComponent.getPresentaciones();
    if (!presentaciones.sucess) {
      let mensaje = this.errorService.displayError(presentaciones.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.listaPresentaciones = presentaciones.data;
    }
  }

  async eliminarPresentacion(presentacion: Presentacion) {
    var confirmacion = confirm("¿Desea eliminar la presentación " + presentacion.descripcion + "?");
    if (confirmacion) {
      this.spinnerShow();
      let respuesta = await this.adminMantenimientosComponent.eliminarPresentacion(presentacion);
      if (!respuesta.sucess) {
        let mensaje = this.errorService.displayError(respuesta.errors);
        this.adminMantenimientosComponent.setMensaje('Error al eliminar la presentacion', mensaje, true);
        this.verMensaje();
      }
      else {
        await this.getPresentaciones();
      }
      this.spinnerHide();
    }
  }

  async actualizarPresentacion(presentacion: Presentacion) {
    this.spinnerShow();
    let respuesta = await this.adminMantenimientosComponent.actualizarPresentacion(presentacion);
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al actualizar la presentacion', mensaje, true);
      this.verMensaje();
    }
    else {
      await this.getPresentaciones();
    }
    this.spinnerHide();
  }

  async agregarPresentacion() {
    this.nuevaPresentacion.orden = 0;
    if (!this.adminMantenimientosComponent.validarNombre(this.nuevaPresentacion.descripcion)) {
      this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar un nombre válido", true);
      this.verMensaje();
      return;
    }
    this.spinnerShow();
    let respuesta = await this.adminMantenimientosComponent.agregarPresentacion(this.nuevaPresentacion);
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al agregar la presentacion', mensaje, true);
      this.verMensaje();
    }
    else {
      $(".close").click();
      this.nuevaPresentacion.descripcion = "";
      await this.getPresentaciones();
    }
    this.spinnerHide();
  }

  /**
  * Función que navega a otra pagina
  * @author msemeraro
  */
  irPagina(nombre: string) {
    this.router.navigate([nombre]);
  }

  spinnerShow() {
    this.spinner.show();
  }

  spinnerHide() {
    this.spinner.hide();
  }

  /**
  * Función que muestra un mensaje
  * @author msemeraro
  */
  verMensaje() {
    this.tituloMensaje = this.adminMantenimientosComponent.getTituloMensaje();
    this.contenidoMensaje = this.adminMantenimientosComponent.getContenidoMensaje();
    this.esError = this.adminMantenimientosComponent.getEsError();
    var element = document.getElementById("btnAbrirMensaje") as any;
    element.click();
  }

  clickModalMensaje() { }

  clickModalCambioEstado() { }

  handlePage(e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }
}
