import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainBackview } from './main.component.backview';

@NgModule({
  declarations: [MainBackview],
  imports: [
    CommonModule
  ],
  exports:[
    MainBackview
  ]
})
export class MainModule { }
