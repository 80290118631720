import { Component, OnInit, ɵConsole, Injectable } from '@angular/core';
/* SERVICES */
import { HttpClientEnersolService } from 'src/services/http-client-enersol.service';
import { GlobalService } from 'src/services/global';
import { ConstantsService } from 'src/services/constants.service';
import { ProductoSugerido } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoSugerido';
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
import { ProductoPresentacion } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoPresentacion';
import { ProductoPorOrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoPorOrdenDeCompra';
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
import { ValidationsService } from 'src/services/validations.service';
import { Parametro } from 'src/models/com.enersol.directshopping.dal.entidad/Parametro';
/* REST MODEL */

/* MODELS */

@Injectable()
export class OrdenSugeridaController {

  urlEnersol: string = "";

  /* Variable con el tipo de cambio */
  tipoCambio:number = 0;

  constructor(
    private httpClientEnersolService: HttpClientEnersolService,
    private globalEnersolService: GlobalService,
    private constantsEnersolService: ConstantsService,
    private validationsEnersolService: ValidationsService
  ) {
    this.urlEnersol = this.constantsEnersolService.getUrlEnersol();
  }


  /* FUNCIONES QUE HACEN PETICIONES A LOS SERVICIOS */

  /**
  * Función obtiene todos los productos a mostrar en la búsqueda
  * @returns ListResponse<ProductoPresentacion>
  * @author msemeraro
  */
  async getProductos(): Promise<ListResponse<ProductoSugerido>> {
    let respuesta: ListResponse<ProductoSugerido>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet<ListResponse<ProductoSugerido>>('ProductoPorOrdenDeCompra/Sugeridos/all');
      respuesta = <ListResponse<ProductoSugerido>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<ProductoSugerido>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      for (let producto of respuesta.data) {
        producto.cantidad = Math.round(producto[1]);
        producto.productoPresentacion = producto[0];
      }
      return respuesta;
    }
  }


  /* FUNCIONES QUE HACEN PETICIONES AL GLOBAL SERVICE */

  /**
    * Función que obtiene el tipo de cambio
    * @return tipoCambio 
    * @author msemeraro
    */
  async getTipoCambio() {
    if(this.tipoCambio == 0){
      await this.globalEnersolService.setTipoCambio();
      let tipoCambio: Parametro = this.globalEnersolService.getTipoCambio();
      this.tipoCambio = Number(tipoCambio.valor); 
    }
    return this.tipoCambio;
  }

  /**
  * Función que almacena en el globalService los datos del producto a mostrar
  * @param producto producto a visualizar
  * @author msemeraro
  */
  verDetalleProducto(producto: ProductoPresentacion, cantidad: number) {
    this.globalEnersolService.setCodProductoSeleccionado(producto.id.codigoProducto);
    this.globalEnersolService.setCodProductoPresentacionSeleccionado(producto.id.codigoProductoPresentacion);
    this.globalEnersolService.setCodPresentacionProductoSeleccionado(producto.id.codigoPresentacion);
    this.globalEnersolService.setCodMarcaProductoSeleccionado(producto.id.codigoMarca);
    this.globalEnersolService.setCantidadProductoSeleccionado(cantidad);
  }

  /**
  * Función que añade un producto a una orden de compra
  * @param producto producto a insertar
  * @param cantidad cantidad de unidades del producto que se quieren insertar
  * @returns Response<ProductoPorOrdenDeCompra>
  * @author msemeraro
  */
  async addCarrito(producto: ProductoPresentacion, cantidad: number): Promise<Response<ProductoPorOrdenDeCompra>> {
    return await this.globalEnersolService.addCarrito(producto, cantidad);
  }

  /**
  * Función que guarda las variables para mostrar un mensaje
  * @param tituloMensaje
  * @param contenidoMensaje
  * @param esError
  * @author msemeraro
  */
  setMensaje(tituloMensaje: String, contenidoMensaje: String, esError: Boolean): void {
    this.globalEnersolService.setTituloMensaje(tituloMensaje);
    this.globalEnersolService.setContenidoMensaje(contenidoMensaje);
    this.globalEnersolService.setEsError(esError);
  }

  /**
  * Función que obtiene el titulo de un mensaje
  * @return tituloMensaje 
  * @author msemeraro
  */
  getTituloMensaje(): String {
    return this.globalEnersolService.getTituloMensaje();
  }

  /**
  * Función que obtiene el contenido de un mensaje
  * @return contenidoMensaje 
  * @author msemeraro
  */
  getContenidoMensaje(): String {
    return this.globalEnersolService.getContenidoMensaje();
  }

  /**
  * Función que obtiene si un mensaje es error o no
  * @return esError 
  * @author msemeraro
  */
  getEsError(): Boolean {
    return this.globalEnersolService.getEsError();
  }

  /* FUNCIONES PARA EL MANEJO DE LOS MONTOS */


  /* FUNCIONES PARA EL FORMATO DE LOS NUMEROS */

  /**
  * Función que formatea un número
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
  getFormatNumber(numero: number) {
    return this.validationsEnersolService.getNumber(numero);
  }

  /* OTRAS FUNCIONES */


}


