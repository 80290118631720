import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { NgxSpinnerService } from 'ngx-spinner';
/* SERVICES */
import { AdminOrdenesController } from './admin-ordenes.component.controller';
import { ErrorService } from 'src/services/error.service';
/* REST MODEL */
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
/* MODELS */
import { EstadoOrden } from 'src/models/com.enersol.directshopping.dal.entidad/EstadoOrden';
import { OrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/OrdenDeCompra';
import { EnumEstadoOrdenInventario } from 'src/models/com.enersol.directshopping.dal.enums/EnumEstadoOrdenInventario';

@Component({
  selector: 'app-admin-ordenes',
  templateUrl: './admin-ordenes.component.html',
  styleUrls: ['./admin-ordenes.component.scss'],
  providers: [AdminOrdenesController]
})
export class AdminOrdenesBackview implements OnInit {

  ordenesDeCompra: Array<OrdenDeCompra>;
  ordenesDeCompraOriginal: Array<OrdenDeCompra>;

  /* Variables para controlar los roles del usuario */
  isAdmin: Boolean;

  /* Variables para controlar las posibles marcas de los detalles de las ordenes a cambiar */
  listaMarcasCambiarEstado = [];

  /* Variable para controlar las ordenes seleccionadas */
  listaOrdenesCambiarEstado: Array<OrdenDeCompra> = [];

  /* Variables para controlar el estado actual y siguientes estados de una marca seleccionada */
  estadoActual = '';
  estadoSeleccionado = '';
  posiblesEstados: ListResponse<EstadoOrden> = new ListResponse<EstadoOrden>(false);
  codigoEstadoSeleccionado = 0;
  marcaSeleccionada = '';

  comentarioCambioEstado = '';

  /* Variable para los mensajes */
  contenidoMensaje: String;
  tituloMensaje: String;
  esError: Boolean;

  /* Variables para la paginacion */
  page_size = 10;
  page_number = 1;
  pageSizeOptions = [5, 10, 20, 50, 100];

  constructor(
    private router: Router,
    private adminOrdenesComponent: AdminOrdenesController,
    private errorService: ErrorService,
    private spinner: NgxSpinnerService
  ) {
  }

  async ngOnInit() {
    this.isAdmin = await this.adminOrdenesComponent.isAdminEnersol();
    await this.getOrdenes();
  }

  /**
   * Función que solicita todas las ordenes del administrador
   * @author msemeraro
   */
  async getOrdenes() {
    const ordenesDeCompra = await this.adminOrdenesComponent.getOrdenes();
    if (!ordenesDeCompra.sucess) {
      const mensaje = this.errorService.displayError(ordenesDeCompra.errors);
      this.adminOrdenesComponent.setMensaje('Error al cargar las órdenes', mensaje, true);
      this.verMensaje();
    } else {
      this.ordenesDeCompra = ordenesDeCompra.data;
      this.ordenesDeCompraOriginal = [];
      for (const entry of this.ordenesDeCompra) {
        entry.totalConImpuesto = entry.total + (entry.total * (entry.impuesto / 100));
        this.ordenesDeCompraOriginal.push(entry);
      }
    }
  }

  /**
   * Función que selecciona o deselecciona todos los productos del carrito
   * @author msemeraro
   */
  seleccionarTodo() {
    let checkboxes = document.getElementsByTagName('input');
    const check = checkboxes[0].checked;
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].type == 'checkbox') {
        checkboxes[i].checked = check;
      }
    }
  }

  /**
  * Función que formatea un número
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
  getFormatNumber(numero: number) {
    return this.adminOrdenesComponent.getFormatNumber(numero);
  }

  /**
  * Función que filtra la lista de ordenes por estado
  * @param codEstado
  * @author msemeraro
  */
  filterEstado(codEstado: number) {
    this.restaurarOrdenes();
    $('#selectOrder').val('-');
    if (codEstado > 0) {
      this.ordenesDeCompra = this.adminOrdenesComponent.filterOrdenesEstado(this.ordenesDeCompra, codEstado);
    }
  }

  /**
   * Filtra las ordenes que son de inventario
   */
  filtrarEstadoInventario(){
    this.filtrarEstadoOrdenInventario(EnumEstadoOrdenInventario.INVENTARIO.codigoEstado);
  }

  /**
   * Filtra las ordenes que son de importación
   */
  filtrarEstadoImportacion(){
    this.filtrarEstadoOrdenInventario(EnumEstadoOrdenInventario.IMPORTACION.codigoEstado);
  }

  /**
  * Función que filtra la lista de ordenes por estado de inventario
  * @param codEstado
  * @author gberrocal
  */
  filtrarEstadoOrdenInventario(codEstadoInventario: number) {
    this.restaurarOrdenes();
    $('#selectOrder').val('-');
    if (codEstadoInventario > 0) {
      this.ordenesDeCompra = this.adminOrdenesComponent.filtrarOrdenesEstadoInventario(this.ordenesDeCompra, codEstadoInventario);
    }
  }

  /**
   * Filtra las ordenes que son marcadas como de inventario
   */
  filtrarMarcaInventario(){
    this.filtrarOrdenesMarca(EnumEstadoOrdenInventario.INVENTARIO.codigoEstado);
  }

  /**
   * Filtra las ordenes que son marcadas como de importación
   */
  filtrarMarcaImportacion(){
    this.filtrarOrdenesMarca(EnumEstadoOrdenInventario.IMPORTACION.codigoEstado);
  }

  /**
  * Función que filtra la lista de ordenes por marcada como
  * @param codEstado
  * @author gberrocal
  */
  filtrarOrdenesMarca(codEstadoInventario: number) {
    this.restaurarOrdenes();
    $('#selectOrder').val('-');
    if (codEstadoInventario > 0) {
      this.ordenesDeCompra = this.adminOrdenesComponent.filtrarOrdenesMarca(this.ordenesDeCompra, codEstadoInventario);
    }
  }

  /**
  * Función que carga la lista de marcas segun las ordenes seleccionadas
  * @author msemeraro
  */
  async cambiarEstado() {
    this.posiblesEstados = new ListResponse<EstadoOrden>(false);
    let elements = document.querySelectorAll('input[type="checkbox"]:checked');
    let checkedElements = Array.prototype.map.call(elements, function(el, i) {
      return el.id;
    });
    if (elements.length < 1) {
      this.adminOrdenesComponent.setMensaje('Error', 'Debe seleccionar al menos una orden', true);
      this.verMensaje();
    } else {
      $('#estadosSiguienteEstado').val(0);
      this.comentarioCambioEstado = '';
      $('#marcasSiguienteEstado').val('-');
      this.estadoSeleccionado = this.estadoActual;
      let element = document.getElementById('btnModalEstado') as any;
      element.click();
      this.listaOrdenesCambiarEstado = [];
      let checkboxes = document.getElementsByTagName('input');
      let i = 0;
      if (checkboxes[0].checked) {
        i = 1;
      }
      for (i; i < checkedElements.length; i++) {
        this.listaOrdenesCambiarEstado.push(this.ordenesDeCompra[checkedElements[i]])
      }
      this.listaMarcasCambiarEstado = await this.adminOrdenesComponent.cambiarEstado(this.listaOrdenesCambiarEstado);
    }
  }

  /**
 * Función que valida que se pueda seleccionar una marca
 * @param marca
 * @author msemeraro
 */
  async onChangeMarca(marca) {
    this.marcaSeleccionada = marca;
    this.codigoEstadoSeleccionado = 0;
    // Valida si se selecciono alguna marca o todas
    if (marca != '-') {
      // Valida si selecciono una marca en especifico
      if (marca != 'all') {
        // Valida si es todos los detalles de esa marca de la lista de ordenes estan en un mismo estado
        if (!this.adminOrdenesComponent.isValidoEstado(this.listaOrdenesCambiarEstado, marca)) {
          this.estadoSeleccionado = this.estadoActual;
          $('#marcasSiguienteEstado').val('-');
          this.adminOrdenesComponent.setMensaje('Error', 'Los productos de la marca ' + marca + ' no se encuentran en un mismo estado', true);
          this.verMensaje();
        } else {
          if (this.adminOrdenesComponent.isMismoTipoOrden(this.listaOrdenesCambiarEstado)) {
            let esOrdenImportacion:boolean = this.listaOrdenesCambiarEstado[0].estadoOrdenInventario.estado == EnumEstadoOrdenInventario.IMPORTACION.nombre;
            const respuesta = await this.getPosiblesEstados(esOrdenImportacion);
            if (!respuesta.sucess) {
              this.estadoSeleccionado = this.estadoActual;
              $('#marcasSiguienteEstado').val('-');
              this.adminOrdenesComponent.setMensaje('Error', 'Los productos de la marca ' + marca + ' se encuentran en un estado no modificable por el administrador', true);
              this.verMensaje();
            }
          }  else {
            this.estadoSeleccionado = this.estadoActual;
            $('#marcasSiguienteEstado').val('-');
            this.posiblesEstados = new ListResponse<EstadoOrden>(false);
            this.adminOrdenesComponent.setMensaje('Error', 'Las órdenes seleccionadas deben ser del mismo tipo (Importación o Inventario)', true);
            this.verMensaje();
          }
        }
      } else {
        if (this.adminOrdenesComponent.isMismoEstado(this.listaOrdenesCambiarEstado)) {
          if (this.adminOrdenesComponent.isMismoTipoOrden(this.listaOrdenesCambiarEstado)) {
            let esOrdenImportacion:boolean = this.listaOrdenesCambiarEstado[0].estadoOrdenInventario.estado == EnumEstadoOrdenInventario.IMPORTACION.nombre;
            const respuesta = await this.getPosiblesEstados(esOrdenImportacion);
            if (!respuesta.sucess) {
              this.estadoSeleccionado = this.estadoActual;
              $('#marcasSiguienteEstado').val('-');
              this.adminOrdenesComponent.setMensaje('Error', 'Los productos de las marca se encuentran en un estado no modificable por el administrador', true);
              this.verMensaje();
            }
          } else {
            this.estadoSeleccionado = this.estadoActual;
            $('#marcasSiguienteEstado').val('-');
            this.posiblesEstados = new ListResponse<EstadoOrden>(false);
            this.adminOrdenesComponent.setMensaje('Error', 'Las órdenes seleccionadas deben ser del mismo tipo (Importación Estándar o Importación Limitada)', true);
            this.verMensaje();
          }
        } else {
          this.estadoSeleccionado = this.estadoActual;
          $('#marcasSiguienteEstado').val('-');
          this.posiblesEstados = new ListResponse<EstadoOrden>(false);
          this.adminOrdenesComponent.setMensaje('Error', 'Los productos de todas las marca no se encuentran en un mismo estado', true);
          this.verMensaje();
        }
      }
    } else {
      this.estadoSeleccionado = this.estadoActual;
      this.posiblesEstados = new ListResponse<EstadoOrden>(false);
    }
  }

  /**
 * Función que carga los posibles estados de una marca de un detalle de orden seleccionada
 * @author msemeraro
 */
  async getPosiblesEstados(esOrdenImportacion:boolean) {
    this.posiblesEstados = await this.adminOrdenesComponent.getPosiblesEstados(esOrdenImportacion);
    if (!this.posiblesEstados.sucess) {
      const mensaje = this.errorService.displayError(this.posiblesEstados.errors);
      this.adminOrdenesComponent.setMensaje('Error al obtener posibles estados', mensaje, true);
      this.verMensaje();
      this.estadoSeleccionado = this.estadoActual;
    } else {
      this.estadoSeleccionado = this.adminOrdenesComponent.nombreEstado;
      // this.estadoActual = this.estadoSeleccionado;
    }
    return this.posiblesEstados;
  }

  /**
 * Función que muestra el estado siguiente de una marca
 * @author msemeraro
 */
  siguienteEstado() {
    if (this.estadoSeleccionado === this.estadoActual) {
      this.estadoSeleccionado = this.posiblesEstados.data[0].nombre;
      this.codigoEstadoSeleccionado = this.posiblesEstados.data[0].codigoEstado;
    } else {
      for (let i = 0; i < this.posiblesEstados.data.length; i++) {
        // let estado of this.posiblesEstados.data){
        if (this.posiblesEstados.data[i].nombre === this.estadoSeleccionado) {
          if ((i + 1) == this.posiblesEstados.data.length) {
            this.estadoSeleccionado = this.estadoActual;
            this.codigoEstadoSeleccionado = 0;
          } else {
            this.estadoSeleccionado = this.posiblesEstados.data[i + 1].nombre;
            this.codigoEstadoSeleccionado = this.posiblesEstados.data[i + 1].codigoEstado;
          }
          break;
        }

      }
    }
  }

  /**
  * Función que controla el cambio de estado
  * @param codigoEstado
  * @author msemeraro
  */
  onChangeEstado(codigoEstado) {
    this.codigoEstadoSeleccionado = codigoEstado;
  }

  /**
  * Función que hace la peticion del cambio de estado a los detalles
  * @author msemeraro
  */
  async modificarEstado() {
    if (this.marcaSeleccionada != '-') {
      this.spinnerShow();
      if (!this.adminOrdenesComponent.validateComentario(this.comentarioCambioEstado)) {
        this.adminOrdenesComponent.setMensaje('Error', 'Debe ingresar un comentario válido', true);
        this.verMensaje();
      } else {
        if (this.marcaSeleccionada != 'all') {
          await this.adminOrdenesComponent.updateEstadosDetallesOrdenes(this.listaOrdenesCambiarEstado, this.marcaSeleccionada, this.codigoEstadoSeleccionado, this.comentarioCambioEstado);
        } else {
          for (const marca of this.listaMarcasCambiarEstado) {
            await this.adminOrdenesComponent.updateEstadosDetallesOrdenes(this.listaOrdenesCambiarEstado, marca, this.codigoEstadoSeleccionado, this.comentarioCambioEstado);
          }
        }
        this.getOrdenes();
        let checkboxes = document.getElementsByTagName('input');
        checkboxes[0].checked = false;
        this.seleccionarTodo();
        this.comentarioCambioEstado = '';
        $('#estadosSiguienteEstado').val(0);
        $('.close').click();
      }
      this.spinnerHide();
    }
  }

  /**
  * Función que ordena la lista de productos mostrada actualmente
  * @param ordenamiento indica si ordena de Mayor a menor o alrevés
  * @author msemeraro
  */
  orderBy(parametro: string) {
    if (parametro == 'fecha') {
      this.ordenesDeCompra = this.adminOrdenesComponent.orderBy(parametro, this.ordenesDeCompra, 'Menor');
    }
    else if (parametro == 'total' || parametro == 'estacion') {
      this.ordenesDeCompra = this.adminOrdenesComponent.orderBy(parametro, this.ordenesDeCompra, 'Mayor');
      this.ordenesDeCompra = this.adminOrdenesComponent.filterOrdenesOrderBy(this.ordenesDeCompra);
    } else {
      this.restaurarOrdenes();
 }
  }

  /**
  * Función que vuelve la lista de ordenes orginal (sin filtros)
  * @author msemeraro
  */
  restaurarOrdenes() {
    this.ordenesDeCompra = [];
    for (const entry of this.ordenesDeCompraOriginal) {
      this.ordenesDeCompra.push(entry);
    }
  }

  /**
  * Función que muestra el detalle de una orden
  * @author msemeraro
  */
  async verOrden(orden) {
    await this.adminOrdenesComponent.verDetalleOrden(orden);
    this.router.navigate(['admin-detalle-orden']);
  }

  /**
   * Indica si la orden de compra es de importación o no
   * @param orden Orden a evaluar
   * @returns True si es importación, false si no
   * @author gberrocal
   */
  isOrdenTipoImportacion(orden: OrdenDeCompra): boolean{
    return (orden && orden.estadoOrdenInventario.id === EnumEstadoOrdenInventario.IMPORTACION.codigoEstado);
  }

  /**
   * Indica si la orden de compra está marcada como de inventario
   * @param orden Orden a evaluar
   * @returns True si es marcada como inventario, false si no
   * @author gberrocal
   */
  isMarcadaInventario(orden: OrdenDeCompra): boolean{
    return this.isMarcadaComo(orden, EnumEstadoOrdenInventario.INVENTARIO.codigoEstado);
  }

  /**
   * Indica si la orden de compra está marcada como de importación
   * @param orden Orden a evaluar
   * @returns True si es marcada como importación, false si no
   * @author gberrocal
   */
  isMarcadaImportacion(orden: OrdenDeCompra): boolean{
    return this.isMarcadaComo(orden, EnumEstadoOrdenInventario.IMPORTACION.codigoEstado);
  }

  /**
   * Indica si la orden de compra está marcada como de un tipo especifico
   * @param orden Orden a evaluar
   * @param codigoEstadoOrdenInventario codigo del tipo a evaluar
   * @returns True si es marcada como importación, false si no
   * @author gberrocal
   */
  isMarcadaComo(orden: OrdenDeCompra, codigoEstadoOrdenInventario: number): boolean{
    if(orden && orden.marcadaComo) {
      if(orden.marcadaComo.id === codigoEstadoOrdenInventario) {
        return true;
      }
    }
    return false;
  }

  /**
   * Controla la selección del valor del select de filtro para pantallas pequeñas
   * @param value Valor seleccionado
   */
  onChangeSelXS(value: number){
    // Filtros por estado de orden
    if(value >= 0 && value <100) {
      this.filterEstado(value);
    }
    // Filtros por estado de inventario
    else if(value >= 100 && value <200) {
      if(value == 100){
        this.filtrarEstadoInventario();
      }
      else if(value == 101){
        this.filtrarEstadoImportacion();
      }
    }
    // Filtros por marcas
    else if(value >= 200 && value <300) {
      if(value == 200){
        this.filtrarMarcaInventario();
      }
      else if(value == 201){
        this.filtrarMarcaImportacion();
      }
    }
  }

  /**
  * Función que navega a otra pagina
  * @author msemeraro
  */
  irPagina(nombre: string) {
    this.router.navigate([nombre]);
  }

  spinnerShow() {
    this.spinner.show();
  }

  spinnerHide() {
    this.spinner.hide();
  }

  /**
  * Función que muestra un mensaje
  * @author msemeraro
  */
  verMensaje() {
    this.tituloMensaje = this.adminOrdenesComponent.getTituloMensaje();
    this.contenidoMensaje = this.adminOrdenesComponent.getContenidoMensaje();
    this.esError = this.adminOrdenesComponent.getEsError();
    let element = document.getElementById('btnAbrirMensaje') as any;
    element.click();
  }

  clickModalMensaje() { }

  clickModalCambioEstado() { }

  handlePage(e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }
}
