import { Component, OnInit, ɵConsole, Injectable } from '@angular/core';
/* SERVICES */
import { HttpClientEnersolService } from 'src/services/http-client-enersol.service';
import { GlobalService } from 'src/services/global';
import { ValidationsService } from 'src/services/validations.service';
/* REST MODEL */
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
/* MODELS */
import { Producto } from 'src/models/com.enersol.directshopping.dal.entidad/Producto';
import { Marca } from 'src/models/com.enersol.directshopping.dal.entidad/Marca';
import { Membresia } from 'src/models/com.enersol.directshopping.dal.entidad/Membresia';
import { SubFamilia } from 'src/models/com.enersol.directshopping.dal.entidad/SubFamilia';
import { ProductoId } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoId';
import { Familia } from 'src/models/com.enersol.directshopping.dal.entidad/Familia';
import { DatosInventarioProducto } from 'src/models/com.enersol.directshopping.bl.model/DatosInventarioProducto';
import { ResultadoRevisionDatosInventarioProducto } from 'src/models/com.enersol.directshopping.bl.model/ResultadoRevisionDatosInventarioProducto';

@Injectable()
export class AdminMantenimientoProductosController {


  constructor(
    private httpClientEnersolService: HttpClientEnersolService,
    private globalEnersolService: GlobalService,
    private validationEnersolService: ValidationsService
  ) { }


  /* FUNCIONES QUE HACEN PETICIONES A LOS SERVICIOS */

  /**
  * Función que obtiene las marcas registradas
  * @returns ListResponse<Marca>
  * @author msemeraro
  */
  async getMarcas(): Promise<ListResponse<Marca>> {
    let respuesta: ListResponse<Marca>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('Marca/all');
      respuesta = <ListResponse<Marca>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<Marca>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que obtiene las familias registradas
  * @returns ListResponse<Familia>
  * @author msemeraro
  */
 async getFamilias(): Promise<ListResponse<Familia>> {
  let respuesta: ListResponse<Familia>;
  try {
    let promiseResponse = await this.httpClientEnersolService.httpGet('Familia/all');
    respuesta = <ListResponse<Familia>>promiseResponse;
  }
  catch (error) {
    console.log(error.message);
    respuesta = new ListResponse<Familia>(false);
    respuesta.addError('code', 'techMessage', 'userMessage');
  }
  finally {
    return respuesta;
  }
}

  /**
  * Función que obtiene laos productos de una marca
  * @returns ListResponse<Producto>
  * @author msemeraro
  */
  async getProductosMarca(codMarca: number): Promise<ListResponse<Producto>> {
    let respuesta: ListResponse<Producto>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('Producto/marca?codMarca=' + codMarca);
      respuesta = <ListResponse<Producto>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<Producto>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que obtiene las membresias registradas
  * @returns ListResponse<Membresia>
  * @author msemeraro
  */
  async getMembresias(): Promise<ListResponse<Membresia>> {
    let respuesta: ListResponse<Membresia>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('Membresia/all');
      respuesta = <ListResponse<Membresia>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<Membresia>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que obtiene las membresias registradas
  * @returns ListResponse<SubFamilia>
  * @author msemeraro
  */
  async getSubFamiliasFamilia(codFamilia: number): Promise<ListResponse<SubFamilia>> {
    let respuesta: ListResponse<SubFamilia>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('SubFamilia/familia?codFamilia=' + codFamilia);
      respuesta = <ListResponse<SubFamilia>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<SubFamilia>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función que obtiene las productos registradas
    * @returns ListResponse<Producto>
    * @author msemeraro
    */
  async getProductos(): Promise<ListResponse<Producto>> {
    let respuesta: ListResponse<Producto>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('Producto/all');
      respuesta = <ListResponse<Producto>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<Producto>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función que actualiza una producto
    * @returns ListResponse<Producto>
    * @author msemeraro
    */
  async actualizarProducto(producto: Producto): Promise<Response<Producto>> {
    let respuesta: Response<Producto>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('Producto/update', producto);
      respuesta = <Response<Producto>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Producto>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función que hace la peticion al servidor de eliminar una producto
    * @returns Response<Boolean>
    * @author msemeraro
    */
  async eliminarProducto(producto: Producto): Promise<Response<Boolean>> {
    let respuesta: Response<Boolean>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('Producto/delete', producto);
      respuesta = <Response<Boolean>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Boolean>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función que hace la peticion al servidor de agregar una producto
    * @returns Response<Producto>
    * @author msemeraro
    */
  async agregarProducto(producto: Producto): Promise<Response<Producto>> {
    let respuesta: Response<Producto>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('Producto/insert', producto);
      respuesta = <Response<Producto>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Producto>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
   * Revisa que los productos leidos del Excel de inventario tengan correspondencia o no en base de datos
   * @param datos Productos a revisar
   * @returns Objeto con los productos que tienen correspondencia y los que no
   * @author gberrocal
   */
  async revisarDatosInventarioProducto(datos: DatosInventarioProducto[]): Promise<Response<ResultadoRevisionDatosInventarioProducto>> {
    let respuesta: Response<ResultadoRevisionDatosInventarioProducto>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('ProductoPresentacion/revisionDatosInventario', datos);
      respuesta = <Response<ResultadoRevisionDatosInventarioProducto>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<ResultadoRevisionDatosInventarioProducto>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  
  /**
   * Aplica los datos en inventario de los productos leidos del Excel
   * @param datos Productos a actualizar
   * @returns Boolean indicando el resultado de la actualización
   * @author gberrocal
   */
  async actualizarDatosInventario(datos: DatosInventarioProducto[]): Promise<Response<Boolean>> {
    let respuesta: Response<Boolean>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('ProductoPresentacion/actualizarDatosInventario', datos);
      respuesta = <Response<Boolean>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Boolean>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /* FUNCIONES QUE HACEN PETICIONES AL GLOBAL SERVICE */
  async verProducto(codProducto: number, codMarca: number) {
    this.globalEnersolService.setCambioPresentacion(true);
    let productoId = new ProductoId();
    productoId.codigoMarca = codMarca;
    productoId.codigoProducto = codProducto;
    await this.globalEnersolService.setProductoId(productoId);
  }

  /**
    * Función que obtiene si el usuario logueado es administrador de enersol
    * @return boolean 
    * @author msemeraro
    */
  isAdminEnersol() {
    return this.globalEnersolService.isAdminEnersol();
  }

  /* FUNCIONES QUE HACEN ORDENAMIENTOS O FILTROS */

  /**
    * Función que valida si un archivo es un PDF
    * @param file
    * @returns boolean
    * @author msemeraro
    */
  validatePDF(file: File): boolean {
    return this.validationEnersolService.validateFilePDF(file);
  }

  /**
  * Función que valida si un archivo es un XLSX
  * @param file
  * @returns boolean
  * @author gberrocal
  */
  validateXLSX(file: File): boolean {
    return this.validationEnersolService.validateFileXLSX(file);
  }

  /**
    * Función que valida el nombre de la nueva producto
    * @param nombre 
    * @author msemeraro
    */
  validarNombre(nombre: string): Boolean {
    return this.validationEnersolService.validateGeneral(nombre);
  }

  /**
    * Función que guarda las variables para mostrar un mensaje
    * @param tituloMensaje
    * @param contenidoMensaje
    * @param esError
    * @author msemeraro
    */
  setMensaje(tituloMensaje: String, contenidoMensaje: String, esError: Boolean): void {
    this.globalEnersolService.setTituloMensaje(tituloMensaje);
    this.globalEnersolService.setContenidoMensaje(contenidoMensaje);
    this.globalEnersolService.setEsError(esError);
  }

  /**
    * Función que obtiene el titulo de un mensaje
    * @return tituloMensaje 
    * @author msemeraro
    */
  getTituloMensaje(): String {
    return this.globalEnersolService.getTituloMensaje();
  }

  /**
    * Función que obtiene el contenido de un mensaje
    * @return contenidoMensaje 
    * @author msemeraro
    */
  getContenidoMensaje(): String {
    return this.globalEnersolService.getContenidoMensaje();
  }

  /**
    * Función que obtiene si un mensaje es error o no
    * @return esError 
    * @author msemeraro
    */
  getEsError(): Boolean {
    return this.globalEnersolService.getEsError();
  }

}
