import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from './constants.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class HttpClientEnersolService {

  private urlEnersolRS: string;

  constructor(
    private httpClient: HttpClient,
    private constantsService: ConstantsService,
    private logService: LogService
  ) {
    this.urlEnersolRS = this.constantsService.getUrlEnersolRS();

  }

  httpGet<T>(path: string) {
    //Construye la url
    let url: string = this.urlEnersolRS + path;

    //Crea la promesa
    let promise = new Promise((resolve, reject) => {

      this.httpClient.get<T>(url)
        .toPromise().then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          });
    });
    return promise;
  }

  httpPost<T>(path: string, data) {
    //Construye la url
    let url: string = this.urlEnersolRS + path;

    let promise = new Promise((resolve, reject) => {

      this.httpClient.post<T>(url, data)
        .toPromise().then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          });
    });
    return promise;
  }

}
