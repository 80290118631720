
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Workbook, Worksheet, Row, Cell } from 'exceljs';
import { ReporteEstacionesMarcas } from 'src/models/com.enersol.directshopping.dal.entidad/ReporteEstacionesMarcas';
import { ReporteEstacionesOrdenes } from 'src/models/com.enersol.directshopping.dal.entidad/ReporteEstacionesOrdenes';
import { DatosInventarioProducto } from 'src/models/com.enersol.directshopping.bl.model/DatosInventarioProducto';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';


const EXCEL_EXTENSION = '.xlsx';

@Injectable({
    providedIn: 'root'
})
export class ExcelService {

    constructor() {

    }

    public exportAsExcelFile(reportesEstacionesMarcas: ReporteEstacionesMarcas[], excelFileName: string): void {
        /*const worksheet: XLSX.WorkSheet = this.reporteEstacionesMarcasToJson(reportesEstacionesMarcas);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };*/
        this.reporteEstacionesMarcasToJson(reportesEstacionesMarcas);
        /*const workbook: XLSX.WorkBook = this.reporteEstacionesMarcasToJson(reportesEstacionesMarcas);
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);*/
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    async reporteEstacionesMarcasToJson(reportesEstacionesMarcas: ReporteEstacionesMarcas[]) {
        const Excel = require('exceljs');
        let workbook = new Excel.Workbook();
        let worksheet = workbook.addWorksheet('Reporte');
        let i: number = 1; // contador de las filas
        for (let reporteEstacionesMarcas of reportesEstacionesMarcas) {
            if (reporteEstacionesMarcas.productosPorMarca.length != 0) {
                worksheet.getRow(i).font = { name: 'Arial', family: 4, size: 18, bold: true };
                let j: number = 1; // contador de las columnas
                worksheet.getRow(i).getCell(j).value = reporteEstacionesMarcas.estacion.codigoRecope;
                // worksheet.getRow(i).getCell(j).fill = {
                //     type: 'solid',
                //     fgColor: { argb: 'FF0093ff' }
                // };
                worksheet.getRow(i).getCell(j).width = 24;
                j++;
                worksheet.getRow(i).getCell(j).value = reporteEstacionesMarcas.estacion.nombre;
                // worksheet.getRow(i).getCell(j).fill = {
                //     type: 'solid',
                //     fgColor: { argb: 'FF0093ff' }
                // };
                worksheet.getRow(i).getCell(j).width = 45;
                j++;
                worksheet.getRow(i).getCell(j).value = reporteEstacionesMarcas.estacion.membresia.nombre;
                // worksheet.getRow(i).getCell(j).fill = {
                //     type: 'solid',
                //     fgColor: { argb: 'FF0093ff' }
                // };
                worksheet.getRow(i).getCell(j).width = 15;
                j = 1; i++;
                for (let reporteMarcasProductos of reporteEstacionesMarcas.productosPorMarca) {
                    worksheet.getRow(i).font = { name: 'Arial', family: 4, size: 16 };
                    worksheet.getRow(i).height = 24;
                    worksheet.getRow(i).getCell(j).value = reporteMarcasProductos.marca.nombre;
                    j = 1; i++;
                    worksheet.getRow(i).getCell(j).value = "Codigo";
                    worksheet.getRow(i).height = 24;
                    j++;
                    worksheet.getRow(i).getCell(j).value = "Nombre";
                    worksheet.getRow(i).height = 24;
                    j++;
                    worksheet.getRow(i).getCell(j).value = "Cantidad de Empaques";
                    worksheet.getRow(i).getCell(j).width = 15;
                    worksheet.getRow(i).height = 24;
                    j++;
                    worksheet.getRow(i).getCell(j).value = "Cantidad de Órdenes";
                    worksheet.getRow(i).getCell(j).width = 20;
                    worksheet.getRow(i).height = 24;
                    j++;
                    worksheet.getRow(i).getCell(j).value = "SubTotal";
                    worksheet.getRow(i).getCell(j).width = 7;
                    worksheet.getRow(i).height = 24;
                    j = 1; i++;
                    for (let producto of reporteMarcasProductos.productos) {
                        worksheet.getRow(i).height = 24;
                        worksheet.getRow(i).getCell(j).value = producto.productoPresentacion.codigoEnersol;
                        j++;
                        worksheet.getRow(i).getCell(j).value = producto.productoPresentacion.nombre;
                        j++;
                        worksheet.getRow(i).getCell(j).value = producto.cantidad;
                        worksheet.getRow(i).getCell(j).width = 15;
                        j++;
                        worksheet.getRow(i).getCell(j).value = producto.cantidadOrdenes;
                        worksheet.getRow(i).getCell(j).width = 20;
                        j++;
                        worksheet.getRow(i).getCell(j).value = producto.promedio;
                        worksheet.getRow(i).getCell(j).width = 7;
                        j = 1; i++;
                    }
                }
                i++; i++;
            }

        }
        let excelBuffer: any;
        await workbook.xlsx.writeBuffer()
            .then(function (buffer) {
                excelBuffer = buffer;
            });

        this.saveAsExcelFile(excelBuffer, "test");

        return workbook;

    }


    async reporteEstacionesOrdenesToJson(reportesEstacionesOrdenes: ReporteEstacionesOrdenes[]) {
        const Excel = require('exceljs');
        let workbook = new Excel.Workbook();
        let worksheet = workbook.addWorksheet('Reporte Ordenes');
        let i: number = 1; // contador de las filas
        console.log(reportesEstacionesOrdenes);
        for (let reporteOrdenes of reportesEstacionesOrdenes) {
            if (reporteOrdenes.ordenes.length != 0) {
                worksheet.getRow(i).font = { name: 'Arial', family: 4, size: 14, bold: true };
                let j: number = 1; // contador de las columnas
                worksheet.getRow(i).getCell(j).value = reporteOrdenes.estacion.codigoRecope;
                worksheet.getRow(i).getCell(j).width = 24;
                worksheet.getRow(i).height = 24;
                j++;
                worksheet.getRow(i).getCell(j).value = reporteOrdenes.estacion.nombre;
                worksheet.getRow(i).getCell(j).width = 42;
                worksheet.getRow(i).height = 24;
                j = 1; i++;
                worksheet.getRow(i).font = { name: 'Arial', family: 4, size: 13 };
                worksheet.getRow(i).getCell(j).value = "Codigo de Orden";
                worksheet.getRow(i).height = 24;
                j++;
                worksheet.getRow(i).getCell(j).value = "Estacion";
                worksheet.getRow(i).height = 24;
                j++;
                worksheet.getRow(i).getCell(j).value = "Comentario";
                worksheet.getRow(i).getCell(j).width = 40;
                worksheet.getRow(i).height = 24;
                j++;
                worksheet.getRow(i).getCell(j).value = "Fecha";
                worksheet.getRow(i).getCell(j).width = 20;
                worksheet.getRow(i).height = 24;
                j++;
                worksheet.getRow(i).getCell(j).value = "Total";
                worksheet.getRow(i).getCell(j).width = 20;
                worksheet.getRow(i).height = 24;
                j = 1; i++;
                for (let orden of reporteOrdenes.ordenes) {
                    worksheet.getRow(i).getCell(j).value = orden.codigoOrden;
                    worksheet.getRow(i).height = 24;
                    j++;
                    worksheet.getRow(i).getCell(j).value = orden.estacion.nombre;
                    worksheet.getRow(i).height = 24;
                    j++;
                    worksheet.getRow(i).getCell(j).value = orden.comentario;
                    worksheet.getRow(i).height = 24;
                    j++;
                    worksheet.getRow(i).getCell(j).value = (new Date(orden.fechaAprobacionParaPricing)).toLocaleString();
                    worksheet.getRow(i).height = 24;
                    j++;
                    worksheet.getRow(i).getCell(j).value = orden.total;
                    worksheet.getRow(i).height = 24;
                    j++;
                    j = 1; i++;
                }
                i++; i++;
            }
        }
        let excelBuffer: any;
        await workbook.xlsx.writeBuffer()
            .then(function (buffer) {
                excelBuffer = buffer;
            });

        this.saveAsExcelFile(excelBuffer, "test");

        return workbook;

    }

  /**
   * Lee un archivo Excel (XLSX) y pasa los datos a una lista
   * @param file 
   */
  leerArchivoInventarioProductos(file: File): Promise<any> {
    let fileReader = new FileReader();    
    fileReader.readAsArrayBuffer(file);
    let arrayBuffer;
    let filas: any[];
    let promise = new Promise((resolve, reject) => {
      fileReader.onload = (e) => {
        // Lee el archivo
        arrayBuffer = fileReader.result;
        var data = new Uint8Array(arrayBuffer);
        var arr = new Array();
        for(var i = 0; i != data.length; ++i) 
          arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, {type:"binary"});
        var first_sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[first_sheet_name];
        filas = XLSX.utils.sheet_to_json(worksheet,{raw:true});
        // retorna el valor
        resolve(filas);
      }
    })
    return promise;
  }


  /**
   * Lee un archivo Excel (XLSX) y pasa los datos a una lista
   * @param filas Filas ya leidas del archivo Excel
   */
  validarFormatoFilasInventarioProductos(filas): string {
    for (let celda of Object.keys(filas)) {
      var campo = filas[celda];

      if(campo.CODIGO_ENERSOL == null || campo.CODIGO_ENERSOL === undefined
        || campo.CANTIDAD_CAJAS_EN_INVENTARIO == null || campo.CANTIDAD_CAJAS_EN_INVENTARIO === undefined
        || campo.CANTIDAD_CAJAS_PARA_IMPORTACION == null || campo.CANTIDAD_CAJAS_PARA_IMPORTACION === undefined
        || campo.MANEJAR_POR_INVENTARIO == null || campo.MANEJAR_POR_INVENTARIO === undefined
        || campo.ESTADO_INVENTARIO == null || campo.ESTADO_INVENTARIO === undefined){
          return "Las columnas no tienen el formato esperado, o tiene celdas vacías.";
      }
    }

    return null;
  }

  /**
   * Tomas las filas leidas de un Excel (XLSX) y pasa los datos a una lista
   * @param filas Filas leidas del Excel
   */
  filasInventarioProductosToList(filas):DatosInventarioProducto[] {
    let lista: DatosInventarioProducto[] = [];
    let elemento: DatosInventarioProducto;
    for (let celda of Object.keys(filas)) {
      var campo = filas[celda];
      elemento = new DatosInventarioProducto();
      elemento.codigoEnersol = campo.CODIGO_ENERSOL;
      elemento.cantidadInventario = campo.CANTIDAD_CAJAS_EN_INVENTARIO;
      elemento.cantidadImportacion = campo.CANTIDAD_CAJAS_PARA_IMPORTACION;
      elemento.manejarPorInventario = campo.MANEJAR_POR_INVENTARIO;
      elemento.estadoInventario = campo.ESTADO_INVENTARIO;
      
      lista.push(elemento);
    }

    return lista;
  }


}