import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ErrorService } from 'src/services/error.service';
import { PageEvent } from '@angular/material/paginator';
import { AdminMantenimientoMembresiasController } from './admin-mantenimiento-membresias.component.controller';
import { Membresia } from 'src/models/com.enersol.directshopping.dal.entidad/Membresia';

@Component({
  selector: 'app-admin-mantenimiento-membresias',
  templateUrl: './admin-mantenimiento-membresias.component.html',
  styleUrls: ['./admin-mantenimiento-membresias.component.scss'],
  providers: [AdminMantenimientoMembresiasController]
})
export class AdminMantenimientoMembresiasBackview implements OnInit {

  /* Variables para controlar los roles del usuario */
  isAdmin: Boolean;

  listaMembresias: Membresia[] = null;
  nuevaMembresia: Membresia = new Membresia();

  /* Variable para los mensajes */
  contenidoMensaje: String;
  tituloMensaje: String;
  esError: Boolean = false;

  /* Variables para la paginacion */
  page_size: number = 10;
  page_number: number = 1;
  pageSizeOptions = [5, 10, 20, 50, 100];


  constructor(
    private router: Router,
    private adminMantenimientosComponent: AdminMantenimientoMembresiasController,
    private errorService: ErrorService,
    private spinner: NgxSpinnerService
  ) {
  }

  async ngOnInit() {
    this.isAdmin = await this.adminMantenimientosComponent.isAdminEnersol();
    await this.getMembresias();
  }


  /**
  * Función que obtiene las membresias registradas
  * @author msemeraro
  */
  async getMembresias() {
    let membresias = await this.adminMantenimientosComponent.getMembresias();
    if (!membresias.sucess) {
      let mensaje = this.errorService.displayError(membresias.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.listaMembresias = membresias.data;
    }
  }

  async eliminarMembresia(membresia: Membresia) {
    var confirmacion = confirm("¿Desea eliminar la membresía " + membresia.nombre + "?");
    if (confirmacion) {
      this.spinnerShow();
      let respuesta = await this.adminMantenimientosComponent.eliminarMembresia(membresia);
      if (!respuesta.sucess) {
        let mensaje = this.errorService.displayError(respuesta.errors);
        this.adminMantenimientosComponent.setMensaje('Error al eliminar la membresia', mensaje, true);
        this.verMensaje();
      }
      else {
        await this.getMembresias();
      }
      this.spinnerHide();
    }

  }

  async actualizarMembresia(membresia: Membresia) {
    this.spinnerShow();
    let respuesta = await this.adminMantenimientosComponent.actualizarMembresia(membresia);
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al actualizar la membresia', mensaje, true);
      this.verMensaje();
    }
    else {
      await this.getMembresias();
    }
    this.spinnerHide();
  }

  async agregarMembresia() {
    this.nuevaMembresia.fotografia = "";
    if (!this.adminMantenimientosComponent.validarNombre(this.nuevaMembresia.nombre)) {
      this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar un nombre válido", true);
      this.verMensaje();
      return;
    }
    if (!this.adminMantenimientosComponent.validarNombre(this.nuevaMembresia.nivel.toString())) {
      this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar un nivel válido", true);
      this.verMensaje();
      return;
    }
    if (!this.adminMantenimientosComponent.validarNombre(this.nuevaMembresia.descripcion)) {
      this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar una descripción válida", true);
      this.verMensaje();
      return;
    }

    this.spinnerShow();
    let respuesta = await this.adminMantenimientosComponent.agregarMembresia(this.nuevaMembresia);
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al agregar la membresia', mensaje, true);
      this.verMensaje();
    }
    else {
      $(".close").click();
      this.nuevaMembresia.nombre = "";
      await this.getMembresias();
    }
    this.spinnerHide();
  }

  /**
  * Función que navega a otra pagina
  * @author msemeraro
  */
  irPagina(nombre: string) {
    this.router.navigate([nombre]);
  }

  spinnerShow() {
    this.spinner.show();
  }

  spinnerHide() {
    this.spinner.hide();
  }

  /**
  * Función que muestra un mensaje
  * @author msemeraro
  */
  verMensaje() {
    this.tituloMensaje = this.adminMantenimientosComponent.getTituloMensaje();
    this.contenidoMensaje = this.adminMantenimientosComponent.getContenidoMensaje();
    this.esError = this.adminMantenimientosComponent.getEsError();
    var element = document.getElementById("btnAbrirMensaje") as any;
    element.click();
  }

  clickModalMensaje() { }

  clickModalCambioEstado() { }

  handlePage(e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }
}
