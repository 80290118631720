import { Component, OnInit, ɵConsole, Injectable } from '@angular/core';
/* SERVICES */
import { HttpClientEnersolService } from 'src/services/http-client-enersol.service';
import { GlobalService } from 'src/services/global';
import { ValidationsService } from 'src/services/validations.service';
import { ConstantsService } from 'src/services/constants.service';
/* REST MODEL */
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
/* MODELS */
import { ProductoPresentacion } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoPresentacion';
import { Presentacion } from 'src/models/com.enersol.directshopping.dal.entidad/Presentacion';
import { ProductoPorOrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoPorOrdenDeCompra';
import { Parametro } from 'src/models/com.enersol.directshopping.dal.entidad/Parametro';

@Injectable()
export class DetalleProductoController {

  urlEnersol: string = "";

  constructor(
    private httpClientEnersolService: HttpClientEnersolService,
    private globalEnersolService: GlobalService,
    private validationsEnersolService: ValidationsService,
    private constantsEnersolService: ConstantsService
  ) {
    this.urlEnersol = this.constantsEnersolService.getUrlEnersol();

  }

  /* FUNCIONES QUE HACEN PETICIONES A LOS SERVICIOS */

  /**
  * Función obtiene el producto a mostrar
  * @returns Response<ProductoPresentacion>
  * @author msemeraro
  */
  async getProductoPresentacion(): Promise<Response<ProductoPresentacion>> {
    let path = this.getPathProductoPresentacion();
    let respuesta: Response<ProductoPresentacion>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet<Response<ProductoPresentacion>>(path);
      respuesta = <Response<ProductoPresentacion>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<ProductoPresentacion>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      respuesta.data = await this.getPreciosProducto(respuesta.data);
      return respuesta;
    }
  }

  /**
  * Función obtiene las presentaciones disponibles del producto a mostrar
  * @returns ListResponse<Presentacion>
  * @author msemeraro
  */
  async getProductoPresentaciones(): Promise<ListResponse<Presentacion>> {
    let path = this.getPathProductoPresentaciones();
    let respuesta: ListResponse<Presentacion>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet<ListResponse<Presentacion>>(path);
      respuesta = <ListResponse<Presentacion>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<Presentacion>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }


  /* FUNCIONES QUE HACEN PETICIONES AL GLOBAL SERVICE */

  /**
  * Función que obtiene la cantidad del producto seleccionado
  * @author msemeraro
  */
  getCantidadProducto() {
    return this.globalEnersolService.getCantidadProductoSeleccionado();
  }

  /**
  * Función que actualiza las variables para mostrar otra presentacion
  * @param producto id del producto presentacion concatenado
  * @author msemeraro
  */
  verOtraPresentacion(producto) {
    var splitted = producto.split(",", 4);
    this.globalEnersolService.setCodProductoSeleccionado(splitted[1]);
    this.globalEnersolService.setCodProductoPresentacionSeleccionado(splitted[0]);
    this.globalEnersolService.setCodPresentacionProductoSeleccionado(splitted[2]);
    this.globalEnersolService.setCodMarcaProductoSeleccionado(splitted[3]);
  }

  /**
  * Función que añade un producto a una orden de compra
  * @param producto producto a insertar
  * @param cantidad cantidad de unidades del producto que se quieren insertar
  * @returns Response<ProductoPorOrdenDeCompra>
  * @author msemeraro
  */
  async addCarrito(producto: ProductoPresentacion, cantidad: number): Promise<Response<ProductoPorOrdenDeCompra>> {
    if (cantidad > 0) {
      return await this.globalEnersolService.addCarrito(producto, cantidad);
    }
  }

  /**
  * Función que guarda las variables para mostrar un mensaje
  * @param tituloMensaje
  * @param contenidoMensaje
  * @param esError
  * @author msemeraro
  */
  setMensaje(tituloMensaje: String, contenidoMensaje: String, esError: Boolean): void {
    this.globalEnersolService.setTituloMensaje(tituloMensaje);
    this.globalEnersolService.setContenidoMensaje(contenidoMensaje);
    this.globalEnersolService.setEsError(esError);
  }

  /**
  * Función que obtiene el titulo de un mensaje
  * @return tituloMensaje 
  * @author msemeraro
  */
  getTituloMensaje(): String {
    return this.globalEnersolService.getTituloMensaje();
  }

  /**
  * Función que obtiene el contenido de un mensaje
  * @return contenidoMensaje 
  * @author msemeraro
  */
  getContenidoMensaje(): String {
    return this.globalEnersolService.getContenidoMensaje();
  }

  /**
  * Función que obtiene si un mensaje es error o no
  * @return esError 
  * @author msemeraro
  */
  getEsError(): Boolean {
    return this.globalEnersolService.getEsError();
  }

  /**
  * Función que obtiene el tipo de cambio
  * @return tipoCambio 
  * @author msemeraro
  */
  async getTipoCambio() {
    await this.globalEnersolService.setTipoCambio();
    let tipoCambio: Parametro = this.globalEnersolService.getTipoCambio();
    return Number(tipoCambio.valor);
  }

  /* FUNCIONES PARA EL FORMATO DE LOS NUMEROS */

  /**
  * Función que redondea un número
  * @param numero
  * @returns number redondeado
  * @author msemeraro
  */
  getRoundNumber(numero: number) {
    return Math.round(numero);
  }

  /**
  * Función que formatea un número
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
  getFormatNumber(numero: number) {
    return this.validationsEnersolService.getNumber(numero);
  }

  /* OTRAS FUNCIONES */

  /**
  * Función que calcula los precios de los productos presentacion de acuerdo con sus porcentajes
  * @returns path para llamar al servicio
  * @author msemeraro
  * @modifiedBy gberrocal
  */
  async getPreciosProducto(producto: ProductoPresentacion) {
    let precioUnitario = producto.precio / producto.unidadEmpaque;
    let precio = producto.precio;

    producto.precioUnitarioFinal = producto.valorMercadoConsumidorFinal / producto.unidadEmpaque;
    producto.precioUnitarioOtros = producto.valorMercadoOtrosComercios / producto.unidadEmpaque;

    let tipoCambio = await this.getTipoCambio(); 

    producto.precioConsumidorFinal = Math.round(precio * tipoCambio * ((producto.valorMercadoConsumidorFinal / 100) + 1));
    producto.precioOtrosComercios = Math.round(precio * tipoCambio * ((producto.valorMercadoOtrosComercios / 100) + 1));
    producto.precioBandaConsumidorFinal = Math.round(precio * tipoCambio * ((producto.bandaMinimaMercadoConsumidorFinal / 100) + 1));
    producto.precioBandaOtrosComercios = Math.round(precio * tipoCambio * ((producto.bandaMinimaMercadoOtrosComercios / 100) + 1));

    return producto;
  }

  /**
  * Función obtiene el path para consultar el producto
  * @returns path para llamar al servicio
  * @author msemeraro
  */
  getPathProductoPresentacion(): string {
    let codProductoPresentacion = this.globalEnersolService.getCodProductoPresentacionSeleccionado();
    let codProducto = this.globalEnersolService.getCodProductoSeleccionado();
    let codPresentacion = this.globalEnersolService.getCodPresentacionProductoSeleccionado();
    let codMarca = this.globalEnersolService.getCodMarcaProductoSeleccionado();
    return 'ProductoPresentacion/id?codProductoPresentacion=' + codProductoPresentacion +
      '&codProducto=' + codProducto + '&codPresentacion=' + codPresentacion + '&codMarca=' + codMarca;
  }

  /**
  * Función obtiene el path para consultar las presentaciones de un producto
  * @returns path para llamar al servicio
  * @author msemeraro
  */
  getPathProductoPresentaciones(): string {
    let codProducto = this.globalEnersolService.getCodProductoSeleccionado();
    let codMarca = this.globalEnersolService.getCodMarcaProductoSeleccionado();
    return 'ProductoPresentacion/producto/all?codProducto=' + codProducto +
      '&codMarca=' + codMarca;
  }

  /**
  * Función que retorna si la nueva cantidad de producto es valida
  * @returns number
  * @author msemeraro
  */
  cambioCantidadProducto(cantidad: string): boolean {
    if(cantidad.length > 3)
      return false
    return this.validationsEnersolService.validateNumeric(cantidad);
  }

  /**
  * Función que se encarga de abrir en una nueva ventana un documento
  * @param url
  */
  abrirArchivo(url: string) {
    window.open(this.urlEnersol + url, '_blank');
  }

}
