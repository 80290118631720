import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetalleOrdenBackview } from './detalle-orden.component.backview';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../footer/footer.module';

@NgModule({
  declarations: [DetalleOrdenBackview],
  imports: [
    CommonModule,
    FormsModule,    HeaderModule,
    FooterModule,
    NgxSpinnerModule
  ],
  exports: [
    DetalleOrdenBackview
  ]
})
export class DetalleOrdenModule { }
