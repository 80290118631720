import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MisOrdenesBackview } from './mis-ordenes.component.backview';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PipeModule } from 'src/app/pipes/pipe-module';
import { FormsModule } from '@angular/forms';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../footer/footer.module';

@NgModule({
  declarations: [MisOrdenesBackview
  ],
  imports: [
    CommonModule,
    FormsModule,
    HeaderModule,
    FooterModule,
    MatPaginatorModule,
    PipeModule
  ],
  exports: [
    MisOrdenesBackview
  ]
})
export class MisOrdenesModule { }
