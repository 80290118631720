import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as $ from 'jquery';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

/* PAGINAS */
import { HeaderModule } from 'src/components/header/header.module';
import { MainModule } from 'src/components/main/main.module';
import { FooterModule } from 'src/components/footer/footer.module';
import { PrincipalModule } from 'src/components/principal/principal.module';
import { BusquedaModule } from 'src/components/busqueda/busqueda.module';
import { DetalleProductoModule } from 'src/components/detalle-producto/detalle-producto.module';
import { CarritoModule } from 'src/components/carrito/carrito.module';
import { MisOrdenesModule } from 'src/components/mis-ordenes/mis-ordenes.module';
import { LoginModule } from 'src/components/login/login.module';
import { AdminOrdenesModule } from 'src/components/admin-ordenes/admin-ordenes.module';
import { DetalleOrdenModule } from 'src/components/detalle-orden/detalle-orden.module';
import { AdminDetalleOrdenModule } from 'src/components/admin-detalle-orden/admin-detalle-orden.module';
import { AdminRevisionPreciosModule } from 'src/components/admin-revision-precios/admin-revision-precios.module';
import { ContenidoModule } from 'src/components/contenido/contenido.module';
import { HistorialRevisionPreciosModule } from 'src/components/historial-revision-precios/historial-revision-precios.module';

/* OTROS */
import { AuthInterceptor } from 'src/interceptors/interceptors';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from 'src/services/paginator-es';
import {MatPaginator} from '@angular/material/paginator';
import { MAT_DATE_LOCALE } from '@angular/material/core'
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource, MatTable} from '@angular/material/table';
import { PaginatePipe } from './pipes/paginate.pipe';
import { PipeModule } from './pipes/pipe-module';
import { ViewportScroller } from '@angular/common';

import { NgxSpinnerModule } from "ngx-spinner";
import { OrdenSugeridaModule } from 'src/components/orden-sugerida/orden-sugerida.module';
import { AdminMantenimientoMarcasModule } from 'src/components/admin-mantenimiento-marcas/admin-mantenimiento-marcas.module';
import { AdminMantenimientoFamiliasModule } from 'src/components/admin-mantenimiento-familias/admin-mantenimiento-familias.module';
import { AdminMantenimientoPresentacionesModule } from 'src/components/admin-mantenimiento-presentaciones/admin-mantenimiento-presentaciones.module';
import { AdminMantenimientoParametrosModule } from 'src/components/admin-mantenimiento-parametros/admin-mantenimiento-parametros.module';
import { AdminMantenimientoEstacionesModule } from 'src/components/admin-mantenimiento-estacion/admin-mantenimiento-estaciones.module';
import { AdminMantenimientoMembresiasModule } from 'src/components/admin-mantenimiento-membresia/admin-mantenimiento-membresias.module';
import { AdminMantenimientoProductosModule } from 'src/components/admin-mantenimiento-productos/admin-mantenimiento-productos.module';
import { AdminMantenimientoProductoPresentacionesModule } from 'src/components/admin-mantenimiento-productos-presentaciones/admin-mantenimiento-productos-presentaciones.module';
import { ReporteMarcasModule } from 'src/components/reporte-marcas/reporte-marcas.module';
import { ReporteOrdenModule } from 'src/components/reporte-orden/reporte-orden.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { DatePipe } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MatTableModule } from '@angular/material';
import { BitacoraModule } from '../components/bitacora/bitacora.component.module'  
//export const options: Partial<IConfig> | (() => Partial<IConfig>);



@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    
    FormsModule,
    MatTableModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HeaderModule,
    MainModule,
    FooterModule,
    BitacoraModule,
    PrincipalModule,
    BusquedaModule,
    DetalleProductoModule,
    CarritoModule,
    MisOrdenesModule,
    LoginModule,
    HistorialRevisionPreciosModule,
    AdminOrdenesModule,
    AdminDetalleOrdenModule,
    AdminRevisionPreciosModule,
    DetalleOrdenModule,
    OrdenSugeridaModule,
    AdminMantenimientoMarcasModule,
    AdminMantenimientoFamiliasModule,
    AdminMantenimientoPresentacionesModule,
    AdminMantenimientoParametrosModule,
    AdminMantenimientoEstacionesModule,
    AdminMantenimientoMembresiasModule,
    AdminMantenimientoProductosModule,
    AdminMantenimientoProductoPresentacionesModule,
    ReporteMarcasModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    MatPaginatorModule,
    ReporteOrdenModule,
    ContenidoModule,
    CarouselModule,
    MatTabsModule,
    MatTooltipModule,

    NgxMaskModule,

    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })//.forRoot(options)
  ],
  providers: [

    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl
    },
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
