import { Component, OnInit, ɵConsole, Injectable } from '@angular/core';
/* SERVICES */
import { HttpClientEnersolService } from 'src/services/http-client-enersol.service';
import { GlobalService } from 'src/services/global';
import { ValidationsService } from 'src/services/validations.service';
/* REST MODEL */
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
import { Presentacion } from 'src/models/com.enersol.directshopping.dal.entidad/Presentacion';
/* MODELS */

@Injectable()
export class AdminMantenimientoPresentacionesController {


  constructor(
    private httpClientEnersolService: HttpClientEnersolService,
    private globalEnersolService: GlobalService,
    private validationEnersolService: ValidationsService
  ) { }


  /* FUNCIONES QUE HACEN PETICIONES A LOS SERVICIOS */

  /**
  * Función que obtiene las presentacions registradas
  * @returns ListResponse<Presentacion>
  * @author msemeraro
  */
  async getPresentaciones(): Promise<ListResponse<Presentacion>> {
    let respuesta: ListResponse<Presentacion>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('Presentacion/all');
      respuesta = <ListResponse<Presentacion>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<Presentacion>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que actualiza una presentacion
  * @returns ListResponse<Presentacion>
  * @author msemeraro
  */
  async actualizarPresentacion(presentacion: Presentacion): Promise<Response<Presentacion>> {
    let respuesta: Response<Presentacion>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('Presentacion/update', presentacion);
      respuesta = <Response<Presentacion>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Presentacion>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función que hace la peticion al servidor de eliminar una presentacion
    * @returns Response<Boolean>
    * @author msemeraro
    */
  async eliminarPresentacion(presentacion: Presentacion): Promise<Response<Boolean>> {
    let respuesta: Response<Boolean>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('Presentacion/delete', presentacion);
      respuesta = <Response<Boolean>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Boolean>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función que hace la peticion al servidor de agregar una presentacion
    * @returns Response<Presentacion>
    * @author msemeraro
    */
  async agregarPresentacion(presentacion: Presentacion): Promise<Response<Presentacion>> {
    let respuesta: Response<Presentacion>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('Presentacion/insert', presentacion);
      respuesta = <Response<Presentacion>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Presentacion>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }



  /* FUNCIONES QUE HACEN PETICIONES AL GLOBAL SERVICE */

  /**
  * Función que obtiene si el usuario logueado es administrador de enersol
  * @return boolean 
  * @author msemeraro
  */
  isAdminEnersol() {
    return this.globalEnersolService.isAdminEnersol();
  }


  /* FUNCIONES QUE HACEN ORDENAMIENTOS O FILTROS */





  /* OTRAS FUNCIONES */

  /**
  * Función que valida el nombre de la nueva presentacion
  * @param nombre 
  * @author msemeraro
  */
  validarNombre(nombre: string): Boolean {
    return this.validationEnersolService.validateGeneral(nombre);
  }

  /**
* Función que guarda las variables para mostrar un mensaje
* @param tituloMensaje
* @param contenidoMensaje
* @param esError
* @author msemeraro
*/
  setMensaje(tituloMensaje: String, contenidoMensaje: String, esError: Boolean): void {
    this.globalEnersolService.setTituloMensaje(tituloMensaje);
    this.globalEnersolService.setContenidoMensaje(contenidoMensaje);
    this.globalEnersolService.setEsError(esError);
  }

  /**
  * Función que obtiene el titulo de un mensaje
  * @return tituloMensaje 
  * @author msemeraro
  */
  getTituloMensaje(): String {
    return this.globalEnersolService.getTituloMensaje();
  }

  /**
  * Función que obtiene el contenido de un mensaje
  * @return contenidoMensaje 
  * @author msemeraro
  */
  getContenidoMensaje(): String {
    return this.globalEnersolService.getContenidoMensaje();
  }

  /**
  * Función que obtiene si un mensaje es error o no
  * @return esError 
  * @author msemeraro
  */
  getEsError(): Boolean {
    return this.globalEnersolService.getEsError();
  }

}
