import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/services/global';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: []
})
export class FooterBackview implements OnInit {

  constructor(private router: Router,
    private globalService: GlobalService) { 
    
  }

  ngOnInit() {
  }

  verContenido(id) {
    this.globalService.setIdContenido(id);
    this.router.navigate(['contenido']);
  }

  getToken(){
     return this.globalService.getToken();
  }

}
