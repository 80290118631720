import { Component, OnInit, Injectable } from '@angular/core';
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
import { Bitacora } from 'src/models/com.enersol.directshopping.dal.entidad/Bitacora';
import { HttpClientEnersolService } from 'src/services/http-client-enersol.service';
import { GlobalService } from 'src/services/global';
import { ValidationsService } from 'src/services/validations.service';
import { ErrorService } from 'src/services/error.service';

@Injectable()
export class BitacoraController {

  constructor(
    private httpClientEnersolService: HttpClientEnersolService,
    private globalEnersolService: GlobalService,
    private validationEnersolService: ValidationsService,
    private errorService: ErrorService,
  ) { }

  /* FUNCIONES QUE HACEN PETICIONES A LOS SERVICIOS */

  /**
  * Función que hace la petición del servicio de todos los productos en pricing en el historico
  * @returns ListResponse<Bitacora>
  * @author kjimenez
  */
  async getBitacoras(): Promise<ListResponse<Bitacora>> {
    let respuesta: ListResponse<Bitacora>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('Bitacora/all');
      respuesta = <ListResponse<Bitacora>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<Bitacora>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }
}
