import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ErrorService } from 'src/services/error.service';
import { PageEvent } from '@angular/material/paginator';
import { AdminMantenimientoEstacionesController } from './admin-mantenimiento-estaciones.component.controller';
import { Estacion } from 'src/models/com.enersol.directshopping.dal.entidad/Estacion';
import { Membresia } from 'src/models/com.enersol.directshopping.dal.entidad/Membresia';
import { Zona } from 'src/models/com.enersol.directshopping.dal.entidad/Zona';

@Component({
  selector: 'app-admin-mantenimiento-estaciones',
  templateUrl: './admin-mantenimiento-estaciones.component.html',
  styleUrls: ['./admin-mantenimiento-estaciones.component.scss'],
  providers: [AdminMantenimientoEstacionesController]
})
export class AdminMantenimientoEstacionesBackview implements OnInit {


  listaEstaciones: Estacion[] = null;
  nuevaEstacion: Estacion = new Estacion();

  listaMembresias: Membresia[] = null;
  listaZonas: Zona[] = null;

  /* Variables para controlar los roles del usuario */
  isAdmin: Boolean;
  
  /* Variable para los mensajes */
  contenidoMensaje: String;
  tituloMensaje: String;
  esError: Boolean = false;

  /* Variables para la paginacion */
  page_size: number = 10;
  page_number: number = 1;
  pageSizeOptions = [5, 10, 20, 50, 100];


  constructor(
    private router: Router,
    private adminMantenimientosComponent: AdminMantenimientoEstacionesController,
    private errorService: ErrorService,
    private spinner: NgxSpinnerService
  ) {
  }

  async ngOnInit() {
    this.isAdmin = await this.adminMantenimientosComponent.isAdminEnersol();
    await this.getEstaciones();
    await this.getMembresias();
    await this.getZonas();
  }


  /**
  * Función que obtiene las estaciones registradas
  * @author msemeraro
  */
  async getEstaciones() {
    let estaciones = await this.adminMantenimientosComponent.getEstaciones();
    if (!estaciones.sucess) {
      let mensaje = this.errorService.displayError(estaciones.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.listaEstaciones = estaciones.data;
    }
  }

  /**
  * Función que obtiene las membresias registradas
  * @author msemeraro
  */
  async getMembresias() {
    let membresias = await this.adminMantenimientosComponent.getMembresias();
    if (!membresias.sucess) {
      let mensaje = this.errorService.displayError(membresias.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.listaMembresias = membresias.data;
    }
  }

  /**
    * Función que obtiene las zonas registradas
    * @author msemeraro
    */
  async getZonas() {
    let zonas = await this.adminMantenimientosComponent.getZonas();
    if (!zonas.sucess) {
      let mensaje = this.errorService.displayError(zonas.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.listaZonas = zonas.data;
    }
  }

  async eliminarEstacion(estacion: Estacion) {
    var confirmacion = confirm("¿Desea eliminar la estación " + estacion.nombre + "?");
    if (confirmacion) {
      this.spinnerShow();
      let respuesta = await this.adminMantenimientosComponent.eliminarEstacion(estacion);
      if (!respuesta.sucess) {
        let mensaje = this.errorService.displayError(respuesta.errors);
        this.adminMantenimientosComponent.setMensaje('Error al eliminar la estacion', mensaje, true);
        this.verMensaje();
      }
      else {
        await this.getEstaciones();
      }
      this.spinnerHide();
    }
  }

  async actualizarEstacion(estacion: Estacion) {
    if (!this.adminMantenimientosComponent.validarNumeroRecope(estacion.codigoRecope)) {
      this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar un código de RECOPE válido", true);
      this.verMensaje();
      return;
    }
    if (!this.adminMantenimientosComponent.validarNombre(estacion.nombre)) {
      this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar un nombre válido", true);
      this.verMensaje();
      return;
    }
    if (!this.adminMantenimientosComponent.validarTelefono(estacion.telefono)) {
      this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar un teléfono válido", true);
      this.verMensaje();
      return;
    }
    if (!this.adminMantenimientosComponent.validarNombre(estacion.ubicacion)) {
      this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar una ubicación válida", true);
      this.verMensaje();
      return;
    }
    this.spinnerShow();
    let respuesta = await this.adminMantenimientosComponent.actualizarEstacion(estacion);
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al actualizar la estacion', mensaje, true);
      this.verMensaje();
    }
    else {
      await this.getEstaciones();
    }
    this.spinnerHide();
  }

  async agregarEstacion() {
    if (!this.adminMantenimientosComponent.validarNumeroRecope(this.nuevaEstacion.codigoRecope)) {
      this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar un código de RECOPE válido", true);
      this.verMensaje();
      return;
    }
    if (!this.adminMantenimientosComponent.validarNombre(this.nuevaEstacion.nombre)) {
      this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar un nombre válido", true);
      this.verMensaje();
      return;
    }
    if (!this.adminMantenimientosComponent.validarTelefono(this.nuevaEstacion.telefono)) {
      this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar un teléfono válido", true);
      this.verMensaje();
      return;
    }
    if (!this.adminMantenimientosComponent.validarNombre(this.nuevaEstacion.ubicacion)) {
      this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar una ubicación válida", true);
      this.verMensaje();
      return;
    }
    if (this.nuevaEstacion.membresia === null) {
      this.adminMantenimientosComponent.setMensaje('Error', "Debe seleccionar una membresía", true);
      this.verMensaje();
      return;
    }
    this.spinnerShow();
    let respuesta = await this.adminMantenimientosComponent.agregarEstacion(this.nuevaEstacion);
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al agregar la estacion', mensaje, true);
      this.verMensaje();
    }
    else {
      $(".close").click();
      this.nuevaEstacion.nombre = "";
      await this.getEstaciones();
    }
    this.spinnerHide();
  }

  changeMembresia(codMembresia: number, estacion: Estacion) {
    for (let membresia of this.listaMembresias) {
      if (membresia.codigoMembresia == codMembresia) {
        let posicion: number = 0;
        for (let estacionActualizar of this.listaEstaciones) {
          if (estacionActualizar.codigoEstacion == estacion.codigoEstacion) {
            this.listaEstaciones[posicion].membresia = membresia;
            break;
          }
          posicion++;
        }
      }
    }
  }

  /**
  * Función que navega a otra pagina
  * @author msemeraro
  */
  irPagina(nombre: string) {
    this.router.navigate([nombre]);
  }

  changeMembresiaNueva(codMembresia: number) {
    for (let membresia of this.listaMembresias) {
      if (membresia.codigoMembresia == codMembresia) {
        this.nuevaEstacion.membresia = membresia;
        break;
      }
    }
  }

  changeZona(codZona: number, estacion: Estacion) {
    for (let zona of this.listaZonas) {
      if (zona.codigoZona == codZona) {
        let posicion: number = 0;
        for (let estacionActualizar of this.listaEstaciones) {
          if (estacionActualizar.codigoEstacion == estacion.codigoEstacion) {
            this.listaEstaciones[posicion].zona = zona;
            break;
          }
          posicion++;
        }
      }
    }
  }

  changeZonaNueva(codZona: number) {
    for (let zona of this.listaZonas) {
      if (zona.codigoZona == codZona) {
        this.nuevaEstacion.zona = zona;
        break;
      }
    }
  }

  spinnerShow() {
    this.spinner.show();
  }

  spinnerHide() {
    this.spinner.hide();
  }

  /**
  * Función que muestra un mensaje
  * @author msemeraro
  */
  verMensaje() {
    this.tituloMensaje = this.adminMantenimientosComponent.getTituloMensaje();
    this.contenidoMensaje = this.adminMantenimientosComponent.getContenidoMensaje();
    this.esError = this.adminMantenimientosComponent.getEsError();
    var element = document.getElementById("btnAbrirMensaje") as any;
    element.click();
  }

  clickModalMensaje() { }

  clickModalCambioEstado() { }

  handlePage(e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }
}
