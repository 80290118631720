export class BaseResponse {

    sucess: boolean = false;
    errors: Array<Error> = Array();

    constructor(sucess: boolean) {
        this.sucess = sucess;
    }

    addError(code: string, techMessage: string, userMessage: string) {
        this.errors.push(new Error(code, techMessage, userMessage));
    }

    getErrors() {
        return this.errors;
    }

    getErrores = function () {
        console.log(0);
    }

}

export class Error {

    code: string;
    techMessage: string;
    userMessage: string;

    constructor(code: string, techMessage: string, userMessage: string) {
        this.code = code;
        this.techMessage = techMessage;
        this.userMessage = userMessage;
    }

}
