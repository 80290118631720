import { Component, OnInit, ɵConsole, Injectable } from '@angular/core';
/* SERVICES */
import { HttpClientEnersolService } from 'src/services/http-client-enersol.service';
import { GlobalService } from 'src/services/global';
import { ValidationsService } from 'src/services/validations.service';
/* REST MODEL */
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
import { Familia } from 'src/models/com.enersol.directshopping.dal.entidad/Familia';
import { SubFamilia } from 'src/models/com.enersol.directshopping.dal.entidad/SubFamilia';
/* MODELS */

@Injectable()
export class AdminMantenimientoFamiliasController {


  constructor(
    private httpClientEnersolService: HttpClientEnersolService,
    private globalEnersolService: GlobalService,
    private validationEnersolService: ValidationsService
  ) { }


  /* FUNCIONES QUE HACEN PETICIONES A LOS SERVICIOS */

  /**
  * Función que obtiene las familias registradas
  * @returns ListResponse<Familia>
  * @author msemeraro
  */
  async getFamilias(): Promise<ListResponse<Familia>> {
    let respuesta: ListResponse<Familia>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('Familia/all');
      respuesta = <ListResponse<Familia>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<Familia>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que obtiene las subfamilias registradas
  * @returns ListResponse<SubFamilia>
  * @author msemeraro
  */
  async getSubFamilias(): Promise<ListResponse<SubFamilia>> {
    let respuesta: ListResponse<SubFamilia>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('SubFamilia/all');
      respuesta = <ListResponse<SubFamilia>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<SubFamilia>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que actualiza una familia
  * @returns ListResponse<Familia>
  * @author msemeraro
  */
  async actualizarFamilia(familia: Familia): Promise<Response<Familia>> {
    let respuesta: Response<Familia>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('Familia/update', familia);
      respuesta = <Response<Familia>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Familia>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función que hace la peticion al servidor de eliminar una familia
    * @returns Response<Boolean>
    * @author msemeraro
    */
  async eliminarFamilia(familia: Familia): Promise<Response<Boolean>> {
    let respuesta: Response<Boolean>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('Familia/delete', familia);
      respuesta = <Response<Boolean>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Boolean>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función que hace la peticion al servidor de agregar una familia
    * @returns Response<Familia>
    * @author msemeraro
    */
  async agregarFamilia(familia: Familia): Promise<Response<Familia>> {
    let respuesta: Response<Familia>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('Familia/insert', familia);
      respuesta = <Response<Familia>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Familia>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que actualiza una familia
  * @returns ListResponse<SubFamilia>
  * @author msemeraro
  */
  async actualizarSubFamilia(subFamilia: SubFamilia): Promise<Response<SubFamilia>> {
    let respuesta: Response<SubFamilia>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('SubFamilia/update', subFamilia);
      respuesta = <Response<SubFamilia>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<SubFamilia>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función que hace la peticion al servidor de eliminar una familia
    * @returns Response<Boolean>
    * @author msemeraro
    */
  async eliminarSubFamilia(subFamilia: SubFamilia): Promise<Response<Boolean>> {
    let respuesta: Response<Boolean>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('SubFamilia/delete', subFamilia);
      respuesta = <Response<Boolean>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Boolean>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función que hace la peticion al servidor de agregar una familia
    * @returns Response<SubFamilia>
    * @author msemeraro
    */
  async agregarSubFamilia(subFamilia: SubFamilia): Promise<Response<SubFamilia>> {
    let respuesta: Response<SubFamilia>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('SubFamilia/insert', subFamilia);
      respuesta = <Response<SubFamilia>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<SubFamilia>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }



  /* FUNCIONES QUE HACEN PETICIONES AL GLOBAL SERVICE */
  /**
  * Función que obtiene si el usuario logueado es administrador de enersol
  * @return boolean 
  * @author msemeraro
  */
  isAdminEnersol() {
    return this.globalEnersolService.isAdminEnersol();
  }

  /* FUNCIONES QUE HACEN ORDENAMIENTOS O FILTROS */





  /* OTRAS FUNCIONES */

  /**
  * Función que valida el nombre de la nueva marca
  * @param nombre 
  * @author msemeraro
  */
  validarNombre(nombre: string): Boolean {
    return this.validationEnersolService.validateGeneral(nombre);
  }

  /**
* Función que guarda las variables para mostrar un mensaje
* @param tituloMensaje
* @param contenidoMensaje
* @param esError
* @author msemeraro
*/
  setMensaje(tituloMensaje: String, contenidoMensaje: String, esError: Boolean): void {
    this.globalEnersolService.setTituloMensaje(tituloMensaje);
    this.globalEnersolService.setContenidoMensaje(contenidoMensaje);
    this.globalEnersolService.setEsError(esError);
  }

  /**
  * Función que obtiene el titulo de un mensaje
  * @return tituloMensaje 
  * @author msemeraro
  */
  getTituloMensaje(): String {
    return this.globalEnersolService.getTituloMensaje();
  }

  /**
  * Función que obtiene el contenido de un mensaje
  * @return contenidoMensaje 
  * @author msemeraro
  */
  getContenidoMensaje(): String {
    return this.globalEnersolService.getContenidoMensaje();
  }

  /**
  * Función que obtiene si un mensaje es error o no
  * @return esError 
  * @author msemeraro
  */
  getEsError(): Boolean {
    return this.globalEnersolService.getEsError();
  }

}
