import { Component, OnInit, ɵConsole, Injectable } from '@angular/core';

/* SERVICES */
import { HttpClientEnersolService } from 'src/services/http-client-enersol.service';
import { GlobalService } from 'src/services/global';
import { ValidationsService } from 'src/services/validations.service';
/* REST MODEL */
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
/* MODELS */
import { DetalleOrdenCompraPorMarca } from 'src/models/com.enersol.directshopping.dal.entidad/DetalleOrdenCompraPorMarca';
import { ProductoPorOrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoPorOrdenDeCompra';
import { OrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/OrdenDeCompra';
import { Parametro } from 'src/models/com.enersol.directshopping.dal.entidad/Parametro';
import { OrdenesCompraCambioDTO } from 'src/models/com.enersol.directshopping.bl.model/OrdenesCompraCambioDTO';
import { EnumNombresParametros } from 'src/models/com.enersol.directshopping.dal.enums/EnumNombresParametros';
import { EnumEstadoOrden } from 'src/models/com.enersol.directshopping.dal.enums/EnumEstadoOrden';


@Injectable()
export class CarritoController {

  constructor(
    private httpClientEnersolService: HttpClientEnersolService,
    private globalEnersolService: GlobalService,
    private validationEnersolService: ValidationsService
  ) { }



  /* FUNCIONES QUE HACEN PETICIONES A LOS SERVICIOS */

  /**
  * Función que obtiene los detalles relacionados a la orden de compra almacenada en el globalService
  * @returns ListResponse<DetalleOrdenCompraPorMarca>
  * @author msemeraro
  */
  async getCarritoDetailsImportacion(): Promise<ListResponse<DetalleOrdenCompraPorMarca>> {
    let respuesta: ListResponse<DetalleOrdenCompraPorMarca>;
    try {
      let path = 'DetalleOrdenCompraPorMarca/DetallesOrden?codOrden=' + this.globalEnersolService.getOrdenDeCompraImportacionBorrador().codigoOrden;
      let promiseResponse = await this.httpClientEnersolService.httpGet(path);
      respuesta = <ListResponse<DetalleOrdenCompraPorMarca>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<DetalleOrdenCompraPorMarca>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }


  async getCarritoDetailsInventario(): Promise<ListResponse<DetalleOrdenCompraPorMarca>> {
    let respuesta: ListResponse<DetalleOrdenCompraPorMarca>;
    try {
      let path = 'DetalleOrdenCompraPorMarca/DetallesOrden?codOrden=' + this.globalEnersolService.getOrdenDeCompraInventarioBorrador().codigoOrden;
      let promiseResponse = await this.httpClientEnersolService.httpGet(path);
      respuesta = <ListResponse<DetalleOrdenCompraPorMarca>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<DetalleOrdenCompraPorMarca>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }


  getFecha(fecha: string): Date {
    // sustituye el caracter - por / del string de parametro
    fecha = this.validationEnersolService.changeString(fecha, '/', '-');
    // Separa el dia, mes y a;o
     let splitFecha = fecha.split('/');
    // // cambia el dia y el mes
     fecha = splitFecha[1] + '/' + splitFecha[0] + '/' + splitFecha[2];
    // lo convierte en Date y lo retorna
    return new Date(fecha);
  }

  /**
  * Función que obtiene los productos relacionados a un detalle de una orden
  * @param idDetalle id del detalle de los productos
  * @returns ListResponse<ProductoPorOrdenDeCompra>
  * @author msemeraro
  */
  async getDetailProducts(idDetalle: number): Promise<ListResponse<ProductoPorOrdenDeCompra>> {
    let respuesta: ListResponse<ProductoPorOrdenDeCompra>;
    try {
      let path = 'ProductoPorOrdenDeCompra/DetalleOrdenMarca?codDetalleOrden=' + idDetalle;
      let promiseResponse = await this.httpClientEnersolService.httpGet(path);
      respuesta = <ListResponse<ProductoPorOrdenDeCompra>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<ProductoPorOrdenDeCompra>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que hace la petición del servicio para limpiar una orden de compra
  * @returns Response<OrdenDeCompra>
  * @author msemeraro
  */
  async cleanCarrito(): Promise<Response<OrdenDeCompra>> {
    let respuesta: Response<OrdenDeCompra>;

    try {
      // Se asegura que la orden no haya cambiado de estado borrador (por ejemplo, por otra sesión)
      let ordenBD = await this.globalEnersolService.getOrdenDeCompraUpdate(this.getDatosOrdenCarrito().codigoOrden);
      if(ordenBD.sucess){
        if(ordenBD.data.estadoOrden.codigoEstado != EnumEstadoOrden.BORRADOR.codigoEstado){
          respuesta = new Response<OrdenDeCompra>(false);
          respuesta.addError('AddCarrito_Orden_No_Borrador', 'techMessage', 'userMessage');
          return respuesta;
        }
      } else {
        respuesta = new Response<OrdenDeCompra>(false);
        respuesta.addError('Gen_0', 'techMessage', 'userMessage');
        return respuesta;
      }

      let promiseResponse = await this.httpClientEnersolService.httpPost('OrdenDeCompra/Clean', this.getDatosOrdenCarrito());
      respuesta = <Response<OrdenDeCompra>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<OrdenDeCompra>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      this.globalEnersolService.getCantidadProductosCarrito();
      return respuesta;
    }
  }



  async cleanCarritoInventario(): Promise<Response<OrdenDeCompra>> {
    let respuesta: Response<OrdenDeCompra>;

    try {
      // Se asegura que la orden no haya cambiado de estado borrador (por ejemplo, por otra sesión)
      let ordenBD = await this.globalEnersolService.getOrdenDeCompraUpdate(this.getDatosOrdenCarritoInventario().codigoOrden);
      if(ordenBD.sucess){
        if(ordenBD.data.estadoOrden.codigoEstado != EnumEstadoOrden.BORRADOR.codigoEstado){
          respuesta = new Response<OrdenDeCompra>(false);
          respuesta.addError('AddCarrito_Orden_No_Borrador', 'techMessage', 'userMessage');
          return respuesta;
        }
      } else {
        respuesta = new Response<OrdenDeCompra>(false);
        respuesta.addError('Gen_0', 'techMessage', 'userMessage');
        return respuesta;
      }

      let promiseResponse = await this.httpClientEnersolService.httpPost('OrdenDeCompra/Clean', this.getDatosOrdenCarritoInventario());
      respuesta = <Response<OrdenDeCompra>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<OrdenDeCompra>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      this.globalEnersolService.getCantidadProductosCarrito();
      return respuesta;
    }
  }

  /**
  * Función que hace la petición del servicio para actualizar un producto de una orden
  * @param producto producto a actualizar
  * @returns Response<ProductoPorOrdenDeCompra>
  * @author msemeraro
  */
  async updateProductoPorOrdenCompra(producto: ProductoPorOrdenDeCompra): Promise<Response<ProductoPorOrdenDeCompra>> {
    let respuesta: Response<ProductoPorOrdenDeCompra>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('ProductoPorOrdenDeCompra/update', this.getDatosUpdateProductoOrdenCompra(producto));
      respuesta = <Response<ProductoPorOrdenDeCompra>>promiseResponse;
      this.globalEnersolService.getCantidadProductosCarrito();
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<ProductoPorOrdenDeCompra>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      this.globalEnersolService.getCantidadProductosCarrito();
      return respuesta;
    }
  }

  /**
  * Función que llama a eliminar los productos de la orden de compra según lista de productos selccionados
  * @param detallesCarrito Detalles de la orden de comora
  * @param productosSeleccionados productos por eliminar
  * @param codigoOrden Codigo de la orden de la cual se elimina el producto
  * @author msemeraro
  * @modifiedBy gberrocal Se agrega el codigoOrden para su validacion respectiva
  */
  async eliminarProductos(detallesCarrito, productosSeleccionados, codigoOrden) {

    let detalle;
    let producto;

    for (let productosPresentacionId of productosSeleccionados) {
      for (detalle of detallesCarrito) {
        for (producto of detalle.productos) {
          if (producto.id.codigoProductoPresentacion == productosPresentacionId) {
            let response = await this.eliminarProducto(producto.id, codigoOrden);
            if(response.sucess){
              this.globalEnersolService.getCantidadProductosCarrito();
              break;
            } else {
              throw new Error(response.errors[0].code);
            }
          }
        }
      }
    }
  }

  /**
  * Función que hace la petición del servicio para eliminar un producto de una orden
  * @param productoId id del producto a eliminar
  * @param codigoOrden Codigo de la orden de la cual se elimina el producto
  * @returns Response<ProductoPorOrdenDeCompra>
  * @author msemeraro
  * @modifiedBy gberrocal Se agrega el codigoOrden y su validacion respectiva
  */
  async eliminarProducto(productoId, codigoOrden): Promise<Response<ProductoPorOrdenDeCompra>> {
    let respuesta: Response<ProductoPorOrdenDeCompra>;
    try {
      // Se asegura que la orden no haya cambiado de estado borrador (por ejemplo, por otra sesión)
      let ordenBD = await this.globalEnersolService.getOrdenDeCompraUpdate(codigoOrden);
      if(ordenBD.sucess){
        if(ordenBD.data.estadoOrden.codigoEstado != EnumEstadoOrden.BORRADOR.codigoEstado){
          respuesta = new Response<ProductoPorOrdenDeCompra>(false);
          respuesta.addError('AddCarrito_Orden_No_Borrador', 'techMessage', 'userMessage');
          return respuesta;
        }
      } else {
        respuesta = new Response<ProductoPorOrdenDeCompra>(false);
        respuesta.addError('Gen_0', 'techMessage', 'userMessage');
        return respuesta;
      }

      let promiseResponse = await this.httpClientEnersolService.httpPost('ProductoPorOrdenDeCompra/delete', this.getDatosEliminarProducto(productoId));
      respuesta = <Response<ProductoPorOrdenDeCompra>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<ProductoPorOrdenDeCompra>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que hace la petición del servicio para limpiar una orden de compra
  * @returns Response<OrdenDeCompra>
  * @author msemeraro
  */
  async realizarPedido(): Promise<Response<OrdenDeCompra>> {
    let respuesta: Response<OrdenDeCompra>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('OrdenDeCompra/RealizarPedido', this.getDatosOrdenCarrito());
      respuesta = <Response<OrdenDeCompra>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<OrdenDeCompra>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      this.globalEnersolService.getCantidadProductosCarrito();
      return respuesta;
    }
  }



  async realizarPedidoInventario(): Promise<Response<OrdenDeCompra>> {
    let respuesta: Response<OrdenDeCompra>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('OrdenDeCompra/RealizarPedido', this.getDatosOrdenCarritoInventario());
      respuesta = <Response<OrdenDeCompra>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<OrdenDeCompra>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      this.globalEnersolService.getCantidadProductosCarrito();
      return respuesta;
    }
  }


  

  /**
  * Función que cancela una orden en estado de borrador
  * @returns Response<OrdenDeCompra>
  * @author gberrocal
  */
  async descartarPedido(ordenDeCompra: OrdenDeCompra): Promise<Response<OrdenDeCompra>> {
    let respuesta: Response<OrdenDeCompra>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('OrdenDeCompra/cancelarOrdenBorrador', ordenDeCompra);
      respuesta = <Response<OrdenDeCompra>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<OrdenDeCompra>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      this.globalEnersolService.getCantidadProductosCarrito();
      return respuesta;
    }
  }

  /**
  * Función que cancela una orden en estado de borrador
  * @returns Response<OrdenDeCompra>
  * @author gberrocal
  */
   async confirmarCambioOrdenImportacion(ordenDeInventario: OrdenDeCompra, ordenDeImportacion: OrdenDeCompra): Promise<Response<OrdenDeCompra>> {
    let respuesta: Response<OrdenDeCompra>;
    try {
      // Crea el DTO para el cambio
      let ordenesDTO: OrdenesCompraCambioDTO = new OrdenesCompraCambioDTO();
      ordenesDTO.ordenDeInventario = ordenDeInventario;
      ordenesDTO.ordenDeImportacion = ordenDeImportacion;

      // Realiza la petición
      let promiseResponse = await this.httpClientEnersolService.httpPost('OrdenDeCompra/confirmarCambioOrdenImportacion', ordenesDTO);
      respuesta = <Response<OrdenDeCompra>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<OrdenDeCompra>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      this.globalEnersolService.getCantidadProductosCarrito();
      return respuesta;
    }
  }

  /**
  * Función que obtiene la fecha de pricing
  * @returns Response<Parametro>
  * @author gberrocal
  */
  async getFechaPricing(): Promise<Response<Parametro>> {
    return await this.getParametro(EnumNombresParametros.FECHA_APROBACION_PRICING.nombre);
  }

  /**
  * Función que obtiene la cantidad de dias para el pago de una orden de inventario
  * @returns Response<Parametro>
  * @author gberrocal
  */
  async getCantDiasPagoOrden(): Promise<Response<Parametro>> {
    return await this.getParametro(EnumNombresParametros.CANT_DIAS_PAGO_ORDEN.nombre);
  }

  /**
  * Función que obtiene la fecha límite para el pago
  * @returns Response<Parametro>
  * @author gberrocal
  */
  async getFechaLimitePago(): Promise<Response<Parametro>> {
    return await this.getParametro(EnumNombresParametros.FECHA_LIMITE_PAGO.nombre);
  }

  /**
   * Función que obtiene un parametro
   * @returns Response<Parametro>
   * @author msemeraro
   */
  async getParametro(nombre: string): Promise<Response<Parametro>> {
    let respuesta: Response<Parametro>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('Parametro/id?nombre=' + nombre);
      respuesta = <Response<Parametro>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Parametro>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }


  /* FUNCIONES QUE HACEN PETICIONES AL GLOBAL SERVICE */

  /**
  * Función que hace la petición del servicio para obtener una orden de compra en estado borrador
  * @returns ListResponse<OrdenDeCompra>
  * @author msemeraro
  */
   async getOrdenesDeCompraBorrador(): Promise<ListResponse<OrdenDeCompra>> {
    return this.globalEnersolService.getNuevaOrdenDeCompraBorrador();
  }

  /**
  * Función que obtiene la orden de compra almacenada en estado borrador
  * @returns OrdenDeCompra
  * @author msemeraro
  */
  getOrdenDeCompraImportacionBorrador(): OrdenDeCompra {
    return this.globalEnersolService.getOrdenDeCompraImportacionBorrador();
  }


  getOrdenDeCompraInventarioBorrador(): OrdenDeCompra {
    return this.globalEnersolService.getOrdenDeCompraInventarioBorrador();
  }

  /**
  * Función que hace la petición del servicio para obtener una orden de compra en estado borrador
  * @returns ListResponse<OrdenDeCompra>
  * @author msemeraro
  */
  async getNuevaOrdenDeCompra(): Promise<ListResponse<OrdenDeCompra>> {
    return this.globalEnersolService.getNuevaOrdenDeCompraBorrador();
  }

  /**
  * Función que hace guarda la orden de compra en estado borrador obtenida del servicio
  * @returns ordenDeCompra OrdenDeCompra
  * @author msemeraro
  */
  async setOrdenDeCompraImportacion(ordenDeCompra: OrdenDeCompra) {
    await this.globalEnersolService.setOrdenDeCompraImportacionBorrador(ordenDeCompra);
    await this.globalEnersolService.getCantidadProductosCarrito();
  }

  /**
  * Función que hace guarda la orden de compra en estado borrador obtenida del servicio
  * @returns ordenDeCompra OrdenDeCompra
  * @author msemeraro
  */
   async setOrdenDeCompraInventario(ordenDeCompra: OrdenDeCompra) {
    await this.globalEnersolService.setOrdenDeCompraInventarioBorrador(ordenDeCompra);
    await this.globalEnersolService.getCantidadProductosCarrito();
  }

  /**
  * Función que guarda las variables para mostrar un mensaje
  * @param tituloMensaje
  * @param contenidoMensaje
  * @param esError
  * @author msemeraro
  */
  setMensaje(tituloMensaje: String, contenidoMensaje: String, esError: Boolean): void {
    this.globalEnersolService.setTituloMensaje(tituloMensaje);
    this.globalEnersolService.setContenidoMensaje(contenidoMensaje);
    this.globalEnersolService.setEsError(esError);
  }

  /**
  * Función que obtiene el titulo de un mensaje
  * @return tituloMensaje 
  * @author msemeraro
  */
  getTituloMensaje(): String {
    return this.globalEnersolService.getTituloMensaje();
  }

  /**
  * Función que obtiene el contenido de un mensaje
  * @return contenidoMensaje 
  * @author msemeraro
  */
  getContenidoMensaje(): String {
    return this.globalEnersolService.getContenidoMensaje();
  }

  /**
  * Función que obtiene si un mensaje es error o no
  * @return esError 
  * @author msemeraro
  */
  getEsError(): Boolean {
    return this.globalEnersolService.getEsError();
  }

  /**
  * Función que obtiene si un usuario es administrador de enersol o no
  * @return esError 
  * @author msemeraro
  */
  async isAdminEnersol(){
    return this.globalEnersolService.isAdminEnersol();
  }

 /**
  * Función que obtiene si un usuario es administrador de la estacion o no
  * @return esError 
  * @author msemeraro
  */
 async isEnvioOrdenesComprobantesEstacion(){
  return this.globalEnersolService.isEnvioOrdenesComprobantesEstacion();
  }

/**
  * Función que obtiene si usuario es usuario normal de la estacion o no
  * @return esError 
  * @author msemeraro
  */
 async isUserEstacion(){
  return this.globalEnersolService.isUserEstacion();
  }

  /* FUNCIONES PARA EL MANEJO DE LOS MONTOS */

  /**
  * Función que obtiene el monto total de una orden
  * @param subTotal monto subtotal de una orden de compra 
  * @returns number
  * @author msemeraro
  */
  getTotalOrden(subTotal: number): number {
    return Math.round(this.getImpuestoOrden(subTotal) + subTotal);
  }


  getTotalOrdenInventario(subTotal: number): number {
    return Math.round(this.getImpuestoOrdenInventario(subTotal) + subTotal);
  }

  /**
  * Función que obtiene el monto de impuesto de una orden
  * @param subTotal monto subtotal de una orden de compra 
  * @returns number
  * @author msemeraro
  */
  getImpuestoOrden(subTotal: number): number {
    let impuestoSubTotal = subTotal * this.globalEnersolService.getOrdenDeCompraImportacionBorrador().impuesto;
    impuestoSubTotal = impuestoSubTotal / 100
    return impuestoSubTotal;
  }


  getImpuestoOrdenInventario(subTotal: number): number {
    let impuestoSubTotal = subTotal * this.globalEnersolService.getOrdenDeCompraInventarioBorrador().impuesto;
    impuestoSubTotal = impuestoSubTotal / 100
    return impuestoSubTotal;
  }


  /* FUNCIONES PARA EL FORMATO DE LOS NUMEROS */

  /**
  * Función que redondea un número
  * @param numero
  * @returns number redondeado
  * @author msemeraro
  */
  getRoundNumber(numero: number) {
    return Math.round(numero);
  }

  /**
  * Función que formatea un número
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
  getFormatNumber(numero: number) {
    return this.validationEnersolService.getNumber(numero);
  }

  /**
  * Función que retorna el porcentaje de ganancia de una orden de compra
  * @param ganancia
  * @param totalOrden
  * @returns porcentaje de ganancia
  * @author msemeraro
  */
  getPorcentajeGanancia(ganancia: number, totalOrden: number): number {
    if (totalOrden > 0)
      return (ganancia * 100) / totalOrden;
    return 0;
  }

  /* FUNCIONES PARA OBTENER LOS JSON */

  /**
  * Función que retorna el json con los datos de la orden almacenada en el globalService
  * @returns Json con los datos de una orden
  * @author msemeraro
  */
  getDatosOrdenCarrito() {
    return {
      "codigoOrden": this.globalEnersolService.getOrdenDeCompraImportacionBorrador().codigoOrden,
      "estacion": null,
      "estadoOrden": null,
      "total": 0.0,
      "impuesto": 0.0,
      "fechaAprobacionParaPricing": null
    };
  }


  getDatosOrdenCarritoInventario() {
    return {
      "codigoOrden": this.globalEnersolService.getOrdenDeCompraInventarioBorrador().codigoOrden,
      "estacion": null,
      "estadoOrden": null,
      "total": 0.0,
      "impuesto": 0.0,
      "fechaAprobacionParaPricing": null
    };
  }

  /**
  * Función que retorna el json con los datos de un producto de una orden de compra
  * @param productoId
  * @returns Json con los datos de un producto de una orden de compra
  * @author msemeraro
  */
  getDatosEliminarProducto(productoId) {
    return {
      "id": {
        "codigoProducto": productoId.codigoProducto,
        "codigoPresentacion": productoId.codigoPresentacion,
        "codigoMarca": productoId.codigoMarca,
        "codigoProductoPresentacion": productoId.codigoProductoPresentacion,
        "codigoDetalleOrden": productoId.codigoDetalleOrden
      }
    }
  }

  /**
  * Función que retorna el json con los datos de un producto de una orden de compra
  * @param producto
  * @returns Json con los datos de un producto de una orden de compra
  * @author msemeraro
  */
  getDatosUpdateProductoOrdenCompra(producto: ProductoPorOrdenDeCompra) {
    return {
      "id": {
        "codigoProducto": producto.id.codigoProducto,
        "codigoPresentacion": producto.id.codigoPresentacion,
        "codigoMarca": producto.id.codigoMarca,
        "codigoProductoPresentacion": producto.id.codigoProductoPresentacion,
        "codigoDetalleOrden": producto.id.codigoDetalleOrden
      },
      "detalleOrdenCompraPorMarca": {
        "codigoDetalleOrden": null
      },
      "productoPresentacion": {
        "id": {
          "codigoProductoPresentacion": null,
          "codigoProducto": null,
          "codigoPresentacion": null,
          "codigoMarca": null
        }
      },
      "descripcion": producto.descripcion,
      "cantidad": producto.cantidad,
      "precioUnitario": producto.precioUnitario,
      "indicadorCumpleMinimoParaCompra": producto.indicadorCumpleMinimoParaCompra
    };
  }
}


