import { Component, OnInit, ViewChild } from '@angular/core';
import { BitacoraController } from './bitacora.component.controller'
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Bitacora } from 'src/models/com.enersol.directshopping.dal.entidad/Bitacora';

@Component({
  selector: 'app-bitacora',
  templateUrl: './bitacora.component.html',
  styleUrls: ['./bitacora.component.scss'],
  providers: [BitacoraController]
})
export class BitacoraBackview implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  
  displayedColumns: string[] = ['autor', 'fechaDeModificacion', 'detalle', 'valorAnterior', 'valorNuevo' ];
  dataSource: MatTableDataSource<Bitacora>;

  constructor(
    private bitacoraController: BitacoraController
  ) { }

  async ngOnInit() {
    let response = await this.bitacoraController.getBitacoras();
    let parsedResponse = this.parseDataSource(response.data);
    this.dataSource = new MatTableDataSource(response.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  parseDataSource(bitacora) {
    for (let register of bitacora) {
      register.valorAnterior = JSON.parse(JSON.stringify(register.valorAnterior));
      register.valorNuevo = JSON.parse(register.valorNuevo);
    }
    return bitacora;
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
