import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
/* SERVICES */
import { ErrorService } from 'src/services/error.service';
import { MisOrdenesController } from './mis-ordenes.component.controller';
/* MODELS */
import { OrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/OrdenDeCompra';
import { GlobalService } from 'src/services/global';
import { EnumEstadoOrdenInventario } from 'src/models/com.enersol.directshopping.dal.enums/EnumEstadoOrdenInventario';

@Component({
  selector: 'app-mis-ordenes',
  templateUrl: './mis-ordenes.component.html',
  styleUrls: ['./mis-ordenes.component.scss'],
  providers: [MisOrdenesController]
})
export class MisOrdenesBackview implements OnInit {

  ordenesDeCompra: Array<OrdenDeCompra>;
  ordenesDeCompraOriginal: Array<OrdenDeCompra>;
  /* Variable para los mensajes */
  contenidoMensaje: String;
  tituloMensaje: String;
  esError: Boolean;

  totalComprado: number = 0;
  totalGanancia: number = 0;

  /* Variables para la paginacion */
  page_size: number = 10;
  page_number: number = 1;
  pageSizeOptions = [5, 10, 20, 50, 100];

  ordenarAscendente = true;
  parametro = "-";
  filtro: number = 0;
  none = '-';
  fecha = "fecha";
  total = 'total';

  constructor(
    private router: Router,
    private misOrdenesComponent: MisOrdenesController,
    private errorService: ErrorService,
    private globalEnersolService: GlobalService
  ) {
  }

  async ngOnInit() {
    await this.getOrdenes();
    await this.globalEnersolService.getCantidadProductosCarrito();
  }

  /**
   * Función que solicita todas las ordenes del administrador
   * @author msemeraro
   */
  async getOrdenes() {
    let ordenesDeCompra = await this.misOrdenesComponent.getOrdenes();
    if (!ordenesDeCompra.sucess) {
      let mensaje = this.errorService.displayError(ordenesDeCompra.errors);
      this.misOrdenesComponent.setMensaje('Error al obtener las órdenes', mensaje, true);
      this.verMensaje();
    }
    else {
      this.totalComprado = 0;
      this.totalGanancia = 0;
      this.ordenesDeCompra = ordenesDeCompra.data;
      this.ordenesDeCompraOriginal = [];
      for (let entry of this.ordenesDeCompra) {
        this.ordenesDeCompraOriginal.push(entry);
        // Verifica que no este en estado cancelado
        if (entry.estadoOrden.codigoEstado != 8 && entry.estadoOrden.codigoEstado != 7) {
          entry.totalConImpuesto = entry.total + (entry.total * (entry.impuesto / 100));
          this.totalComprado = this.totalComprado + entry.total;
          this.totalGanancia = this.totalGanancia + (entry.margenGanancia * entry.tipoCambio);
        }
      }
      this.restaurarOrdenes();
    }
  }

  /**
   * Función que solicita todas las ordenes del administrador
   * @author msemeraro
   */
  async getCantidadProductos(codOrden: number) {
    let respuesta = await this.misOrdenesComponent.getCantidadProductos(codOrden);
    if (respuesta.sucess)
      return respuesta.data;
  }

  /**
  * Función que formatea un número
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
  getFormatNumber(numero: number) {
    return this.misOrdenesComponent.getFormatNumber(numero);
  }

  /**
    * Función que filtra la lista de ordenes por estado
    * @param codEstado
    * @author msemeraro
    */
  async filterEstado(codEstado: number) {
    this.filtro = codEstado;
    await this.restaurarOrdenes();
    $("#selectOrder").val('-');
    if (codEstado > 0)
      this.ordenesDeCompra = this.misOrdenesComponent.filterOrdenesEstado(this.ordenesDeCompra, codEstado);
  }

  /**
   * Filtra las ordenes que son de inventario
   */
   filtrarEstadoInventario(){
    this.filtrarEstadoOrdenInventario(EnumEstadoOrdenInventario.INVENTARIO.codigoEstado);
  }

  /**
   * Filtra las ordenes que son de importación
   */
  filtrarEstadoImportacion(){
    this.filtrarEstadoOrdenInventario(EnumEstadoOrdenInventario.IMPORTACION.codigoEstado);
  }

  /**
  * Función que filtra la lista de ordenes por estado de inventario
  * @param codEstado
  * @author gberrocal
  */
  filtrarEstadoOrdenInventario(codEstadoInventario: number) {
    this.restaurarOrdenes();
    $('#selectOrder').val('-');
    if (codEstadoInventario > 0) {
      this.ordenesDeCompra = this.misOrdenesComponent.filtrarOrdenesEstadoInventario(this.ordenesDeCompra, codEstadoInventario);
    }
  }

  /**
  * Función que ordena la lista de productos mostrada actualmente
  * @param ordenamiento indica si ordena de Mayor a menor o alrevés
  * @author msemeraro
  */
  orderBy(parametro: string) {
    //Setea el parametro de ordenamiento
    this.parametro = parametro;
    //parametro = this.parametro;
    let ordenamiento = this.ordenarAscendente ? "Mayor" : "Menor";
    if (parametro == "fecha") {
      this.ordenesDeCompra = this.misOrdenesComponent.orderBy(parametro, this.ordenesDeCompra, ordenamiento);
      this.ordenesDeCompra = this.misOrdenesComponent.filterOrdenesOrderBy(this.ordenesDeCompra);
    }
    else if (parametro == "total") {
      this.ordenesDeCompra = this.misOrdenesComponent.orderBy(parametro, this.ordenesDeCompra, ordenamiento);
      this.ordenesDeCompra = this.misOrdenesComponent.filterOrdenesOrderBy(this.ordenesDeCompra);
    }
    else {
      this.restaurarOrdenes();
    }
  }

  /**
   * Cambia el ordenamiento de ascendente a descendente o viceversa
   */
  cambiarOrdenamiento() {
    this.ordenarAscendente = !this.ordenarAscendente;
    this.orderBy(this.parametro);
  }

  /**
    * Función que vuelve la lista de ordenes orginal (sin filtros)
    * @author msemeraro
    */
  restaurarOrdenes() {
    this.ordenesDeCompra = [];
    let estadoOrden;
    for (let entry of this.ordenesDeCompraOriginal) {
      estadoOrden = entry.estadoOrden.codigoEstado;
      if (estadoOrden != 7) {
        this.ordenesDeCompra.push(entry);
      }
      else if (this.filtro == 7 && estadoOrden == 7) {
        this.ordenesDeCompra.push(entry);
      }
      else if (this.filtro == 8 && estadoOrden == 8) {
        this.ordenesDeCompra.push(entry);
      }
    }
  }

  /**
  * Función que muestra el detalle de una orden
  * @author msemeraro
  */
  async verOrden(orden) {
    await this.misOrdenesComponent.verDetalleOrden(orden);
    this.router.navigate(['detalle-orden']);
  }

  /**
  * Función que redondea un número
  * @author msemeraro
  */
  getRoundNumber(numero: number) {
    return this.misOrdenesComponent.getRoundNumber(numero);
  }

  /**
  * Función que retorna el porcentaje de ganancia de una orden de compra
  * @author msemeraro
  */
  getPorcentajeGanancia(ganancia: number, totalOrden: number): number {
    let porcentaje = this.misOrdenesComponent.getPorcentajeGanancia(ganancia, totalOrden);
    return this.getRoundNumber(porcentaje);
  }

  /**
   * Indica si la orden de compra es de importación o no
   * @param orden Orden a evaluar
   * @returns True si es importación, false si no
   * @author gberrocal
   */
   isOrdenTipoImportacion(orden: OrdenDeCompra): boolean{
    return (orden && orden.estadoOrdenInventario.id === EnumEstadoOrdenInventario.IMPORTACION.codigoEstado);
  }

  /**
  * Función que muestra un mensaje
  * @author msemeraro
  */
  verMensaje() {
    this.tituloMensaje = this.misOrdenesComponent.getTituloMensaje();
    this.contenidoMensaje = this.misOrdenesComponent.getContenidoMensaje();
    this.esError = this.misOrdenesComponent.getEsError();
    var element = document.getElementById("btnAbrirMensaje") as any;
    element.click();
  }

  verPaginaPrincipal() {
    this.router.navigate(['']);
  }

  handlePage(e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }

}
