import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PaginatePipe } from 'src/app/pipes/paginate.pipe';
import { PipeModule } from 'src/app/pipes/pipe-module';
import { AdminMantenimientoMarcasBackview } from './admin-mantenimiento-marcas.component.backview';
import { NgxSpinnerModule } from "ngx-spinner";
import { MatMenuModule } from '@angular/material/menu';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../footer/footer.module';

@NgModule({
  declarations: [AdminMantenimientoMarcasBackview
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatPaginatorModule,
    PipeModule,
    NgxSpinnerModule,
    HeaderModule,
    FooterModule,
    MatMenuModule
  ],
  exports: [
    AdminMantenimientoMarcasBackview]
})
export class AdminMantenimientoMarcasModule { }
