import { Component, OnInit, ɵConsole, Injectable } from '@angular/core';
/* SERVICES */
import { HttpClientEnersolService } from 'src/services/http-client-enersol.service';
import { GlobalService } from 'src/services/global';
import { ValidationsService } from 'src/services/validations.service';
import { ErrorService } from 'src/services/error.service';
/* REST MODEL */
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
/* MODELS */
import { OrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/OrdenDeCompra';
import { DetalleOrdenCompraPorMarca } from 'src/models/com.enersol.directshopping.dal.entidad/DetalleOrdenCompraPorMarca';
import { EstadoOrden } from 'src/models/com.enersol.directshopping.dal.entidad/EstadoOrden';
import { EnumEstadoOrdenInventario } from 'src/models/com.enersol.directshopping.dal.enums/EnumEstadoOrdenInventario';

@Injectable()
export class AdminOrdenesController {

  /* Variable para controlar el estado de la marca seleccionada */
  codigoEstado = 0;
  nombreEstado = '';

  constructor(
    private httpClientEnersolService: HttpClientEnersolService,
    private globalEnersolService: GlobalService,
    private validationEnersolService: ValidationsService,
    private errorService: ErrorService
  ) { }

  /* FUNCIONES QUE HACEN PETICIONES A LOS SERVICIOS */

  /**
  * Función que hace la petición del servicio de todas las ordenes
  * @returns ListResponse<OrdenDeCompra>
  * @author msemeraro
  */
  async getOrdenes(): Promise<ListResponse<OrdenDeCompra>> {
    let respuesta: ListResponse<OrdenDeCompra>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('OrdenDeCompra/Admin');
      respuesta = <ListResponse<OrdenDeCompra>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<OrdenDeCompra>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      for (let orden of respuesta.data) {
        /*let detallesOrden = await this.getDetallesOrden(orden.codigoOrden);
        orden.detallesOrden = detallesOrden.data;*/
        orden.fechaAprobacionParaPricing = new Date(orden.fechaAprobacionParaPricing);
        orden.fecha = orden.fechaAprobacionParaPricing.toLocaleDateString();
        orden.estados = this.getColoresDetalles(orden.estados);
      }
      return respuesta;
    }
  }

  /**
  * Función que hace la petición del servicio de cambiar el estado a un detalle
  * @returns Response<DetalleOrdenCompraPorMarca>
  * @author msemeraro
  */
  async updateEstadoDetalleOrden(codDetalleOrden: number, codEstado: number, comentario: string): Promise<Response<DetalleOrdenCompraPorMarca>> {
    let respuesta: Response<DetalleOrdenCompraPorMarca>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('DetalleOrdenCompraPorMarca/update/state', this.getDatosUpdateEstadoDetalle(codDetalleOrden, codEstado, comentario));
      respuesta = <Response<DetalleOrdenCompraPorMarca>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<DetalleOrdenCompraPorMarca>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }


  /**
  * Función que obtiene los detalles de una orden de compra
  * @returns ListResponse<DetalleOrdenCompraPorMarca>
  * @author msemeraro
  */
  async getDetallesOrden(codOrden: number): Promise<ListResponse<DetalleOrdenCompraPorMarca>> {
    let respuesta: ListResponse<DetalleOrdenCompraPorMarca>;
    try {
      let path = 'DetalleOrdenCompraPorMarca/DetallesOrden?codOrden=' + codOrden;
      let promiseResponse = await this.httpClientEnersolService.httpGet(path);
      respuesta = <ListResponse<DetalleOrdenCompraPorMarca>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<DetalleOrdenCompraPorMarca>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que hace la petición del servicio de los posibles estados para un determinado estado
  * @returns ListResponse<EstadoOrden>
  * @author msemeraro
  */
  async getPosiblesEstados(esOrdenImportacion:boolean): Promise<ListResponse<EstadoOrden>> {
    let respuesta: ListResponse<EstadoOrden>;
    try {
      let promiseResponse = await this.httpClientEnersolService
      .httpGet('EstadoOrden/PosiblesEstados?codEstado=' + this.codigoEstado + '&tipoOrden=' + esOrdenImportacion);
      respuesta = <ListResponse<EstadoOrden>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<EstadoOrden>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }



  /* FUNCIONES QUE HACEN PETICIONES AL GLOBAL SERVICE */

  /**
  * Función que obtiene la cantidad de productos de una orden de compra
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
  async getCantidadProductos(codOrden: number) {
    let respuesta: Response<Number>;
    try {
      respuesta = await this.globalEnersolService.getCantidadProductosOrden(codOrden);
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Number>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que guarda las variables para mostrar un mensaje
  * @param tituloMensaje
  * @param contenidoMensaje
  * @param esError
  * @author msemeraro
  */
  setMensaje(tituloMensaje: String, contenidoMensaje: String, esError: Boolean): void {
    this.globalEnersolService.setTituloMensaje(tituloMensaje);
    this.globalEnersolService.setContenidoMensaje(contenidoMensaje);
    this.globalEnersolService.setEsError(esError);
  }

  /**
  * Función que obtiene el titulo de un mensaje
  * @return tituloMensaje 
  * @author msemeraro
  */
  getTituloMensaje(): String {
    return this.globalEnersolService.getTituloMensaje();
  }

  /**
  * Función que obtiene el contenido de un mensaje
  * @return contenidoMensaje 
  * @author msemeraro
  */
  getContenidoMensaje(): String {
    return this.globalEnersolService.getContenidoMensaje();
  }

  /**
  * Función que obtiene si un mensaje es error o no
  * @return esError 
  * @author msemeraro
  */
  getEsError(): Boolean {
    return this.globalEnersolService.getEsError();
  }

  /**
  * Función que obtiene si el usuario logueado es administrador de enersol
  * @return boolean 
  * @author msemeraro
  */
  isAdminEnersol() {
    return this.globalEnersolService.isAdminEnersol();
  }

  /**
  * Función que almacena en el global service la orden de compra a mostrar
  * @param orden
  * @author msemeraro
  */
  async verDetalleOrden(orden: OrdenDeCompra) {
    if (orden.detallesOrden == null) {
      let detallesOrden = await this.getDetallesOrden(orden.codigoOrden);
      orden.detallesOrden = detallesOrden.data;
    }
    this.globalEnersolService.setOrdenDeCompraImportacion(orden);
  }


  /* FUNCIONES QUE HACEN ORDENAMIENTOS O FILTROS */

  /**
  * Función que filtra una lista de ordenes según el estado indicado
  * @param listaOrdenes Lista de ordenes a filtrar
  * @param codEstado codigo del estado por filtrar
  * @returns OrdenDeCompra[]
  * @author msemeraro
  */
  filterOrdenesEstado(listaOrdenes: Array<OrdenDeCompra>, codEstado: number): OrdenDeCompra[] {
    return listaOrdenes.filter((item) => {
      for (let estado of item.estados) {
        if (estado == codEstado)
          return true;
      } return false;
    });
  }

  /**
  * Función que filtra una lista de ordenes según el estado orden inventario
  * @param listaOrdenes Lista de ordenes a filtrar
  * @param codEstadoInventario codigo del estado por filtrar
  * @returns OrdenDeCompra[]
  * @author gberrocal
  */
   filtrarOrdenesEstadoInventario(listaOrdenes: Array<OrdenDeCompra>, codEstadoInventario: number): OrdenDeCompra[] {
    return listaOrdenes.filter((item) => {
      if(item.estadoOrdenInventario){
        return item.estadoOrdenInventario.id == codEstadoInventario;
      }
      return false;
    });
  }

  /**
  * Función que filtra una lista de ordenes según la marca que tengan (si tienen)
  * @param listaOrdenes Lista de ordenes a filtrar
  * @param codEstadoInventario codigo del estado por filtrar
  * @returns OrdenDeCompra[]
  * @author gberrocal
  */
  filtrarOrdenesMarca(listaOrdenes: Array<OrdenDeCompra>, codEstadoInventario: number): OrdenDeCompra[] {
    return listaOrdenes.filter((item) => {
      if(item.marcadaComo){
        return item.marcadaComo.id == codEstadoInventario;
      }
      return false;
    });
  }

  /**
  * Función que ordena una lista de ordenes según los parámetros
  * @param prop atributo de la lista por el cual ordenar
  * @param listaOrdenes lista de ordenes a ordenar
  * @param ordenamiento ordenamiento a utilizar (mayor a menor o alrevés)
  * @returns OrdenDeCompra[] lista de ordenes ordenada
  * @author msemeraro
  */
  orderBy(prop: string, listaOrdenes: Array<OrdenDeCompra>, ordenamiento: string): OrdenDeCompra[] {
    if (prop == "estacion") {
      return listaOrdenes.sort((a, b) => b[prop]['codigoEstacion'] > a[prop]['codigoEstacion'] ? 1 : a[prop]['codigoEstacion'] === b[prop]['codigoEstacion'] ? 0 : -1);
    }
    if (ordenamiento == "Mayor") {
      return listaOrdenes.sort((a, b) => b[prop] > a[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    } else {
      return listaOrdenes.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    }
  }

  /**
  * Función que aplica un filtro despues de ordenar (refrescar la lista)
  * @param listaOrdenes lista de ordenes a ordenar
  * @returns OrdenDeCompra[] 
  * @author msemeraro
  */
  filterOrdenesOrderBy(listaOrdenes: Array<OrdenDeCompra>): OrdenDeCompra[] {
    return listaOrdenes.filter((item) => {
      return true;
    });
  }




  /* OTRAS FUNCIONES */

  /**
  * Función que obtiene el JSON para actualizar el estado de un detalle de orden
  * @param codDetalleOrden
  * @param codEstado
  * @returns json
  * @author msemeraro
  */
  getDatosUpdateEstadoDetalle(codDetalleOrden: number, codEstado: number, comentario: string) {
    return {
      "codigoDetalleOrden": codDetalleOrden,
      "codigoEstado": codEstado,
      "comentario": comentario
    };
  }

  /**
  * Función que formatea un número
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
  getFormatNumber(numero: number) {
    return this.validationEnersolService.getNumber(numero);
  }

  /**
  * Función que obtiene los códigos de estados de los detalles de una orden
  * @param detalles
  * @returns lista de números de estados
  * @author msemeraro
  */
  getColoresDetalles(listaEstados: number[]) {
    listaEstados.sort((n1, n2) => n1 - n2);
    let sinRepetidos = [...new Set(listaEstados)];
    return sinRepetidos;
  }

  /**
  * Función que carga las marcas de los detalles de las ordenes seleccionadas
  * @param listaOrdenes
  * @returns lista de marcas
  * @author msemeraro
  */
  async cambiarEstado(listaOrdenes: Array<OrdenDeCompra>) {
    let listaMarcas = [];
    let detallesOrden;
    let marca;
    for (let orden of listaOrdenes) {
      if (orden.detallesOrden == null) {
        detallesOrden = await this.getDetallesOrden(orden.codigoOrden);
        orden.detallesOrden = detallesOrden.data;
      }
      for (let detalle of orden.detallesOrden) {
        marca = detalle.marca.nombre;/*{
          codigo: detalle.marca.codigoMarca,
          nombre: detalle.marca.nombre
        }*/
        listaMarcas.push(marca);
      }
    }
    let listaMarcasSinRepetidas = [...new Set(listaMarcas)];
    return listaMarcasSinRepetidas;
  }

  /**
  * Función que retorna si es valido el cambio de estado de una marca para una serie de ordenes
  * @param listaOrdenes
  * @param marca
  * @returns boolean
  * @author msemeraro
  */
  isValidoEstado(listaOrdenes: Array<OrdenDeCompra>, marca: string): boolean {
    // Variable para almacenar el codigo del detalle
    this.codigoEstado = 0;
    this.nombreEstado = '';
    let detalle;
    // Recorre las ordenes para ver si el los detalles cumplen con el mismo estado
    for (let orden of listaOrdenes) {
      //let tieneMarca = false;
      // Recorre los detalles para encontrar el detalle de la marca
      for (detalle of orden.detallesOrden) {
        // Valida si se cargo el codigo de estado de la marca
        if (detalle.marca.nombre === marca) {
          //tieneMarca = true;
          if (this.codigoEstado == 0) {
            this.codigoEstado = detalle.estadoOrden.codigoEstado;
            this.nombreEstado = detalle.estadoOrden.nombre;
          }
          else {
            // Valida que todos los detalles de la marca de las ordenes tengan el mismo estado
            if (detalle.estadoOrden.codigoEstado != this.codigoEstado)
              return false;
          }
        }
      }
      //if (!tieneMarca)
        //return false;
    }
    return true;
  }


  /**
  * Función que retorna si todas las marcas de una lista de ordenes tienen el mismo estado
  * @param listaOrdenes
  * @param codEstado
  * @returns boolean
  * @author msemeraro
  */
  isMismoEstado(listaOrdenes: Array<OrdenDeCompra>): boolean {
    // Variable para almacenar el codigo del detalle
    this.codigoEstado = 0;
    this.nombreEstado = '';
    let detalle;
    // Recorre las ordenes para ver si el los detalles cumplen con el mismo estado
    for (let orden of listaOrdenes) {
      // Recorre los detalles para encontrar el estado
      for (detalle of orden.detallesOrden) {
        // Valida si se cargo el codigo de estado de la marca
        if (this.codigoEstado == 0) {
          this.codigoEstado = detalle.estadoOrden.codigoEstado;
          this.nombreEstado = detalle.estadoOrden.nombre;
        }
        else {
          // Valida si es el mismo estado
          if (detalle.estadoOrden.codigoEstado != this.codigoEstado)
            return false;
        }
      }
    }
    return true;
  }





  /**
  * Función que retorna si todas las ordenes son de importación o todas son de inventario
  * @param listaOrdenes
  * @param codEstado
  * @returns boolean
  * @author msemeraro
  */
   isMismoTipoOrden(listaOrdenes: Array<OrdenDeCompra>): boolean {
    // Variable para almacenar el codigo del detalle
    // Recorre las ordenes para ver si son del mismo tipo
    let estadoImportacion:boolean = false;
    let estadoInventario:boolean = false;
    for (let orden of listaOrdenes) {
      if(orden.estadoOrdenInventario.estado == EnumEstadoOrdenInventario.IMPORTACION.nombre) {
        estadoImportacion = true;
      }

      if(orden.estadoOrdenInventario.estado == EnumEstadoOrdenInventario.INVENTARIO.nombre) {
        estadoInventario = true;
      }

      if(estadoInventario && estadoImportacion) {
        return false;
      }
    }
    return true;
  }

  /**
  * Función que recorre una lista de ordenes para actualizarles el estado
  * @param listaOrdenes
  * @param marca
  * @param estado
  * @author msemeraro
  */
  async updateEstadosDetallesOrdenes(listaOrdenes: Array<OrdenDeCompra>, marca: string, estado: number, comentario: string): Promise<boolean> {
    
    let respuesta;
    let detalle;
    let detallesOrden;
    for (let orden of listaOrdenes) {
      if (orden.detallesOrden == null) {
        detallesOrden = await this.getDetallesOrden(orden.codigoOrden);
        orden.detallesOrden = detallesOrden.data;
      }
      for (detalle of orden.detallesOrden) {
        if (detalle.marca.nombre === marca) {
          respuesta = await this.updateEstadoDetalleOrden(detalle.codigoDetalleOrden, estado, comentario);
          if (!respuesta.sucess) {
            this.errorService.displayError(respuesta.error);
            return false;
          }
          else {
          }
        }
      }
    }
    return true;
  }



  /**
  * Función que valida si un comentario es valido o no
  * @param comentario
  * @returns boolean
  * @author msemeraro
  */
  validateComentario(comentario: string): boolean {
    return this.validationEnersolService.validateComentarioCambioEstado(comentario);
  }


}
