import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
/* SERVICES */
import { NgxSpinnerService } from "ngx-spinner";
import { ErrorService } from 'src/services/error.service';
import { ExcelService } from 'src/services/excel.service';
import { ValidationsService } from 'src/services/validations.service';
import { PageEvent } from '@angular/material/paginator';
import { AdminMantenimientoProductosController } from './admin-mantenimiento-productos.component.controller';
/* MODELS */
import { Producto } from 'src/models/com.enersol.directshopping.dal.entidad/Producto';
import { Marca } from 'src/models/com.enersol.directshopping.dal.entidad/Marca';
import { Membresia } from 'src/models/com.enersol.directshopping.dal.entidad/Membresia';
import { Familia } from 'src/models/com.enersol.directshopping.dal.entidad/Familia';
import { SubFamilia } from 'src/models/com.enersol.directshopping.dal.entidad/SubFamilia';
import { ProductoId } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoId';
import { GlobalService } from 'src/services/global';
import { DatosInventarioProducto } from 'src/models/com.enersol.directshopping.bl.model/DatosInventarioProducto';
import { ResultadoRevisionDatosInventarioProducto } from 'src/models/com.enersol.directshopping.bl.model/ResultadoRevisionDatosInventarioProducto';
import { EnumEstadoInventarioProducto } from 'src/models/com.enersol.directshopping.dal.enums/EnumEstadoInventarioProducto';

@Component({
  selector: 'app-admin-mantenimiento-productos',
  templateUrl: './admin-mantenimiento-productos.component.html',
  styleUrls: ['./admin-mantenimiento-productos.component.scss'],
  providers: [AdminMantenimientoProductosController]
})
export class AdminMantenimientoProductosBackview implements OnInit {

  /* Variables para controlar los roles del usuario */
  isAdmin: Boolean;

  /* Variables para el nuevo producto */
  nuevoProducto: Producto = new Producto();

  /* Variables para los catalogos */
  listaProductos: Producto[] = null;
  listaMarcas: Marca[] = null;
  listaMembresias: Membresia[] = null;
  listaFamilias: Familia[] = null;
  listaSubFamilias: SubFamilia[] = null;

  /* Variable para los mensajes */
  contenidoMensaje: String;
  tituloMensaje: String;
  esError: Boolean = false;

  /* Variables para la paginacion */
  page_size: number = 10;
  page_number: number = 1;
  pageSizeOptions = [5, 10, 20, 50, 100];

  page_sizeEncontrados: number = 5;
  page_numberEncontrados: number = 1;

  page_sizeNoEncontrados: number = 5;
  page_numberNoEncontrados: number = 1;


  // Variables para la visualización de resultados para carga de invenario
  productosEncontrados: DatosInventarioProducto[];
  productosNoEncontrados: DatosInventarioProducto[];
  productosCorrectos: DatosInventarioProducto[];
  idBtnModalRevisionInventario = "btnModalRevisionInventario";


  constructor(
    private router: Router,
    private adminMantenimientosComponent: AdminMantenimientoProductosController,
    private errorService: ErrorService,
    private excelService: ExcelService,
    public globalEnersolService: GlobalService,
    private validationEnersolService: ValidationsService,
    private spinner: NgxSpinnerService
  ) {
  }

  async ngOnInit() {
    this.isAdmin = await this.adminMantenimientosComponent.isAdminEnersol();
    this.spinnerShow();
    await this.getMarcas();
    await this.getMembresias();
    await this.getFamilias();

    this.productosEncontrados = [];
    this.productosNoEncontrados = [];

    this.spinnerHide();
  }

  /**
  * Función que obtiene las marcas registradas
  * @author msemeraro
  */
  async getMarcas() {
    let marcas = await this.adminMantenimientosComponent.getMarcas();
    if (!marcas.sucess) {
      let mensaje = this.errorService.displayError(marcas.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.listaMarcas = marcas.data;
      this.nuevoProducto.marca = this.listaMarcas[0];
    }
  }

  /**
  * Función que obtiene las familias registradas
  * @author msemeraro
  */
  async getFamilias() {
    let familias = await this.adminMantenimientosComponent.getFamilias();
    if (!familias.sucess) {
      let mensaje = this.errorService.displayError(familias.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.listaFamilias = familias.data;
      await this.getSubFamilias(this.listaFamilias[0].codigoFamilia);
    }
  }

  /**
  * Función que obtiene las familias registradas
  * @author msemeraro
  */
  async getSubFamilias(codFamilia:number) {
    let subFamilias = await this.adminMantenimientosComponent.getSubFamiliasFamilia(codFamilia);
    if (!subFamilias.sucess) {
      let mensaje = this.errorService.displayError(subFamilias.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.listaSubFamilias = subFamilias.data;
      this.nuevoProducto.subFamilia = this.listaSubFamilias[0];
    }
  }

  /**
  * Función que obtiene los productos de una marca
  * @author msemeraro
  */
  async getProductosMarca(marca: Marca) {
    let productos = await this.adminMantenimientosComponent.getProductosMarca(marca.codigoMarca);
    if (!productos.sucess) {
      let mensaje = this.errorService.displayError(productos.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      marca.listaProductos = productos.data;
    }

  }

  /**
  * Función que obtiene las membresias registradas
  * @author msemeraro
  */
  async getMembresias() {
    let membresias = await this.adminMantenimientosComponent.getMembresias();
    if (!membresias.sucess) {
      let mensaje = this.errorService.displayError(membresias.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.listaMembresias = membresias.data;
      this.nuevoProducto.membresia = this.listaMembresias[0];
    }
  }

  /**
  * Función que elimina un producto
  * @author msemeraro
  */
  async eliminarProducto(producto: Producto) {
    var confirmacion = confirm("¿Desea eliminar la producto " + producto.nombre + "?");
    if (confirmacion) {
      this.spinnerShow();
      let respuesta = await this.adminMantenimientosComponent.eliminarProducto(producto);
      if (!respuesta.sucess) {
        let mensaje = this.errorService.displayError(respuesta.errors);
        this.adminMantenimientosComponent.setMensaje('Error al eliminar la producto', mensaje, true);
        this.verMensaje();
      }
      else {
        await this.getMarcas();
      }
      this.spinnerHide();
    }
  }

  /**
  * Función que actualiza un producto
  * @author msemeraro
  */
  async actualizarProducto(producto: Producto) {
    this.spinnerShow();
    let respuesta = await this.adminMantenimientosComponent.actualizarProducto(producto);
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al actualizar la producto', mensaje, true);
      this.verMensaje();
    }
    else {
      await this.getMarcas();
    }
    this.spinnerHide();
  }

  /**
  * Función que valida los datos del nuevo producto a registrar
  * @author msemeraro
  */
  validarDatosNuevoProducto():boolean{
    if (!this.adminMantenimientosComponent.validarNombre(this.nuevoProducto.nombre)) {
      this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar un nombre válido", true);
      this.verMensaje();
      return false;
    }
    if (!this.adminMantenimientosComponent.validarNombre(this.nuevoProducto.descripcion)) {
      this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar una descripción válida", true);
      this.verMensaje();
      return false;
    }
    if (!this.adminMantenimientosComponent.validarNombre(this.nuevoProducto.analisisLaboratorio)) {
      this.nuevoProducto.analisisLaboratorio = "";
      //this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar un análisis de laboratorio", true);
      //this.verMensaje();
     // return false;
    }
    if (!this.adminMantenimientosComponent.validarNombre(this.nuevoProducto.fichaTecnica)) {
      this.nuevoProducto.fichaTecnica = "";
      // this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar una ficha técnica", true);
     // this.verMensaje();
     // return false;
    }
    if (!this.adminMantenimientosComponent.validarNombre(this.nuevoProducto.hojaSeguridad)) {
      this.nuevoProducto.hojaSeguridad = "";
      //this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar una hoja de seguridad", true);
      //this.verMensaje();
      //return false;
    }
    return true;
  }

  /**
  * Función que agrega un nuevo producto
  * @author msemeraro
  */
  async agregarProducto() {
    
    let  productoId:ProductoId = new ProductoId();
    productoId.codigoMarca = this.nuevoProducto.marca.codigoMarca
    this.nuevoProducto.id = productoId;
    
    if (!this.validarDatosNuevoProducto()) {
      return;
    }
    this.spinnerShow();
    let respuesta = await this.adminMantenimientosComponent.agregarProducto(this.nuevoProducto);
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al agregar la producto', mensaje, true);
      this.verMensaje();
    }
    else {
      $(".close").click();
      this.nuevoProducto.nombre = "";
      await this.getMarcas();
    }
    this.spinnerHide();
  }

  /**
  * Función que controla el cambio de membresia en un producto registrado
  * @author msemeraro
  */
  changeMembresia(codMembresia: number, producto: Producto) {

    let posicion: number
    let marca;
    let productoActualizar;
    
    for (let membresia of this.listaMembresias) {
      if (membresia.codigoMembresia == codMembresia) {
        posicion= 0;
        for (marca of this.listaMarcas) {
          if (marca.listaProductos != null) {
            for (productoActualizar of marca.listaProductos) {
              if (productoActualizar.id.codigoProducto == producto.id.codigoProducto) {
                marca.listaProductos[posicion].membresia = membresia;
                break;
              }
              posicion++;
            }
          }
        }
      }
    }
  }

  /**
  * Función que controla el cambio de sub familia en el nuevo producto
  * @author msemeraro
  */
  changeSubFamiliaNuevo(codSubFamilia:number){
    for(let subFamilia of this.listaSubFamilias){
      if(subFamilia.id.codigoSubFamilia == codSubFamilia){
        this.nuevoProducto.subFamilia = subFamilia;
        break;
      }
    }
  }

  /**
  * Función que controla el cambio de membresia en el nuevo producto
  * @author msemeraro
  */
  changeMembresiaNuevo(codMembresia: number) {
    for (let membresia of this.listaMembresias) {
      if (membresia.codigoMembresia == codMembresia) {
        this.nuevoProducto.membresia = membresia;
        break;
      }
    }
  }

  /**
  * Función que controla el cambio de marca en el nuevo producto
  * @author msemeraro
  */
  changeMarcaNuevo(codMarca: number) {
    for (let marca of this.listaMarcas) {
      if (marca.codigoMarca == codMarca) {
        this.nuevoProducto.marca = marca;
        break;
      }
    }
  }

  /**
  * Función que muestra los productos registrados de una marca
  * @author msemeraro
  */
  async verProductos(marca: Marca) {
    if (marca.listaProductos == null) {
      this.spinnerShow();
      await this.getProductosMarca(marca);
      this.spinnerHide();
    }
    marca.verProductos = !marca.verProductos;
  }

  /**
  * Función que muestra las presentaciones de un producto
  * @author msemeraro
  */
  async verProducto(codProducto: number, codMarca: number) {
    await this.adminMantenimientosComponent.verProducto(codProducto, codMarca);
    this.router.navigate(['admin-mantenimiento-productos-presentacion']);
  }

  /**
  * Función que agrega la ficha técnica al producto
  * @author msemeraro
  */
  async agregarFichaTecnica(event) {
    if (event.target.files[0] != null) {
      let archivo:File = <File>event.target.files[0];
      if (!this.adminMantenimientosComponent.validatePDF(archivo)) {
        this.adminMantenimientosComponent.setMensaje('Error', "Debe de adjuntar un PDF válido", true);
      this.verMensaje();
      }
      else {
        // Agregar el PDF
        this.nuevoProducto.fichaTecnica = archivo.name;
        await this.getFichaTecnicaBase64(archivo);
      }
    }
  }

  /**
  * Función que agrega la ficha técnica al producto
  * @author msemeraro
  */
  async agregarHojaSeguridad(event) {
    if (event.target.files[0] != null) {
      let archivo:File = <File>event.target.files[0];
      if (!this.adminMantenimientosComponent.validatePDF(archivo)) {
        this.adminMantenimientosComponent.setMensaje('Error', "Debe de adjuntar un PDF válido", true);
      this.verMensaje();
      }
      else {
        // Agregar el PDF
        this.nuevoProducto.hojaSeguridad = archivo.name;
        await this.getHojaSeguridadBase64(archivo);
      }
    }
  }

  /**
  * Función que carga un archivo de inventario de productos y lo muestra para su verificación
  * @author gberrocal
  */
  async validarCargarInventarioProductos(event) {
    this.spinnerShow();
    // Limpia las variables
    this.productosEncontrados = [];
    this.productosNoEncontrados = [];

    if (event.target.files[0] != null) {
      let archivo:File = <File>event.target.files[0];
      if (this.adminMantenimientosComponent.validateXLSX(archivo)) {
        // Lee el archivo y extrae las filas
        let filas = await this.excelService.leerArchivoInventarioProductos(archivo);
        // Verifica el formato del archivo
        let mensaje: string = this.excelService.validarFormatoFilasInventarioProductos(filas);

        if(mensaje != null){
          this.spinnerHide();
          this.adminMantenimientosComponent.setMensaje('Archivo con mal formato', mensaje, true);
          this.verMensaje();
        }
        else{
          let datos: DatosInventarioProducto[] = this.excelService.filasInventarioProductosToList(filas);

          // Revisa que todo se pueda cargar
          this.validarDatosCarga(datos);

          let respuesta = await this.adminMantenimientosComponent.revisarDatosInventarioProducto(this.productosCorrectos);
          if (respuesta.sucess && respuesta.data != null && respuesta.data !== undefined) {
            let revision: ResultadoRevisionDatosInventarioProducto = respuesta.data;

            // Actualiza las variables
            this.productosEncontrados = revision.encontrados;
            this.productosNoEncontrados = this.productosNoEncontrados.concat(revision.noEncontrados);

            // Abre el modal para ver la previsualización de la carga
            this.verModalRevisionInventario();
          }
          else {
            let mensaje = this.errorService.displayError(respuesta.errors);
            this.adminMantenimientosComponent.setMensaje('Error al verificar los productos', "No se pudo verificar los productos", true);
            this.verMensaje();
          }

          this.spinnerHide();
        }
      }
      else {
        this.spinnerHide();
        this.adminMantenimientosComponent.setMensaje('Error', "Debe de adjuntar un XLSX válido", true);
        this.verMensaje();
      }
    }
  }
  
  // Revisa que todo se pueda cargar
  validarDatosCarga(datos: DatosInventarioProducto[]) {
    let strSi1:string = "SÍ";
    let strSi2:string = "SI";
    let strNo:string = "NO";
    let registroValido:boolean;
    this.productosCorrectos = [];
    this.productosNoEncontrados = [];

    for(let registro of datos){
      // Inicia en true
      registroValido = true;

      // Valida la cantidad de Inventario
      if(typeof registro.cantidadInventario === 'string'){
        let strCantidad:string = registro.cantidadInventario;
        if(!this.validationEnersolService.validateNumeric(strCantidad)){
          registro.comentario = "La cantidad de inventario no corresponde a un número";
          registroValido = false;
        }
      }else{
        if(registro.cantidadInventario < 0){
          registro.comentario = "La cantidad de inventario no es válida";
          registroValido = false;
        }
      }

      // Valida la cantidad de Importacion
      if(typeof registro.cantidadImportacion === 'string'){
        let strCantidad:string = registro.cantidadImportacion;
        if(!this.validationEnersolService.validateNumeric(strCantidad)){
          registro.comentario = "La cantidad de importación no corresponde a un número";
          registroValido = false;
        }
      }else{
        if(registro.cantidadImportacion < 0){
          registro.comentario = "La cantidad de importación no es válida";
          registroValido = false;
        }
      }

      // Valida el manejar por inventario
      if(registro.manejarPorInventario){
        if(registro.manejarPorInventario.toUpperCase() !== strSi1 && registro.manejarPorInventario.toUpperCase() !== strSi2
          && registro.manejarPorInventario.toUpperCase() !== strNo){
          registro.comentario = "El dato de manejar por inventario no es correcto";
          registroValido = false;
        }
      }else{
        registro.comentario = "El dato de manejar por inventario no es correcto";
        registroValido = false;
      }

      // Valida el estado
      if(registro.estadoInventario){
        if(registro.estadoInventario !== EnumEstadoInventarioProducto.INVENTARIO.nombre
          && registro.estadoInventario !== EnumEstadoInventarioProducto.FUERA_INVENTARIO.nombre
          && registro.estadoInventario !== EnumEstadoInventarioProducto.PROXIMAMENTE.nombre
          && registro.estadoInventario !== EnumEstadoInventarioProducto.PRODUCCION_SUSPENDIDA.nombre) {
          registro.comentario = "El estado no es correcto";
          registroValido = false;
        }
      }else{
        registro.comentario = "El estado no es correcto";
        registroValido = false;
      }

      // Valida que tenga alguna cantidad
      if(registro.cantidadInventario === 0 && registro.cantidadImportacion === 0){
        registro.comentario = "Debe indicar una cantidad de inventario o de importación";
        registroValido = false;
      }

      // Si todo esta correcto hasta ahora, revisa que el estado tenga sentido
      if(registroValido) {
        // Valida que el estado tenga sentido con el flag
        if((registro.manejarPorInventario.toUpperCase() === strSi1 || registro.manejarPorInventario.toUpperCase() === strSi2)
          && registro.estadoInventario === EnumEstadoInventarioProducto.PROXIMAMENTE.nombre){
          registro.comentario = "El dato de manejar por inventario no corresponde al estado";
          registroValido = false;
        }
        if(registro.manejarPorInventario.toUpperCase() === strNo
          && (registro.estadoInventario === EnumEstadoInventarioProducto.INVENTARIO.nombre 
            || registro.estadoInventario === EnumEstadoInventarioProducto.FUERA_INVENTARIO.nombre)){
          registro.comentario = "El dato de manejar por inventario no corresponde al estado";
          registroValido = false;
        }

        // Valida que el estado tenga sentido con la cantidad
        if(registro.cantidadInventario === 0 && registro.estadoInventario === EnumEstadoInventarioProducto.INVENTARIO.nombre){
          registro.comentario = "La cantidad de inventario no corresponde al estado";
          registroValido = false;
        }
        else if(registro.cantidadInventario > 0 && registro.estadoInventario === EnumEstadoInventarioProducto.FUERA_INVENTARIO.nombre){
          registro.comentario = "La cantidad de inventario no corresponde al estado";
          registroValido = false;
        }
        else if(registro.cantidadInventario > 0 && registro.estadoInventario === EnumEstadoInventarioProducto.PROXIMAMENTE.nombre){
          registro.comentario = "La cantidad de inventario no corresponde al estado";
          registroValido = false;
        }
      }

      // Si esta correcto, lo agrega a la lista que se envia
      if(registroValido){
        this.productosCorrectos.push(registro);
      }else{ // Sino, lo coloca en la lista de no encontrados
        this.productosNoEncontrados.push(registro);
      }
    }
  }

  /**
  * Función que aplica los cambios del inventario cargado de productos
  * @author gberrocal
  */
   async aplicarActualizarInventarioProductos() {
    this.spinnerShow();

    if (this.productosEncontrados && this.productosEncontrados.length > 0) {
      let respuesta = await this.adminMantenimientosComponent.actualizarDatosInventario(this.productosEncontrados);
      if (respuesta.sucess && respuesta.data != null && respuesta.data !== undefined) {
        this.adminMantenimientosComponent.setMensaje('Información', "La actualización de inventario se realizó correctamente", false);
        this.verMensaje();

        // Limpia las variables
        this.productosEncontrados = [];
        this.productosNoEncontrados = [];
      }
      else {
        let mensaje = this.errorService.displayError(respuesta.errors);
        this.adminMantenimientosComponent.setMensaje('Error', "Ocurrió un error al actualizar los productos", true);
        this.verMensaje();
      }
      this.spinnerHide();
    }
    else {
      this.spinnerHide();
      this.adminMantenimientosComponent.setMensaje('Error', "No se han identificado productos para actualizar", true);
      this.verMensaje();
    }
  }

  /**
  * Función que agrega la ficha técnica al producto
  * @author msemeraro
  */
  async agregarAnalisisLaboratorio(event) {
    if (event.target.files[0] != null) {
      let archivo:File = <File>event.target.files[0];
      if (!this.adminMantenimientosComponent.validatePDF(archivo)) {
        this.adminMantenimientosComponent.setMensaje('Error', "Debe de adjuntar un PDF válido", true);
      this.verMensaje();
      }
      else {
        // Agregar el PDF
        this.nuevoProducto.analisisLaboratorio = archivo.name;
        await this.getAnalisisLaboratorioBase64(archivo);
      }
    }
  }

  async getFichaTecnicaBase64(file:File){
    var myReader:FileReader = new FileReader();
    myReader.onloadend = () => {
      this.nuevoProducto.fichaTecnicaBase64 =  myReader.result;
    }
    await myReader.readAsDataURL(file);
  }

  async getHojaSeguridadBase64(file:File){
    var myReader:FileReader = new FileReader();
    myReader.onloadend = () => {
      this.nuevoProducto.hojaSeguridadBase64 =  myReader.result;
    }
    await myReader.readAsDataURL(file);
  }

  async getAnalisisLaboratorioBase64(file:File){
    var myReader:FileReader = new FileReader();
    myReader.onloadend = () => {
      this.nuevoProducto.analisisLaboratorioBase64 =  myReader.result;
    }
    await myReader.readAsDataURL(file);
  }

  /**
  * Función que navega a otra pagina
  * @author msemeraro
  */
  irPagina(nombre: string) {
    this.router.navigate([nombre]);
  }

  spinnerShow() {
    this.spinner.show();
  }

  spinnerHide() {
    this.spinner.hide();
  }

  /**
  * Función que muestra un mensaje
  * @author msemeraro
  */
  verMensaje() {
    this.tituloMensaje = this.adminMantenimientosComponent.getTituloMensaje();
    this.contenidoMensaje = this.adminMantenimientosComponent.getContenidoMensaje();
    this.esError = this.adminMantenimientosComponent.getEsError();
    var element = document.getElementById("btnAbrirMensaje") as any;
    element.click();
  }

  /**
   * Abre el modal para revisar la carga de inventario
   */
  verModalRevisionInventario() {
    this.esError = false;
    var element = document.getElementById(this.idBtnModalRevisionInventario) as any;
    element.click();
  }

  clickModalMensaje() { }

  clickModalCambioEstado() { }

  handlePage(e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }

  handlePageEncontrados(e: PageEvent) {
    this.page_sizeEncontrados = e.pageSize;
    this.page_numberEncontrados = e.pageIndex + 1;
  }

  handlePageNoEncontrados(e: PageEvent) {
    this.page_sizeNoEncontrados = e.pageSize;
    this.page_numberNoEncontrados = e.pageIndex + 1;
  }
}
