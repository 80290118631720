import { Component, OnInit, ɵConsole, Injectable } from '@angular/core';
/* SERVICES */
import { HttpClientEnersolService } from 'src/services/http-client-enersol.service';
import { GlobalService } from 'src/services/global';
import { ValidationsService } from 'src/services/validations.service';
/* REST MODEL */
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
import { Parametro } from 'src/models/com.enersol.directshopping.dal.entidad/Parametro';
/* MODELS */

@Injectable()
export class AdminMantenimientoParametrosController {

  constructor(
    private httpClientEnersolService: HttpClientEnersolService,
    private globalEnersolService: GlobalService,
    private validationEnersolService: ValidationsService
  ) { }


  /* FUNCIONES QUE HACEN PETICIONES A LOS SERVICIOS */

  /**
   * Función que obtiene un parametro
   * @returns Response<Parametro>
   * @author msemeraro
   */
  async getParametro(nombre: string): Promise<Response<Parametro>> {
    let respuesta: Response<Parametro>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('Parametro/id?nombre=' + nombre);
      respuesta = <Response<Parametro>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Parametro>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
 * Función que actualiza los Parámetros fecha de pricing, fecha limite pago y tipo de cambio
 * @returns void
 * @author msemeraro
 */
  async actualizarParametroFecha(nombre: string, fecha: Date) {
    // Actualiza los Parámetros
    let fechaString:string = this.validationEnersolService.formatDate_DDMMYYYY(fecha);
    let parametro: Parametro = new Parametro();
    parametro.nombre = nombre;
    parametro.valor = fechaString;
    return await this.actualizarParametro(parametro);
  }

  /**
  * Función que actualiza una parametro
  * @returns ListResponse<Parametro>
  * @author msemeraro
  */
  async actualizarParametro(parametro: Parametro): Promise<Response<Parametro>> {
    let respuesta: Response<Parametro>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('Parametro/update', parametro);
      respuesta = <Response<Parametro>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Parametro>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función que hace la peticion al servidor de agregar una parametro
    * @returns Response<Parametro>
    * @author msemeraro
    */
  async agregarParametro(parametro: Parametro): Promise<Response<Parametro>> {
    let respuesta: Response<Parametro>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('Parametro/insert', parametro);
      respuesta = <Response<Parametro>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Parametro>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }



  /* FUNCIONES QUE HACEN PETICIONES AL GLOBAL SERVICE */

  /**
  * Función que obtiene el tipo de cambio
  * @return tipoCambio 
  * @author msemeraro
  */
  async getTipoCambio() {
    await this.globalEnersolService.setTipoCambio();
    let tipoCambio: Parametro = this.globalEnersolService.getTipoCambio();
    return Number(tipoCambio.valor);
  }

  /**
  * Función que obtiene si el usuario logueado es administrador de enersol
  * @return boolean 
  * @author msemeraro
  */
  isAdminEnersol() {
    return this.globalEnersolService.isAdminEnersol();
  }


  /* FUNCIONES QUE HACEN ORDENAMIENTOS O FILTROS */





  /* OTRAS FUNCIONES */

  /**
  * Función que valida si una fecha es mayor a la actual
  * @param fecha
  * @returns boolean
  * @author msemeraro
  */
  validarFecha(fecha: Date) {
    let fechaActual = Date.now();
    return (fecha != null && fechaActual < fecha.getTime());
  }

  /**
 * Función que actualiza los Parámetros fecha de pricing, fecha limite pago y tipo de cambio
 * @returns void
 * @author msemeraro
 */
  actualizarStringFecha(fecha: string) {
    return this.validationEnersolService.changeString(fecha, '/', '-');
  }

  validarNumero(numero: string) {

  }

  /**
  * Función que valida el nombre de la nueva parametro
  * @param nombre 
  * @author msemeraro
  */
  validarNombre(nombre: string): Boolean {
    return this.validationEnersolService.validateGeneral(nombre);
  }

  /**
* Función que guarda las variables para mostrar un mensaje
* @param tituloMensaje
* @param contenidoMensaje
* @param esError
* @author msemeraro
*/
  setMensaje(tituloMensaje: String, contenidoMensaje: String, esError: Boolean): void {
    this.globalEnersolService.setTituloMensaje(tituloMensaje);
    this.globalEnersolService.setContenidoMensaje(contenidoMensaje);
    this.globalEnersolService.setEsError(esError);
  }

  /**
  * Función que obtiene el titulo de un mensaje
  * @return tituloMensaje 
  * @author msemeraro
  */
  getTituloMensaje(): String {
    return this.globalEnersolService.getTituloMensaje();
  }

  /**
  * Función que obtiene el contenido de un mensaje
  * @return contenidoMensaje 
  * @author msemeraro
  */
  getContenidoMensaje(): String {
    return this.globalEnersolService.getContenidoMensaje();
  }

  /**
  * Función que obtiene si un mensaje es error o no
  * @return esError 
  * @author msemeraro
  */
  getEsError(): Boolean {
    return this.globalEnersolService.getEsError();
  }

}
