import { Component, OnInit, ɵConsole, Injectable } from '@angular/core';
/* SERVICES */
import { HttpClientEnersolService } from 'src/services/http-client-enersol.service';
import { GlobalService } from 'src/services/global';
import { ConstantsService } from 'src/services/constants.service';
/* REST MODEL */
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
/* MODELS */
import { ProductoPresentacion } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoPresentacion';
import { Marca } from 'src/models/com.enersol.directshopping.dal.entidad/Marca';
import { Familia } from 'src/models/com.enersol.directshopping.dal.entidad/Familia';
import { ProductoPorOrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoPorOrdenDeCompra';
import { ValidationsService } from 'src/services/validations.service';
import { Parametro } from 'src/models/com.enersol.directshopping.dal.entidad/Parametro';

@Injectable()
export class BusquedaController {

  esBusqueda: boolean = true;
  tituloBusqueda = '';
  urlEnersol: string = "";
  /* Variable que controla y guarda el filtro de búsqueda */
  filtroDeBusqueda;
  /* Variable con el tipo de cambio */
  tipoCambio:number = 0;

  constructor(
    private httpClientEnersolService: HttpClientEnersolService,
    public globalEnersolService: GlobalService,
    private constantsEnersolService: ConstantsService,
    private validationsEnersolService: ValidationsService
  ) {
    this.urlEnersol = this.constantsEnersolService.getUrlEnersol();
  }

  /* FUNCIONES QUE HACEN PETICIONES A LOS SERVICIOS */

  /**
  * Función obtiene todos los productos a mostrar en la búsqueda
  * @returns ListResponse<ProductoPresentacion>
  * @author msemeraro
  */
  async getProductos(): Promise<ListResponse<ProductoPresentacion>> {
    let path = this.getPath();
    let respuesta: ListResponse<ProductoPresentacion>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet<ListResponse<ProductoPresentacion>>(path);
      respuesta = <ListResponse<ProductoPresentacion>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<ProductoPresentacion>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función obtiene las marcas registradas
  * @returns ListResponse<Marca>
  * @author msemeraro
  */
  async getMarcas(): Promise<ListResponse<Marca>> {
    let path = this.getPath();
    let respuesta: ListResponse<Marca>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet<ListResponse<Marca>>('Marca/all');
      respuesta = <ListResponse<Marca>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<Marca>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función obtiene las familias registradas
  * @returns ListResponse<Familia>
  * @author msemeraro
  */
  async getFamilias(): Promise<ListResponse<Familia>> {
    let path = this.getPath();
    let respuesta: ListResponse<Familia>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet<ListResponse<Familia>>('Familia/all');
      respuesta = <ListResponse<Familia>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<Familia>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }


  /* FUNCIONES QUE HACEN PETICIONES AL GLOBAL SERVICE */

  /**
    * Función que obtiene el tipo de cambio
    * @return tipoCambio 
    * @author msemeraro
    */
   async getTipoCambio() {
    if(this.tipoCambio == 0){
      await this.globalEnersolService.setTipoCambio();
      let tipoCambio: Parametro = this.globalEnersolService.getTipoCambio();
      this.tipoCambio = Number(tipoCambio.valor); 
    }
    return this.tipoCambio;
  }

  /**
  * Función que añade un producto a una orden de compra
  * @param producto producto a insertar
  * @param cantidad cantidad de unidades del producto que se quieren insertar
  * @returns Response<ProductoPorOrdenDeCompra>
  * @author msemeraro
  */
  async addCarrito(producto: ProductoPresentacion, cantidad: number): Promise<Response<ProductoPorOrdenDeCompra>> {
    return this.globalEnersolService.addCarrito(producto, cantidad);
  }

  /**
  * Función que almacena en el globalService los datos del producto a mostrar
  * @param producto producto a visualizar
  * @author msemeraro
  */
  verDetalleProducto(producto: ProductoPresentacion) {
    this.globalEnersolService.setCodProductoSeleccionado(producto.id.codigoProducto);
    this.globalEnersolService.setCodProductoPresentacionSeleccionado(producto.id.codigoProductoPresentacion);
    this.globalEnersolService.setCodPresentacionProductoSeleccionado(producto.id.codigoPresentacion);
    this.globalEnersolService.setCodMarcaProductoSeleccionado(producto.id.codigoMarca);
    this.globalEnersolService.setCantidadProductoSeleccionado(1);
  }

  /**
  * Función que guarda las variables para mostrar un mensaje
  * @param tituloMensaje
  * @param contenidoMensaje
  * @param esError
  * @author msemeraro
  */
  setMensaje(tituloMensaje: String, contenidoMensaje: String, esError: Boolean): void {
    this.globalEnersolService.setTituloMensaje(tituloMensaje);
    this.globalEnersolService.setContenidoMensaje(contenidoMensaje);
    this.globalEnersolService.setEsError(esError);
  }

  /**
  * Función que obtiene el titulo de un mensaje
  * @return tituloMensaje 
  * @author msemeraro
  */
  getTituloMensaje(): String {
    return this.globalEnersolService.getTituloMensaje();
  }

  /**
  * Función que obtiene el contenido de un mensaje
  * @return contenidoMensaje 
  * @author msemeraro
  */
  getContenidoMensaje(): String {
    return this.globalEnersolService.getContenidoMensaje();
  }

  /**
  * Función que obtiene si un mensaje es error o no
  * @return esError 
  * @author msemeraro
  */
  getEsError(): Boolean {
    return this.globalEnersolService.getEsError();
  }


  /* FUNCIONES QUE HACEN ORDENAMIENTOS O FILTROS */

  /**
  * Función que formatea un número
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
 getFormatNumber(numero: number) {
  return this.validationsEnersolService.getNumber(numero);
}

  /**
  * Función que filtra una lista de productos según el parámetro indicado
  * @param listaProductos Lista de productos a filtrar
  * @param nombreMarca nombre de la marca por filtrar
  * @returns Response<ProductoPorOrdenDeCompra>
  * @author msemeraro
  */
  filterProductosMarca(listaProductos: ListResponse<ProductoPresentacion>, nombreMarca: string): ProductoPresentacion[] {
    return listaProductos.data.filter((item) => {
      return item.producto.marca.nombre.toLowerCase().
        indexOf(nombreMarca.toLowerCase()) > -1;
    });
  }

  /**
  * Función que filtra una lista de productos según el parámetro indicado
  * @param listaProductos Lista de productos a filtrar
  * @param nombreFamilia nombre de la familia por filtrar
  * @returns Response<ProductoPorOrdenDeCompra>
  * @author msemeraro
  */
  filterProductosFamilia(listaProductos: ListResponse<ProductoPresentacion>, nombreFamilia: string): ProductoPresentacion[] {
    return listaProductos.data.filter((item) => {
      return item.producto.subFamilia.familia.nombre.toLowerCase().
        indexOf(nombreFamilia.toLowerCase()) > -1;
    });
  }

  /**
  * Función que ordena una lista de productos según los parámetros
  * @param prop atributo de la lista por el cual ordenar
  * @param listaProductos lista de productos a ordenar
  * @param ordenamiento ordenamiento a utilizar (mayor a menor o alrevés)
  * @returns ProductoPresentacion[] lista de productos ordenada
  * @author msemeraro
  */
  orderBy(prop: string, listaProductos: ProductoPresentacion[], ordenamiento: string): ProductoPresentacion[] {
    if (ordenamiento == "Mayor") {
      return listaProductos.sort((a, b) => b[prop] > a[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    } else {
      return listaProductos.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    }
  }

  /**
  * Función que aplica un filtro despues de ordenar (refrescar la lista)
  * @param listaProductos 
  * @returns ProductoPresentacion[] 
  * @author msemeraro
  */
  filterProductosOrderBy(listaProductos: ProductoPresentacion[]): ProductoPresentacion[] {
    return listaProductos.filter((item) => {
      return true;
    });
  }

  /**
  * Función que aplica un filtro segun el termino, lo aplica sobre el nombre y codigo de enersol del producto
  * @param listaProductos 
  * @returns ProductoPresentacion[] 
  * @author msemeraro
  */
  filterProductosBySearchTerm(listaProductos: ProductoPresentacion[], searchTerm:string): ProductoPresentacion[]{
    return listaProductos.filter((item) => {
      return item.nombre.toLowerCase().
        indexOf(searchTerm.toLowerCase()) > -1 
        // Filtrar por codigo enersol
        ||
        String(item.codigoEnersol).
          indexOf(searchTerm.toLowerCase()) > -1
        ;
    });
  }


  /* OTRAS FUNCIONES */

  /**
  * Función obtiene el path según los datos del globalService
  * @returns path para llamar al servicio
  * @author msemeraro
  */
  getPath() {
    let nombre = this.globalEnersolService.getNombreListaProductos();
    this.tituloBusqueda = this.globalEnersolService.getNombreProductos();
    switch (nombre) {
      case 'productosNuevos': {
        this.esBusqueda = false;
        return 'ProductoPresentacion/nuevos/all';
      }
      case 'productosPromociones': {
        this.esBusqueda = false;
        return 'ProductoPresentacion/promociones/all';
      }
      case 'productosFamilia': {
        this.esBusqueda = false;
        let codFamilia = this.globalEnersolService.getCodigoFamiliaProductos();
        return ('ProductoPresentacion/familia/all?codFamilia=' + codFamilia);
      }
      default: {
        this.esBusqueda = false;
        return 'ProductoPresentacion/all/habilitados';
      }
    }
  }
}
