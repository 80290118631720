import { ProductoPresentacionId } from './ProductoPresentacionId';
import { Presentacion } from './Presentacion';
import { Producto } from './Producto';
import { FotografiaProducto } from './FotografiaProducto';
import { EstadoInventarioProducto } from './EstadoInventarioProducto';

export class ProductoPresentacion {
    id: ProductoPresentacionId;
    codigoEnersol:number=0;
    presentacion: Presentacion;
    producto: Producto;
    precio: number;
    elementosPorTarima: number;
    indicadorHabilitado: boolean;
    indicadorVisible: boolean;
    cantidadMinima: number;
    palletStacking: number;
    indicadorNuevo: boolean;
    indicadorPromocion: boolean;
    unidadEmpaque: number;
    numeroParte: string;
    bandaMinimaMercadoOtrosComercios: number;
    bandaMinimaMercadoConsumidorFinal: number;
    valorMercadoOtrosComercios: number;
    valorMercadoConsumidorFinal: number;
    fotografias: FotografiaProducto[] = [];
    fotografiaProductos: FotografiaProducto[] = [];
    //margenOtros: number = 0;
    //margenFinal: number = 0;
    //margenUnitarioOtros: number = 0;
    //margenUnitarioFinal: number = 0;
    precioUnitarioOtros: number = 0;
    precioUnitarioFinal: number = 0;
    nombre: string;

    // Estos campos reemplazan lo que eran los márgenes
    precioOtrosComercios: number = 0;
    precioConsumidorFinal: number = 0;
    precioBandaOtrosComercios: number = 0;
    precioBandaConsumidorFinal: number = 0;

    cantidadInventario: number;
	  inventarioTransito: number;
    cantidadImportacion: number;
	  importacionTransito: number;
	  manejarPorInventario: Boolean;
	  estadoInventarioProducto: EstadoInventarioProducto;
}