import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
/**
 * Provedor de validaciones de la aplicacion
 */
@Injectable({
  providedIn: 'root'
})
export class ValidationsService {

  constructor(public http: HttpClient) { }

  /**
  * Función que verifica que un dato no esté en blanco
  * @param input es el dato a validar
  * @returns retorna un valor booleano
  * @author msemeraro
  */
  validateGeneral(input: string) {
    return input != null && input != "";
  }

  /**
  * Función que verifica que un dato contenga únicamente letras y números
  * @param input es el dato a validar
  * @returns retorna un valor booleano
  * @author msemeraro
  */
  validateAlphaNumeric(input: string) {
    if (this.validateGeneral(input)) {
      if (input.match("^[a-zA-Z0-9]*$"))
        return true
    }
    return false
  }

  /**
  * Función que verifica que un dato contenga únicamente números
  * @param input es el dato a validar
  * @returns retorna un valor booleano
  * @author msemeraro
  */
  validateNumeric(input: string) {
    if (this.validateGeneral(input)) {
      if (input.match("^[0-9]*$"))
        return true
    }
    return false
  }

  /**
  * Función que verifica que un dato sea double
  * @param input es el dato a validar
  * @returns retorna un valor booleano
  * @author msemeraro
  */
  validateDouble(input) {
    if (this.validateGeneral(input)) {
      if (input.match("^[0-9]*$"))
        return true
    }
    return false
  }

  /**
  * Función que verifica que un dato cumpla con el formato de un télefono
  * @param telefono es el dato a validar
  * @returns retorna un valor booleano
  * @author msemeraro
  */
  validateTelefono(telefono: string) {
    if (this.validateGeneral(telefono)) {
      if (telefono.length == 8 && this.validateNumeric(telefono))
        return true
    }
    return false
  }

  /**
  * Función que sustituye un caracter de un string
  * @param dato es el dato a modificar
  * @param reemplazo es el nuevo caracter
  * @param caracter es el caracter a reemplazar
  * @returns retorna el valor modificado
  * @author msemeraro
  */
  changeString(dato: string, reemplazo: string, caracter: string): string {
    dato = dato.replace(caracter, reemplazo);
    return dato.replace(caracter, reemplazo);
  }

  formatDate_DDMMYYYY(fecha: Date): string{
    let fechaFormateada: string = "";
    let hora:String = fecha.toLocaleTimeString().split(' ')[0];

    if(fecha.getDate() < 10){ fechaFormateada += "0"+ fecha.getDate() + "-"; }
    else { fechaFormateada += fecha.getDate() + "-"; }

    if(fecha.getMonth() < 9){ fechaFormateada += "0"+ (fecha.getMonth()+1) + "-"; }
    else { fechaFormateada += (fecha.getMonth()+1) + "-"; }

    fechaFormateada += fecha.getFullYear() +" "+ hora;

    return fechaFormateada;
  }

  /**
  * Función que verifica que un dato cumpla con el formato de un correo electrónico
  * @param correo es el dato a validar
  * @returns retorna un valor booleano
  * @author msemeraro
  */
  validateCorreo(correo: string) {
    if (this.validateGeneral(correo) && correo.match('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'))
      return true
    return false
  }

  /**
  * Función que le da formato a un número
  * @param number es el numero a arreglar
  * @returns el número formateado
  * @author msemeraro
  */
  getNumber(number) {
    return (Number(number)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  /**
  * Función que verifica que un comentario en el cambio de estado de ordenes
  * @param comentario es el dato a validar
  * @returns retorna un valor booleano
  * @author msemeraro
  */
  validateComentarioCambioEstado(comentario: string) {
    return ((this.validateGeneral(comentario)) && (comentario.length <= 500));
  }

  /**
  * Función que verifica que un archivo sea PDF
  * @param file es el dato a validar
  * @returns retorna un valor booleano
  * @author msemeraro
  */
  validateFilePDF(file: File) {
    return (file.type === 'application/pdf');
  }

  /**
  * Función que verifica que un archivo sea XLSX
  * @param file es el dato a validar
  * @returns retorna un valor booleano
  * @author gberrocal
  */
  validateFileXLSX(file: File) {
    return (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  }

  /**
    * Función que verifica que un archivo sea una imagen
    * @param file es el dato a validar
    * @returns retorna un valor booleano
    * @author msemeraro
    */
  validateFileImage(file: File) {
    return (file.type === 'image/jpeg' || file.type === 'image/png');
  }



}