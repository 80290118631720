import { NgModule } from '@angular/core';
import { PaginatePipe } from 'src/app/pipes/paginate.pipe';

@NgModule({
    declarations: [PaginatePipe
    ],
    exports: [
        PaginatePipe
    ]
})
export class PipeModule { }
