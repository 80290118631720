import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AdminDetalleOrdenBackview } from './admin-detalle-orden.component.backview';
import { NgxSpinnerModule } from "ngx-spinner";
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../footer/footer.module';

@NgModule({
  declarations: [AdminDetalleOrdenBackview],
  imports: [
    CommonModule,
    FormsModule,
    NgxSpinnerModule,
    HeaderModule,
    FooterModule,
  ],
  exports: [
    AdminDetalleOrdenBackview
  ]
})
export class AdminDetalleOrdenModule { }
