import { Injectable } from '@angular/core';
import { Estacion } from 'src/models/com.enersol.directshopping.dal.entidad/Estacion';
import { OrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/OrdenDeCompra';
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
import { HttpClientEnersolService } from './http-client-enersol.service';
import { ProductoPorOrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoPorOrdenDeCompra';
import { ProductoPresentacion } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoPresentacion';
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
import { ConstantsService } from './constants.service';
import { LogService } from './log.service';
import { Parametro } from 'src/models/com.enersol.directshopping.dal.entidad/Parametro';
import { ProductoId } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoId';
import { EstadoInventarioProducto } from 'src/models/com.enersol.directshopping.dal.entidad/EstadoInventarioProducto';
import { type } from 'jquery';
import { EnumFormaInventario } from 'src/models/com.enersol.directshopping.dal.enums/EnumFormaInventario';
import { EnumEstadoOrdenInventario } from 'src/models/com.enersol.directshopping.dal.enums/EnumEstadoOrdenInventario';
import { EnumEstadoOrden } from 'src/models/com.enersol.directshopping.dal.enums/EnumEstadoOrden';
/**
 * Provedor de datos globales de la aplicacion
 */
@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  /**
   * Nombre con que se guarda el token en el local storage
   */
  private idtoken = 'token';
  private codigoEstacion: string;
  public username: string;
  /* Variables para controlar la opcion de ver mas en la pagina principal */
  private nombreListaProductos = '';
  private codigoFamiliaProductos = 0;
  private nombreProductos = '';

  /* Variables para controlar el detalle de un producto */
  private codProductoSeleccionado = 0;
  private codProductoPresentacionSeleccionado = 0;
  private codPresentacionProductoSeleccionado = 0;
  private codMarcaProductoSeleccionado = 0;
  private cantidadProductoSeleccionado = 1;

  /* Variables para controlar la cantidad de productos en el carrito */
  public cantidadProductosCarrito = 0;
  public cantidadProductosCarritoInventario = 0;
  public cantidadProductosCarritoImportacion = 0;

  /* Variables para controlar la orden de compra en estado borrador (Carrito) */
  private ordenDeCompraImportacionBorrador: OrdenDeCompra;
  private ordenDeCompraInventarioBorrador: OrdenDeCompra;

  /* Variables para controlar la orden de compra que se esta consultando */
  private ordenDeCompra: OrdenDeCompra;

  /* Variables para controlar el tipo de cambio */
  private tipoCambio: Parametro = null;
  private formaInventario: Parametro = null;

  /* Variables para controlar el producto seleccionado por el administrador */
  private productoId: ProductoId;

  /* Variables para controlar los roles de usuario */
  private roleUser: String = '';
  private isEnersolAdmin: Boolean = false;

  /* Variables para controlar los mensajes */
  private tituloMensaje: String = '';
  private contenidoMensaje: String = '';
  private esError: Boolean = false;

  /* Variable para controlar el contenido a mostrar */
  private idContenido: number;

  filtroDeBusqueda;
  scrollPosition;
  
  public showHeader: boolean;

  public cambioPresentacion: boolean = false;

  constructor(
    private httpClientEnersolService: HttpClientEnersolService,
    private constantsService: ConstantsService,
    private logService: LogService
  ) {

  }

  /* FUNCIONES DE TOKEN */

  /**
  * Función que almacena el token en el local storage
  * @param token
  */
  saveToken(token: string) {
    localStorage.setItem(this.idtoken, token);
    this.logService.log('Se  guarda token: ' + token);
  }

  getToken() {
    return localStorage.getItem(this.idtoken);
  }

  removeToken() {
    localStorage.removeItem(this.idtoken);
  }

  /**
  * Función que se encarga de limpiar los datos al salir de la sesion
  * @author msemeraro
  */
  cerrarSesion(): void {
    this.setCantidadProductosCarrito(0);
    this.setCantidadProductosCarritoImportacion(0);
    this.setCantidadProductosCarritoInventario(0);
    this.setCodMarcaProductoSeleccionado(0);
    this.setCodPresentacionProductoSeleccionado(0);
    this.setCodProductoPresentacionSeleccionado(0);
    this.setCodProductoSeleccionado(0);
    this.setCodigoFamiliaProductos(0);
    this.setNombreListaProductos('');
    this.setNombreProductos('');
    this.setOrdenDeCompraImportacion(null);
    this.setOrdenDeCompraImportacionBorrador(null);
    this.setOrdenDeCompraInventarioBorrador(null);
    this.removeToken();
    /*
      VALORAR HABILITAR ESTO PARA ASEGURAR QUE NO QUEDE NADA EN EL LOCAL STORAGE
      console.log(localStorage.length);
      localStorage.clear();
      console.log(localStorage.length);
    */
  }
  /* FUNCIONES QUE HACEN PETICIONES A LOS SERVICIOS */

  /**
  * Función que consulta la cantidad de productos en el carrito de una estacion
  * @returns number
  * @author msemeraro
  */
  async getCantidadProductosCarrito(): Promise<Response<number>> {
    let respuesta: Response<number> = null;
    this.cantidadProductosCarrito = 0;

    respuesta = await this.getCantidadProductosCarritoInventario();
    this.cantidadProductosCarrito = this.cantidadProductosCarrito +  respuesta.data;

    respuesta = await this.getCantidadProductosCarritoImportacion();
    this.cantidadProductosCarrito = this.cantidadProductosCarrito + respuesta.data;

    return respuesta;
  }



  async getCantidadProductosCarritoImportacion(): Promise<Response<number>> {
    let respuesta: Response<number> = null;
    this.cantidadProductosCarritoImportacion = 0;
    if (this.getOrdenDeCompraImportacionBorrador() != null) {
      respuesta = await this.getCantidadProductosOrden(this.getOrdenDeCompraImportacionBorrador().codigoOrden);
    }
    if (respuesta != null && respuesta.sucess) {
      this.cantidadProductosCarritoImportacion = respuesta.data;
    }
    return respuesta;
  }



  async getCantidadProductosCarritoInventario(): Promise<Response<number>> {
    let respuesta: Response<number> = null;
    this.cantidadProductosCarritoInventario = 0;
    if (this.getOrdenDeCompraInventarioBorrador() != null) {
      respuesta = await this.getCantidadProductosOrden(this.getOrdenDeCompraInventarioBorrador().codigoOrden);
    }
    if (respuesta != null && respuesta.sucess) {
      this.cantidadProductosCarritoInventario = respuesta.data;
    }
    return respuesta;
  }

  /**
  * Función que consulta la cantidad de productos de una orden de compra
  * @returns number
  * @author msemeraro
  */
  async getCantidadProductosOrden(idOrden: number): Promise<Response<number>> {
    let respuesta: Response<number>;
    try {
      const promiseResponse = await this.httpClientEnersolService.httpGet<Response<number>>('ProductoPorOrdenDeCompra/CantidadProductosOrdenDeCompra?codOrden=' + idOrden);
      respuesta = promiseResponse as Response<number>;
    } catch (error) {
      console.log(error.message);
      respuesta = new Response<number>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    } finally {
      if (respuesta.sucess) {
        this.cantidadProductosCarrito = respuesta.data;
      }
      return respuesta;
    }
  }

  /**
  * Función que añade un producto a una orden de compra
  * @param producto producto a insertar
  * @param cantidad cantidad de unidades del producto que se quieren insertar
  * @returns Response<ProductoPorOrdenDeCompra>
  * @author msemeraro
  */
  async addCarrito(producto: ProductoPresentacion, cantidad:number): Promise<Response<ProductoPorOrdenDeCompra>> {

    // Toma los códigos de ordenes para sus validaciones
    let codigosOrdenes = [];
    if(this.getFormaInventario().valor == EnumFormaInventario.INVENTARIO.nombre || 
    this.getFormaInventario().valor == EnumFormaInventario.MIXTO.nombre){
      codigosOrdenes.push(this.getOrdenDeCompraInventarioBorrador().codigoOrden);
    }
    if(this.getFormaInventario().valor == EnumFormaInventario.IMPORTACION.nombre || 
    this.getFormaInventario().valor == EnumFormaInventario.MIXTO.nombre){
      codigosOrdenes.push(this.getOrdenDeCompraImportacionBorrador().codigoOrden);
    }
    
    // Se asegura que la orden no haya cambiado de estado borrador (por ejemplo, por otra sesión)
    for(let codigo of codigosOrdenes){
      let ordenBD = await this.getOrdenDeCompraUpdate(codigo);
      console.log(ordenBD)
      if(ordenBD.sucess){
        if(ordenBD.data.estadoOrden.codigoEstado != EnumEstadoOrden.BORRADOR.codigoEstado){
          throw new Error('AddCarrito_Orden_No_Borrador');
        }
      } else {
        throw new Error('Gen_0');
      }
    }

    // Si tiene el indicador no permite agregar productos al carrito
    if(this.getOrdenDeCompraInventarioBorrador().indicadorConfirmarCambioInventario){
      throw new Error('AddCarrito_5');
    }

    let respuesta: Response<ProductoPorOrdenDeCompra>;
    //EN CASO DE SER NECESARIO CAMBIAR EL ESTADO A OUTSTOCK
    let respuestaEstadoInventario: Response<EstadoInventarioProducto>;
    let respuestaProductoPresentacion: Response<ProductoPresentacion>;


    try {
      let codigoOrdenCompra:number  = 0;
      if(this.getFormaInventario().valor == EnumFormaInventario.INVENTARIO.nombre) {
        // Valida que tenga suficiente cantidad para poder pedir del inventario
        if(producto.cantidadInventario >= (producto.inventarioTransito + cantidad)) {
          //ACTUALIZA EL PRODUCTO PRESENTACION
          producto.inventarioTransito += cantidad;
          if(producto.inventarioTransito == producto.cantidadInventario) {
            //verificar esto ya que esta con id
            const promiseResponse = await this.httpClientEnersolService.httpGet('EstadoInventarioProducto/id?idEstado=2');
            respuestaEstadoInventario = promiseResponse as Response<EstadoInventarioProducto>;
            producto.estadoInventarioProducto = respuestaEstadoInventario.data;
          }
          const promiseResponse2 = await this.httpClientEnersolService.httpPost('ProductoPresentacion/updateCarrito', producto);
          respuestaProductoPresentacion = promiseResponse2 as Response<ProductoPresentacion>;
          
          if(respuestaProductoPresentacion.sucess) {
              //REALIZA LA ORDEN PARA INVENTARIO
              codigoOrdenCompra =  this.getOrdenDeCompraInventarioBorrador().codigoOrden;
              const promiseResponse = await this.httpClientEnersolService.httpPost('ProductoPorOrdenDeCompra/insert', this.getDatosAddCarrito(producto, cantidad, codigoOrdenCompra));
              respuesta = promiseResponse as Response<ProductoPorOrdenDeCompra>;
          } else {
            throw new Error('AddCarrito_1');  
          }
        } else {
          throw new Error('AddCarrito_2');
        }
      } else {
          if(this.getFormaInventario().valor == EnumFormaInventario.IMPORTACION.nombre 
            || (this.getFormaInventario().valor == EnumFormaInventario.MIXTO.nombre && !producto.manejarPorInventario)) {
            // Valida que tenga suficiente cantidad para poder pedir para importación 
            if(producto.cantidadImportacion >= (producto.importacionTransito + cantidad)) {
              //ACTUALIZA EL PRODUCTO PRESENTACION
              producto.importacionTransito += cantidad;
              // (No hay nada equivalente al Outstock, pero aquí iría)
              const promiseResponse2 = await this.httpClientEnersolService.httpPost('ProductoPresentacion/updateCarrito', producto);
              respuestaProductoPresentacion = promiseResponse2 as Response<ProductoPresentacion>;
              if(respuestaProductoPresentacion.sucess) {
                //REALIZA LA ORDEN PARA INVENTARIO
                codigoOrdenCompra =  this.getOrdenDeCompraImportacionBorrador().codigoOrden
                const promiseResponse = await this.httpClientEnersolService.httpPost('ProductoPorOrdenDeCompra/insert', this.getDatosAddCarrito(producto, cantidad, codigoOrdenCompra));
                respuesta = promiseResponse as Response<ProductoPorOrdenDeCompra>;
              }
              else{
                throw new Error('AddCarrito_1');
              }
            }else{
              throw new Error('AddCarrito_3');
            }
          }
          else {
            if(this.getFormaInventario().valor == EnumFormaInventario.MIXTO.nombre) {
              if(producto.cantidadInventario >= (producto.inventarioTransito + cantidad)) {

                //ACTUALIZA EL PRODUCTO PRESENTACION
                producto.inventarioTransito += cantidad;
                if(producto.inventarioTransito == producto.cantidadInventario) {
                  //verificar esto ya que esta con id
                  const promiseResponse = await this.httpClientEnersolService.httpGet('EstadoInventarioProducto/id?idEstado=2');
                  respuestaEstadoInventario = promiseResponse as Response<EstadoInventarioProducto>;
                  producto.estadoInventarioProducto = respuestaEstadoInventario.data;
                }
                const promiseResponse2 = await this.httpClientEnersolService.httpPost('ProductoPresentacion/updateCarrito', producto);
                respuestaProductoPresentacion = promiseResponse2 as Response<ProductoPresentacion>;
                if(respuestaProductoPresentacion.sucess) {
                  //REALIZA LA ORDEN PARA INVENTARIO
                  codigoOrdenCompra =  this.getOrdenDeCompraInventarioBorrador().codigoOrden
                  const promiseResponse = await this.httpClientEnersolService.httpPost('ProductoPorOrdenDeCompra/insert', this.getDatosAddCarrito(producto, cantidad, codigoOrdenCompra));
                  respuesta = promiseResponse as Response<ProductoPorOrdenDeCompra>;
                } else {
                  throw new Error('AddCarrito_1');  
                }
              } else {
                // CASO DOBLE
                let cantidadtransitoria:number = producto.cantidadInventario - producto.inventarioTransito;
                let cantidadFaltante: number = (producto.inventarioTransito + cantidad) - producto.cantidadInventario;
                
                if(producto.cantidadImportacion >= (producto.importacionTransito + cantidadFaltante)) {
                  //ACTUALIZA EL PRODUCTO PRESENTACION
                  producto.inventarioTransito = producto.cantidadInventario;
                  producto.importacionTransito = producto.importacionTransito + cantidadFaltante;
                  //verificar esto ya que esta con id
                  const promiseResponse = await this.httpClientEnersolService.httpGet('EstadoInventarioProducto/id?idEstado=2');
                  respuestaEstadoInventario = promiseResponse as Response<EstadoInventarioProducto>;
                  producto.estadoInventarioProducto = respuestaEstadoInventario.data;

                  const promiseResponse2 = await this.httpClientEnersolService.httpPost('ProductoPresentacion/updateCarrito', producto);
                  respuestaProductoPresentacion = promiseResponse2 as Response<ProductoPresentacion>;
                  
                  // Hace el pedido de inventario solo si hay cantidad por pedir
                  if(cantidadtransitoria > 0){
                    if(respuestaProductoPresentacion.sucess) {
                      //REALIZA LA ORDEN PARA INVENTARIO
                      codigoOrdenCompra =  this.getOrdenDeCompraInventarioBorrador().codigoOrden;
                      const promiseResponse2 = await this.httpClientEnersolService.httpPost('ProductoPorOrdenDeCompra/insert', this.getDatosAddCarrito(producto, cantidadtransitoria, codigoOrdenCompra));
                      respuesta = promiseResponse2 as Response<ProductoPorOrdenDeCompra>;
                    } else {
                      throw new Error('AddCarrito_1');  
                    }
                  }

                  codigoOrdenCompra =  this.getOrdenDeCompraImportacionBorrador().codigoOrden;
                  const promiseResponse3 = await this.httpClientEnersolService.httpPost('ProductoPorOrdenDeCompra/insert', this.getDatosAddCarrito(producto, cantidadFaltante, codigoOrdenCompra));
                  respuesta = promiseResponse3 as Response<ProductoPorOrdenDeCompra>;
                }
                else{
                  throw new Error('AddCarrito_4');
                }
              }
            }
          }
      }
    } catch (error) {
      console.log(error.message);
      respuesta = new Response<ProductoPorOrdenDeCompra>(false);
      respuesta.addError(error.message, 'techMessage', 'La cantidad pedida excede la cantidad del producto en el inventario');
    } finally {
      this.getCantidadProductosCarrito();
      return respuesta;
    }
  }

  /**
 * Función que obtiene una orden de compra
 * @param codOrden id de la orden de compra
 * @returns Response<OrdenDeCompra>
 * @author msemeraro
 */
  async getOrdenDeCompraUpdate(codOrden: number): Promise<Response<OrdenDeCompra>> {
    let respuesta: Response<OrdenDeCompra>;
    try {
      const path = 'OrdenDeCompra/id?id=' + codOrden;
      const promiseResponse = await this.httpClientEnersolService.httpGet(path);
      respuesta = promiseResponse as Response<OrdenDeCompra>;
    } catch (error) {
      respuesta = new Response<OrdenDeCompra>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    } finally {
      return respuesta;
    }
  }

  /**
  * Función que solicita al servicio una orden de compra en borrador
  * @returns ListResponse<OrdenDeCompra>
  * @author msemeraro
  */
  async getNuevaOrdenDeCompraBorrador(): Promise<ListResponse<OrdenDeCompra>> {
    let respuesta: ListResponse<OrdenDeCompra>;
    try {
      const promiseResponse = await this.httpClientEnersolService.httpGet<ListResponse<OrdenDeCompra>>('OrdenDeCompra/Estacion/Borrador');
      respuesta = promiseResponse as ListResponse<OrdenDeCompra>;
    } catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<OrdenDeCompra>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    } finally {
      return respuesta;
    }
  }

  /**
 * Función que obtiene el tipo de cambio
 * @returns Response<Parametro>
 * @author msemeraro
 */
  async getTipoCambioParametro(): Promise<Response<Parametro>> {
    let respuesta: Response<Parametro>;
    try {
      const promiseResponse = await this.httpClientEnersolService.httpGet('Parametro/id?nombre=tipoCambio');
      respuesta = promiseResponse as Response<Parametro>;
    } catch (error) {
      respuesta = new Response<Parametro>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    } finally {
      return respuesta;
    }
  }


  /**
 * Función que obtiene el Parametro de formaInventario
 * @returns Response<Parametro>
 * @author dchinchilla
 */
   async getFormaInventarioParametro(): Promise<Response<Parametro>> {
    let respuesta: Response<Parametro>;
    try {
      const promiseResponse = await this.httpClientEnersolService.httpGet('Parametro/id?nombre=formaInventario');
      respuesta = promiseResponse as Response<Parametro>;
    } catch (error) {
      respuesta = new Response<Parametro>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    } finally {
      return respuesta;
    }
  }



  /* OTRAS FUNCIONES */

  /**
  * Función que obtiene un margen de ganancia
  * @author msemeraro
  */
  getMargenGanancia(precioVenta: number, precioOriginal): number {
    return Math.round((precioVenta - precioOriginal) * precioOriginal / 100);
  }


  /* FUNCIONES PARA OBTENER LOS JSON */

  /**
  * Función que retorna el json con los datos de un producto para agregar al carrito
  * @param producto producto a insertar
  * @param cantidad cantidad de unidades del producto que se quieren insertar
  * @returns Json con los datos de un producto de una orden de compra
  * @author msemeraro
  */
  getDatosAddCarrito(producto: ProductoPresentacion, cantidad: number, codigoOrdenCompra:number) {
    return {
      'id': {
        'codigoProducto': producto.id.codigoProducto,
        'codigoPresentacion': producto.id.codigoPresentacion,
        'codigoMarca': producto.id.codigoMarca,
        'codigoProductoPresentacion': producto.id.codigoProductoPresentacion,
        'codigoDetalleOrden': null
      },
      'detalleOrdenCompraPorMarca': {
        'codigoDetalleOrden': null
      },
      'productoPresentacion': {
        'id': {
          'codigoProductoPresentacion': null,
          'codigoProducto': null,
          'codigoPresentacion': null,
          'codigoMarca': null
        }
      },
      'descripcion': producto.nombre,

      'cantidad': cantidad,
      'precioUnitario': producto.precio,
      'indicadorCumpleMinimoParaCompra': true,
      //'codigoOrdenDeCompra': this.getOrdenDeCompraImportacionBorrador().codigoOrden
      'codigoOrdenDeCompra' : codigoOrdenCompra
    };
  }

  /* Setters - Getters */

  getCodigoFamiliaProductos() {
    if (this.codigoFamiliaProductos == 0) {
      this.codigoFamiliaProductos = JSON.parse(localStorage.getItem('codigoFamiliaProductos'));
    }
    return this.codigoFamiliaProductos;
  }

  setCodigoFamiliaProductos(codigoFamilia: number) {
    this.codigoFamiliaProductos = codigoFamilia;
    localStorage.setItem('codigoFamiliaProductos', JSON.stringify(codigoFamilia));
  }

  getNombreListaProductos() {
    if (this.nombreListaProductos == '') {
      this.nombreListaProductos = JSON.parse(localStorage.getItem('nombreListaProductos'));
    }
    return this.nombreListaProductos;
  }

  setNombreListaProductos(listaProductos: string) {
    this.nombreListaProductos = listaProductos;
    localStorage.setItem('nombreListaProductos', JSON.stringify(listaProductos));
  }

  getNombreProductos() {
    if (this.nombreProductos == '') {
      this.nombreProductos = JSON.parse(localStorage.getItem('nombreProductos'));
    }
    return this.nombreProductos;
  }

  setNombreProductos(nombre: string) {
    this.nombreProductos = nombre;
    localStorage.setItem('nombreProductos', JSON.stringify(nombre));
  }

  getCodProductoSeleccionado() {
    if (this.codProductoSeleccionado == 0) {
      this.codProductoSeleccionado = JSON.parse(localStorage.getItem('codProductoSeleccionado'));
    }
    return this.codProductoSeleccionado;
  }

  setCodProductoSeleccionado(codigo: number) {
    this.codProductoSeleccionado = codigo;
    localStorage.setItem('codProductoSeleccionado', JSON.stringify(codigo));
  }

  getCodPresentacionProductoSeleccionado() {
    if (this.codPresentacionProductoSeleccionado == 0) {
      this.codPresentacionProductoSeleccionado = JSON.parse(localStorage.getItem('codPresentacionProductoSeleccionado'));
    }
    return this.codPresentacionProductoSeleccionado;
  }

  setCodPresentacionProductoSeleccionado(codigo: number) {
    this.codPresentacionProductoSeleccionado = codigo;
    localStorage.setItem('codPresentacionProductoSeleccionado', JSON.stringify(codigo));
  }

  getCodMarcaProductoSeleccionado() {
    if (this.codMarcaProductoSeleccionado == 0) {
      this.codMarcaProductoSeleccionado = JSON.parse(localStorage.getItem('codMarcaProductoSeleccionado'));
    }
    return this.codMarcaProductoSeleccionado;
  }

  setCodMarcaProductoSeleccionado(codigo: number) {
    this.codMarcaProductoSeleccionado = codigo;
    localStorage.setItem('codMarcaProductoSeleccionado', JSON.stringify(codigo));
  }

  getCodProductoPresentacionSeleccionado() {
    if (this.codProductoPresentacionSeleccionado == 0) {
      this.codProductoPresentacionSeleccionado = JSON.parse(localStorage.getItem('codProductoPresentacionSeleccionado'));
    }
    return this.codProductoPresentacionSeleccionado;
  }

  setCantidadProductoSeleccionado(cantidad: number) {
    this.cantidadProductoSeleccionado = cantidad;
    localStorage.setItem('cantidadProductoSeleccionado', JSON.stringify(cantidad));
  }

  getCantidadProductoSeleccionado() {
    this.cantidadProductoSeleccionado = JSON.parse(localStorage.getItem('cantidadProductoSeleccionado'));

    return this.cantidadProductoSeleccionado;
  }

  setCodProductoPresentacionSeleccionado(codigo: number) {
    this.codProductoPresentacionSeleccionado = codigo;
    localStorage.setItem('codProductoPresentacionSeleccionado', JSON.stringify(codigo));
  }

  setCantidadProductosCarrito(cantidad: number) {
    this.cantidadProductosCarrito = cantidad;
  }


  setCantidadProductosCarritoImportacion(cantidad: number) {
    this.cantidadProductosCarritoImportacion = cantidad;
  }

  setCantidadProductosCarritoInventario(cantidad: number) {
    this.cantidadProductosCarritoInventario = cantidad;
  }

  getOrdenDeCompra() {
    if (this.ordenDeCompra == null) {
      this.ordenDeCompra = JSON.parse(localStorage.getItem('ordenDeCompra'));
    }
    return this.ordenDeCompra;
  }

  setOrdenDeCompraImportacion(ordenDeCompra: OrdenDeCompra) {
    this.ordenDeCompra = ordenDeCompra;
    localStorage.setItem('ordenDeCompra', JSON.stringify(ordenDeCompra));
  }

  getOrdenDeCompraImportacionBorrador() {
    if (this.ordenDeCompraImportacionBorrador == null) {
      this.ordenDeCompraImportacionBorrador = JSON.parse(localStorage.getItem('ordenDeCompraImportacionBorrador'));
    }
    return this.ordenDeCompraImportacionBorrador;
  }

  setOrdenDeCompraImportacionBorrador(ordenDeCompraImportacionBorrador: OrdenDeCompra) {
    this.ordenDeCompraImportacionBorrador = ordenDeCompraImportacionBorrador;
    localStorage.setItem('ordenDeCompraImportacionBorrador', JSON.stringify(ordenDeCompraImportacionBorrador));
  }



  getOrdenDeCompraInventarioBorrador() {
    if (this.ordenDeCompraInventarioBorrador == null) {
      this.ordenDeCompraInventarioBorrador = JSON.parse(localStorage.getItem('ordenDeCompraInventarioBorrador'));
    }
    return this.ordenDeCompraInventarioBorrador;
  }

  setOrdenDeCompraInventarioBorrador(ordenDeCompraInventarioBorrador: OrdenDeCompra) {
    this.ordenDeCompraInventarioBorrador = ordenDeCompraInventarioBorrador;
    localStorage.setItem('ordenDeCompraInventarioBorrador', JSON.stringify(ordenDeCompraInventarioBorrador));
  }

  getTipoCambio() {
    if (this.tipoCambio == null) {
      this.tipoCambio = JSON.parse(localStorage.getItem('tipoCambio'));
    }
    return this.tipoCambio;
  }

  async setTipoCambio() {
    const respuesta = await this.getTipoCambioParametro();
    if (respuesta.sucess) {
      this.tipoCambio = respuesta.data;
      localStorage.setItem('tipoCambio', JSON.stringify(this.tipoCambio));
    }
  }


  getFormaInventario() {
    if (this.formaInventario == null) {
      this.formaInventario = JSON.parse(localStorage.getItem('formaInventario'));
    }
    return this.formaInventario;
  }

  async setFormaInventario() {
    const respuesta = await this.getFormaInventarioParametro();
    if (respuesta.sucess) {
      this.formaInventario = respuesta.data;
      localStorage.setItem('formaInventario', JSON.stringify(this.formaInventario));
    }
  }

  getProductoId() {
    if (this.productoId == null) {
      this.productoId = JSON.parse(localStorage.getItem('productoId'));
    }
    return this.productoId;
  }

  setProductoId(productoId: ProductoId) {
    this.productoId = productoId;
    localStorage.setItem('productoId', JSON.stringify(productoId));
  }

  setTituloMensaje(tituloMensaje: String) {
    this.tituloMensaje = tituloMensaje;
  }

  getTituloMensaje() {
    return this.tituloMensaje;
  }

  setContenidoMensaje(contenidoMensaje: String) {
    this.contenidoMensaje = contenidoMensaje;
  }

  getContenidoMensaje() {
    return this.contenidoMensaje;
  }

  setEsError(esError: Boolean) {
    this.esError = esError;
  }

  getEsError() {
    return this.esError;
  }

  /* Funciones para el rol de usuario */

  /**
  * Función que obtiene el rol del usuario registrado y lo almacena en la variable roleUser
  * @author msemeraro
  */
  async isRoleUser(roleName: string) {
    let respuesta: Response<boolean>;
    try {
      const promiseResponse = await this.httpClientEnersolService.httpGet<Response<string>>('Usuario/role?id=' + roleName);
      respuesta = promiseResponse as Response<boolean>;
      return respuesta;
    } catch (error) {
      console.log(error.message);
      respuesta = new Response<boolean>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    } finally {
      return respuesta;
    }
  }

  /**
  * Función que retorna si un usuario es ADMIN de ENERSOL o no
  * @returns Boolean
  * @author msemeraro
  */
  async isAdminEnersol() {
    const role = await this.isRoleUser('ENERSOL_Admin');
    this.isEnersolAdmin = role.data;
    return this.isEnersolAdmin;
  }

  /**
  * Función que retorna si un usuario tiene el rol de consultar y mantener órdenes
  * @returns Boolean
  * @author msemeraro
  */
  async isConsultaMantenimientoOrdenesEnersol() {
    const role = await this.isRoleUser('ENERSOL_Consulta_Mantenimiento_Ordenes');
    return role.data;
  }

  /**
  * Función que retorna si un usuario tiene el rol de realizar pricing
  * @returns Boolean
  * @author msemeraro
  */
  async isPricingEnersol() {
    const role = await this.isRoleUser('ENERSOL_Pricing');
    return role.data;
  }

  /**
  * Función que retorna si un usuario tiene el rol de mantener productos
  * @returns Boolean
  * @author msemeraro
  */
  async isMantenimientoProductosEnersol() {
    const role = await this.isRoleUser('ENERSOL_Mantenimiento_Productos');
    return role.data;
  }

  /**
  * Función que retorna si un usuario tiene el rol de mantener catálogos
  * @returns Boolean
  * @author msemeraro
  */
  async isMantenimientoGeneralEnersol() {
    const role = await this.isRoleUser('ENERSOL_Mantenimiento_General');
    return role.data;
  }

  /**
  * Función que retorna si un usuario tiene el rol de acceder a los reportes
  * @author msemeraro
  */
  async isReportesEnersol() {
    const role = await this.isRoleUser('ENERSOL_Reportes');
    return role.data;
  }

  /**
  * Función que retorna si un usuario tiene el rol de crear órdenes de ENERSOL
  * @author msemeraro
  */
  async isCreacionOrdenesEnersol() {
    const role = await this.isRoleUser('ENERSOL_Creacion_Ordenes');
    return role.data;
  }

  /**
  * Función que retorna si un usuario tiene el rol de crear órdenes de una Estación
  * @author msemeraro
  */
  async isCreacionOrdenesEstacion() {
    const role = await this.isRoleUser('ESTACION_Creacion_Ordenes');
    return role.data;
  }

  /**
  * Función que retorna si un usuario tiene el rol de enviar órdenes y comprobantes de una Estación
  * @author msemeraro
  */
  async isEnvioOrdenesComprobantesEstacion() {
    const role = await this.isRoleUser('ESTACION_Envio_Ordenes_Comprobantes');
    return role.data;
  }

  /**
  * Función que retorna si un usuario tiene el rol de crear usuarios
  * @author msemeraro
  */
  async isCrearUsuariosEnersol() {
    const role = await this.isRoleUser('ENERSOL_Crear_Usuarios');
    return role.data;
  }

  /**
    * Función que retorna si un usuario tiene el rol de crear usuarios
    * @author msemeraro
    */
  async isCrearUsuariosEstacion() {
    const role = await this.isRoleUser('ESTACION_Crear_Usuarios');
    return role.data;
  }



  /**
  * Función que retorna si un usuario es GUEST o no
  * @returns Boolean
  * @author msemeraro
  */
  async isAdminEstacion() {
    return true;
  }

  /**
    * Función que retorna si un usuario es USER o no
    * @returns Boolean
    * @author msemeraro
    */
  async isUserEstacion() {
    return false;
  }

  getIdContenido() {
    return this.idContenido;
  }

  setIdContenido(idContenido) {
    this.idContenido = idContenido;
  }

/**
 * Funcion para guardar el codigo de recope
 * @param codigo 
 */
  async setCodigoEstacion(codigo: string) {
    this.codigoEstacion = codigo;
    await localStorage.setItem('codigoEstacion', JSON.stringify(this.codigoEstacion));
  }

  /**
   * Funcion para obtener el codigo de recope
   */
  async getCodigoEstacion() {
    if (this.codigoEstacion == null) {
      this.codigoEstacion = await JSON.parse(localStorage.getItem('codigoEstacion'));
    }
    return this.codigoEstacion;
  }

/**
 * Funcion para guardar el nombre de usuario
 * @param usuario 
 */
  async setUsername(usuario: string) {
    this.username = usuario;
    await localStorage.setItem('usuario', JSON.stringify(this.username));
  }

  /**
   * Funcion para obtener el nombre de usuario
   */
  async getusername() {
    if (this.username == null) {
      this.username = await JSON.parse(localStorage.getItem('usuario'));
    }
    return this.username;
  }


  async setShowClientHeader(flag: boolean) {
    this.showHeader = flag;
    await localStorage.setItem('showHeader', JSON.stringify(this.showHeader));
  }

  async isShowClientHeader() {
    if (this.showHeader == null) {
      this.showHeader = await JSON.parse(localStorage.getItem('showHeader'));
    }
    if (this.showHeader == null) {
      this.verificarMostrarHeader();
    }
    return this.showHeader;
  }

  async verificarMostrarHeader() {
    if ((await this.isAdminEnersol() || await this.isCreacionOrdenesEnersol())
      && (this.codigoEstacion != '000000')) {
      this.setShowClientHeader(true);
    }
    else if (await this.isAdminEnersol()) {
      this.setShowClientHeader(false);
    }
    else {
      this.setShowClientHeader(true);
    }
  }

  isCambioPresentacion() {
    return this.cambioPresentacion;
  }

  setCambioPresentacion(valorBool) {
    this.cambioPresentacion = valorBool;

  }

  }
