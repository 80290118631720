import { Component, OnInit, ɵConsole, Injectable } from '@angular/core';
/* SERVICES */
import { HttpClientEnersolService } from 'src/services/http-client-enersol.service';
import { GlobalService } from 'src/services/global';
import { ValidationsService } from 'src/services/validations.service';
import { ErrorService } from 'src/services/error.service';
import { ConstantsService } from 'src/services/constants.service';
/* REST MODEL */
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
/* MODELS */
import { ProductoPorOrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoPorOrdenDeCompra';
import { HistorialEstadoDetalleOrden } from 'src/models/com.enersol.directshopping.dal.entidad/HistorialEstadoDetalleOrden';
import { EstadoOrden } from 'src/models/com.enersol.directshopping.dal.entidad/EstadoOrden';
import { DetalleOrdenCompraPorMarca } from 'src/models/com.enersol.directshopping.dal.entidad/DetalleOrdenCompraPorMarca';
import { OrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/OrdenDeCompra';
import { ComprobantePagoOrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/ComprobantePagoOrdenDeCompra';
import { EstadoOrdenInventario } from 'src/models/com.enersol.directshopping.dal.entidad/EstadoOrdenInventario';


@Injectable()
export class AdminDetalleOrdenController {

  urlEnersol: string = "";

  constructor(
    private httpClientEnersolService: HttpClientEnersolService,
    private globalEnersolService: GlobalService,
    private validationEnersolService: ValidationsService,
    private errorService: ErrorService,
    private constantsEnersolService: ConstantsService
  ) {

    this.urlEnersol = this.constantsEnersolService.getUrlEnersol();
  }

  /* FUNCIONES QUE HACEN PETICIONES A LOS SERVICIOS */

  /**
  * Función que obtiene los productos relacionados a un detalle de una orden
  * @param idDetalle id del detalle de los productos
  * @returns ListResponse<ProductoPorOrdenDeCompra>
  * @author msemeraro
  */
  async getDetailProducts(idDetalle: number): Promise<ListResponse<ProductoPorOrdenDeCompra>> {
    let respuesta: ListResponse<ProductoPorOrdenDeCompra>;
    try {
      let path = 'ProductoPorOrdenDeCompra/DetalleOrdenMarca?codDetalleOrden=' + idDetalle;
      let promiseResponse = await this.httpClientEnersolService.httpGet(path);
      respuesta = <ListResponse<ProductoPorOrdenDeCompra>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<ProductoPorOrdenDeCompra>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que obtiene el historial de estados detalles de una orden
  * @param idDetalle id del detalle de los productos
  * @returns ListResponse<HistorialEstadoDetalleOrden>
  * @author msemeraro
  */
  async getHistorialEstadosDetalles(codOrden: number): Promise<ListResponse<HistorialEstadoDetalleOrden>> {
    let respuesta: ListResponse<HistorialEstadoDetalleOrden>;
    try {
      let path = 'HistorialEstadoDetalleOrden/PorOrden?codOrden=' + codOrden;
      let promiseResponse = await this.httpClientEnersolService.httpGet(path);
      respuesta = <ListResponse<HistorialEstadoDetalleOrden>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<HistorialEstadoDetalleOrden>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que hace la petición del servicio de los posibles estados para un determinado estado
  * @returns ListResponse<EstadoOrden>
  * @author msemeraro
  */
  async getPosiblesEstados(codigoEstado): Promise<ListResponse<EstadoOrden>> {
    let respuesta: ListResponse<EstadoOrden>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('EstadoOrden/PosiblesEstados?codEstado=' + codigoEstado);
      respuesta = <ListResponse<EstadoOrden>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<EstadoOrden>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que hace la petición del servicio de los estados de orden inventario
  * @returns EstadoInventarioOrden[]
  * @author gberrocal
  */
  async getEstadosOrdenInventario(): Promise<ListResponse<EstadoOrdenInventario>> {
    let respuesta: ListResponse<EstadoOrdenInventario>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('EstadoOrdenInventario/all');
      respuesta = <ListResponse<EstadoOrdenInventario>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<EstadoOrdenInventario>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que marca una orden de compra como de Importacion o Inventario
  * @returns Response<OrdenDeCompra>
  * @author gberrocal
  */
   async marcarOrdenComo(ordenDeCompra: OrdenDeCompra): Promise<Response<OrdenDeCompra>> {
    let respuesta: Response<OrdenDeCompra>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('OrdenDeCompra/marcarComo', ordenDeCompra);
      respuesta = <Response<OrdenDeCompra>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<OrdenDeCompra>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que hace la petición del servicio de cambiar el estado a un detalle
  * @returns Response<DetalleOrdenCompraPorMarca>
  * @author msemeraro
  */
  async updateEstadoDetalleOrden(codDetalleOrden: number, codEstado: number, comentario: string): Promise<Response<DetalleOrdenCompraPorMarca>> {
    let respuesta: Response<DetalleOrdenCompraPorMarca>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('DetalleOrdenCompraPorMarca/update/state', this.getDatosUpdateEstadoDetalle(codDetalleOrden, codEstado, comentario));
      respuesta = <Response<DetalleOrdenCompraPorMarca>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<DetalleOrdenCompraPorMarca>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que hace la petición del servicio de cambiar el estado a un comprobante de pago
  * @returns Response<ComprobantePagoOrdenDeCompra>
  * @author msemeraro
  */
  async updateEstadoComprobantePago(codComprobantePago: number, codEstado: number): Promise<Response<ComprobantePagoOrdenDeCompra>> {
    let respuesta: Response<ComprobantePagoOrdenDeCompra>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('ComprobantePagoOrdenDeCompra/update/state', this.getDatosUpdateEstadoComprobante(codComprobantePago, codEstado));
      respuesta = <Response<ComprobantePagoOrdenDeCompra>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<ComprobantePagoOrdenDeCompra>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que hace la petición del servicio de aceptar o rechazar un comprobante
  * @returns Response<DetalleOrdenCompraPorMarca>
  * @author msemeraro
  */
  async verificarComprobanteOrden(codOrden: number, codEstado: number, comentario: string): Promise<Response<OrdenDeCompra>> {
    let respuesta: Response<OrdenDeCompra>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('OrdenDeCompra/VerificarComprobantePago', this.getDatosUpdateEstadoDetalle(codOrden, codEstado, comentario));
      respuesta = <Response<OrdenDeCompra>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<OrdenDeCompra>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que obtiene los detalles de una orden de compra
  * @returns ListResponse<DetalleOrdenCompraPorMarca>
  * @author msemeraro
  */
  async getDetallesOrden(codOrden: number): Promise<ListResponse<DetalleOrdenCompraPorMarca>> {
    let respuesta: ListResponse<DetalleOrdenCompraPorMarca>;
    try {
      let path = 'DetalleOrdenCompraPorMarca/DetallesOrden?codOrden=' + codOrden;
      let promiseResponse = await this.httpClientEnersolService.httpGet(path);
      respuesta = <ListResponse<DetalleOrdenCompraPorMarca>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<DetalleOrdenCompraPorMarca>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que obtiene el ultimo comprobante adjunto de una orden de compra
  * @returns Response<ComprobantePagoOrdenDeCompra>
  * @author msemeraro
  */
  async obtenerUltimoComprobante(codOrden: number) {
    let respuesta: Response<ComprobantePagoOrdenDeCompra>;
    try {
      let path = 'ComprobantePagoOrdenDeCompra/OrdenCompra/Ultimo?codOrden=' + codOrden;
      let promiseResponse = await this.httpClientEnersolService.httpGet(path);
      respuesta = <Response<ComprobantePagoOrdenDeCompra>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<ComprobantePagoOrdenDeCompra>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /* FUNCIONES QUE HACEN PETICIONES AL GLOBAL SERVICE */

  /**
  * Función que obtiene la orden de compra del global service
  * @returns OrdenDeCompra
  * @author msemeraro
  */
  getOrdenDeCompra() {
    return this.globalEnersolService.getOrdenDeCompra();
  }

  /**
  * Función que actualiza la orden de compra del global service
  * @returns orden
  * @author msemeraro
  */
  setOrdenDeCompraImportacion(orden: OrdenDeCompra) {
    this.globalEnersolService.setOrdenDeCompraImportacion(orden);
  }

  /**
   * Función que obtiene una orden de compra
   * @param codOrden id de la orden de compra
   * @returns Response<OrdenDeCompra>
   * @author msemeraro
   */
  async getOrdenDeCompraUpdate(codOrden: number): Promise<Response<OrdenDeCompra>> {
    return this.globalEnersolService.getOrdenDeCompraUpdate(codOrden);
  }

  /**
    * Función que obtiene si el usuario logueado es administrador de enersol
    * @return boolean 
    * @author msemeraro
    */
 isAdminEnersol() {
  return this.globalEnersolService.isAdminEnersol();
  }

  /* FUNCIONES QUE HACEN ORDENAMIENTOS O FILTROS */


  /* FUNCIONES PARA EL MANEJO DE LOS MONTOS */

  /**
    * Función que obtiene el monto total de una orden
    * @param subTotal monto subtotal de una orden de compra 
    * @returns number
    * @author msemeraro
    */
  getTotalOrden(subTotal: number): number {
    return Math.round(this.getImpuestoOrden(subTotal) + subTotal);
  }

  /**
    * Función que obtiene el monto de impuesto de una orden
    * @param subTotal monto subtotal de una orden de compra 
    * @returns number
    * @author msemeraro
    */
  getImpuestoOrden(subTotal: number): number {
    let impuestoSubTotal = subTotal * this.globalEnersolService.getOrdenDeCompra().impuesto;
    impuestoSubTotal = impuestoSubTotal / 100
    return impuestoSubTotal;
  }

  /**
    * Función que retorna el porcentaje de ganancia de una orden de compra
    * @param ganancia
    * @param totalOrden
    * @returns porcentaje de ganancia
    * @author msemeraro
    */
  getPorcentajeGanancia(ganancia: number, totalOrden: number): number {
    if (totalOrden > 0)
      return (ganancia * 100) / totalOrden;
    return 0;
  }

  /* FUNCIONES PARA EL FORMATO DE LOS NUMEROS */

  /**
    * Función que redondea un número
    * @param numero
    * @returns number redondeado
    * @author msemeraro
    */
  getRoundNumber(numero: number) {
    return Math.round(numero);
  }

  /**
    * Función que formatea un número
    * @param numero
    * @returns number formateado
    * @author msemeraro
    */
  getFormatNumber(numero: number) {
    return this.validationEnersolService.getNumber(numero);
  }


  /* OTRAS FUNCIONES */

  /**
    * Función que obtiene las fechas para mostrar de una lista
    * @param listaHistorial
    * @returns listaHistorial
    * @author msemeraro
    */
  getFormatoFechas(listaHistorial: HistorialEstadoDetalleOrden[]): HistorialEstadoDetalleOrden[] {
    for (let historial of listaHistorial) {
      historial.id.fecha = new Date(historial.id.fecha);
      historial.fechaFormatoNormal = historial.id.fecha.toLocaleDateString() + " " + historial.id.fecha.toLocaleTimeString();
    }
    return listaHistorial;
  }

  /**
    * Función que obtiene el JSON para actualizar el estado de un detalle de orden
    * @param codDetalleOrden
    * @param codEstado
    * @returns json
    * @author msemeraro
    */
  getDatosUpdateEstadoDetalle(codDetalleOrden: number, codEstado: number, comentario: string) {
    return {
      "codigoDetalleOrden": codDetalleOrden,
      "codigoEstado": codEstado,
      "comentario": comentario
    };
  }

  /**
    * Función que obtiene el JSON para actualizar el estado de un comprobante de pago
    * @param codComprobantePago
    * @param codEstado
    * @returns json
    * @author msemeraro
    */
  getDatosUpdateEstadoComprobante(codComprobantePago: number, codEstado: number) {
    return {
      "codigoDetalleOrden": codComprobantePago,
      "codigoEstado": codEstado,
      "comentario": null
    };
  }

  /**
    * Función que ordena una lista de historial según la fecha
    * @param listaHistorial lista de historial a ordenar
    * @returns HistorialEstadoDetalleOrden[] lista de historial ordenada
    * @author msemeraro
    */
  orderByFecha(listaHistorial: HistorialEstadoDetalleOrden[]): HistorialEstadoDetalleOrden[] {
    return listaHistorial.sort((a, b) => (new Date(a['fechaFormatoNormal']).getTime()) < (new Date(b['fechaFormatoNormal']).getTime()) ? 1 : (new Date(a['fechaFormatoNormal']).getTime()) === (new Date(b['fechaFormatoNormal']).getTime()) ? 0 : -1);
  }


  /**
    * Función que guarda las variables para mostrar un mensaje
    * @param tituloMensaje
    * @param contenidoMensaje
    * @param esError
    * @author msemeraro
    */
  setMensaje(tituloMensaje: String, contenidoMensaje: String, esError: Boolean): void {
    this.globalEnersolService.setTituloMensaje(tituloMensaje);
    this.globalEnersolService.setContenidoMensaje(contenidoMensaje);
    this.globalEnersolService.setEsError(esError);
  }

  /**
    * Función que obtiene el titulo de un mensaje
    * @return tituloMensaje 
    * @author msemeraro
    */
  getTituloMensaje(): String {
    return this.globalEnersolService.getTituloMensaje();
  }

  /**
    * Función que obtiene el contenido de un mensaje
    * @return contenidoMensaje 
    * @author msemeraro
    */
  getContenidoMensaje(): String {
    return this.globalEnersolService.getContenidoMensaje();
  }

  /**
    * Función que obtiene si un mensaje es error o no
    * @return esError 
    * @author msemeraro
    */
  getEsError(): Boolean {
    return this.globalEnersolService.getEsError();
  }

  /**
    * Función que valida si un comentario es valido o no
    * @param comentario
    * @returns boolean
    * @author msemeraro
    */
  validateComentario(comentario: string): boolean {
    return this.validationEnersolService.validateComentarioCambioEstado(comentario);
  }

  /**
    * Función que se encarga de abrir en una nueva ventana un documento
    * @param url
    */
  abrirArchivo(url: string) {
    window.open(this.urlEnersol + url, '_blank');
  }

}
