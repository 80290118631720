import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxSpinnerModule } from "ngx-spinner";
import { OrdenSugeridaBackview } from './orden-sugerida.component.backview';
import { PipeModule } from 'src/app/pipes/pipe-module';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../footer/footer.module';

@NgModule({
  declarations: [OrdenSugeridaBackview],
  imports: [
    CommonModule,
    PipeModule,
    HeaderModule,
    FooterModule,
    MatPaginatorModule,
    NgxSpinnerModule
  ],
  exports: [
    OrdenSugeridaBackview
  ]
})
export class OrdenSugeridaModule { }
