import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrincipalBackview } from './principal.component.backview';
import { NgxSpinnerModule } from "ngx-spinner";
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../footer/footer.module';



@NgModule({
  declarations: [PrincipalBackview],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    HeaderModule,
    FooterModule,
    CarouselModule
  ],
  exports: [
    PrincipalBackview
  ]
})
export class PrincipalModule { }
