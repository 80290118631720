import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from "@angular/common/http";
import { Observable } from 'rxjs';

import { LogService } from 'src/services/log.service';
import { ConstantsService } from 'src/services/constants.service';
import { GlobalService } from 'src/services/global';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private logService: LogService,
        private globalService: GlobalService,
        private constantsService: ConstantsService
    ) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.logService.log('AuthInterceptor');
        this.logService.log(request.url);
        this.logService.log(request.method);
        this.logService.log(request.body);
        const token = this.globalService.getToken();

        if (token) {
            const cloned = request.clone({
                headers: request.headers.set(this.constantsService.getHeaderAuth(), token)
            });
            this.logService.log(this.constantsService.getHeaderAuth() + ': ' + token);

            return next.handle(cloned);
        }
        else {
            return next.handle(request);
        }
    }
}