import { Component, OnInit, OnChanges, DoCheck, AfterContentInit, AfterContentChecked, AfterViewInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ErrorService } from 'src/services/error.service';
import { PageEvent } from '@angular/material/paginator';
import { AdminMantenimientoProductoPresentacionesController } from './admin-mantenimiento-productos-presentaciones.component.controller';
import { ProductoPresentacion } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoPresentacion';
import { Producto } from 'src/models/com.enersol.directshopping.dal.entidad/Producto';
import { Presentacion } from 'src/models/com.enersol.directshopping.dal.entidad/Presentacion';
import { ProductoPresentacionId } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoPresentacionId';
import { FotografiaProducto } from 'src/models/com.enersol.directshopping.dal.entidad/FotografiaProducto';
import { FotografiaProductoId } from 'src/models/com.enersol.directshopping.dal.entidad/FotografiaProductoId';
import { EstadoInventarioProducto } from 'src/models/com.enersol.directshopping.dal.entidad/EstadoInventarioProducto';
import { GlobalService } from 'src/services/global';

@Component({
  selector: 'mantenimiento-presentaciones',
  templateUrl: './admin-mantenimiento-productos-presentaciones.component.html',
  styleUrls: ['./admin-mantenimiento-productos-presentaciones.component.scss'],
  providers: [AdminMantenimientoProductoPresentacionesController]
})
export class AdminMantenimientoProductoPresentacionesBackview implements OnInit {

  /* Variables para controlar los roles del usuario */
  isAdmin: Boolean;

  productoSeleccionado: Producto;

  listaProductoPresentaciones: ProductoPresentacion[] = null;
  nuevoProductoPresentacion: ProductoPresentacion = new ProductoPresentacion();
  agregarProducto: Boolean = false;

  fotografiaNuevoProductoPresentacion: FotografiaProducto = new FotografiaProducto();

  listaPresentaciones: Presentacion[] = null;

  urlEnersol: string = "";

  /* Variables para la fotografia del producto */
  nuevaFotografia: File = null;
  nuevaFotografiaBase64: string | ArrayBuffer = "";

  /* Variable para los mensajes */
  contenidoMensaje: String;
  tituloMensaje: String;
  esError: Boolean = false;

  /* Variables para la paginacion */
  page_size: number = 10;
  page_number: number = 1;
  pageSizeOptions = [5, 10, 20, 50, 100];
  productoInOrdenes: boolean;


  // gberrocal 2022-05-30 - Se agregan campos para el manejo de inventario
  listaEstadosInventarioProducto: EstadoInventarioProducto[] = null;

  constructor(
    private router: Router,
    private adminMantenimientosComponent: AdminMantenimientoProductoPresentacionesController,
    private errorService: ErrorService,
    private globalEnersolService: GlobalService,
    private spinner: NgxSpinnerService,

  ) { }

  async ngOnInit() {
    this.isAdmin = await this.adminMantenimientosComponent.isAdminEnersol();
    this.spinnerShow();
    this.nuevoProductoPresentacion.id = new ProductoPresentacionId();
    this.nuevoProductoPresentacion.presentacion = new Presentacion();
    this.nuevoProductoPresentacion.fotografiaProductos = new Array<FotografiaProducto>();
    this.nuevoProductoPresentacion.estadoInventarioProducto = new EstadoInventarioProducto();
    this.urlEnersol = await this.adminMantenimientosComponent.urlEnersol;
    await this.getProducto();
    await this.getProductoPresentacionesProducto(this.productoSeleccionado);
    await this.getPresentaciones();
    await this.getEstadosInventarioProducto();

    this.spinnerHide();
  }

  /**
  * Función que obtiene el producto
  * @author msemeraro
  */
  async getProducto() {
    let producto = await this.adminMantenimientosComponent.getProducto();
    if (!producto.sucess) {
      let mensaje = this.errorService.displayError(producto.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.productoSeleccionado = producto.data;
    }
  }

  /**
  * Función que obtiene los productos presentacion del producto
  * @author msemeraro
  */
  async getProductoPresentacionesProducto(producto: Producto) {
    let productoPresentaciones = await this.adminMantenimientosComponent.getProductoPresentacionesProducto(producto.id.codigoProducto, producto.id.codigoMarca);
    if (!productoPresentaciones.sucess) {
      let mensaje = this.errorService.displayError(productoPresentaciones.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.listaProductoPresentaciones = productoPresentaciones.data;
      for(let productoPresentacion of this.listaProductoPresentaciones){
        if(!productoPresentacion.estadoInventarioProducto){
          productoPresentacion.estadoInventarioProducto = new EstadoInventarioProducto();
        }
      }
    }

  }

  /**
  * Función que obtiene las presentaciones registradas
  * @author msemeraro
  */
  async getPresentaciones() {
    let presentaciones = await this.adminMantenimientosComponent.getPresentaciones();
    if (!presentaciones.sucess) {
      let mensaje = this.errorService.displayError(presentaciones.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.listaPresentaciones = presentaciones.data;
    }
  }

  /**
  * Función que obtiene los estados del inventario de productos
  * @author gberrocal
  */
   async getEstadosInventarioProducto() {
    let resultListaEstadosInventarioProducto = await this.adminMantenimientosComponent.getEstadosInventarioProducto();
    if (!resultListaEstadosInventarioProducto.sucess) {
      let mensaje = this.errorService.displayError(resultListaEstadosInventarioProducto.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.listaEstadosInventarioProducto = resultListaEstadosInventarioProducto.data;
    }
  }

  /**
  * Función que controla el cambio de estado
  * @param codigoEstado 
  * @author gberrocal
  */
  onChangeEstadoInventarioProducto(codigoEstado) {
    if(this.listaEstadosInventarioProducto != null){
      for (var estado of this.listaEstadosInventarioProducto) {
        if(estado.id == codigoEstado){
          this.nuevoProductoPresentacion.estadoInventarioProducto.id = estado.id;
          this.nuevoProductoPresentacion.estadoInventarioProducto.estado = estado.estado;
          break;
        }
      }
    }
  }

  /**
  * Función que elimina un producto presentación
  * @author msemeraro
  */
  async eliminarProductoPresentacion(productoPresentacion: ProductoPresentacion) {
    var confirmacion = confirm("¿Desea eliminar la productoPresentacion " + productoPresentacion.nombre + "?");
    if (confirmacion) {
      await this.spinnerShow();
      let respuesta = await this.adminMantenimientosComponent.isProductoInOrdenes(productoPresentacion.id.codigoProductoPresentacion);
      // Si se obtuvo respuesta
      if(respuesta.sucess){
        // Si está en una orden
        if (respuesta.data) {
          this.adminMantenimientosComponent.setMensaje('Error al eliminar la presentacion del producto', 'No se puede eliminar el producto porque está asociado a órdenes de compra', true);
          this.verMensaje();
        }
        else {
          let respuesta = await this.adminMantenimientosComponent.eliminarProductoPresentacion(productoPresentacion);
          if (!respuesta.sucess) {
            let mensaje = this.errorService.displayError(respuesta.errors);
            this.adminMantenimientosComponent.setMensaje('Error al eliminar la productoPresentacion', mensaje, true);
            this.verMensaje();
          }
          else {
            await this.getProductoPresentacionesProducto(this.productoSeleccionado);
          }
        }
      }
      else {
        this.adminMantenimientosComponent.setMensaje('Error al eliminar la presentacion del producto', 'No se pudo verificar si el producto está en una orden', true);
        this.verMensaje();
      }
      await this.spinnerHide();
    }
  }

  /**
  * Función que actualiza un producto presentación
  * @author msemeraro
  */
  async actualizarProductoPresentacion(productoPresentacion: ProductoPresentacion) {
    this.spinnerShow();
    let respuesta = await this.adminMantenimientosComponent.actualizarProductoPresentacion(productoPresentacion);
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al actualizar la presentación del producto', mensaje, true);
      this.verMensaje();
    }
    else {
      this.adminMantenimientosComponent.setMensaje('Actualización exitosa', "Se actualizó la presentación del producto", false);
      this.verMensaje();
      this.nuevoProductoPresentacion = new ProductoPresentacion();
      this.nuevoProductoPresentacion.id = new ProductoPresentacionId();
      this.nuevoProductoPresentacion.presentacion = new Presentacion();
      this.nuevoProductoPresentacion.fotografiaProductos = new Array<FotografiaProducto>();
      this.nuevoProductoPresentacion.estadoInventarioProducto = new EstadoInventarioProducto();
      await this.getProductoPresentacionesProducto(this.productoSeleccionado);
    }
    this.spinnerHide();
  }

  /**
  * Función que agrega un producto presentación
  * @author msemeraro
  */
  async agregarProductoPresentacion() {
    this.nuevoProductoPresentacion.producto = this.productoSeleccionado;
    this.nuevoProductoPresentacion.id.codigoMarca = this.productoSeleccionado.id.codigoMarca;
    this.nuevoProductoPresentacion.id.codigoProducto = this.productoSeleccionado.id.codigoProducto;
    this.nuevoProductoPresentacion.id.codigoPresentacion = this.nuevoProductoPresentacion.presentacion.codigoPresentacion;
    this.nuevoProductoPresentacion.id.codigoProductoPresentacion = null;
    /* Crea la fotografia */
    // let fotografia:FotografiaProducto  = new FotografiaProducto();
    // fotografia.id.codigoMarca = this.productoSeleccionado.id.codigoMarca;
    // fotografia.id.codigoPresentacion = this.nuevoProductoPresentacion.presentacion.codigoPresentacion;
    // fotografia.id.codigoProducto = this.productoSeleccionado.id.codigoProducto;
    // fotografia.archivoBase64 = this.nuevaFotografiaBase64;
    // fotografia.mimeType = this.nuevaFotografia.type;
    // fotografia.nombreFotografia = this.nuevaFotografia.name;
    // fotografia.productoPresentacion = this.nuevoProductoPresentacion;
    // this.nuevoProductoPresentacion.fotografiaProductos.push()

    if (!this.adminMantenimientosComponent.validarNombre(this.nuevoProductoPresentacion.nombre)) {
      this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar un nombre válido", true);
      this.verMensaje();
      return;
    }
    this.spinnerShow();
    let respuesta = await this.adminMantenimientosComponent.agregarProductoPresentacion(this.nuevoProductoPresentacion);
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al agregar la productoPresentacion', mensaje, true);
      this.verMensaje();
    }
    else {
      this.nuevoProductoPresentacion = respuesta.data;
      if ((this.nuevaFotografia != null) || (this.nuevaFotografia != undefined)) {
        await this.agregarFotografiaProducto(this.nuevaFotografia);
      }
      $(".close").click();
      await this.nuevoProducto();
      await this.getProductoPresentacionesProducto(this.productoSeleccionado);
    }
    this.spinnerHide();
  }

  /**
  * Función cambia el indicador de habilitado de un producto presentación
  * @author msemeraro
  */
  habilitarProducto(productoPresentacion: ProductoPresentacion) {
    let posicion: number = 0;
    for (let productoPresentacionActualizar of this.listaProductoPresentaciones) {
      if (productoPresentacionActualizar.id.codigoProductoPresentacion == productoPresentacion.id.codigoProductoPresentacion) {
        this.listaProductoPresentaciones[posicion].indicadorHabilitado = !this.listaProductoPresentaciones[posicion].indicadorHabilitado;
        break;
      }
      posicion++;
    }
  }

  /**
  * Función carga los datos del producto a editar en el modal
  * @author msemeraro
  */
  async editarProducto(productoPresentacion: ProductoPresentacion) {
    if (await this.isProductoInOrdenes(productoPresentacion.id.codigoProductoPresentacion)) {
      // this.adminMantenimientosComponent.setMensaje(
      // 'Advertencia', 
      // "El producto está asociado a una o más órdenes, dado esto, solo puede editar ciertos campos de información",
      //  false);
      // this.verMensaje();
      this.productoInOrdenes = true;
    } else {
      this.productoInOrdenes = false;
    }

    this.agregarProducto = false;
    this.nuevoProductoPresentacion = productoPresentacion;
    this.fotografiaNuevoProductoPresentacion = productoPresentacion.fotografiaProductos[0];

  }

  /**
  * Función limpia los datos para crear un nuevo producto en el modal
  * @author msemeraro
  */
  async nuevoProducto() {
    this.agregarProducto = true;
    this.nuevoProductoPresentacion = new ProductoPresentacion();
    this.nuevoProductoPresentacion.id = new ProductoPresentacionId();
    this.nuevoProductoPresentacion.presentacion = await new Presentacion();
    this.nuevoProductoPresentacion.fotografiaProductos = new Array<FotografiaProducto>();
    this.nuevoProductoPresentacion.estadoInventarioProducto = new EstadoInventarioProducto();
    this.nuevoProductoPresentacion.indicadorHabilitado = false;
    this.nuevoProductoPresentacion.indicadorNuevo = false;
    this.nuevoProductoPresentacion.indicadorPromocion = false;
    if (this.nuevoProductoPresentacion.numeroParte == undefined ||
      this.nuevoProductoPresentacion.numeroParte == null) {
      this.nuevoProductoPresentacion.numeroParte = "";
    }
  }

  /**
  * Función actualiza la presentación al producto de la lista seleccionado
  * @author msemeraro
  */
  changePresentacion(codPresentacion: number, productoPresentacion: ProductoPresentacion) {
    let posicion: number = 0;

    for (let presentacion of this.listaPresentaciones) {
      if (presentacion.codigoPresentacion == codPresentacion) {
        posicion = 0;
        for (let productoPresentacionActualizar of this.listaProductoPresentaciones) {
          if (productoPresentacionActualizar.id.codigoProductoPresentacion == productoPresentacion.id.codigoProductoPresentacion) {
            this.listaProductoPresentaciones[posicion].presentacion = presentacion;
            break;
          }
          posicion++;
        }
      }
    }
  }

  /**
  * Función cambia el estado del indicador de un producto presentación
  * @author msemeraro
  */
  estadoProductoNuevo(atributo: string) {
    if (atributo === 'indicadorHabilitado')
      this.nuevoProductoPresentacion.indicadorHabilitado = !this.nuevoProductoPresentacion.indicadorHabilitado;
    else if (atributo === 'indicadorNuevo')
      this.nuevoProductoPresentacion.indicadorNuevo = !this.nuevoProductoPresentacion.indicadorNuevo;
    else
      this.nuevoProductoPresentacion.indicadorPromocion = !this.nuevoProductoPresentacion.indicadorPromocion;
  }

  /**
  * Función actualiza la presentación al producto del modal
  * @author msemeraro
  */
  changePresentacionNuevo(codPresentacion: number) {
    for (let presentacion of this.listaPresentaciones) {
      if (presentacion.codigoPresentacion == codPresentacion) {
        this.nuevoProductoPresentacion.presentacion = presentacion;
        break;
      }
    }
  }

  /**
  * Función actualiza la fotografia seleccionada del producto del modal
  * @author msemeraro
  */
  changeFotografia(codFotografia: number) {
    for (let fotografia of this.nuevoProductoPresentacion.fotografiaProductos) {
      if (fotografia.id.idFotografia == codFotografia)
        this.fotografiaNuevoProductoPresentacion = fotografia;
    }
  }

  /**
  * Función que agrega una fotografía al producto
  * @author msemeraro
  */
  agregarFotografia(event) {
    if (event.target.files[0] != null) {
      this.nuevaFotografia = <File>event.target.files[0];
      if (!this.adminMantenimientosComponent.validateImage(this.nuevaFotografia)) {
        this.nuevaFotografia = null;
        this.adminMantenimientosComponent.setMensaje('Error', "Debe de adjuntar una imagen válida", true);
        this.verMensaje();
      }
      else {
        // Obtiene el base64 de la fotografia
        this.getImageBase64(this.nuevaFotografia);
      }
    }

  }

  async getImageBase64(file: File) {
    var myReader: FileReader = new FileReader();
    myReader.onloadend = async () => {
      this.nuevaFotografiaBase64 = myReader.result;
      if (!this.agregarProducto) {
        this.spinnerShow();
        await this.agregarFotografiaProducto(file);
      }
    }
    await myReader.readAsDataURL(file);
  }

  async agregarFotografiaProducto(file: File) {
    let nuevaFotografiaProducto: FotografiaProducto = new FotografiaProducto();
    let nuevaFotografiaProductoId: FotografiaProductoId = new FotografiaProductoId();
    nuevaFotografiaProducto.archivoBase64 = this.nuevaFotografiaBase64;
    nuevaFotografiaProducto.mimeType = file.type;
    nuevaFotografiaProducto.nombreFotografia = file.name;
    nuevaFotografiaProducto.productoPresentacion = this.nuevoProductoPresentacion;
    nuevaFotografiaProductoId.codigoMarca = this.nuevoProductoPresentacion.id.codigoMarca;
    nuevaFotografiaProductoId.codigoPresentacion = this.nuevoProductoPresentacion.id.codigoPresentacion;
    nuevaFotografiaProductoId.codigoProducto = this.nuevoProductoPresentacion.id.codigoProducto;
    nuevaFotografiaProductoId.codigoProductoPresentacion = this.nuevoProductoPresentacion.id.codigoProductoPresentacion;
    nuevaFotografiaProducto.id = nuevaFotografiaProductoId;
    let respuesta = await this.adminMantenimientosComponent.agregarFotografiaProductoPresentacion(nuevaFotografiaProducto);
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al cargar la fotografía', mensaje, true);
      this.spinnerHide();
      this.verMensaje();
    }
    else {
      this.spinnerHide();
      this.editarProducto(this.nuevoProductoPresentacion);
    }
  }


  /**
  * Función que elimina una fotografía si no es la única del producto
  * @author msemeraro
  */
  async eliminarFotografia() {
    var confirmacion = confirm("¿Desea eliminar la fotografía " + this.fotografiaNuevoProductoPresentacion.nombreFotografia + "?");
    if (confirmacion) {
      if (this.nuevoProductoPresentacion.fotografiaProductos.length == 1) {
        this.adminMantenimientosComponent.setMensaje('Error', "El producto debe de tener al menos una fotografía", true);
        this.verMensaje();
        return;
      }
      else {
        await this.adminMantenimientosComponent.eliminarFotografiaProductoPresentacion(this.fotografiaNuevoProductoPresentacion);
        this.editarProducto(this.nuevoProductoPresentacion);
      }
      /*
      let fotografias: FotografiaProducto[] = new Array<FotografiaProducto>();
      for (let fotografia of this.nuevoProductoPresentacion.fotografiaProductos) {
        if (fotografia.id.idFotografia != this.fotografiaNuevoProductoPresentacion.id.idFotografia)
          fotografias.push(fotografia);
      }
      this.nuevoProductoPresentacion.fotografiaProductos = fotografias;
      this.fotografiaNuevoProductoPresentacion = this.nuevoProductoPresentacion.fotografiaProductos[0];
      */
    }
  }


  async actualizarProducto() {
    this.spinnerShow();
    let respuesta = await this.adminMantenimientosComponent.actualizarProducto(this.productoSeleccionado);
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al actualizar el producto', mensaje, true);
      this.spinnerHide();
      this.verMensaje();
    }
    else {
      this.nuevoProductoPresentacion = new ProductoPresentacion();
      this.nuevoProductoPresentacion.id = new ProductoPresentacionId();
      this.nuevoProductoPresentacion.presentacion = new Presentacion();
      this.nuevoProductoPresentacion.fotografiaProductos = new Array<FotografiaProducto>();
      await this.getProducto();
      await this.getProductoPresentacionesProducto(this.productoSeleccionado);
      this.spinnerHide();
    }
  }

  editarFichaTecnica() {
    var confirmacion = confirm("¿Desea cargar un nuevo archivo para la ficha técnica del producto?");
    if (confirmacion) {
      document.getElementById("upload-ficha").click();
    }
  }

  async cargarFichaTecnica(event) {
    if (event.target.files[0] != null) {
      let ficha: File = <File>event.target.files[0];
      if (!this.adminMantenimientosComponent.validatePDF(ficha)) {
        this.adminMantenimientosComponent.setMensaje('Error', "Debe de adjuntar un PDF válido", true);
        this.verMensaje();
      }
      else {
        await this.getFichaTecnicaBase64(ficha);
      }
    }
  }

  async getFichaTecnicaBase64(file: File) {
    this.spinnerShow();
    var myReader: FileReader = new FileReader();
    myReader.onloadend = async () => {
      let base64 = myReader.result;
      this.productoSeleccionado.fichaTecnica = file.name;
      this.productoSeleccionado.fichaTecnicaBase64 = base64;
      await this.actualizarProducto();
    }
    await myReader.readAsDataURL(file);
  }

  editarHojaSeguridad() {
    var confirmacion = confirm("¿Desea cargar un nuevo archivo para la hoja de seguridad del producto?");
    if (confirmacion) {
      document.getElementById("upload-hoja").click();
    }
  }

  async getHojaSeguridadBase64(file: File) {
    this.spinnerShow();
    var myReader: FileReader = new FileReader();
    myReader.onloadend = async () => {
      let base64 = myReader.result;
      this.productoSeleccionado.hojaSeguridad = file.name;
      this.productoSeleccionado.hojaSeguridadBase64 = base64;
      await this.actualizarProducto();
    }
    await myReader.readAsDataURL(file);
  }

  async cargarHojaSeguridad(event) {
    if (event.target.files[0] != null) {
      let ficha: File = <File>event.target.files[0];
      if (!this.adminMantenimientosComponent.validatePDF(ficha)) {
        this.adminMantenimientosComponent.setMensaje('Error', "Debe de adjuntar un PDF válido", true);
        this.verMensaje();
      }
      else {
        await this.getHojaSeguridadBase64(ficha);
      }
    }
  }

  editarAnalisisSeguridad() {
    var confirmacion = confirm("¿Desea cargar un nuevo archivo para el análisis de laboratorio del producto?");
    if (confirmacion) {
      document.getElementById("upload-analisis").click();
    }
  }

  async getAnalisisSeguridadBase64(file: File) {
    this.spinnerShow();
    var myReader: FileReader = new FileReader();
    myReader.onloadend = async () => {
      let base64 = myReader.result;
      this.productoSeleccionado.analisisLaboratorio = file.name;
      this.productoSeleccionado.analisisLaboratorioBase64 = base64;
      await this.actualizarProducto();
    }
    await myReader.readAsDataURL(file);
  }

  async cargarAnalisisLaboratorio(event) {
    if (event.target.files[0] != null) {
      let ficha: File = <File>event.target.files[0];
      if (!this.adminMantenimientosComponent.validatePDF(ficha)) {
        this.adminMantenimientosComponent.setMensaje('Error', "Debe de adjuntar un PDF válido", true);
        this.verMensaje();
      }
      else {
        await this.getAnalisisSeguridadBase64(ficha);
      }
    }
  }

  async isProductoInOrdenes(codigoProductoPresentacion: number) {
    let respuesta = await this.adminMantenimientosComponent.isProductoInOrdenes(codigoProductoPresentacion);
    if (respuesta.sucess) {
      return respuesta.data;
    } else {
      this.adminMantenimientosComponent.setMensaje('Error', "El codigo del producto no existe", true);
      this.verMensaje();
    }
  }

  /**
    * Función que muestra un documento
    * @author msemeraro
    */
  descargaArchivo(url) {
    this.adminMantenimientosComponent.abrirArchivo(url);
  }

  /**
  * Función que navega a otra pagina
  * @author msemeraro
  */
  irPagina(nombre: string) {
    this.router.navigate([nombre]);
  }

  spinnerShow() {
    this.spinner.show();
  }

  spinnerHide() {
    this.spinner.hide();
  }

  /**
  * Función que muestra un mensaje
  * @author msemeraro
  */
  verMensaje() {
    this.tituloMensaje = this.adminMantenimientosComponent.getTituloMensaje();
    this.contenidoMensaje = this.adminMantenimientosComponent.getContenidoMensaje();
    this.esError = this.adminMantenimientosComponent.getEsError();
    var element = document.getElementById("btnAbrirMensaje") as any;
    element.click();
  }

  clickModalMensaje() { }

  clickModalCambioEstado() { }

  handlePage(e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }
}
