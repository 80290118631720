import { Component, OnInit, ɵConsole, Injectable } from '@angular/core';
/* SERVICES */
import { HttpClientEnersolService } from 'src/services/http-client-enersol.service';
import { GlobalService } from 'src/services/global';
import { ValidationsService } from 'src/services/validations.service';
/* REST MODEL */
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
/* MODELS */
import { ProductoPorOrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoPorOrdenDeCompra';
import { HistorialEstadoDetalleOrden } from 'src/models/com.enersol.directshopping.dal.entidad/HistorialEstadoDetalleOrden';
import { DetalleOrdenCompraPorMarca } from 'src/models/com.enersol.directshopping.dal.entidad/DetalleOrdenCompraPorMarca';
import { OrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/OrdenDeCompra';
import { ComprobantePagoOrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/ComprobantePagoOrdenDeCompra';

@Injectable()
export class DetalleOrdenController {

  fileBase64: string |ArrayBuffer = '';

  constructor(
    private httpClientEnersolService: HttpClientEnersolService,
    private globalEnersolService: GlobalService,
    private validationEnersolService: ValidationsService
  ) { }

  /* FUNCIONES QUE HACEN PETICIONES A LOS SERVICIOS */

  /**
  * Función que obtiene los productos relacionados a un detalle de una orden
  * @param idDetalle id del detalle de los productos
  * @returns ListResponse<ProductoPorOrdenDeCompra>
  * @author msemeraro
  */
  async getDetailProducts(idDetalle: number): Promise<ListResponse<ProductoPorOrdenDeCompra>> {
    let respuesta: ListResponse<ProductoPorOrdenDeCompra>;
    try {
      const path = 'ProductoPorOrdenDeCompra/DetalleOrdenMarca?codDetalleOrden=' + idDetalle;
      const promiseResponse = await this.httpClientEnersolService.httpGet(path);
      respuesta =  promiseResponse as ListResponse<ProductoPorOrdenDeCompra>;
    } catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<ProductoPorOrdenDeCompra>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    } finally {
      return respuesta;
    }
  }

  /**
  * Función que obtiene el historial de estados detalles de una orden
  * @param idDetalle id del detalle de los productos
  * @returns ListResponse<HistorialEstadoDetalleOrden>
  * @author msemeraro
  */
  async getHistorialEstadosDetalles(codOrden: number): Promise<ListResponse<HistorialEstadoDetalleOrden>> {
    let respuesta: ListResponse<HistorialEstadoDetalleOrden>;
    try {
      const path = 'HistorialEstadoDetalleOrden/PorOrden?codOrden=' + codOrden;
      const promiseResponse = await this.httpClientEnersolService.httpGet(path);
      respuesta =  promiseResponse as ListResponse<HistorialEstadoDetalleOrden>;
    } catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<HistorialEstadoDetalleOrden>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    } finally {
      return respuesta;
    }
  }

  /**
   * Función que obtiene los detalles de una orden de compra
   * @returns ListResponse<DetalleOrdenCompraPorMarca>
   * @author msemeraro
   */
  async getDetallesOrden(codOrden: number): Promise<ListResponse<DetalleOrdenCompraPorMarca>> {
    let respuesta: ListResponse<DetalleOrdenCompraPorMarca>;
    try {
      const path = 'DetalleOrdenCompraPorMarca/DetallesOrden?codOrden=' + codOrden;
      const promiseResponse = await this.httpClientEnersolService.httpGet(path);
      respuesta =  promiseResponse as ListResponse<DetalleOrdenCompraPorMarca>;
    } catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<DetalleOrdenCompraPorMarca>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    } finally {
      return respuesta;
    }
  }

  /**
  * Función que hace la petición del servicio de cambiar el estado a un detalle
  * @returns Response<DetalleOrdenCompraPorMarca>
  * @author msemeraro
  */
  async updateEstadoDetalleOrden(codDetalleOrden: number,
                                 codEstado: number, comentario: string): Promise<Response<DetalleOrdenCompraPorMarca>> {
    let respuesta: Response<DetalleOrdenCompraPorMarca>;
    try {
      const promiseResponse = await this.httpClientEnersolService.httpPost('DetalleOrdenCompraPorMarca/update/state', this.getDatosUpdateEstadoDetalle(codDetalleOrden, codEstado, comentario));
      respuesta =  promiseResponse as Response<DetalleOrdenCompraPorMarca>;
    } catch (error) {
      console.log(error.message);
      respuesta = new Response<DetalleOrdenCompraPorMarca>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    } finally {
      return respuesta;
    }
  }

  /**
  * Función que retorna si el nombre de un comprobante ya fue registrado
  * @param nombre nombre del comprobante
  * @returns Response<Boolean>
  * @author msemeraro
  */
  async existeNombreComprobante(nombre: String): Promise<Response<Boolean>> {
    let respuesta: Response<Boolean>;
    try {
      const path = 'ComprobantePagoOrdenDeCompra/ExisteNombre?nombre=' + nombre;
      const promiseResponse = await this.httpClientEnersolService.httpGet(path);
      respuesta =  promiseResponse as Response<Boolean>;
    } catch (error) {
      console.log(error.message);
      respuesta = new Response<Boolean>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    } finally {
      return respuesta;
    }
  }

  /**
  * Función que actualiza el estado de una orden
  * @returns boolean
  * @author msemeraro
  */
  async updateEstadoOrden(orden: OrdenDeCompra, codEstado: number, comentario: string): Promise<boolean> {
    for (const detalle of orden.detallesOrden) {
      const respuesta = await this.updateEstadoDetalleOrden(detalle.codigoDetalleOrden, codEstado, comentario);
      if (!respuesta.sucess) {
        return false;
      }
    }
    return true;
  }

  /* FUNCIONES QUE HACEN PETICIONES AL GLOBAL SERVICE */

  /**
  * Función que obtiene la orden de compra del global service
  * @returns OrdenDeCompra
  * @author msemeraro
  */
  getOrdenDeCompra() {
    return this.globalEnersolService.getOrdenDeCompra();
  }

  /**
  * Función que actualiza la orden de compra del global service
  * @returns orden
  * @author msemeraro
  */
  setOrdenDeCompraImportacion(orden: OrdenDeCompra) {
    this.globalEnersolService.setOrdenDeCompraImportacion(orden);
  }

  /**
  * Función que almacena en el global service la orden de compra a mostrar
  * @param orden
  * @author msemeraro
  */
  async verDetalleOrden(orden: OrdenDeCompra) {
    if (orden.detallesOrden == null) {
      const detallesOrden = await this.getDetallesOrden(orden.codigoOrden);
      orden.detallesOrden = detallesOrden.data;
    }
    this.globalEnersolService.setOrdenDeCompraImportacion(orden);
  }

  /**
   * Función que obtiene una orden de compra
   * @param codOrden id de la orden de compra
   * @returns Response<OrdenDeCompra>
   * @author msemeraro
   */
  async getOrdenDeCompraUpdate(codOrden: number): Promise<Response<OrdenDeCompra>> {
    return this.globalEnersolService.getOrdenDeCompraUpdate(codOrden);
  }

  /**
    * Función que obtiene si un usuario es administrador de la estacion o no
    * @return esError
    * @author msemeraro
    */
  async isEnvioOrdenesComprobantesEstacion() {
    return this.globalEnersolService.isEnvioOrdenesComprobantesEstacion();
    }

  /* FUNCIONES PARA EL MANEJO DE LOS MONTOS */

  /**
  * Función que obtiene el monto total de una orden
  * @param subTotal monto subtotal de una orden de compra
  * @returns number
  * @author msemeraro
  */
  getTotalOrden(subTotal: number): number {
    return Math.round(this.getImpuestoOrden(subTotal) + subTotal);
  }

  /**
  * Función que obtiene el monto de impuesto de una orden
  * @param subTotal monto subtotal de una orden de compra
  * @returns number
  * @author msemeraro
  */
  getImpuestoOrden(subTotal: number): number {
    let impuestoSubTotal = subTotal * this.globalEnersolService.getOrdenDeCompra().impuesto;
    impuestoSubTotal = impuestoSubTotal / 100;
    return impuestoSubTotal;
  }

  /* FUNCIONES PARA EL FORMATO DE LOS NUMEROS */

  /**
  * Función que redondea un número
  * @param numero
  * @returns number redondeado
  * @author msemeraro
  */
  getRoundNumber(numero: number) {
    return Math.round(numero);
  }

  /**
  * Función que formatea un número
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
  getFormatNumber(numero: number) {
    return this.validationEnersolService.getNumber(numero);
  }

  /**
  * Función que retorna el porcentaje de ganancia de una orden de compra
  * @param ganancia
  * @param totalOrden
  * @returns porcentaje de ganancia
  * @author msemeraro
  */
  getPorcentajeGanancia(ganancia: number, totalOrden: number): number {
    if (totalOrden > 0) {
      return (ganancia * 100) / totalOrden;
    }
    return 0;
  }

  /**
  * Función que valida si un comentario es valido o no
  * @param comentario
  * @returns boolean
  * @author msemeraro
  */
  validateComentario(comentario: string): boolean {
    return this.validationEnersolService.validateComentarioCambioEstado(comentario);
  }

  /**
  * Función que valida si un archivo es un PDF o una imagen
  * @param file
  * @returns boolean
  * @author msemeraro
  */
  validateFile(file: File): boolean {
    return (this.validationEnersolService.validateFilePDF(file) || this.validationEnersolService.validateFileImage(file));
  }

  /* OTRAS FUNCIONES */

  /**
  * Función que obtiene las fechas para mostrar de una lista
  * @param listaHistorial
  * @returns listaHistorial
  * @author msemeraro
  */
  getFormatoFechas(listaHistorial: HistorialEstadoDetalleOrden[]): HistorialEstadoDetalleOrden[] {
    for (const historial of listaHistorial) {
      historial.id.fecha = new Date(historial.id.fecha);
      historial.fechaFormatoNormal = historial.id.fecha.toLocaleDateString() + ' ' + historial.id.fecha.toLocaleTimeString();
    }
    return listaHistorial;
  }

  /**
    * Función que ordena una lista de historial según la fecha
    * @param listaHistorial lista de historial a ordenar
    * @returns HistorialEstadoDetalleOrden[] lista de historial ordenada
    * @author msemeraro
    */
  orderByFecha(listaHistorial: HistorialEstadoDetalleOrden[]): HistorialEstadoDetalleOrden[] {
    return listaHistorial.sort((a, b) => (new Date(a.fechaFormatoNormal).getTime()) < (new Date(b.fechaFormatoNormal).getTime()) ? 1 : (new Date(a.fechaFormatoNormal).getTime()) === (new Date(b.fechaFormatoNormal).getTime()) ? 0 : -1);
  }


  /**
  * Función que obtiene el JSON para actualizar el estado de un detalle de orden
  * @param codDetalleOrden
  * @param codEstado
  * @returns json
  * @author msemeraro
  */
  getDatosUpdateEstadoDetalle(codDetalleOrden: number, codEstado: number, comentario: string) {
    return {
      codigoDetalleOrden: codDetalleOrden,
      codigoEstado: codEstado,
      comentario: comentario
    };
  }

  /**
  * Función que guarda las variables para mostrar un mensaje
  * @param tituloMensaje
  * @param contenidoMensaje
  * @param esError
  * @author msemeraro
  */
  setMensaje(tituloMensaje: String, contenidoMensaje: String, esError: Boolean): void {
    this.globalEnersolService.setTituloMensaje(tituloMensaje);
    this.globalEnersolService.setContenidoMensaje(contenidoMensaje);
    this.globalEnersolService.setEsError(esError);
  }

  /**
  * Función que obtiene el titulo de un mensaje
  * @return tituloMensaje
  * @author msemeraro
  */
  getTituloMensaje(): String {
    return this.globalEnersolService.getTituloMensaje();
  }

  /**
  * Función que obtiene el contenido de un mensaje
  * @return contenidoMensaje
  * @author msemeraro
  */
  getContenidoMensaje(): String {
    return this.globalEnersolService.getContenidoMensaje();
  }

  /**
  * Función que obtiene si un mensaje es error o no
  * @return esError
  * @author msemeraro
  */
  getEsError(): Boolean {
    return this.globalEnersolService.getEsError();
  }


  validarNombreComprobante(): Boolean {
    return true;
  }

  /**
  * Función que hace la petición al servicio de registrar un comprobante de pago
  * @returns Response<ComprobantePagoOrdenDeCompra>
  * @author msemeraro
  */
  async registrarComprobante(file: File, ordenDeCompra: OrdenDeCompra): Promise<Response<ComprobantePagoOrdenDeCompra>> {
    let respuesta: Response<ComprobantePagoOrdenDeCompra>;
    try {
      const promiseResponse = await this.httpClientEnersolService.httpPost('ComprobantePagoOrdenDeCompra/insert', await this.getDatosArchivoComprobante(file, ordenDeCompra));
      respuesta =  promiseResponse as Response<ComprobantePagoOrdenDeCompra>;
    } catch (error) {
      console.log(error.message);
      respuesta = new Response<ComprobantePagoOrdenDeCompra>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    } finally {
      return respuesta;
    }
  }

  /**
  * Función que retorna el json con los datos de un comprobante de pago
  * @param file
  * @param comprobante
  * @returns Json con los datos de un producto de un comprobante de pago
  * @author msemeraro
  */
  async getDatosArchivoComprobante(file: File, ordenDeCompra: OrdenDeCompra) {
    return {
      ordenDeCompra: {
        codigoOrden: ordenDeCompra.codigoOrden
      },
      comprobantePago: ordenDeCompra.codigoOrden + file.name,
      mimeType: file.type,
      archivoBase64: this.fileBase64
    };
  }

  async getFileBase64(file: File) {
    const myReader: FileReader = new FileReader();
    myReader.onloadend = () => {
      this.fileBase64 =  myReader.result;
    };
    await myReader.readAsDataURL(file);
  }

}
