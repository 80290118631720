import { BaseResponse } from './BaseResponse';

export class ListResponse<T> extends BaseResponse {

    data: Array<T>;

    constructor(sucess: boolean) {
        super(sucess);
    }

}