import { Component, OnInit, ɵConsole, Injectable } from '@angular/core';
/* SERVICES */
import { HttpClientEnersolService } from 'src/services/http-client-enersol.service';
import { GlobalService } from 'src/services/global';
import { ValidationsService } from 'src/services/validations.service';
/* REST MODEL */
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
import { Estacion } from 'src/models/com.enersol.directshopping.dal.entidad/Estacion';
import { Membresia } from 'src/models/com.enersol.directshopping.dal.entidad/Membresia';
import { Zona } from 'src/models/com.enersol.directshopping.dal.entidad/Zona';
/* MODELS */

@Injectable()
export class AdminMantenimientoEstacionesController {


  constructor(
    private httpClientEnersolService: HttpClientEnersolService,
    private globalEnersolService: GlobalService,
    private validationEnersolService: ValidationsService
  ) { }


  /* FUNCIONES QUE HACEN PETICIONES A LOS SERVICIOS */

  /**
  * Función que obtiene las estaciones registradas
  * @returns ListResponse<Estacion>
  * @author msemeraro
  */
  async getEstaciones(): Promise<ListResponse<Estacion>> {
    let respuesta: ListResponse<Estacion>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('Estacion/all');
      respuesta = <ListResponse<Estacion>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<Estacion>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que obtiene las membresias registradas
  * @returns ListResponse<Membresia>
  * @author msemeraro
  */
  async getMembresias(): Promise<ListResponse<Membresia>> {
    let respuesta: ListResponse<Membresia>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('Membresia/all');
      respuesta = <ListResponse<Membresia>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<Membresia>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que obtiene las zonas registradas
  * @returns ListResponse<Membresia>
  * @author msemeraro
  */
 async getZonas(): Promise<ListResponse<Zona>> {
  let respuesta: ListResponse<Zona>;
  try {
    let promiseResponse = await this.httpClientEnersolService.httpGet('Zona/all');
    respuesta = <ListResponse<Zona>>promiseResponse;
  }
  catch (error) {
    console.log(error.message);
    respuesta = new ListResponse<Zona>(false);
    respuesta.addError('code', 'techMessage', 'userMessage');
  }
  finally {
    return respuesta;
  }
}

  /**
  * Función que actualiza una estacion
  * @returns ListResponse<Estacion>
  * @author msemeraro
  */
  async actualizarEstacion(estacion: Estacion): Promise<Response<Estacion>> {
    let respuesta: Response<Estacion>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('Estacion/update', estacion);
      respuesta = <Response<Estacion>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Estacion>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función que hace la peticion al servidor de eliminar una estacion
    * @returns Response<Boolean>
    * @author msemeraro
    */
  async eliminarEstacion(estacion: Estacion): Promise<Response<Boolean>> {
    let respuesta: Response<Boolean>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('Estacion/delete', estacion);
      respuesta = <Response<Boolean>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Boolean>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función que hace la peticion al servidor de agregar una estacion
    * @returns Response<Estacion>
    * @author msemeraro
    */
  async agregarEstacion(estacion: Estacion): Promise<Response<Estacion>> {
    let respuesta: Response<Estacion>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('Estacion/insert', estacion);
      respuesta = <Response<Estacion>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Estacion>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }



  /* FUNCIONES QUE HACEN PETICIONES AL GLOBAL SERVICE */
  /**
  * Función que obtiene si el usuario logueado es administrador de enersol
  * @return boolean 
  * @author msemeraro
  */
  isAdminEnersol() {
    return this.globalEnersolService.isAdminEnersol();
  }


  /* FUNCIONES QUE HACEN ORDENAMIENTOS O FILTROS */





  /* OTRAS FUNCIONES */

  /**
  * Función que valida el nombre de la nueva estacion
  * @param nombre 
  * @author msemeraro
  */
  validarNombre(nombre: string): Boolean {
    return this.validationEnersolService.validateGeneral(nombre);
  }

  /**
  * Función que valida el que el string solo contenga numeros y sean 6 digitos
  * @param nombre 
  * @author msemeraro
  */
  validarNumeroRecope(numero: string): Boolean {
    return this.validationEnersolService.validateNumeric(numero) && numero.length == 6;
  }

  /**
  * Función que valida el que el string solo contenga numeros y sean 8 digitos
  * @param nombre 
  * @author msemeraro
  */
  validarTelefono(numero: string): Boolean {
    return this.validationEnersolService.validateNumeric(numero) && numero.length == 8;
  }

  /**
* Función que guarda las variables para mostrar un mensaje
* @param tituloMensaje
* @param contenidoMensaje
* @param esError
* @author msemeraro
*/
  setMensaje(tituloMensaje: String, contenidoMensaje: String, esError: Boolean): void {
    this.globalEnersolService.setTituloMensaje(tituloMensaje);
    this.globalEnersolService.setContenidoMensaje(contenidoMensaje);
    this.globalEnersolService.setEsError(esError);
  }

  /**
  * Función que obtiene el titulo de un mensaje
  * @return tituloMensaje 
  * @author msemeraro
  */
  getTituloMensaje(): String {
    return this.globalEnersolService.getTituloMensaje();
  }

  /**
  * Función que obtiene el contenido de un mensaje
  * @return contenidoMensaje 
  * @author msemeraro
  */
  getContenidoMensaje(): String {
    return this.globalEnersolService.getContenidoMensaje();
  }

  /**
  * Función que obtiene si un mensaje es error o no
  * @return esError 
  * @author msemeraro
  */
  getEsError(): Boolean {
    return this.globalEnersolService.getEsError();
  }

}
