import { Component, OnInit, Injectable } from '@angular/core';
import { HttpClientEnersolService } from 'src/services/http-client-enersol.service';
/* REST MODEL */
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
/* SERVICES */
import { GlobalService } from 'src/services/global';
import { ExcelService } from 'src/services/excel.service';
/* MODEL */
import { ReporteEstacionesMarcas } from 'src/models/com.enersol.directshopping.dal.entidad/ReporteEstacionesMarcas';
import { Zona } from 'src/models/com.enersol.directshopping.dal.entidad/Zona';
import { Estacion } from 'src/models/com.enersol.directshopping.dal.entidad/Estacion';

import { ReporteEstacionesOrdenes } from 'src/models/com.enersol.directshopping.dal.entidad/ReporteEstacionesOrdenes';
import { ValidationsService } from 'src/services/validations.service';


@Injectable()
export class ReporteOrdenController {

  constructor(private httpClientEnersolService: HttpClientEnersolService,
    private globalEnersolService: GlobalService,
    private excelService: ExcelService,
    private validationEnersolService: ValidationsService) { }

  /**
    * Función de prueba de reportes
    */
   async getReporte(codEstacions, fechaInicio, fechaFinal) {
    let respuesta: ListResponse<ReporteEstacionesMarcas>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet("ProductoPorOrdenDeCompra/Marcas/Estaciones?codEstacions=" + codEstacions + "&fechaInicio=" + fechaInicio + "&fechaFinal=" + fechaFinal);
      respuesta = <ListResponse<ReporteEstacionesMarcas>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<ReporteEstacionesMarcas>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función de prueba de reportes
    */
   async getReporteExcel(reportes:ReporteEstacionesMarcas[]) {
    let respuesta: Response<Boolean>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost("ProductoPorOrdenDeCompra/Marcas/Estaciones/Excel", reportes);
      respuesta = <Response<Boolean>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Boolean>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función de prueba de reportes
    */
   async getReporteOrdenes(codEstacions, fechaInicio, fechaFinal) {
    let respuesta: ListResponse<ReporteEstacionesOrdenes>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet("OrdenDeCompra/Estaciones?codEstacions=" + codEstacions + "&fechaInicio=" + fechaInicio + "&fechaFinal=" + fechaFinal);
      respuesta = <ListResponse<ReporteEstacionesOrdenes>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<ReporteEstacionesOrdenes>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      
      for(let reporteEstacionesOrdenes of respuesta.data ){
        for(let orden of reporteEstacionesOrdenes.ordenes){
          orden.fechaAprobacionParaPricing = new Date(orden.fechaAprobacionParaPricing);
          orden.fecha = orden.fechaAprobacionParaPricing.toLocaleDateString();
          orden.estados = this.getColoresDetalles(orden.estados);
        }
      }
      return respuesta;
    }
  }

  /**
    * Función que obtiene los códigos de estados de los detalles de una orden
    * @param detalles
    * @returns lista de números de estados
    * @author msemeraro
    */
  getColoresDetalles(listaEstados: number[]) {
    listaEstados.sort((n1, n2) => n1 - n2);
    let sinRepetidos = [...new Set(listaEstados)];
    return sinRepetidos;
  }

  exportAsExcelFile(json: any[], excelFileName: string){
    return this.excelService.exportAsExcelFile(json, excelFileName);
  }
  
  /**
    * Función que obtiene todas las zonas
    */
   async getZonas() {
    let respuesta: ListResponse<Zona>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet("Zona/all");
      respuesta = <ListResponse<Zona>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<Zona>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función que obtiene los Socios por Zona
    */
   async getEstacionesPorZona(codZona:number) {
    let respuesta: ListResponse<Estacion>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet("Estacion/PorZona?codigoZona="+codZona);
      respuesta = <ListResponse<Estacion>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<Estacion>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función que guarda las variables para mostrar un mensaje
    * @param tituloMensaje
    * @param contenidoMensaje
    * @param esError
    * @author msemeraro
    */
  setMensaje(tituloMensaje: String, contenidoMensaje: String, esError: Boolean): void {
    this.globalEnersolService.setTituloMensaje(tituloMensaje);
    this.globalEnersolService.setContenidoMensaje(contenidoMensaje);
    this.globalEnersolService.setEsError(esError);
  }

  /**
  * Función que obtiene el titulo de un mensaje
  * @return tituloMensaje 
  * @author msemeraro
  */
  getTituloMensaje(): String {
    return this.globalEnersolService.getTituloMensaje();
  }

  /**
  * Función que obtiene el contenido de un mensaje
  * @return contenidoMensaje 
  * @author msemeraro
  */
  getContenidoMensaje(): String {
    return this.globalEnersolService.getContenidoMensaje();
  }

  /**
  * Función que obtiene si un mensaje es error o no
  * @return esError 
  * @author msemeraro
  */
  getEsError(): Boolean {
    return this.globalEnersolService.getEsError();
  }


  /**
    * Función que obtiene los Socios por Zona
    */
  //  async getOrdenesDeCompraPorEstacion() {
  //   let respuesta: ListResponse<Orden>;
  //   try {
  //     let promiseResponse = await this.httpClientEnersolService.httpGet("OrdenDeCompra/Estaciones");
  //     respuesta = <ListResponse<Estacion>>promiseResponse;
  //   }
  //   catch (error) {
  //     console.log(error.message);
  //     respuesta = new ListResponse<Estacion>(false);
  //     respuesta.addError('code', 'techMessage', 'userMessage');
  //   }
  //   finally {
  //     return respuesta;
  //   }
  // }

    /**
  * Función que formatea un número
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
 getFormatNumber(numero: number) {
  return this.validationEnersolService.getNumber(numero);
}



}
