import { Component, OnInit, OnDestroy, AfterViewInit, AfterContentChecked } from '@angular/core';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { NgxSpinnerService } from "ngx-spinner";
/* SERVICES */
import { ErrorService } from 'src/services/error.service';
import { BusquedaController } from './busqueda.component.controller';
/* REST MODEL */
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
/* MODELS */
import { ProductoPresentacion } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoPresentacion';
import { Marca } from 'src/models/com.enersol.directshopping.dal.entidad/Marca';
import { Familia } from 'src/models/com.enersol.directshopping.dal.entidad/Familia';
import { GlobalService } from 'src/services/global';
import { ViewportScroller } from '@angular/common';
import { EnumEstadoInventarioProducto } from 'src/models/com.enersol.directshopping.dal.enums/EnumEstadoInventarioProducto';
import { EnumFormaInventario } from 'src/models/com.enersol.directshopping.dal.enums/EnumFormaInventario';
import { Parametro } from 'src/models/com.enersol.directshopping.dal.entidad/Parametro';

@Component({
  selector: 'app-busqueda',
  templateUrl: './busqueda.component.html',
  styleUrls: ['./busqueda.component.scss'],
  providers: [BusquedaController]
})
export class BusquedaBackview implements OnInit, OnDestroy {

  urlImagenNoDisponible = "../assets/img/no-disponible.png"
  /* Lista de productos a mostrar */
  productos: ListResponse<ProductoPresentacion> = new ListResponse<ProductoPresentacion>(false);
  productosBusqueda: Array<ProductoPresentacion> = new Array();

  /* Lista de productos original (No se filtra) */
  productosSinFiltrar: ListResponse<ProductoPresentacion> = new ListResponse<ProductoPresentacion>(false);

  /* Variables con los catálogos para los filtros */
  marcas: ListResponse<Marca> = new ListResponse<Marca>(false);
  familias: ListResponse<Familia> = new ListResponse<Familia>(false);

  /* Flags para saber si se selecciono algún filtro */
  marcaSeleccionada = '-';
  familiaSeleccionada = '-';

  ordenamientos = [{ 'nombre': "Precio: de más bajo a más alto", 'valor': "Menor" },
  { 'nombre': "Precio: de más alto a más bajo", 'valor': "Mayor" }];

  urlEnersol: string = "";
  
  inputBuscar: string;

  /* Variable para los mensajes */
  contenidoMensaje: String;
  tituloMensaje: String;
  esError: Boolean;

  /* Variables para la paginacion */
  page_size: number = 18;
  page_number: number = 1;
  pageSizeOptions = [18, 36, 54, 72, 90];
  verDetalleCatalogo: boolean;

  formaInventario: Parametro;

  constructor(
    private router: Router,
    public busquedaComponent: BusquedaController,
    private errorService: ErrorService,
    public globalEnersolService: GlobalService,
    private spinner: NgxSpinnerService,
    private scroll: ViewportScroller
  ) {

  }

  async ngOnInit() {
    await this.spinnerShow();
    
    this.verDetalleCatalogo = false;
    this.urlEnersol = await this.busquedaComponent.urlEnersol;
    await this.getProductos();
    await this.getMarcas();
    await this.getFamilias();
    await this.getOrdenamientos();
    if(this.globalEnersolService.filtroDeBusqueda != null && this.globalEnersolService.filtroDeBusqueda != undefined) {
      this.marcaSeleccionada = this.globalEnersolService.filtroDeBusqueda.marcaSeleccionada;
      this.familiaSeleccionada = this.globalEnersolService.filtroDeBusqueda.familiaSeleccionada;
      this.inputBuscar = this.globalEnersolService.filtroDeBusqueda.inputBuscar;
      if (this.marcaSeleccionada) {
        this.onChangeMarca()
      }
      if (this.familiaSeleccionada) {
        this.onChangeFamilia();
      }
    }
    this.globalEnersolService.getCantidadProductosCarrito();
    this.formaInventario = await this.globalEnersolService.getFormaInventario();
    await this.spinnerHide();
  }

  ngOnDestroy(){
    console.log("Exiting...");
    if (this.verDetalleCatalogo) {
      let filtro = {
        marcaSeleccionada: this.marcaSeleccionada,
        familiaSeleccionada: this.familiaSeleccionada,
        inputBuscar: this.inputBuscar
      }
      this.globalEnersolService.filtroDeBusqueda = filtro;
      console.log(this.scroll.getScrollPosition());
      this.globalEnersolService.scrollPosition = this.scroll.getScrollPosition();
    } else {
      this.globalEnersolService.filtroDeBusqueda = null;
      //this.globalEnersolService.scrollPosition = [0,0];
    }    
  }

  // (gberrocal 08/07/2022) Por qué está esto aquí? Creo que no se necesita, ahorita esta provocando fallo
  /*ngAfterViewChecked() {
    console.log(this.globalEnersolService.scrollPosition != null && this.globalEnersolService.scrollPosition != undefined)
    if (this.globalEnersolService.scrollPosition != null && this.globalEnersolService.scrollPosition != undefined) {
      this.scroll.setOffset(this.globalEnersolService.scrollPosition);
      this.scroll.scrollToPosition(this.globalEnersolService.scrollPosition);
      this.scroll.scrollToAnchor(this.globalEnersolService.scrollPosition);
    }
  }*/

  /**
  * Función que carga la lista de productos
  * @author msemeraro
  */
  async getProductos() {
    this.productos = await this.busquedaComponent.getProductos();
    if (!this.productos.sucess) {
      let mensaje = this.errorService.displayError(this.productos.errors);
      this.busquedaComponent.setMensaje('Error al obtener los productos', mensaje, true);
      this.verMensaje();
    }
    this.productosSinFiltrar.data = [];
    this.productosBusqueda = [];
    for (let entry of this.productos.data) {
      this.productosBusqueda.push(entry);
      this.productosSinFiltrar.data.push(entry);
    }
  }

  /**
  * Función que carga la lista de marcas
  * @author msemeraro
  */
  async getMarcas() {
    this.marcas = await this.busquedaComponent.getMarcas();
    if (!this.marcas.sucess) {
      let mensaje = this.errorService.displayError(this.marcas.errors);
      this.busquedaComponent.setMensaje('Error al obtener las marcas', mensaje, true);
      this.verMensaje();
    }
  }

  /**
  * Función que carga la lista de familias
  * @author msemeraro
  */
  async getFamilias() {
    this.familias = await this.busquedaComponent.getFamilias();
    if (!this.familias.sucess) {
      let mensaje = this.errorService.displayError(this.familias.errors);
      this.busquedaComponent.setMensaje('Error al obtener las familias', mensaje, true);
      this.verMensaje();
    }
  }

  /**
  * Función que filtra cuando se selecciona el filtro de marca
  * @author msemeraro
  */
  onChangeMarca() {
    this.getOrdenamientos();
    let nombreMarca = this.marcaSeleccionada;
    if (nombreMarca == '-') {
      if (this.familiaSeleccionada != '-') {
        this.onChangeFamilia();
      }
      else {
        this.productosBusqueda = this.productosSinFiltrar.data;
      }
    }
    else {
      if (this.familiaSeleccionada != '-') {
        this.filterMarcaFamilia();
      }
      else {
        let productosBorrador = this.productosSinFiltrar;
        this.productosBusqueda = this.busquedaComponent.filterProductosMarca(productosBorrador, nombreMarca);
      }
    }
  }

  /**
  * Función que filtra cuando se selecciona el filtro de familia
  * @author msemeraro
  */
  onChangeFamilia() {
    this.getOrdenamientos();
    let nombrefamilia = this.familiaSeleccionada;
    if (nombrefamilia == '-') {
      if (this.marcaSeleccionada != '-') {
        this.onChangeMarca();
      }
      else {
        this.productosBusqueda = this.productosSinFiltrar.data;
      }
    }
    else {
      if (this.marcaSeleccionada != '-') {
        this.filterMarcaFamilia();
      }
      else {
        let productosBorrador = this.productosSinFiltrar;
        this.productosBusqueda = this.busquedaComponent.filterProductosFamilia(productosBorrador, nombrefamilia);
      }
    }
  }

  onChangeSearchTerm() {
    if (this.marcaSeleccionada != '-') {
      this.onChangeMarca();
      this.productos.data = this.productosBusqueda;
    }
    else if (this.familiaSeleccionada != '-') {
      this.onChangeFamilia();
      this.productos.data = this.productosBusqueda;
    }
    else {
      if (this.inputBuscar == "") {
        this.productos.data = this.productosSinFiltrar.data;
      }
    }
    this.getOrdenamientos();
    // this.productos.data = this.productosSinFiltrar.data;
    this.productosBusqueda = this.busquedaComponent.filterProductosBySearchTerm(this.productos.data, this.inputBuscar);
  }

  /**
  * Función que filtra cuando se selecciona el filtro de familia y el de marca
  * @author msemeraro
  */
  async filterMarcaFamilia() {
    let productosBorrador = this.productosSinFiltrar;
    this.productosBusqueda = await this.busquedaComponent.filterProductosMarca(productosBorrador, this.marcaSeleccionada);
    this.productos.data = this.productosBusqueda;
    this.productosBusqueda = await this.busquedaComponent.filterProductosFamilia(this.productos, this.familiaSeleccionada);
  }

  /**
  * Función que muestra el detalle de un producto
  * @param producto producto a mostrar
  * @author msemeraro
  */
  verDetalle(producto: ProductoPresentacion) {
    this.busquedaComponent.verDetalleProducto(producto);
    this.verDetalleCatalogo = true;
    console.log(this.verDetalleCatalogo);
    this.router.navigate(['detalle-producto']);
  }

  /**
  * Función que añade una unidad de un producto al carrito
  * @param producto producto a añadir al carrito
  * @author msemeraro
  */
  async addCarrito(producto: ProductoPresentacion) {
    try{
      let respuesta = await this.busquedaComponent.addCarrito(producto, 1);
      if (!respuesta.sucess) {
        let mensaje = this.errorService.displayError(respuesta.errors);
        this.busquedaComponent.setMensaje('Error al añadir al carrito', mensaje, true);
        this.verMensaje();
      }
      else {
        this.busquedaComponent.setMensaje('Producto añadido al carrito', 'Se añadió una cantidad del producto: ' + producto.producto.nombre, false);
        this.verMensaje();
      }
    }
    catch(error){
      let mensaje = this.errorService.getErrorMessage(error.message);
      this.busquedaComponent.setMensaje('Error al añadir al carrito', mensaje, true);
      this.verMensaje();
    }
  }

  /**
  * Función que formatea un número
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
  getFormatNumber(numero: number) {
    return this.busquedaComponent.getFormatNumber(numero);
  }

  /**
  * Función que limpia los filtros de navegación
  * @author msemeraro
  */
  limpiarFiltros() {
    this.getMarcas();
    this.getFamilias();
    this.getOrdenamientos();
    this.productosBusqueda = this.productosSinFiltrar.data;
  }

  /**
  * Función que ordena la lista de productos mostrada actualmente
  * @param ordenamiento indica si ordena de Mayor a menor o alrevés
  * @author msemeraro
  */
  orderBy(ordenamiento: string) {
    if (ordenamiento != '-') {
      this.productosBusqueda = this.busquedaComponent.orderBy('precio', this.productosBusqueda, ordenamiento);
      this.productosBusqueda = this.busquedaComponent.filterProductosOrderBy(this.productosBusqueda);
    }
  }
  /**
  * Función limpia el select de ordenamiento
  * @author msemeraro
  */
  getOrdenamientos() {
    $("#selectOrder").val('-');
  }

  spinnerShow() {
    this.spinner.show();
  }

  spinnerHide() {
    this.spinner.hide();
  }

  isEstadoInventario(producto: ProductoPresentacion) {
    return this.isEstado(producto, EnumEstadoInventarioProducto.INVENTARIO.codigoEstado);
  }

  isEstadoProximamente(producto: ProductoPresentacion) {
    return this.isEstado(producto, EnumEstadoInventarioProducto.PROXIMAMENTE.codigoEstado);
  }

  isEstadoFueraInventario(producto: ProductoPresentacion) {
    return this.isEstado(producto, EnumEstadoInventarioProducto.FUERA_INVENTARIO.codigoEstado);
  }

  isEstadoProduccionSuspendida(producto: ProductoPresentacion) {
    return this.isEstado(producto, EnumEstadoInventarioProducto.PRODUCCION_SUSPENDIDA.codigoEstado);
  }

  isEstado(producto: ProductoPresentacion, codigoEstado: number){
    if(producto) {
      return producto.estadoInventarioProducto.id == codigoEstado;
    }
  }

  /**
   * Obtiene la cantidad disponible para importación
   * @returns 
   */
  getCantidadImportacionDisponible(producto: ProductoPresentacion): number{
    if(producto){
      return producto.cantidadImportacion - producto.importacionTransito;
    }
    return null;
  }

  /**
   * Indica si una presentacion de producto está en estado No Disponible
   * @param producto 
   * @author gberrocal
   */
   isProductoNoDisponible(producto: ProductoPresentacion):boolean {
    if(producto){
      return producto.estadoInventarioProducto.id == EnumEstadoInventarioProducto.PRODUCCION_SUSPENDIDA.codigoEstado;
    }
    return false;
  }

  /**
   * Indica si tiene que mostrar tanto la cantidad de inventario como de importación
   * @returns 
   */
   mostrarInventarioImportacion(producto: ProductoPresentacion): boolean{
    // Se muestra solo si la modalidad no es inventario
    if(this.formaInventario.valor != EnumFormaInventario.INVENTARIO.nombre){
      // Si el producto esta en inventario o fuera de invnetario
      if(this.isEstadoInventario(producto) || this.isEstadoFueraInventario(producto)){
        var cantidadImportacion = this.getCantidadImportacionDisponible(producto);
        return (cantidadImportacion && cantidadImportacion > 0);
      }
    }
    return false;
  }

  /**
   * Indica si se muestra el estado normal del producto
   * @param producto 
   * @returns 
   */
  mostrarEstadoNormal(producto: ProductoPresentacion): boolean{
    if(this.isModalidad(EnumFormaInventario.MIXTO.nombre) 
      || this.isModalidad(EnumFormaInventario.INVENTARIO.nombre)){
      return true;
    }
    else if(this.isModalidad(EnumFormaInventario.IMPORTACION.nombre)){
      if(this.isEstadoProximamente(producto)){
        return true;
      }
    }
    return false;
  }

  /**
   * Indica si la modalidad es mixta
   */
  isModalidadMixta(): boolean{
    return this.isModalidad(EnumFormaInventario.MIXTO.nombre);
  }

  /**
   * Indica si la modalidad de la plataforma es de un tipo determinado
   * @param modalidad 
   * @returns 
   */
  private isModalidad(modalidad: string): boolean{
    if(this.formaInventario){
      return this.formaInventario.valor == modalidad;
    }
    return false;
  }

  getNombreEstadoProximamente(): string{
    return EnumEstadoInventarioProducto.PROXIMAMENTE.nombre;
  }

  /**
  * Función que muestra un mensaje
  * @author msemeraro
  */
  verMensaje() {
    this.tituloMensaje = this.busquedaComponent.getTituloMensaje();
    this.contenidoMensaje = this.busquedaComponent.getContenidoMensaje();
    this.esError = this.busquedaComponent.getEsError();
    var element = document.getElementById("btnAbrirMensaje") as any;
    element.click();
  }

  clickModalMensaje() { }

  handlePage(e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }

}
