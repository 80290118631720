import { Component, OnInit, ɵConsole, Injectable } from '@angular/core';
/* SERVICES */
import { HttpClientEnersolService } from 'src/services/http-client-enersol.service';
import { GlobalService } from 'src/services/global';
import { ValidationsService } from 'src/services/validations.service';
/* REST MODEL */
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
/* MODELS */
import { OrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/OrdenDeCompra';
import { DetalleOrdenCompraPorMarca } from 'src/models/com.enersol.directshopping.dal.entidad/DetalleOrdenCompraPorMarca';

@Injectable()
export class MisOrdenesController {

  constructor(
    private httpClientEnersolService: HttpClientEnersolService,
    private globalEnersolService: GlobalService,
    private validationEnersolService: ValidationsService
  ) { }

  /* FUNCIONES QUE HACEN PETICIONES A LOS SERVICIOS */
  
  /**
  * Función que hace la petición del servicio de todas las ordenes
  * @returns ListResponse<OrdenDeCompra>
  * @author msemeraro
  */
  async getOrdenes(): Promise<ListResponse<OrdenDeCompra>> {
    let respuesta: ListResponse<OrdenDeCompra>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('OrdenDeCompra/Estacion');
      respuesta = <ListResponse<OrdenDeCompra>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<OrdenDeCompra>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      for (let orden of respuesta.data) {
        /*let detallesOrden = await this.getDetallesOrden(orden.codigoOrden);
        orden.detallesOrden = detallesOrden.data;*/
        orden.fechaAprobacionParaPricing = new Date(orden.fechaAprobacionParaPricing);
        orden.fecha = orden.fechaAprobacionParaPricing.toLocaleDateString();
        orden.estados = this.getColoresDetalles(orden.estados);
      }
      return respuesta;
    }
  }

  /**
    * Función que obtiene los detalles de una orden de compra
    * @returns ListResponse<DetalleOrdenCompraPorMarca>
    * @author msemeraro
    */
  async getDetallesOrden(codOrden: number): Promise<ListResponse<DetalleOrdenCompraPorMarca>> {
    let respuesta: ListResponse<DetalleOrdenCompraPorMarca>;
    try {
      let path = 'DetalleOrdenCompraPorMarca/DetallesOrden?codOrden=' + codOrden;
      let promiseResponse = await this.httpClientEnersolService.httpGet(path);
      respuesta = <ListResponse<DetalleOrdenCompraPorMarca>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<DetalleOrdenCompraPorMarca>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /* FUNCIONES QUE HACEN PETICIONES AL GLOBAL SERVICE */

  /**
  * Función que obtiene la cantidad de productos de una orden de compra
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
  async getCantidadProductos(codOrden: number) {
    let respuesta: Response<number>;
    try {
      respuesta = await this.globalEnersolService.getCantidadProductosOrden(codOrden);
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<number>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función que guarda las variables para mostrar un mensaje
    * @param tituloMensaje
    * @param contenidoMensaje
    * @param esError
    * @author msemeraro
    */
  setMensaje(tituloMensaje: String, contenidoMensaje: String, esError: Boolean): void {
    this.globalEnersolService.setTituloMensaje(tituloMensaje);
    this.globalEnersolService.setContenidoMensaje(contenidoMensaje);
    this.globalEnersolService.setEsError(esError);
  }

  /**
  * Función que obtiene el titulo de un mensaje
  * @return tituloMensaje 
  * @author msemeraro
  */
  getTituloMensaje(): String {
    return this.globalEnersolService.getTituloMensaje();
  }

  /**
  * Función que obtiene el contenido de un mensaje
  * @return contenidoMensaje 
  * @author msemeraro
  */
  getContenidoMensaje(): String {
    return this.globalEnersolService.getContenidoMensaje();
  }

  /**
  * Función que obtiene si un mensaje es error o no
  * @return esError 
  * @author msemeraro
  */
  getEsError(): Boolean {
    return this.globalEnersolService.getEsError();
  }

  /**
    * Función que almacena en el global service la orden de compra a mostrar
    * @param orden
    * @author msemeraro
    */
  async verDetalleOrden(orden: OrdenDeCompra) {
    if (orden.detallesOrden == null) {
      let detallesOrden = await this.getDetallesOrden(orden.codigoOrden);
      orden.detallesOrden = detallesOrden.data;
    }
    this.globalEnersolService.setOrdenDeCompraImportacion(orden);
  }


  /* FUNCIONES QUE HACEN ORDENAMIENTOS O FILTROS */

  /**
  * Función que filtra una lista de ordenes según el estado indicado
  * @param listaOrdenes Lista de ordenes a filtrar
  * @param codEstado codigo del estado por filtrar
  * @returns OrdenDeCompra[]
  * @author msemeraro
  */
  filterOrdenesEstado(listaOrdenes: Array<OrdenDeCompra>, codEstado: number): OrdenDeCompra[] {
    return listaOrdenes.filter((item) => {
      for (let estado of item.estados) {
        if (estado == codEstado) {
          if ((estado == 7) && codEstado == 7) {
            return true;
          } else if ((estado == 8) && codEstado == 8) {
            return true;
          }
          else if (estado == 7 && codEstado != 7) {
            return false;
          }
          else if (estado == 8 && codEstado != 8) {
            return false;
          }
          else {
          return true;
          }
        }
      } return false;
    });
  }

  /**
  * Función que filtra una lista de ordenes según el estado orden inventario
  * @param listaOrdenes Lista de ordenes a filtrar
  * @param codEstadoInventario codigo del estado por filtrar
  * @returns OrdenDeCompra[]
  * @author gberrocal
  */
   filtrarOrdenesEstadoInventario(listaOrdenes: Array<OrdenDeCompra>, codEstadoInventario: number): OrdenDeCompra[] {
    return listaOrdenes.filter((item) => {
      if(item.estadoOrdenInventario){
        return item.estadoOrdenInventario.id == codEstadoInventario;
      }
      return false;
    });
  }

  /**
  * Función que ordena una lista de ordenes según los parámetros
  * @param prop atributo de la lista por el cual ordenar
  * @param listaOrdenes lista de ordenes a ordenar
  * @param ordenamiento ordenamiento a utilizar (mayor a menor o alrevés)
  * @returns OrdenDeCompra[] lista de ordenes ordenada
  * @author msemeraro
  */
  orderBy(prop: string, listaOrdenes: Array<OrdenDeCompra>, ordenamiento: string): OrdenDeCompra[] {
    if (ordenamiento == "Mayor") {
      return listaOrdenes.sort((a, b) => b[prop] > a[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    } else {
      return listaOrdenes.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    }
  }

  /**
  * Función que aplica un filtro despues de ordenar (refrescar la lista)
  * @param listaOrdenes lista de ordenes a ordenar
  * @returns OrdenDeCompra[] 
  * @author msemeraro
  */
  filterOrdenesOrderBy(listaOrdenes: Array<OrdenDeCompra>): OrdenDeCompra[] {
    return listaOrdenes.filter((item) => {
      return true;
    });
  }

  /* OTRAS FUNCIONES */
  /**
  * Función que formatea un número
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
  getFormatNumber(numero: number) {
    return this.validationEnersolService.getNumber(numero);
  }

  /**
  * Función que redondea un número
  * @param numero
  * @returns number redondeado
  * @author msemeraro
  */
  getRoundNumber(numero: number) {
    return Math.round(numero);
  }

  /**
  * Función que retorna el porcentaje de ganancia de una orden de compra
  * @param ganancia
  * @param totalOrden
  * @returns porcentaje de ganancia
  * @author msemeraro
  */
  getPorcentajeGanancia(ganancia: number, totalOrden: number): number {
    if (totalOrden > 0)
      return (ganancia * 100) / totalOrden;
    return 0;
  }

  /**
  * Función que obtiene los códigos de estados de los detalles de una orden
  * @param detalles
  * @returns lista de números de estados
  * @author msemeraro
  */
  getColoresDetalles(listaEstados: number[]) {
    listaEstados.sort((n1, n2) => n1 - n2);
    let sinRepetidos = [...new Set(listaEstados)];
    return sinRepetidos;
  }

}
