import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AdminRevisionPreciosController } from './admin-revision-precios.component.controller';
import { ErrorService } from 'src/services/error.service'; 
import { ProductoPricing } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoPricing';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RequestPricing } from 'src/models/com.enersol.directshopping.dal.entidad/RequestPricing';

@Component({
  selector: 'app-admin-revision-precios',
  templateUrl: './admin-revision-precios.component.html',
  styleUrls: ['./admin-revision-precios.component.scss'],
  providers: [AdminRevisionPreciosController]
})
export class AdminRevisionPreciosBackview implements OnInit {

  /* Variables para controlar los roles del usuario */
  isAdmin: Boolean;

  fechaPago: Date;
  fechaPricing: Date;

  productosPricing: Array<ProductoPricing>;

  /* Variable con el tipo de cambio */
  tipoCambio: number = 0;

  /* Variable para los mensajes */
  contenidoMensaje: String;
  tituloMensaje: String;
  esError: Boolean;

  subTotal: number = 0;
  porcentajeImpuesto: number = 0;
  impuesto: number = 0;
  total: number = 0;
  constructor(
    private router: Router,
    private adminRevisionPreciosComponent: AdminRevisionPreciosController,
    private errorService: ErrorService,
    private spinner: NgxSpinnerService
  ) {
  }

  async ngOnInit() {
    this.isAdmin = await this.adminRevisionPreciosComponent.isAdminEnersol();
    this.tipoCambio = await this.adminRevisionPreciosComponent.getTipoCambio();
    this.getProductos();
  }

  /**
   * Función que solicita los productos en pricing
   * @author msemeraro
   */
  async getProductos() {
    let productosPricing = await this.adminRevisionPreciosComponent.getProductosPricing();
    if (!productosPricing.sucess) {
      let mensaje = this.errorService.displayError(productosPricing.errors);
      this.adminRevisionPreciosComponent.setMensaje('Error al cargar los productos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.productosPricing = productosPricing.data;
      await this.getSubTotal();
    }
  }

  /**
   * Función que aprueba un producto en pricing
   * @author msemeraro
   */
  async aprobarProducto(producto: ProductoPricing) {
    this.spinnerShow();
    let productosPricing = await this.adminRevisionPreciosComponent.aprobarProductoPricing(producto);
    if (!productosPricing.sucess) {
      let mensaje = this.errorService.displayError(productosPricing.errors);
      this.adminRevisionPreciosComponent.setMensaje('Error al aprobar el producto', mensaje, true);
      this.verMensaje();
    }
    else {
      this.productosPricing = productosPricing.data;
      await this.getSubTotal();
    }
    this.spinnerHide();
  }

  /**
  * Función que formatea un número
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
  getFormatNumber(numero: number) {
    return this.adminRevisionPreciosComponent.getFormatNumber(numero);
  }

  /**
  * Función que obtiene el subTotal del pedido
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
  async getSubTotal() {
    this.subTotal = this.adminRevisionPreciosComponent.getSubTotalPricing(this.productosPricing);
    await this.getImpuesto();
  }

  /**
    * Función que obtiene el porcentaje y el monto de impuesto y el total
    * @param numero
    * @returns number formateado
    * @author msemeraro
    */
  async getImpuesto() {
    let respuesta = await this.adminRevisionPreciosComponent.getImpuesto();
    if (respuesta.sucess) {
      this.porcentajeImpuesto = Number(respuesta.data.valor);
      this.impuesto = (this.subTotal * this.porcentajeImpuesto) / 100;
      this.total = this.impuesto + this.subTotal;
    }
  }

  /**
  * Función que realiza el Pricing
  * @author msemeraro
  */
  async realizarPricing() {
    if(this.productosPricing){
      for(let producto of this.productosPricing){
        if(!producto.aprobado){
          this.adminRevisionPreciosComponent.setMensaje('Falta aprobación', 'Hay algunos productos que faltan de tener su aprobación del precio', true);
          this.verMensaje();
          return;
        }
      }

      if (!this.validarFecha(this.fechaPago)) {
        this.adminRevisionPreciosComponent.setMensaje('Fecha incorrecta', 'Error, debe de ingresar una fecha límite de pago válida', true);
        this.verMensaje();
        return;
      }
      if (!this.validarFecha(this.fechaPricing)) {
        this.adminRevisionPreciosComponent.setMensaje('Fecha incorrecta', 'Error, debe de ingresar una fecha próxima de Cotización Internacional válida', true);
        this.verMensaje();
        return;
      }
      this.spinnerShow();
      if (this.productosPricing.length > 0) {
        let respuesta = await this.adminRevisionPreciosComponent.realizarPricing(this.tipoCambio,this.fechaPago,this.fechaPricing);
        if (respuesta.sucess)
          await this.getProductos();
      }
      this.spinnerHide();
    }
  }

  validarFecha(fecha: Date) {
    return this.adminRevisionPreciosComponent.validarFecha(fecha);
  }

  /**
  * Función que valida el cambio en la cantidad de producto
  * @author msemeraro
  */
  cambioPrecioProducto(precio: number) {
    if (!this.adminRevisionPreciosComponent.cambioCantidadProducto(precio.toString())) {
      this.adminRevisionPreciosComponent.setMensaje('Modificación Precio', 'Error, debe de ingresar un precio válido para el producto ', true);
      this.verMensaje();
    }
  }

  /**
  * Función que valida el cambio en el tipo de cambio
  * @author msemeraro
  */
  cambioTipoCambio() {
    if (!this.adminRevisionPreciosComponent.cambioCantidadProducto(this.tipoCambio.toString())) {
      this.adminRevisionPreciosComponent.setMensaje('Modificación Tipo de Cambio', 'Error, debe de ingresar un tipo de cambio válido', true);
      this.verMensaje();
    }
  }

  /**
   * Función que selecciona o deselecciona todos los productos del carrito
   * @author msemeraro
   */
  seleccionarTodo() {
    var checkboxes = document.getElementsByTagName('input');
    let check = checkboxes[0].checked;
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].type == 'checkbox') {
        checkboxes[i].checked = check;
      }
    }
  }

  /**
   * Función que elimina los productos seleccionados del carrito
   * @author msemeraro
   */
  async aprobarProductos(aprobar: boolean) {
    this.spinnerShow();
    var elements = document.querySelectorAll('input[type="checkbox"]:checked');
    if (elements.length == 0) {
      this.adminRevisionPreciosComponent.setMensaje('Error al aprobar', 'Debe seleccionar al menos un producto', true);
      this.verMensaje();
    } else {
      var checkedElements = Array.prototype.map.call(elements, function (el, i) {
        return el.id;
      });
      await this.adminRevisionPreciosComponent.aprobarProductos(this.productosPricing, checkedElements, aprobar);
      await this.getProductos();
    }
    var checkboxes = document.getElementsByTagName('input');
    checkboxes[0].checked = false;
    this.spinnerHide();
  }

  /**
  * Función que navega a otra pagina
  * @author msemeraro
  */
  irPagina(nombre: string) {
    this.router.navigate([nombre]);
  }

  /**
  * Función que muestra un mensaje
  * @author msemeraro
  */
  verMensaje() {
    this.tituloMensaje = this.adminRevisionPreciosComponent.getTituloMensaje();
    this.contenidoMensaje = this.adminRevisionPreciosComponent.getContenidoMensaje();
    this.esError = this.adminRevisionPreciosComponent.getEsError();
    var element = document.getElementById("btnAbrirMensaje") as any;
    element.click();
  }

  /**
  * Función que muestra el modal para confirmar el pricing
  * @author gberrocal
  */
  verConfimarPricing() {
    var element = document.getElementById("btnAbrirModalConfirmarPricing") as any;
    element.click();
  }

  spinnerShow() {
    this.spinner.show();
  }

  spinnerHide() {
    this.spinner.hide();
  }

  clickModalMensaje() { }

  clickModalCambioEstado() { }

  verHistorico() {
    this.router.navigate(['historial-revision-precios']);
  }
}
