import { Component, OnInit } from '@angular/core';
import { ReporteOrdenController } from './reporte-orden.component.controller';
import { ReporteEstacionesMarcas } from 'src/models/com.enersol.directshopping.dal.entidad/ReporteEstacionesMarcas';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { Estacion } from 'src/models/com.enersol.directshopping.dal.entidad/Estacion';
import { ReporteMarcasProductos } from 'src/models/com.enersol.directshopping.dal.entidad/ReporteMarcasProductos';
import { ReporteProductosInformacion } from 'src/models/com.enersol.directshopping.dal.entidad/ReporteProductosInformacion';
import { Zona } from 'src/models/com.enersol.directshopping.dal.entidad/Zona';
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
import { ReporteEstacionesOrdenes } from 'src/models/com.enersol.directshopping.dal.entidad/ReporteEstacionesOrdenes';
import { ErrorService } from 'src/services/error.service';
import { ExcelService } from 'src/services/excel.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminMantenimientoEstacionesController } from '../admin-mantenimiento-estacion/admin-mantenimiento-estaciones.component.controller';

@Component({
  selector: 'app-reporte-orden',
  templateUrl: './reporte-orden.component.html',
  styleUrls: ['./reporte-orden.component.scss'],
  providers: [ReporteOrdenController, AdminMantenimientoEstacionesController]
})
export class ReporteOrdenBackview implements OnInit {

  // Variable que almacena los datos del reporte de socios
  reporte: ReporteEstacionesMarcas[] = new Array();

  //Variable que almacena los datos del reporte de ordenes
  reporteOrdenes: ReporteEstacionesOrdenes[] = new Array();

  // Variable que almacena los datos de las zonas
  zonas: Zona[] = new Array();

  // Variable que almacena los datos de las estaciones según la zona seleccionada
  estacionesPorZona: Estacion[] = new Array();

  // Variables de Control
  fechaInicial: Date;
  fechaFinal: Date;
  zone: Zona = new Zona();
  grupo: number;
  reporteMostrado: boolean = false;

  /* Variable para los mensajes */
  contenidoMensaje: String;
  tituloMensaje: String;
  esError: Boolean;

  /* VARIABLE PARA GENERAR EL REPORTE DE LAS ESTACIONES SELECCIONADAS */
  estacionesIds = [];

  constructor(private reporteComponent: ReporteOrdenController,
    private errorService: ErrorService,
    private excelService: ExcelService,
    private spinner: NgxSpinnerService,
    private adminMantenimientoEstacionesController: AdminMantenimientoEstacionesController) {

  }

  async ngOnInit() {
    await this.getZonas();
  }

  /**
   * Función que solicita el reporte de productos por marca por estacion
   * @author msemeraro
   */
  async getReporte() {
    let codEstacions = this.estacionesIds;
    this.spinnerShow();
    let reportes = await this.reporteComponent.getReporte(codEstacions, this.fechaInicial, this.fechaFinal);

    if (!reportes.sucess) {
      let mensaje = this.errorService.displayError(reportes.errors);
      this.reporteComponent.setMensaje('Error al obtener el reporte', mensaje, true);
      this.verMensaje();
    }
    else {
      if (await this.isReporteOrdenesSocioEmpty(reportes.data)) {
        this.reporteComponent.setMensaje('Reporte Vacío', 'No se encontraron datos para este reporte', false);
        this.verMensaje();
      } else {
        await this.getReporteCompleto(reportes.data);
      }
    }
    this.spinnerHide();
  }

  /**
   * Funcion para reporte de socio, que verifica si la lista con ordenes viene vacia.
   * @author kjimenez
   */
  isReporteOrdenesSocioEmpty(reportesMarcaSocio) {
    for (let reporte of reportesMarcaSocio) {
      if (reporte.productosPorMarca.length != 0) {
        return false;
      }
    }
    return true;
  }

  /**
   * Función que solicita el reporte de productos por marca por estacion
   * @author msemeraro
   */
  async getReporteOrdenes() {
    let codEstacions = this.estacionesIds;
    this.spinnerShow();
    let reportes = await this.reporteComponent.getReporteOrdenes(codEstacions, this.fechaInicial, this.fechaFinal);

    if (!reportes.sucess) {
      let mensaje = this.errorService.displayError(reportes.errors);
      this.reporteComponent.setMensaje('Error al obtener el reporte', mensaje, true);
      this.verMensaje();
    }
    else {
      if (this.isReporteOrdenesEmpty(reportes.data)) {
        this.reporteComponent.setMensaje('Reporte Vacío', 'No se encontraron datos para este reporte', false);
        this.verMensaje();
      } else {
        //this.reporteComponent.exportAsExcelFile(reportes.data, "prueba.xlsx")
        this.reporteOrdenes = reportes.data;
      }
    }
    this.spinnerHide();
  }

  /**
   * Funcion para reporte de socio, que verifica si la lista con ordenes viene vacia.
   * @author kjimenez
   */
  isReporteOrdenesEmpty(reportes) {
    for (let reporte of reportes) {
      if (reporte.ordenes != []) {
        return false;
      }
    }
    return true;
  }

  /**
   * Función que obtiene las zonas registradas en la base de datos
   */
  async getZonas() {
    let response = await this.reporteComponent.getZonas();
    if (response.sucess) {
      this.zonas = response.data;
      let todasLasZonas = new Zona();
      todasLasZonas.codigoZona = -1;
      todasLasZonas.nombre = "Todas";
      await this.zonas.push(todasLasZonas)
    }
    else {
      let mensaje = this.errorService.displayError(response.errors);
      this.reporteComponent.setMensaje('Error al obtener las zonas', mensaje, true);
      this.verMensaje();
    }
  }

  /**
   * Función que obtiene las estaciones ubicadas en la zona seleccionada
   */
  async getEstacionesPorZona() {
    this.ocultarReporte();
    let response;
    if (this.zone == undefined) {
      response = await this.adminMantenimientoEstacionesController.getEstaciones();
    }
    else if (this.zone.codigoZona != null) {
      if (this.zone.codigoZona != -1) {
        response = await this.reporteComponent.getEstacionesPorZona(this.zone.codigoZona);
      } else {
        response = await this.adminMantenimientoEstacionesController.getEstaciones();
      }
    }
    if (response.sucess) {
      this.estacionesPorZona = response.data;
      this.obtenerTodasEstaciones();
    } else {
      let mensaje = this.errorService.displayError(response.errors);
      this.reporteComponent.setMensaje('Error al obtener las estaciones de la zona', mensaje, true);
      this.verMensaje();
    }
  }

  /**
   * Función que mapea los datos de la respuesta del servicio del reporte al modelo ReporteMarcasProductos
   * @author msemeraro
   */
  async getReporteCompleto(respuesta: any) {
    this.reporte = new Array();

    let estacion: Estacion = new Estacion();
    let nuevoReporteProductosInformacion: ReporteProductosInformacion = new ReporteProductosInformacion();
    let nuevoReporteMarcasProductos = new ReporteMarcasProductos();
    let suma = 0;
    let reportes: ReporteMarcasProductos[] = new Array();
    let reporteEstacionesMarcas: ReporteEstacionesMarcas = new ReporteEstacionesMarcas();

    for (let reporte of respuesta) {
      estacion = new Estacion();
      estacion = reporte.estacion;
      reportes = new Array();
      for (let reporteMarcasProductos of reporte.productosPorMarca) {
        nuevoReporteMarcasProductos = new ReporteMarcasProductos();
        nuevoReporteMarcasProductos.marca = reporteMarcasProductos.marca;
        nuevoReporteMarcasProductos.productos = new Array();
        suma = 0;
        for (let reporteProductosInformacion of reporteMarcasProductos.productos) {
          nuevoReporteProductosInformacion = new ReporteProductosInformacion();
          nuevoReporteProductosInformacion.productoPresentacion = reporteProductosInformacion[0];
          nuevoReporteProductosInformacion.cantidad = reporteProductosInformacion[1];
          nuevoReporteProductosInformacion.promedio = reporteProductosInformacion[2];
          nuevoReporteProductosInformacion.ganancia = reporteProductosInformacion[3];
          nuevoReporteProductosInformacion.cantidadOrdenes = reporteProductosInformacion[4];
          nuevoReporteMarcasProductos.productos.push(nuevoReporteProductosInformacion);
          suma += reporteProductosInformacion[2];
        }
        nuevoReporteMarcasProductos.total = suma;
        reportes.push(nuevoReporteMarcasProductos);
      }
      reporteEstacionesMarcas = new ReporteEstacionesMarcas();
      reporteEstacionesMarcas.estacion = estacion;
      reporteEstacionesMarcas.productosPorMarca = reportes;
      await this.reporte.push(reporteEstacionesMarcas);
    }
  }

  spinnerShow() {
    this.spinner.show();
  }

  spinnerHide() {
    this.spinner.hide();
  }

  /**
   * Funciones para botón de Mostrar Reportes
   */
  async mostrarReporte() {


    if (this.validarDatos()) {
      this.spinnerShow();
      if (this.grupo == 2) {
        await this.getReporte();
      } else {
        await this.getReporteOrdenes();
      }
      this.spinnerHide();
    }


    //this.reporteMostrado = true;
  }

  /**
   * Función que valida los datos de entrada para el reporte
   * @author msemeraro
   */
  validarDatos() {
    if (this.fechaFinal == null || this.fechaInicial == null) {
      this.reporteComponent.setMensaje('Datos de entrada inválidos', "Debe ingresar fechas válidas", true);
      this.verMensaje();
      return false;
    }
    if (this.zone.codigoZona == null) {
      this.reporteComponent.setMensaje('Datos de entrada inválidos', "Debe seleccionar una zona", true);
      this.verMensaje();
      return false;
    }
    if (this.grupo == null) {
      this.reporteComponent.setMensaje('Datos de entrada inválidos', "Debe seleccionar un agrupamiento", true);
      this.verMensaje();
      return false;
    }
    return true;
  }

  /**
   * Función que limpia las listas de reportes para un despliegue limpio
   */
  ocultarReporte() {
    this.reporteMostrado = false;
    this.reporte = [];
    this.reporteOrdenes = [];
  }

  /**
    * Función que formatea un número
    * @param numero
    * @returns number formateado
    * @author msemeraro
    */
  getFormatNumber(numero: number) {
    return this.reporteComponent.getFormatNumber(numero);
  }

  /**
   * Función que selecciona o deselecciona todos los productos del carrito
   * @author msemeraro
   */
  seleccionarTodasEstaciones() {
    var checkboxes = document.getElementsByTagName('input');
    let selectAllInput = false;
    let check = checkboxes[0].checked;
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].type == 'checkbox') {
        if (!selectAllInput) {
          selectAllInput = !selectAllInput;
          check = checkboxes[i].checked;
        }
        checkboxes[i].checked = check;
      }
    }
  }

  /**
   * Función que guarda en el array estacionesIds los codigos de las estaciones seleccionadas en el modal
   * Si no se selecciona ninguna estación se cargan todas las estaciones de la zona
   * @author msemeraro
   */
  guardarEstacionesSeleccionadas() {
    var elements = document.querySelectorAll('input[type="checkbox"]:checked');
    var estaciones = [];
    var checkedElements = Array.prototype.map.call(elements, function (el, i) {
      if (el.id != "seleccionar_todo") {
        estaciones.push(Number(el.id));
      }
      return el.id;
    });
    this.estacionesIds = estaciones;
    if (this.estacionesIds.length == 0)
      this.obtenerTodasEstaciones();
    $(".close").click();
  }

  /**
   * Función que guarda en el array estacionesIds los codigos de todas las estaciones de la zona seleccionada
   * @author msemeraro
   */
  obtenerTodasEstaciones() {
    this.estacionesIds = [];
    for (let estacion of this.estacionesPorZona) {
      this.estacionesIds.push(estacion.codigoEstacion);
    }
  }

  /**
    * Función que muestra un mensaje
    * @author msemeraro
    */
  verMensaje() {
    this.tituloMensaje = this.reporteComponent.getTituloMensaje();
    this.contenidoMensaje = this.reporteComponent.getContenidoMensaje();
    this.esError = this.reporteComponent.getEsError();
    var element = document.getElementById("btnAbrirMensaje") as any;
    element.click();
  }

  clickModalMensaje() { }

  /**
   * Funcion encargada de la exportacion del reporte a XLS
   * @author kjimenez
   */
  async generarReporte() {
    if (this.grupo == null) {

    } else {
      if (this.grupo == 1) {
        await this.excelService.reporteEstacionesOrdenesToJson(this.reporteOrdenes);
      } else {
        await this.excelService.reporteEstacionesMarcasToJson(this.reporte);
      }
    }
  }
}

