import { Membresia } from './Membresia';
import { Zona } from './Zona';

export class Estacion {
    codigoEstacion: number;
    membresia: Membresia;
    zona: Zona;
    nombre: string;
    ubicacion: string;
    telefono: string;
    codigoRecope: string;
}