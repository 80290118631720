/**
 * Crea y exporta una constante equivalente al EnumNombresParametros del DAL
 */
export const EnumNombresParametros = {
  CANT_DIAS_PAGO_ORDEN: {nombre: "cantidadDiasPagoOrden"},
	CANT_DIAS_VENCIMIENTO_ORDEN: {nombre: "cantidadDiasVencimientoOrden"},
	FECHA_APROBACION_PRICING: {nombre: "fechaAprobacionParaPricing"},
	FECHA_LIMITE_PAGO: {nombre: "fechaLimitePago"},
	FORMA_INVENTARIO: {nombre: "formaInventario"},
	IMPUESTO: {nombre: "Impuesto"},
	TIPO_CAMBIO: {nombre: "tipoCambio"},
	XML_ESTADOS: {nombre: "xmlEstados"},
	XML_ESTADOS_INVENTARIO: {nombre: "xmlEstadosInventario"}
}