import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { Location } from '@angular/common';
/* SERVICES */
import { ErrorService } from 'src/services/error.service';
import { DetalleProductoController } from './detalle-producto.component.controller';
/* REST MODEL */
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
/* MODELS */
import { Presentacion } from 'src/models/com.enersol.directshopping.dal.entidad/Presentacion';
import { ProductoPresentacion } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoPresentacion';
import { EnumEstadoOrdenInventario } from 'src/models/com.enersol.directshopping.dal.enums/EnumEstadoOrdenInventario';
import { EnumEstadoInventarioProducto } from 'src/models/com.enersol.directshopping.dal.enums/EnumEstadoInventarioProducto';
import { Parametro } from 'src/models/com.enersol.directshopping.dal.entidad/Parametro';
import { GlobalService } from 'src/services/global';
import { EnumFormaInventario } from 'src/models/com.enersol.directshopping.dal.enums/EnumFormaInventario';

@Component({
  selector: 'app-detalle-producto',
  templateUrl: './detalle-producto.component.html',
  styleUrls: ['./detalle-producto.component.scss'],
  providers: [DetalleProductoController]
})
export class DetalleProductoBackview implements OnInit {

  /* Variable que lleva la cantidad de unidades a pedir */
  cantidadProducto: number = 1;

  /* Variable con el tipo de cambio */
  tipoCambio: number = 0;

  /* Producto que se muestra */
  productoPresentacion: Response<ProductoPresentacion> = new Response<ProductoPresentacion>(false);

  /* Lista de presentaciones del producto que se muestra */
  presentaciones: ListResponse<Presentacion> = new ListResponse<Presentacion>(false);

  /* Variable para los mensajes */
  contenidoMensaje: String;
  tituloMensaje: String;
  esError: Boolean;

  formaInventario: Parametro;

  urlEnersol: string = "";
  urlImagenNoDisponible = "../assets/img/no-disponible.png"
  constructor(
    private router: Router,
    private detalleProductoComponent: DetalleProductoController,
    private errorService: ErrorService,
    private spinner: NgxSpinnerService,
    private globalEnersolService: GlobalService,
    private location: Location
  ) {
  }

  async ngOnInit() {
    this.getProductoPresentacion();
    this.getProductoPresentaciones();
    this.urlEnersol = this.detalleProductoComponent.urlEnersol;
    this.tipoCambio = await this.detalleProductoComponent.getTipoCambio();
    this.cantidadProducto = await this.detalleProductoComponent.getCantidadProducto();
    this.formaInventario = await this.globalEnersolService.getFormaInventario();
  }

  /**
  * Función que obtiene el producto a mostrar
  * @author msemeraro
  */
  async getProductoPresentacion() {
    this.productoPresentacion = await this.detalleProductoComponent.getProductoPresentacion();
    if (!this.productoPresentacion.sucess) {
      let mensaje = this.errorService.displayError(this.productoPresentacion.errors);
      this.detalleProductoComponent.setMensaje('Error al cargar los datos', mensaje, true);
      this.verMensaje();
    }

    /*else
      this.productoPresentacion.data = this.detalleProductoComponent.getMargenesProducto(this.productoPresentacion.data);
    */
  }

  /**
  * Función que obtiene las presentaciones disponibles del producto
  * @author msemeraro
  */
  async getProductoPresentaciones() {
    this.presentaciones = await this.detalleProductoComponent.getProductoPresentaciones();
    if (!this.presentaciones.sucess) {
      let mensaje = this.errorService.displayError(this.presentaciones.errors);
      this.detalleProductoComponent.setMensaje('Error al cargar los datos', mensaje, true);
      this.verMensaje();
    }
  }

  /**
  * Función que cambia la presentación del producto
  * @author msemeraro
  */
  verOtraPresentacion(producto) {
    if (producto != "-") {
      this.detalleProductoComponent.verOtraPresentacion(producto);
      this.getProductoPresentacion();
    }
  }

  /**
  * Función que añade el producto al carrito
  * @param producto producto a añadir al carrito
  * @author msemeraro
  */
  async addCarrito(producto: ProductoPresentacion) {
    await this.spinnerShow();
    try{
      let respuesta = await this.detalleProductoComponent.addCarrito(producto, this.cantidadProducto);
      if (!respuesta.sucess) {
        let mensaje = this.errorService.displayError(respuesta.errors);
        this.detalleProductoComponent.setMensaje('Error al añadir al carrito', mensaje, true);
        this.verMensaje();
      }
      else {
        if(this.cantidadProducto == 1)
          this.detalleProductoComponent.setMensaje('Producto añadido al carrito', 'Se añadió ' + this.cantidadProducto + ' cantidad del producto: ' + producto.producto.nombre, false);
        else
        this.detalleProductoComponent.setMensaje('Producto añadido al carrito', 'Se añadieron ' + this.cantidadProducto + ' cantidades del producto: ' + producto.producto.nombre, false);
        this.verMensaje();
      }
    }
    catch(error){
      let mensaje = this.errorService.getErrorMessage(error.message);
      this.detalleProductoComponent.setMensaje('Error al añadir al carrito', mensaje, true);
      this.verMensaje();
    }
    await this.spinnerHide();
  }

  /**
  * Función que aumenta la cantidad de producto
  * @author msemeraro
  */
  aumentarCantidadProducto() {
    if (this.cantidadProducto >= 0)
      this.cantidadProducto++;
  }

  /**
  * Función que disminuye la cantidad de producto
  * @author msemeraro
  */
  disminuirCantidadProducto() {
    if (this.cantidadProducto > 1)
      this.cantidadProducto--;
    else {
      this.detalleProductoComponent.setMensaje('Error', 'Debe ingresar una cantidad válida ', true);
      this.verMensaje();
    }
  }

  /**
  * Función que valida el cambio en la cantidad de producto
  * @author msemeraro
  */
  cambioCantidadProducto() {
    if (!this.detalleProductoComponent.cambioCantidadProducto(this.cantidadProducto.toLocaleString())) {
      this.cantidadProducto = 1;
      this.detalleProductoComponent.setMensaje('Error', 'Debe ingresar una cantidad válida ', true);
      this.verMensaje();
    }
    else {
      if (this.cantidadProducto == 0)
        this.cantidadProducto = 1;
    }
  }

  /**
  * Función que redondea un número
  * @author msemeraro
  */
  getRoundNumber(numero: number) {
    return this.detalleProductoComponent.getRoundNumber(numero);
  }

  /**
  * Función que formatea un número
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
  getFormatNumber(numero: number) {
    return this.detalleProductoComponent.getFormatNumber(numero);
  }

  verPrincipal() {
    this.router.navigate(['principal']);
  }

  /**
   * Se devuelve a la pagina previa
   */
  volverPrevio(){
    this.location.back();
  }

  spinnerShow() {
    this.spinner.show();
  }

  spinnerHide() {
    this.spinner.hide();
  }

  /**
  * Función que muestra un mensaje
  * @author msemeraro
  */
  verMensaje() {
    this.tituloMensaje = this.detalleProductoComponent.getTituloMensaje();
    this.contenidoMensaje = this.detalleProductoComponent.getContenidoMensaje();
    this.esError = this.detalleProductoComponent.getEsError();
    var element = document.getElementById("btnAbrirMensaje") as any;
    element.click();
  }

  /**
  * Función que muestra un documento
  * @author msemeraro
  */
  descargaArchivo(url:string) {
    if(url.length !=0)
      this.detalleProductoComponent.abrirArchivo(url);
    else{
      this.detalleProductoComponent.setMensaje('Error', 'El producto no cuenta con un archivo', true);
      this.verMensaje();
    }
  }


  isEstadoInventario() {
    return this.isEstado(this.productoPresentacion.data, EnumEstadoInventarioProducto.INVENTARIO.codigoEstado);
  }

  isEstadoProximamente() {
    return this.isEstado(this.productoPresentacion.data, EnumEstadoInventarioProducto.PROXIMAMENTE.codigoEstado);
  }

  isEstadoFueraInventario() {
    return this.isEstado(this.productoPresentacion.data, EnumEstadoInventarioProducto.FUERA_INVENTARIO.codigoEstado);
  }

  isEstadoProduccionSuspendida() {
    return this.isEstado(this.productoPresentacion.data, EnumEstadoInventarioProducto.PRODUCCION_SUSPENDIDA.codigoEstado);
  }

  isEstado(producto: ProductoPresentacion, codigoEstado: number){
    if(producto) {
      return producto.estadoInventarioProducto.id == codigoEstado;
    }
  }

  /**
   * Indica si tiene que mostrar tanto la cantidad de inventario como de importación
   * @returns 
   */
  mostrarInventarioImportacion(): boolean{
    // Se muestra solo si la modalidad no es inventario
    if(this.formaInventario.valor != EnumFormaInventario.INVENTARIO.nombre){
      // Si el producto esta en inventario o fuera de invnetario
      if(this.isEstadoInventario() || this.isEstadoFueraInventario()){
        var cantidadImportacion = this.getCantidadImportacionDisponible();
        return (cantidadImportacion && cantidadImportacion > 0);
      }
    }
    return false;
  }

  /**
   * Obtiene la cantidad disponible para importación
   * @returns 
   */
  getCantidadImportacionDisponible(): number{
    if(this.productoPresentacion){
      return this.productoPresentacion.data.cantidadImportacion - this.productoPresentacion.data.importacionTransito;
    }
    return null;
  }

  /**
   * Indica si se muestra el estado normal del producto
   * @param producto 
   * @returns 
   */
   mostrarEstadoNormal(): boolean{
    if(this.isModalidad(EnumFormaInventario.MIXTO.nombre) 
      || this.isModalidad(EnumFormaInventario.INVENTARIO.nombre)){
      return true;
    }
    else if(this.isModalidad(EnumFormaInventario.IMPORTACION.nombre)){
      if(this.isEstadoProximamente()){
        return true;
      }
    }
    return false;
  }

  /**
   * Indica si la modalidad de la plataforma es de un tipo determinado
   * @param modalidad 
   * @returns 
   */
  private isModalidad(modalidad: string): boolean{
    if(this.formaInventario){
      return this.formaInventario.valor == modalidad;
    }
    return false;
  }

  getNombreEstadoProximamente(): string{
    return EnumEstadoInventarioProducto.PROXIMAMENTE.nombre;
  }

  /**
   * Indica si una presentacion de producto está en estado No Disponible
   * @param producto 
   * @author gberrocal
   */
   isProductoNoDisponible(producto: ProductoPresentacion):boolean {
    if(producto){
      return producto.estadoInventarioProducto.id == EnumEstadoInventarioProducto.PRODUCCION_SUSPENDIDA.codigoEstado;
    }
    return false;
  }

  clickModalMensaje() { }
}
