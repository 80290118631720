import { Injectable } from '@angular/core';

import { ConstantsService } from './constants.service';

import { Error } from '../models/com.enersol.directshopping.ws.rest.model/BaseResponse';

import errorsJson from '../assets/i18n/es.json';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private constantsService: ConstantsService
  ) {

  }

  displayError(errors: Array<Error>) {
    let errorMessageCode = "";
    console.log('--------------------');
    errors.forEach(error => {
      console.log('');
      console.log(error.code);
      console.log(error.techMessage);
      console.log(error.userMessage);
      console.log('');
      let errorCode = error.code;
      errorMessageCode = errorsJson[errorCode];
    });
    console.log('--------------------');
    return errorMessageCode;
  }

  /**
   * Obtiene el mensaje de un error a partir de su código
   * @param codigoError 
   * @returns 
   * @author gberrocal
   */
  getErrorMessage(codigoError: string) {
    let errorMessageCode = errorsJson[codigoError];
    if(!errorMessageCode){
      errorMessageCode = "Desconocido";
    }

    return errorMessageCode;
  }



}