import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginBackview } from './login.component.backview';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  declarations: [LoginBackview],
  imports: [
    CommonModule,
    FormsModule,
    NgxSpinnerModule
  ],
  exports: [
    LoginBackview
  ]
})
export class LoginModule { }
