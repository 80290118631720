import { Marca } from './Marca';
import { SubFamilia } from './SubFamilia';
import { Membresia } from './Membresia';
import { ProductoId } from './ProductoId';
import { ProductoPresentacion } from './ProductoPresentacion';
import { EstadoInventarioProducto } from './EstadoInventarioProducto';

export class Producto {
    id: ProductoId;
    marca: Marca;
    membresia: Membresia;
    subFamilia: SubFamilia;
    nombre: string;
    descripcion: string;
    fichaTecnica: string;
    analisisLaboratorio: string;
    hojaSeguridad: string;
    productosPresentaciones: ProductoPresentacion[] = null;
    fichaTecnicaBase64: string | ArrayBuffer;
    analisisLaboratorioBase64: string | ArrayBuffer;
    hojaSeguridadBase64: string | ArrayBuffer;
}