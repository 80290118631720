import { Component, OnInit, Injectable } from '@angular/core';
import { HttpClientEnersolService } from 'src/services/http-client-enersol.service';
/* REST MODEL */
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
import { ReporteEstacionesMarcas } from 'src/models/com.enersol.directshopping.dal.entidad/ReporteEstacionesMarcas';
import { ExcelService } from 'src/services/excel.service';

@Injectable()
export class ReporteMarcasController {

  constructor(private httpClientEnersolService: HttpClientEnersolService,
    private excelService: ExcelService) { }

  /**
    * Función de prueba de reportes
    */
   async getReporte(codMarcas, codEstacions) {
    let respuesta: ListResponse<ReporteEstacionesMarcas>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet("ProductoPorOrdenDeCompra/Marcas/Estaciones?codMarcas="+ codMarcas + "&codEstacions=" + codEstacions);
      respuesta = <ListResponse<ReporteEstacionesMarcas>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<ReporteEstacionesMarcas>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función de prueba de reportes
    */
   async getReporteExcel(reportes:ReporteEstacionesMarcas[]) {
    let respuesta: Response<Boolean>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost("ProductoPorOrdenDeCompra/Marcas/Estaciones/Excel", reportes);
      respuesta = <Response<Boolean>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Boolean>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función de prueba de reportes
    */
   async getReporteOrdenes(codEstacions) {
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet("ProductoPorOrdenDeCompra/Marcas/Estaciones?codEstacions=" + codEstacions);
    }
    catch (error) {
      console.log(error.message);
    }
    finally {
     
    }
  }

  exportAsExcelFile(json: any[], excelFileName: string){
    return this.excelService.exportAsExcelFile(json, excelFileName);
  }
  
}