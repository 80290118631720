import { Component, OnInit, ɵConsole, Injectable } from '@angular/core';
/* SERVICES */
import { HttpClientEnersolService } from 'src/services/http-client-enersol.service';
import { GlobalService } from 'src/services/global';
import { ValidationsService } from 'src/services/validations.service';
import { ConstantsService } from 'src/services/constants.service';
/* REST MODEL */
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
/* MODELS */
import { ProductoPresentacion } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoPresentacion';
import { Producto } from 'src/models/com.enersol.directshopping.dal.entidad/Producto';
import { Presentacion } from 'src/models/com.enersol.directshopping.dal.entidad/Presentacion';
import { FotografiaProducto } from 'src/models/com.enersol.directshopping.dal.entidad/FotografiaProducto';
import { EstadoInventarioProducto } from 'src/models/com.enersol.directshopping.dal.entidad/EstadoInventarioProducto';
import { EnumEstadoInventarioProducto } from 'src/models/com.enersol.directshopping.dal.enums/EnumEstadoInventarioProducto';

@Injectable()
export class AdminMantenimientoProductoPresentacionesController {

  urlEnersol: string = "";

  constructor(
    private httpClientEnersolService: HttpClientEnersolService,
    private globalEnersolService: GlobalService,
    private validationEnersolService: ValidationsService,
    private constantsEnersolService: ConstantsService
  ) {
    this.urlEnersol = this.constantsEnersolService.getUrlEnersol();
  }


  /* FUNCIONES QUE HACEN PETICIONES A LOS SERVICIOS */

  /**
  * Función que obtiene el producto seleccionado por el administrador
  * @returns Response<Producto>
  * @author msemeraro
  */
  async getProducto(): Promise<Response<Producto>> {
    let productoId = this.globalEnersolService.getProductoId();
    let respuesta: Response<Producto>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('Producto/id?codProducto=' + productoId.codigoProducto + "&codMarca=" + productoId.codigoMarca);
      respuesta = <Response<Producto>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Producto>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que obtiene los productos presentacion de un producto
  * @returns ListResponse<ProductoPresentacion>
  * @author msemeraro
  */
  async getProductoPresentacionesProducto(codProducto: number, codMarca: number): Promise<ListResponse<ProductoPresentacion>> {
    let respuesta: ListResponse<ProductoPresentacion>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('ProductoPresentacion/producto/allMantenimiento?codProducto=' + codProducto + "&codMarca=" + codMarca);
      respuesta = <ListResponse<ProductoPresentacion>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<ProductoPresentacion>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
  * Función que obtiene las presentaciones registradas
  * @returns ListResponse<Presentacion>
  * @author msemeraro
  */
  async getPresentaciones(): Promise<ListResponse<Presentacion>> {
    let respuesta: ListResponse<Presentacion>;
    try {
      let promiseListResponse = await this.httpClientEnersolService.httpGet('Presentacion/all');
      respuesta = <ListResponse<Presentacion>>promiseListResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<Presentacion>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  async actualizarProductoPresentacion(productoPresentacion: ProductoPresentacion): Promise<Response<ProductoPresentacion>> {
    let respuesta: Response<ProductoPresentacion>;
    let mensaje = this.validarDatosInventario(productoPresentacion);
    if(mensaje === ""){
      try {
        let promiseResponse = await this.httpClientEnersolService.httpPost('ProductoPresentacion/update', productoPresentacion);
        respuesta = <Response<ProductoPresentacion>>promiseResponse;
      }
      catch (error) {
        console.log(error.message);
        respuesta = new Response<ProductoPresentacion>(false);
        respuesta.addError('code', 'techMessage', 'userMessage');
      }
      finally {
        return respuesta;
      }
    }
    else{
      console.log(mensaje);
      respuesta = new Response<ProductoPresentacion>(false);
      respuesta.addError(mensaje, 'techMessage', mensaje);
      return respuesta;
    }
  }

  /**
   * Valida los datos del producto presentacion que se quiere modificar
   * @param productoPresentacion 
   * @returns 
   */
  validarDatosInventario(productoPresentacion: ProductoPresentacion): string{
    let mensaje:string = "";

    // Valida que los valores de mercado y las bandas sean porcentajes
    if(!productoPresentacion.valorMercadoConsumidorFinal 
      || !productoPresentacion.valorMercadoOtrosComercios
      || !productoPresentacion.bandaMinimaMercadoConsumidorFinal
      || !productoPresentacion.bandaMinimaMercadoOtrosComercios){
        mensaje = "ValidarProductoPresentacion_12";
    }else if(productoPresentacion.valorMercadoConsumidorFinal < 0
      || productoPresentacion.valorMercadoOtrosComercios < 0 
      || productoPresentacion.bandaMinimaMercadoConsumidorFinal < 0
      || productoPresentacion.bandaMinimaMercadoOtrosComercios < 0){
        mensaje = "ValidarProductoPresentacion_13";
    }

    if((!productoPresentacion.cantidadInventario || productoPresentacion.cantidadInventario == 0)
      && (!productoPresentacion.cantidadImportacion || productoPresentacion.cantidadImportacion == 0)
      && productoPresentacion.estadoInventarioProducto.id != EnumEstadoInventarioProducto.PRODUCCION_SUSPENDIDA.codigoEstado){
      // Debe haber cantidad de inventario o de importacion
      mensaje = "ValidarProductoPresentacion_10";
    }
    
    // Valida la cantidad para inventario
    if(productoPresentacion.cantidadInventario || productoPresentacion.cantidadInventario == 0){
      if(productoPresentacion.cantidadInventario < 0){
        mensaje = "ValidarProductoPresentacion_1";
      }
      // Valida que el estado tenga sentido
      else if((productoPresentacion.cantidadInventario <= productoPresentacion.inventarioTransito)
        && productoPresentacion.estadoInventarioProducto.id == EnumEstadoInventarioProducto.INVENTARIO.codigoEstado){
          // Si no hay cantidad disponible no puede estar en inventario
          mensaje = "ValidarProductoPresentacion_4";
      }
      else if((productoPresentacion.cantidadInventario > productoPresentacion.inventarioTransito)
        && productoPresentacion.estadoInventarioProducto.id == EnumEstadoInventarioProducto.FUERA_INVENTARIO.codigoEstado){
          // Si hay cantidad disponible, no puede estar en outstock
          mensaje = "ValidarProductoPresentacion_5";
      }
      else if((productoPresentacion.cantidadInventario > productoPresentacion.inventarioTransito)
        && productoPresentacion.estadoInventarioProducto.id == EnumEstadoInventarioProducto.PROXIMAMENTE.codigoEstado){
          // Si hay cantidad disponible, no puede estar en comming soon
          mensaje = "ValidarProductoPresentacion_6";
      }
      else if(productoPresentacion.cantidadInventario < productoPresentacion.inventarioTransito){
        mensaje = "ValidarProductoPresentacion_2";
      }
    }else{
      mensaje = "ValidarProductoPresentacion_3";
    }

    // Valida la cantidad para importacion
    if(productoPresentacion.cantidadImportacion || productoPresentacion.cantidadImportacion == 0){
      if(productoPresentacion.cantidadImportacion < 0){
        mensaje = "ValidarProductoPresentacion_7";
      }
      else if(productoPresentacion.cantidadImportacion < productoPresentacion.importacionTransito){
        mensaje = "ValidarProductoPresentacion_8";
      }
    }else{
      // Pide la cantidad para importación, solo si ya hay algo en tránsito
      if(productoPresentacion.importacionTransito || productoPresentacion.importacionTransito == 0){
        mensaje = "ValidarProductoPresentacion_9";
      }
    }

    if(productoPresentacion.manejarPorInventario){
      if(productoPresentacion.estadoInventarioProducto.id == EnumEstadoInventarioProducto.PROXIMAMENTE.codigoEstado){
        // Dice que si entonces debe estar en inventario
        mensaje = "ValidarProductoPresentacion_11";
      }
    }else{
      if(productoPresentacion.estadoInventarioProducto.id == EnumEstadoInventarioProducto.INVENTARIO.codigoEstado
        || productoPresentacion.estadoInventarioProducto.id == EnumEstadoInventarioProducto.FUERA_INVENTARIO.codigoEstado){
        // Dice que no entonces debe estar en importacion
        mensaje = "ValidarProductoPresentacion_11";
      }
    }


    return mensaje;
  }

  /**
    * Función que actualiza un producto
    * @returns ListResponse<Producto>
    * @author msemeraro
    */
   async actualizarProducto(producto: Producto): Promise<Response<Producto>> {
    let respuesta: Response<Producto>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('Producto/update', producto);
      respuesta = <Response<Producto>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Producto>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función que hace la peticion al servidor de eliminar una productoPresentacion
    * @returns Response<Boolean>
    * @author msemeraro
    */
  async eliminarProductoPresentacion(productoPresentacion: ProductoPresentacion): Promise<Response<Boolean>> {
    let respuesta: Response<Boolean>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('ProductoPresentacion/delete', productoPresentacion);
      respuesta = <Response<Boolean>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Boolean>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función que hace la peticion al servidor de agregar una productoPresentacion
    * @returns Response<ProductoPresentacion>
    * @author msemeraro
    */
  async agregarProductoPresentacion(productoPresentacion: ProductoPresentacion): Promise<Response<ProductoPresentacion>> {
    let respuesta: Response<ProductoPresentacion>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('ProductoPresentacion/insert', productoPresentacion);
      respuesta = <Response<ProductoPresentacion>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<ProductoPresentacion>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función que hace la peticion al servidor de eliminar una fotografia de un producto presentacion
    * @returns Response<Boolean>
    * @author msemeraro
    */
   async eliminarFotografiaProductoPresentacion(fotografia: FotografiaProducto): Promise<Response<Boolean>> {
    let respuesta: Response<Boolean>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('ProductoPresentacion/FotografiaProducto/delete', fotografia);
      respuesta = <Response<Boolean>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<Boolean>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }

  /**
    * Función que hace la peticion al servidor de agregar una fotografia de un producto presentacion
    * @returns Response<FotografiaProducto>
    * @author msemeraro
    */
   async agregarFotografiaProductoPresentacion(fotografia: FotografiaProducto): Promise<Response<FotografiaProducto>> {
    let respuesta: Response<FotografiaProducto>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpPost('ProductoPresentacion/FotografiaProducto/insert', fotografia);
      respuesta = <Response<FotografiaProducto>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new Response<FotografiaProducto>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }


  /**
  * Función que verifica si el producto tiene ordenes asociadas 
  * @returns Response<Boolean>
  * @author kjimenez
  */
 async isProductoInOrdenes(codProductoPresentacion: number): Promise<Response<Boolean>> {
  let respuesta: Response<Boolean>;
  try {
    let promiseResponse = await this.httpClientEnersolService.httpGet('ProductoPorOrdenDeCompra/ProductoPresentacionEnOrdenes?codProductoPresentacion=' + codProductoPresentacion);
    respuesta = <Response<Boolean>>promiseResponse;
  }
  catch (error) {
    console.log(error.message);
    respuesta = new Response<Boolean>(false);
    respuesta.addError('code', 'techMessage', 'userMessage');
  }
  finally {
    return respuesta;
  }
}

  /**
  * Función que obtiene todos los estados del inventario de productos
  * @returns ListResponse<EstadoInventarioProducto>
  * @author gberrocal
  */
  async getEstadosInventarioProducto(): Promise<ListResponse<EstadoInventarioProducto>> {
    let respuesta: ListResponse<EstadoInventarioProducto>;
    try {
      let promiseResponse = await this.httpClientEnersolService.httpGet('EstadoInventarioProducto/all');
      respuesta = <ListResponse<EstadoInventarioProducto>>promiseResponse;
    }
    catch (error) {
      console.log(error.message);
      respuesta = new ListResponse<EstadoInventarioProducto>(false);
      respuesta.addError('code', 'techMessage', 'userMessage');
    }
    finally {
      return respuesta;
    }
  }


  /* FUNCIONES QUE HACEN PETICIONES AL GLOBAL SERVICE */

  /**
  * Función que obtiene si el usuario logueado es administrador de enersol
  * @return boolean 
  * @author msemeraro
  */
  isAdminEnersol() {
    return this.globalEnersolService.isAdminEnersol();
  }

  /* FUNCIONES QUE HACEN ORDENAMIENTOS O FILTROS */





  /* OTRAS FUNCIONES */

  /**
  * Función que valida si un archivo es un PDF o una imagen
  * @param file
  * @returns boolean
  * @author msemeraro
  */
  validateImage(file: File): boolean {
    return this.validationEnersolService.validateFileImage(file);
  }

  /**
    * Función que valida si un archivo es un PDF
    * @param file
    * @returns boolean
    * @author msemeraro
    */
  validatePDF(file: File): boolean {
    return this.validationEnersolService.validateFilePDF(file);
  }

  /**
  * Función que valida el nombre de la nueva productoPresentacion
  * @param nombre 
  * @author msemeraro
  */
  validarNombre(nombre: string): Boolean {
    return this.validationEnersolService.validateGeneral(nombre);
  }

  /**
    * Función que se encarga de abrir en una nueva ventana un documento
    * @param url
    */
  abrirArchivo(url: string) {
    window.open(this.urlEnersol + url, '_blank');
  }

  /**
* Función que guarda las variables para mostrar un mensaje
* @param tituloMensaje
* @param contenidoMensaje
* @param esError
* @author msemeraro
*/
  setMensaje(tituloMensaje: String, contenidoMensaje: String, esError: Boolean): void {
    this.globalEnersolService.setTituloMensaje(tituloMensaje);
    this.globalEnersolService.setContenidoMensaje(contenidoMensaje);
    this.globalEnersolService.setEsError(esError);
  }

  /**
  * Función que obtiene el titulo de un mensaje
  * @return tituloMensaje 
  * @author msemeraro
  */
  getTituloMensaje(): String {
    return this.globalEnersolService.getTituloMensaje();
  }

  /**
  * Función que obtiene el contenido de un mensaje
  * @return contenidoMensaje 
  * @author msemeraro
  */
  getContenidoMensaje(): String {
    return this.globalEnersolService.getContenidoMensaje();
  }

  /**
  * Función que obtiene si un mensaje es error o no
  * @return esError 
  * @author msemeraro
  */
  getEsError(): Boolean {
    return this.globalEnersolService.getEsError();
  }



}
