/**
 * Clase que maneja los datos que se van a enviar al servicio para las cantidades de inventario
 * @author gberrocal
 */
export class DatosInventarioProducto {
  codigoEnersol: number;
	//presentacion: string;
	cantidadInventario: number;
  cantidadImportacion: number;
	manejarPorInventario: string;
	estadoInventario: string;
  comentario: string;
}