import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReporteOrdenBackview } from './reporte-orden.component.backview';
import { NgxSpinnerModule } from "ngx-spinner";
import { MatDatepickerModule, MatNativeDateModule, MatSelectModule } from '@angular/material';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../footer/footer.module';

@NgModule({
  declarations: [ReporteOrdenBackview],
  imports: [
    CommonModule,
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    HeaderModule,
    FooterModule,
    MatNativeDateModule,
    MatSelectModule
  ],
  exports: [
    ReporteOrdenBackview
  ]
})
export class ReporteOrdenModule { }
