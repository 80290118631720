import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
/* SERVICES */
import { ErrorService } from 'src/services/error.service';
import { OrdenSugeridaController } from './orden-sugerida.component.controller';
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
import { ProductoSugerido } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoSugerido';
import { ProductoPresentacion } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoPresentacion';
import { EnumEstadoInventarioProducto } from 'src/models/com.enersol.directshopping.dal.enums/EnumEstadoInventarioProducto';
import { Parametro } from 'src/models/com.enersol.directshopping.dal.entidad/Parametro';
import { GlobalService } from 'src/services/global';
import { EnumFormaInventario } from 'src/models/com.enersol.directshopping.dal.enums/EnumFormaInventario';
/* REST MODEL */

/* MODELS */


@Component({
  selector: 'app-orden-sugerida',
  templateUrl: './orden-sugerida.component.html',
  styleUrls: ['./orden-sugerida.component.scss'],
  providers: [OrdenSugeridaController]
})
export class OrdenSugeridaBackview implements OnInit {

  /* Lista de productos a mostrar */
  productos: ListResponse<ProductoSugerido> = new ListResponse<ProductoSugerido>(false);
  productosBusqueda: Array<ProductoSugerido> = new Array();

  urlEnersol: string = "";

  /* Variable para los mensajes */
  contenidoMensaje: String;
  tituloMensaje: String;
  esError: Boolean;

  /* Variables para la paginacion */
  page_size: number = 18;
  page_number: number = 1;
  pageSizeOptions = [18, 36, 54, 72, 90];

  formaInventario: Parametro;

  constructor(
    private router: Router,
    private ordenSugeridaComponent: OrdenSugeridaController,
    private errorService: ErrorService,
    private spinner: NgxSpinnerService,
    private globalEnersolService: GlobalService
  ) {
  }

  async ngOnInit() {
    this.spinnerShow();
    this.urlEnersol = await this.ordenSugeridaComponent.urlEnersol;
    await this.getProductos();
    this.formaInventario = await this.globalEnersolService.getFormaInventario();
    this.spinnerHide();
  }

  /**
  * Función que carga la lista de productos
  * @author msemeraro
  */
  async getProductos() {
    this.productos = await this.ordenSugeridaComponent.getProductos();
    if (!this.productos.sucess) {
      let mensaje = this.errorService.displayError(this.productos.errors);
      this.ordenSugeridaComponent.setMensaje('Error al cargar los datos', mensaje, true);
      this.verMensaje();
    }
    this.productosBusqueda = [];
    for (let entry of this.productos.data) {
      // AQUI ESTA ESA VARA
      entry.precio = entry[3] / entry.productoPresentacion.unidadEmpaque;
      entry.productoPresentacion.precio = entry.precio;
      this.productosBusqueda.push(entry);
    }
  }

  /**
    * Función que despliega el detalle del producto seleccionado
    * @param producto
    * @author msemeraro
    */
  verDetalle(producto: ProductoPresentacion, cantidad: number) {
    this.ordenSugeridaComponent.verDetalleProducto(producto, cantidad);
    this.router.navigate(['detalle-producto']);
  }

  /**
   * Función que añade un producto al carrito
   * @param producto
   * @author msemeraro
   */
  async addCarrito(producto: ProductoPresentacion, cantidad: number) {
    try{
      let respuesta = await this.ordenSugeridaComponent.addCarrito(producto, cantidad);
      if (!respuesta.sucess) {
        let mensaje = this.errorService.displayError(respuesta.errors);
        this.ordenSugeridaComponent.setMensaje('Error al añadir al carrito', mensaje, true);
        this.verMensaje();
      }
      else {
        if (cantidad == 1)
          this.ordenSugeridaComponent.setMensaje('Producto añadido al carrito', 'Se añadió una cantidad del producto: ' + producto.producto.nombre, false);
        else
          this.ordenSugeridaComponent.setMensaje('Producto añadido al carrito', 'Se añadieron ' + cantidad + ' cantidades del producto: ' + producto.producto.nombre, false);
  
        this.verMensaje();
      }
    }
    catch(error){
      let mensaje = this.errorService.getErrorMessage(error.message);
      this.ordenSugeridaComponent.setMensaje('Error al añadir al carrito', mensaje, true);
      this.verMensaje();
    }
  }

  /**
   * Función que añade los productos seleccionadps al carrito
   * @author msemeraro
   */
  async addCarritoSeleccionados() {
    var elements = document.querySelectorAll('input[type="checkbox"]:checked');
    var checkedElements = Array.prototype.map.call(elements, function (el, i) {
      return el.id;
    });
    if (elements.length < 1) {
      this.ordenSugeridaComponent.setMensaje('Error', 'Debe seleccionar al menos un producto', true);
      this.verMensaje();
    }
    else {
      this.spinnerShow();
      let checkboxes = document.getElementsByTagName('input');
      let i = 0;
      if (checkboxes[0].checked) {
        i = 1;
      }

      let respuesta;
      let mensaje;
      let producto;

      for (i; i < checkedElements.length; i++) {
        for (producto of this.productos.data) {
          if (producto.productoPresentacion.id.codigoProductoPresentacion == checkedElements[i]) {
            // Solo los agrega si no están en estado "No disponible"
            if(!this.isEstadoProduccionSuspendida(producto.productoPresentacion)){
              respuesta = await this.ordenSugeridaComponent.addCarrito(producto.productoPresentacion, producto.cantidad);
              if (!respuesta.sucess) {
                this.spinnerHide();
                mensaje = this.errorService.displayError(respuesta.errors);
                this.ordenSugeridaComponent.setMensaje('Error al añadir al carrito', mensaje, true);
                this.verMensaje();
                return;
              }
            }
            break;
          }
        }

      }
      this.spinnerHide();
      this.ordenSugeridaComponent.setMensaje('Productos añadidos al carrito', 'Los productos seleccionados fueron añadidos al carrito', false);
      this.verMensaje();
    }

  }

  /**
   * Función que selecciona o deselecciona todos los productos del carrito
   * @author msemeraro
   */
  seleccionarTodo(cuadroSeleccion: boolean) {
    var checkboxes = document.getElementsByTagName('input');
    let check = checkboxes[0].checked;
    if (!cuadroSeleccion)
      check = true;
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].type == 'checkbox') {
        // Selecciona solo los que no son no disponible
        if(checkboxes[i].name != EnumEstadoInventarioProducto.PRODUCCION_SUSPENDIDA.nombre){
          checkboxes[i].checked = check;
        }
      }
    }
  }

  /**
  * Función que formatea un número
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
  getFormatNumber(numero: number) {
    return this.ordenSugeridaComponent.getFormatNumber(numero);
  }

  spinnerShow() {
    this.spinner.show();
  }

  spinnerHide() {
    this.spinner.hide();
  }

  isEstadoInventario(producto: ProductoPresentacion) {
    return this.isEstado(producto, EnumEstadoInventarioProducto.INVENTARIO.codigoEstado);
  }

  isEstadoProximamente(producto: ProductoPresentacion) {
    return this.isEstado(producto, EnumEstadoInventarioProducto.PROXIMAMENTE.codigoEstado);
  }

  isEstadoFueraInventario(producto: ProductoPresentacion) {
    return this.isEstado(producto, EnumEstadoInventarioProducto.FUERA_INVENTARIO.codigoEstado);
  }

  isEstadoProduccionSuspendida(producto: ProductoPresentacion) {
    return this.isEstado(producto, EnumEstadoInventarioProducto.PRODUCCION_SUSPENDIDA.codigoEstado);
  }

  isEstado(producto: ProductoPresentacion, codigoEstado: number){
    if(producto) {
      return producto.estadoInventarioProducto.id == codigoEstado;
    }
  }

  /**
   * Indica si tiene que mostrar tanto la cantidad de inventario como de importación
   * @returns 
   */
   mostrarInventarioImportacion(producto: ProductoPresentacion): boolean{
    // Se muestra solo si la modalidad no es inventario
    if(this.formaInventario.valor != EnumFormaInventario.INVENTARIO.nombre){
      // Si el producto esta en inventario o fuera de invnetario
      if(this.isEstadoInventario(producto) || this.isEstadoFueraInventario(producto)){
        var cantidadImportacion = this.getCantidadImportacionDisponible(producto);
        return (cantidadImportacion && cantidadImportacion > 0);
      }
    }
    return false;
  }

  /**
   * Indica si se muestra el estado normal del producto
   * @param producto 
   * @returns 
   */
  mostrarEstadoNormal(producto: ProductoPresentacion): boolean{
    if(this.isModalidad(EnumFormaInventario.MIXTO.nombre) 
      || this.isModalidad(EnumFormaInventario.INVENTARIO.nombre)){
      return true;
    }
    else if(this.isModalidad(EnumFormaInventario.IMPORTACION.nombre)){
      if(this.isEstadoProximamente(producto)){
        return true;
      }
    }
    return false;
  }

  /**
   * Indica si la modalidad es mixta
   */
  isModalidadMixta(): boolean{
    return this.isModalidad(EnumFormaInventario.MIXTO.nombre);
  }

  /**
   * Indica si la modalidad de la plataforma es de un tipo determinado
   * @param modalidad 
   * @returns 
   */
  private isModalidad(modalidad: string): boolean{
    if(this.formaInventario){
      return this.formaInventario.valor == modalidad;
    }
    return false;
  }

  getNombreEstadoProximamente(): string{
    return EnumEstadoInventarioProducto.PROXIMAMENTE.nombre;
  }

  /**
   * Obtiene la cantidad disponible para importación
   * @returns 
   */
  getCantidadImportacionDisponible(producto: ProductoPresentacion): number{
    if(producto){
      return producto.cantidadImportacion - producto.importacionTransito;
    }
    return null;
  }

  /**
   * Indica si una presentacion de producto está en estado No Disponible
   * @param producto 
   * @author gberrocal
   */
  isProductoNoDisponible(producto: ProductoPresentacion):boolean {
    if(producto){
      return producto.estadoInventarioProducto.id == EnumEstadoInventarioProducto.PRODUCCION_SUSPENDIDA.codigoEstado;
    }
    return false;
  }

  /**
  * Función que muestra un mensaje
  * @author msemeraro
  */
  verMensaje() {
    this.tituloMensaje = this.ordenSugeridaComponent.getTituloMensaje();
    this.contenidoMensaje = this.ordenSugeridaComponent.getContenidoMensaje();
    this.esError = this.ordenSugeridaComponent.getEsError();
    var element = document.getElementById("btnAbrirMensaje") as any;
    element.click();
  }

  clickModalMensaje() { }

  handlePage(e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }

}
