import { Component, OnInit } from '@angular/core';
import { ReporteMarcasController } from './reporte-marcas.component.controller';
import { ReporteEstacionesMarcas } from 'src/models/com.enersol.directshopping.dal.entidad/ReporteEstacionesMarcas';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { Estacion } from 'src/models/com.enersol.directshopping.dal.entidad/Estacion';
import { ReporteMarcasProductos } from 'src/models/com.enersol.directshopping.dal.entidad/ReporteMarcasProductos';
import { ReporteProductosInformacion } from 'src/models/com.enersol.directshopping.dal.entidad/ReporteProductosInformacion';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-reporte-marcas',
  templateUrl: './reporte-marcas.component.html',
  styleUrls: ['./reporte-marcas.component.scss'],
  providers: [ReporteMarcasController]
})
export class ReporteMarcasBackview implements OnInit {

  reporte: ReporteEstacionesMarcas[] = new Array();

  fechaPago: Date;
  fechaPricing: Date;

  constructor(
    private reporteComponent: ReporteMarcasController,
    ) { }

  async ngOnInit() {
    await this.getReporte();
    
  }

  /**
   * Función que solicita el reporte de productos por marca por estacion
   * @author msemeraro
   */
  async getReporte() {
    
    let codMarcas = [1,2,5,6,7,8,16];
    let codEstacions = [1,2];
    let reportes = await this.reporteComponent.getReporte(codMarcas, codEstacions);
    if (!reportes.sucess) {
      /*let mensaje = this.errorService.displayError(ordenesDeCompra.errors);
      this.misOrdenesComponent.setMensaje('Error al obtener las órdenes', mensaje, true);
      this.verMensaje();*/
    }
    else {
      //this.reporteComponent.exportAsExcelFile(reportes.data, "prueba.xlsx")
      this.getReporteCompleto(reportes.data);
    }
  }

  getReporteCompleto(respuesta:any){
    this.reporte = new Array();

    let estacion: Estacion = new Estacion();
    let reportes:ReporteMarcasProductos[] = new Array();
    let nuevoReporteMarcasProductos = new ReporteMarcasProductos();
    let nuevoReporteProductosInformacion:ReporteProductosInformacion = new ReporteProductosInformacion();
    let reporteEstacionesMarcas:ReporteEstacionesMarcas = new ReporteEstacionesMarcas();

    for(let reporte of respuesta){
      estacion = new Estacion();
      estacion = reporte.estacion;
      reportes = new Array();
      for(let reporteMarcasProductos of reporte.productosPorMarca){
        nuevoReporteMarcasProductos = new ReporteMarcasProductos();
        nuevoReporteMarcasProductos.marca = reporteMarcasProductos.marca;
        nuevoReporteMarcasProductos.productos = new Array();
        nuevoReporteProductosInformacion = new ReporteProductosInformacion();
        for(let reporteProductosInformacion of reporteMarcasProductos.productos){
          nuevoReporteProductosInformacion.productoPresentacion = reporteProductosInformacion[0];
          nuevoReporteProductosInformacion.cantidad = reporteProductosInformacion[1];
          nuevoReporteProductosInformacion.promedio = reporteProductosInformacion[2];
          nuevoReporteProductosInformacion.ganancia = reporteProductosInformacion[3];
          nuevoReporteProductosInformacion.cantidadOrdenes = reporteProductosInformacion[4];
          nuevoReporteMarcasProductos.productos.push(nuevoReporteProductosInformacion);
        }
        reportes.push(nuevoReporteMarcasProductos);
      }
      reporteEstacionesMarcas = new ReporteEstacionesMarcas();
      reporteEstacionesMarcas.estacion = estacion;
      reporteEstacionesMarcas.productosPorMarca = reportes;
      this.reporte.push(reporteEstacionesMarcas);
    }
  }

}
