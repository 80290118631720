import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReporteMarcasBackview } from './reporte-marcas.component.backview';
import { NgxSpinnerModule } from "ngx-spinner";
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../footer/footer.module';

@NgModule({
  declarations: [ReporteMarcasBackview],
  imports: [
    CommonModule,
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    HeaderModule,
    FooterModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  exports: [
    ReporteMarcasBackview
  ]
})
export class ReporteMarcasModule { }
