import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
/* SERVICES */
import { ErrorService } from 'src/services/error.service';
import { DetalleOrdenController } from './detalle-orden.component.controller';
/* MODELS */
import { OrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/OrdenDeCompra';
import { ComprobantePagoOrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/ComprobantePagoOrdenDeCompra';
import { GlobalService } from 'src/services/global';
import { EnumEstadoOrdenInventario } from 'src/models/com.enersol.directshopping.dal.enums/EnumEstadoOrdenInventario';

@Component({
  selector: 'app-detalle-orden',
  templateUrl: './detalle-orden.component.html',
  styleUrls: ['./detalle-orden.component.scss'],
  providers: [DetalleOrdenController]
})
export class DetalleOrdenBackview implements OnInit {

  /* Orden de compra que se muestra */
  ordenDeCompra: OrdenDeCompra;

  /* Montos de la orden */
  subTotalOrden = 0;
  totalOrden = 0;
  impuestoOrden = 0;

  /* Variables para el modal del historial de los detalles */
  historialEstadosDetalle = [];

  /* Variable para los mensajes */
  contenidoMensaje: String;
  tituloMensaje: String;
  esError: Boolean;

  /* Variables para el comprobante de pago */
  comentarioComprobante = '';
  comprobanteSeleccionado: File = null;

  constructor(
    private router: Router,
    private detalleOrdenComponent: DetalleOrdenController,
    private errorService: ErrorService,
    private globalEnersolService: GlobalService,
    private spinner: NgxSpinnerService
  ) {
  }

  async ngOnInit() {
    this.ordenDeCompra = this.detalleOrdenComponent.getOrdenDeCompra();
    this.getDetailProducts(this.ordenDeCompra.detallesOrden);
    this.getSubtotal();
    this.globalEnersolService.getCantidadProductosCarrito();
  }

  /**
  * Función que redondea un número
  * @author msemeraro
  */
  getRoundNumber(numero: number) {
    return this.detalleOrdenComponent.getRoundNumber(numero);
  }

  /**
  * Función que formatea un número
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
  getFormatNumber(numero: number) {
    return this.detalleOrdenComponent.getFormatNumber(numero);
  }

  /**
  * Función que retorna el porcentaje de ganancia de una orden de compra
  * @author msemeraro
  */
  getPorcentajeGanancia(ganancia: number, totalOrden: number): number {
    const porcentaje = this.detalleOrdenComponent.getPorcentajeGanancia(ganancia, totalOrden);
    return this.getRoundNumber(porcentaje);
  }

  /**
  * Función que obtiene los productos de un detalle de una orden de compra
  * @author msemeraro
  */
  async getDetailProducts(listaDetalle) {
    try {
      let subtotal;
      for (const element of listaDetalle) {
        subtotal = 0;
        await this.detalleOrdenComponent.getDetailProducts(element.codigoDetalleOrden).then(data => {
          element.productos = data.data;
        });
      }
    } catch (error) {
      console.log(error);
      console.log('Error al obtener los datos');
    }
    return listaDetalle;
  }

  /**
  * Función que obtiene el subtotal de la orden de compra
  * @author msemeraro
  */
  getSubtotal() {
    this.subTotalOrden = 0;
    for (const element of this.ordenDeCompra.detallesOrden) {
      this.subTotalOrden = this.subTotalOrden + element.subTotal;
      this.subTotalOrden = this.subTotalOrden;
    }
    this.totalOrden = this.detalleOrdenComponent.getTotalOrden(this.subTotalOrden);
    this.impuestoOrden = this.detalleOrdenComponent.getImpuestoOrden(this.subTotalOrden);
  }

  /**
  * Función que obtiene el historial de los detalles de la orden
  * @author msemeraro
  */
  async verHistorialEstados() {
    const historialDetalles = await this.detalleOrdenComponent.getHistorialEstadosDetalles(this.ordenDeCompra.codigoOrden);
    if (!historialDetalles.sucess) {
      const mensaje = this.errorService.displayError(historialDetalles.errors);
      this.detalleOrdenComponent.setMensaje('Error al cargar el historial', mensaje, true);
      this.verMensaje();
    } else {
      // Ordena la lista por fecha
      this.historialEstadosDetalle = historialDetalles.data;
      this.getFormatoFechas();
      this.historialEstadosDetalle = await this.detalleOrdenComponent.orderByFecha(this.historialEstadosDetalle);
    }
  }

  /**
  * Función que le da formato a una fecha
  * @author msemeraro
  */
  getFormatoFechas() {
    this.historialEstadosDetalle = this.detalleOrdenComponent.getFormatoFechas(this.historialEstadosDetalle);
  }

  /**
    * Función que muestra un mensaje
    * @author msemeraro
    */
  verMensaje() {
    this.tituloMensaje = this.detalleOrdenComponent.getTituloMensaje();
    this.contenidoMensaje = this.detalleOrdenComponent.getContenidoMensaje();
    this.esError = this.detalleOrdenComponent.getEsError();
    let element = document.getElementById('btnAbrirMensaje') as any;
    element.click();
  }


  async archivoSeleccionado(event) {
    if (event.target.files[0] != null) {
      this.comprobanteSeleccionado =  event.target.files[0] as File;
      await this.detalleOrdenComponent.getFileBase64(this.comprobanteSeleccionado);
    }
  }

  /**
  * Función que hace la peticion del envio del comprobante
  * @author msemeraro
  */
  async enviarComprobante() {this.spinnerShow();
    // Comprueba el rol del usuario
                             let rolAdmin: Boolean = await this.detalleOrdenComponent.isEnvioOrdenesComprobantesEstacion();
    // Si no posee los roles necesarios envía un mensaje
                             if (!rolAdmin) {
      this.spinnerHide();
      this.detalleOrdenComponent.setMensaje('Error', 'No posee con los permisos de administrador para enviar el comprobante', true);
      this.verMensaje();
      return;
    }
                             let respuesta;
                             if (this.comprobanteSeleccionado == null) {
      this.detalleOrdenComponent.setMensaje('Error', 'Debe adjuntar un comprobante de pago', true);
      this.verMensaje();
      return;
    } else if (!(this.detalleOrdenComponent.validateFile(this.comprobanteSeleccionado))) {
      this.detalleOrdenComponent.setMensaje('Error', 'Debe adjuntar un comprobante de pago válido', true);
      this.verMensaje();
      return;
    } else {
      if (await this.existeNombreComprobante(this.ordenDeCompra.codigoOrden + this.comprobanteSeleccionado.name)) {
        this.detalleOrdenComponent.setMensaje('Error', 'Debe ingresar un nombre de archivo válido', true);
        this.verMensaje();
        return;
      } else {
        if (!this.detalleOrdenComponent.validateComentario(this.comentarioComprobante)) {
          this.comentarioComprobante = "El comprobante de Pago ha sido adjuntado";
        }
        this.spinnerShow();
        // Registra el comprobante
        respuesta = await this.detalleOrdenComponent.registrarComprobante(this.comprobanteSeleccionado, this.ordenDeCompra);
        if (!respuesta.sucess) {
          const mensaje = this.errorService.displayError(respuesta.errors);
          this.detalleOrdenComponent.setMensaje('Error al registrar el comprobante', mensaje, true);
          this.verMensaje();
          this.spinnerHide();
          return;
        }
        // Actualiza el estado de la orden
        respuesta = await this.detalleOrdenComponent.updateEstadoOrden(this.ordenDeCompra, 4, this.comentarioComprobante);

        if (respuesta) {
          await this.getOrdenDeCompraUpdate();
          this.spinnerHide();
        } else {
          this.spinnerHide();
          const mensaje = this.errorService.displayError(respuesta.errors);
          this.detalleOrdenComponent.setMensaje('Error al actualizar la orden', mensaje, true);
          this.verMensaje();
        }
      }
    }
  }


  /**
   * Indica si la orden de compra es de importación o no
   * @returns True si es importación, false si no
   * @author gberrocal
   */
   isOrdenTipoImportacion(): boolean{
    if(this.ordenDeCompra && this.ordenDeCompra.estadoOrdenInventario.id === EnumEstadoOrdenInventario.IMPORTACION.codigoEstado){
      return true;
    }
    return false;
  }

  /**
  * Función que cancela la orden de compra
  * @author msemeraro
  */
  async cancelarOrden() {
    let confirmacion = confirm('¿Desea cancelar la orden?');
    if (confirmacion) {
      this.spinnerShow();
      const rolAdmin: Boolean = await this.detalleOrdenComponent.isEnvioOrdenesComprobantesEstacion();
      if (!rolAdmin) {
        this.spinnerHide();
        this.detalleOrdenComponent.setMensaje('Error', 'No posee con los permisos de administrador para cancelar la orden', true);
        this.verMensaje();
        return;
      } else {
        let respuesta;
        // Actualiza el estado de la orden
        respuesta = await this.detalleOrdenComponent.updateEstadoOrden(this.ordenDeCompra, 8, 'Orden cancelada por el socio');
        if (respuesta) {
          await this.getOrdenDeCompraUpdate();
        } else {
          const mensaje = this.errorService.displayError(respuesta.errors);
          this.detalleOrdenComponent.setMensaje('Error al cancelar la orden', mensaje, true);
          this.verMensaje();
        }
        this.spinnerHide();
      }
    }
  }

  /**
  * Función que consulta si un nombre ya fue registrado a un comprobante
  * @author msemeraro
  */
  async existeNombreComprobante(nombre: String) {
    const respuesta = await this.detalleOrdenComponent.existeNombreComprobante(nombre);
    if (respuesta.sucess) {
      return respuesta.data;
    }
    else {
      const mensaje = this.errorService.displayError(respuesta.errors);
      this.detalleOrdenComponent.setMensaje('Error al validar el comprobante', mensaje, true);
      this.verMensaje();
    }
  }

  /**
  * Función que hace la peticion de obtener la orden actualizada
  * @author msemeraro
  */
  async getOrdenDeCompraUpdate() {
    const respuesta = await this.detalleOrdenComponent.getOrdenDeCompraUpdate(this.ordenDeCompra.codigoOrden);
    if (respuesta.sucess) {
      this.detalleOrdenComponent.setOrdenDeCompraImportacion(respuesta.data);
      await this.detalleOrdenComponent.verDetalleOrden(respuesta.data);
      this.ordenDeCompra = await this.detalleOrdenComponent.getOrdenDeCompra();
      await this.getDetailProducts(this.ordenDeCompra.detallesOrden);
      this.getSubtotal();
    } else {
      const mensaje = this.errorService.displayError(respuesta.errors);
      this.detalleOrdenComponent.setMensaje('Error al actualizar', mensaje, true);
      this.verMensaje();
    }
    $('.close').click();
  }

  verMisOrdenes() {
    this.router.navigate(['mis-ordenes']);
  }

  spinnerShow() {
    this.spinner.show();
  }

  spinnerHide() {
    this.spinner.hide();
  }

  clickModalMensaje() { }

}
