import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContenidoBackview } from './contenido.component.backview';
import { NgxSpinnerModule } from "ngx-spinner";
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../footer/footer.module';

@NgModule({
  declarations: [ContenidoBackview],
  imports: [
    CommonModule,
    HeaderModule,
    FooterModule,
    NgxSpinnerModule
  ],
  exports: [
    ContenidoBackview
  ]
})
export class ContenidoModule { }