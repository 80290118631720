import { Component, OnInit, OnChanges, DoCheck } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
/* SERVICES */
import { ErrorService } from 'src/services/error.service';
import { CarritoController } from './carrito.component.controller';
/* REST MODEL */
import { Response } from 'src/models/com.enersol.directshopping.ws.rest.model/Response';
/* MODELS */
import { OrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/OrdenDeCompra';
import { ProductoPorOrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoPorOrdenDeCompra';
import { GlobalService } from 'src/services/global';
import { interval } from 'rxjs';
import { initialConfig } from 'ngx-mask';
import { EnumEstadoOrdenInventario } from 'src/models/com.enersol.directshopping.dal.enums/EnumEstadoOrdenInventario';


@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.scss'],
  providers: [CarritoController]
})
export class CarritoBackview implements OnInit {

  /* Variable con la fecha de Pricing */
  fechaPricing: string = "";
  cantidadDiasPagoOrden: number;
  fechaLimitePagoOrden: Date;
  fechaLimiteRealizarPedidoInventario: string;

  /* Detalles de las marcas relacionadas al carrito */
  detallesCarrito = [];
  detallesCarritoInventario = [];

  /* Orden de compra que se muestra en el carrito */
  ordenDeCompra : OrdenDeCompra;
  ordenDeCompraInventario : OrdenDeCompra;

  /* Montos de la orden del carrito */
  subTotalOrden = 0;
  totalOrden = 0;
  impuestoOrden = 0;
  gananciaOrden = 0;



  subTotalOrdenInventario = 0;
  totalOrdenInventario = 0;
  impuestoOrdenInventario = 0;
  gananciaOrdenInventario = 0;

  /* Variable para los mensajes */
  contenidoMensaje: String;
  tituloMensaje: String;
  esError: Boolean;

  /*  */
  cantidad;

  opcionesFormatoFecha: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  constructor(
    private router: Router,
    private carritoComponent: CarritoController,
    private errorService: ErrorService,
    private globalEnersolService: GlobalService,
    private spinner: NgxSpinnerService
  ) {
   
  }

  async ngOnInit() {
    await this.getOrdenDeCompraImportacionBorrador();
    this.ordenDeCompra = await this.carritoComponent.getOrdenDeCompraImportacionBorrador();
    this.ordenDeCompraInventario = await this.carritoComponent.getOrdenDeCompraInventarioBorrador();
    await this.getCarritoDetailsImportacion();
    await this.getCarritoDetailsInventario();
    await this.getFechaPricing();
    await this.getCantDiasPagoOrden();
    await this.getFechaLimitePago();
    this.globalEnersolService.getCantidadProductosCarrito();
    // Dejar de último, porque necesita varios de los datos previos
    this.getFechaLimitePagoInventario();
  }


  /**
  * Función que navega a la página principal
  * @author msemeraro
  */
  verPaginaPrincipal() {
    this.router.navigate(['/principal']);
  }

  /**
   * Función que carga una orden de compra para el carrito
   * @author msemeraro
   */
   async getOrdenDeCompraImportacionBorrador() {
    let ordenDeCompra = await this.carritoComponent.getOrdenesDeCompraBorrador();
    if (!ordenDeCompra.sucess) {
      this.errorService.displayError(ordenDeCompra.errors);
    }
    else {
      if(ordenDeCompra.data[0].estadoOrdenInventario.estado == EnumEstadoOrdenInventario.IMPORTACION.nombre) {
        this.carritoComponent.setOrdenDeCompraImportacion(ordenDeCompra.data[0]);
        this.carritoComponent.setOrdenDeCompraInventario(ordenDeCompra.data[1]);
      } else {
        this.carritoComponent.setOrdenDeCompraInventario(ordenDeCompra.data[0]);
        this.carritoComponent.setOrdenDeCompraImportacion(ordenDeCompra.data[1]);
      }
    }
  }

  /**
  * Función que obtiene los detalles de una orden de compra en borrador
  * @author msemeraro
  */
  async getCarritoDetailsImportacion() {
    let carritoDetalles = await this.carritoComponent.getCarritoDetailsImportacion();
    if (!carritoDetalles.sucess) {
      let mensaje = this.errorService.displayError(carritoDetalles.errors);
      this.carritoComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.detallesCarrito = await this.getCarritoDetailProducts(carritoDetalles.data);
      this.getSubtotal();
    }
  }


  async getCarritoDetailsInventario() {
    let carritoDetalles = await this.carritoComponent.getCarritoDetailsInventario();
    if (!carritoDetalles.sucess) {
      let mensaje = this.errorService.displayError(carritoDetalles.errors);
      this.carritoComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.detallesCarritoInventario = await this.getCarritoDetailProducts(carritoDetalles.data);
      this.getSubtotalInventario();
    }
  }

  /**
  * Función que obtiene la fecha para prizing
  * @author msemeraro
  */
  async getFechaPricing() {
    let fechaDePricing = await this.carritoComponent.getFechaPricing();
    if (!fechaDePricing.sucess) {
      let mensaje = this.errorService.displayError(fechaDePricing.errors);
      this.carritoComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      let fecha: Date = this.carritoComponent.getFecha(fechaDePricing.data.valor);
      this.fechaPricing = fecha.toLocaleDateString('es-ES', this.opcionesFormatoFecha);
    }
  }

  /**
  * Función que obtiene la cantidad de días para el pago de la orden
  * @author gberrocal
  */
   async getCantDiasPagoOrden() {
    let respuesta = await this.carritoComponent.getCantDiasPagoOrden();
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.carritoComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.cantidadDiasPagoOrden = Number(respuesta.data.valor);
    }
  }

  /**
  * Función que obtiene la fecha límite de pago
  * @author gberrocal
  */
  async getFechaLimitePago() {
    let respuesta = await this.carritoComponent.getFechaLimitePago();
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.carritoComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      // Saca las partes para generar el Date
      var partesFecha = respuesta.data.valor.split(" ");
      const [dia, mes, anno] = partesFecha[0].split('-');
      const [horas, minutos, segundos] = partesFecha[1].split(':');
      this.fechaLimitePagoOrden = new Date(+anno, +mes - 1, +dia, +horas, +minutos, +segundos);
    }
  }

  /**
  * Función que obtiene los productos de un detalle de una orden de compra
  * @author msemeraro
  */
  async getCarritoDetailProducts(listaDetalle) {
    try {
      let subtotal;
      for (let element of listaDetalle) {
        subtotal = 0;
        await this.carritoComponent.getDetailProducts(element.codigoDetalleOrden).then(data => {
          element.productos = data.data;
          for (let producto of element.productos) {
            subtotal += producto.precioUnitario * producto.cantidad;
          }
          element.subTotal = subtotal;
        });
      }
    } catch (error) {
      console.log(error);
      console.log("Error al obtener los datos");
    }
    return listaDetalle;
  }

  /**
  * Función que obtiene el subtotal de la orden de compra
  * @author msemeraro
  */
  getSubtotal() {
    this.subTotalOrden = 0;
    this.gananciaOrden = 0;
    for (let element of this.detallesCarrito) {
      this.subTotalOrden = this.subTotalOrden + element.subTotal;
      this.subTotalOrden = this.subTotalOrden;
      this.getGanancia(element.productos);
    }
    this.totalOrden = this.carritoComponent.getTotalOrden(this.subTotalOrden);
    this.impuestoOrden = this.carritoComponent.getImpuestoOrden(this.subTotalOrden);
  }


  getSubtotalInventario() {
    this.subTotalOrdenInventario = 0;
    this.gananciaOrdenInventario = 0;
    for (let element of this.detallesCarritoInventario) {
      this.subTotalOrdenInventario = this.subTotalOrdenInventario + element.subTotal;
      this.subTotalOrdenInventario = this.subTotalOrdenInventario;
      this.getGananciaInventario(element.productos);
    }
    this.totalOrdenInventario = this.carritoComponent.getTotalOrdenInventario(this.subTotalOrdenInventario);
    this.impuestoOrdenInventario = this.carritoComponent.getImpuestoOrdenInventario(this.subTotalOrdenInventario);
  }

  /**
  * Función que obtiene el subtotal de la orden de compra
  * @author msemeraro
  */
  getGanancia(productos) {
    for (let element of productos) {
      this.gananciaOrden = this.gananciaOrden + (element.margenGanancia * element.cantidad);
    }
  }


  getGananciaInventario(productos) {
    for (let element of productos) {
      this.gananciaOrdenInventario = this.gananciaOrdenInventario + (element.margenGanancia * element.cantidad);
    }
  }

  /**
  * Función que llama al controlador para limpiar el carrito
  * @author msemeraro
  */
  async cleanCarrito() {
    var confirmacion = confirm("¿Desea limpiar el carrito?");
    if (confirmacion) {
      this.spinnerShow();
      let orden: Response<OrdenDeCompra> = await this.carritoComponent.cleanCarrito();
      if (orden.sucess) {
        await this.getCarritoDetailsImportacion();
        await this.getCarritoDetailsInventario();
        this.getSubtotal();
        this.getSubtotalInventario();
      } else {
        let mensaje = this.errorService.displayError(orden.errors);
        this.carritoComponent.setMensaje('Error', mensaje, true);
        this.verMensaje();
      }
      this.spinnerHide();
    }
  }

  async cleanCarritoInventario() {
    var confirmacion = confirm("¿Desea limpiar el carrito?");
    if (confirmacion) {
      this.spinnerShow();
      let orden: Response<OrdenDeCompra> = await this.carritoComponent.cleanCarritoInventario();
      if (orden.sucess) {
        await this.getCarritoDetailsImportacion();
        await this.getCarritoDetailsInventario();
        this.getSubtotal();
        this.getSubtotalInventario();
      } else {
        let mensaje = this.errorService.displayError(orden.errors);
        this.carritoComponent.setMensaje('Error', mensaje, true);
        this.verMensaje();
      }
      this.spinnerHide();
    }
  }

  /**
  * Función que aumenta en una unidad el producto de una orden de compra
  * @author msemeraro
  */
  async aumentarUnidades(producto: ProductoPorOrdenDeCompra) {
    this.spinnerShow();
    producto.cantidad++;
    let productoActualizado: Response<ProductoPorOrdenDeCompra> = await this.carritoComponent.updateProductoPorOrdenCompra(producto);
    if (!productoActualizado.sucess) {
      let mensaje = this.errorService.displayError(productoActualizado.errors);
      this.carritoComponent.setMensaje('Error al aumentar las unidades', mensaje, true);
      this.verMensaje();
      producto.cantidad--;
    }
    else {
      await this.getCarritoDetailsImportacion();
    }
    this.spinnerHide();
  }

  /**
  * Función que disminuye en una unidad el producto de una orden de compra
  * @author msemeraro
  */
  async disminuirUnidades(producto: ProductoPorOrdenDeCompra) {
    if (producto.cantidad > 1) {
      this.spinnerShow();
      producto.cantidad--;
      let productoActualizado = await this.carritoComponent.updateProductoPorOrdenCompra(producto);
      if (!productoActualizado.sucess) {
        let mensaje = this.errorService.displayError(productoActualizado.errors);
        this.carritoComponent.setMensaje('Error al disminuir las unidades', mensaje, true);
        this.verMensaje();
        producto.cantidad++;
      }
      else {
        await this.getCarritoDetailsImportacion();
      }
      this.spinnerHide();
    }

  }

  /**
  * Función que aumenta en una unidad el producto de una orden de compra
  * @author msemeraro
  */
 async cambiarUnidades(event, producto: ProductoPorOrdenDeCompra) {
  // Guarda los datos para reversar en memoria el cambio
  let cantidadPrevia = producto.cantidad;
  let cantidadNueva = event.target.value;

  await this.spinnerShow();
  if(cantidadNueva > 0){
    producto.cantidad = cantidadNueva;
    let productoActualizado: Response<ProductoPorOrdenDeCompra> = await this.carritoComponent.updateProductoPorOrdenCompra(producto);
    if (!productoActualizado.sucess) {
      producto.cantidad = cantidadPrevia;
      event.target.value = producto.cantidad;
      let mensaje = this.errorService.displayError(productoActualizado.errors);
      this.carritoComponent.setMensaje('Error al cambiar las unidades', mensaje, true);
      this.verMensaje();
      // producto.cantidad--;
    }
    else {
      await this.getCarritoDetailsImportacion();
      await this.getCarritoDetailsInventario();
    }
  }
  else{
    event.target.value = producto.cantidad;
    this.carritoComponent.setMensaje('Error al cambiar las unidades', "Ingrese una cantidad positiva", true);
    this.verMensaje();
  }
  await this.spinnerHide();
}

  /**
   * Función que elimina los productos seleccionados del carrito
   * @author msemeraro
   */
  async eliminarProductos() {
    var confirmacion = confirm("¿Desea eliminar los productos seleccionados?");
    if (confirmacion) {
      this.spinnerShow();
      var elements = document.querySelectorAll('input[type="checkbox"]:checked');
      if (elements.length == 0) {
        this.carritoComponent.setMensaje('Error al eliminar', 'Debe seleccionar al menos un producto', true);
        this.verMensaje();
      } else {
        var checkedElements = Array.prototype.map.call(elements, function (el, i) {
          return el.id;
        });
        
        await this.carritoComponent.eliminarProductos(this.detallesCarrito, checkedElements, this.carritoComponent.getDatosOrdenCarrito().codigoOrden)
        .then(async () => {
          await this.getCarritoDetailsImportacion();
        })
        .catch((error) => {
          let mensaje = this.errorService.getErrorMessage(error.message);
          this.carritoComponent.setMensaje('Error', mensaje, true);
          this.verMensaje();
        });
      }
      this.spinnerHide();
    }

  }


  async eliminarProductosInventario() {
    var confirmacion = confirm("¿Desea eliminar los productos seleccionados?");
    if (confirmacion) {
      this.spinnerShow();
      var elements = document.querySelectorAll('input[type="checkbox"]:checked');
      if (elements.length == 0) {
        this.carritoComponent.setMensaje('Error al eliminar', 'Debe seleccionar al menos un producto', true);
        this.verMensaje();
      } else {
        var checkedElements = Array.prototype.map.call(elements, function (el, i) {
          return el.id;
        });

        await this.carritoComponent.eliminarProductos(this.detallesCarritoInventario, checkedElements, this.carritoComponent.getDatosOrdenCarritoInventario().codigoOrden)
        .then(async () => {
          await this.getCarritoDetailsInventario();
        })
        .catch((error) => {
          let mensaje = this.errorService.getErrorMessage(error.message);
          this.carritoComponent.setMensaje('Error', mensaje, true);
          this.verMensaje();
        });
      }
      this.spinnerHide();
    }

  }

  /**
   * Función que realiza la orden del carrito
   * @author msemeraro
   */
  async realizarPedido() {
    let rolAdmin:Boolean = await this.carritoComponent.isEnvioOrdenesComprobantesEstacion();
    if(!rolAdmin){
      this.carritoComponent.setMensaje('Error', "No posee con los permisos de administrador para realizar la orden", true);
      this.verMensaje();
      return;
    }
    else{
      if (this.detallesCarrito.length > 0) {
        this.spinnerShow();
        let respuesta = await this.carritoComponent.realizarPedido();
        this.spinnerHide();
        if (!respuesta.sucess) {
          let mensaje = this.errorService.displayError(respuesta.errors);
          this.carritoComponent.setMensaje('Error al realizar el pedido', mensaje, true);
          this.verMensaje();
        }
        await this.getOrdenDeCompraBorrador();
        this.router.navigate(['mis-ordenes']);
      }
      else {
        this.carritoComponent.setMensaje('Error al realizar el pedido', 'Debe añadir a la orden al menos un producto', true);
        this.verMensaje();
      }
    }
    
  }



  async realizarPedidoInventario() {
    let rolAdmin:Boolean = await this.carritoComponent.isEnvioOrdenesComprobantesEstacion();
    if(!rolAdmin){
      this.carritoComponent.setMensaje('Error', "No posee con los permisos de administrador para realizar la orden", true);
      this.verMensaje();
      return;
    }
    else{
      if (this.detallesCarritoInventario.length > 0) {
        this.spinnerShow();
        let respuesta = await this.carritoComponent.realizarPedidoInventario();
        this.spinnerHide();
        if (!respuesta.sucess) {
          let mensaje = this.errorService.displayError(respuesta.errors);
          this.carritoComponent.setMensaje('Error al realizar el pedido', mensaje, true);
          this.verMensaje();
        }
        await this.getOrdenDeCompraBorrador();
        this.router.navigate(['mis-ordenes']);
      }
      else {
        this.carritoComponent.setMensaje('Error al realizar el pedido', 'Debe añadir a la orden al menos un producto', true);
        this.verMensaje();
      }
    }
    
  }

  /**
   * Cancela una orden inventario que se encuentra en estado borrador
   * @returns 
   */
   async descartarPedidoInventario(){
    let rolAdmin:Boolean = await this.carritoComponent.isEnvioOrdenesComprobantesEstacion();
    if(!rolAdmin){
      this.carritoComponent.setMensaje('Error', "No posee con los permisos de administrador para descartar el pedido", true);
      this.verMensaje();
      return;
    }
    else{
      this.spinnerShow();
      let respuesta = await this.carritoComponent.descartarPedido(this.ordenDeCompraInventario);
      this.spinnerHide();
      if (!respuesta.sucess) {
        let mensaje = this.errorService.displayError(respuesta.errors);
        this.carritoComponent.setMensaje('Error al descartar el pedido', mensaje, true);
        this.verMensaje();
      }
      await this.getOrdenDeCompraBorrador();
      this.router.navigate(['principal']);
    }
  }

  /**
   * Confirma el cambio de una orden a estado de importación y realiza el pedido
   * @returns 
   */
   async confirmarPedidoImportacion(){
    let rolAdmin:Boolean = await this.carritoComponent.isEnvioOrdenesComprobantesEstacion();
    if(!rolAdmin){
      this.carritoComponent.setMensaje('Error', "No posee con los permisos de administrador para confirmar el pedido", true);
      this.verMensaje();
      return;
    }
    else{
      this.spinnerShow();
      let respuesta = await this.carritoComponent.confirmarCambioOrdenImportacion(this.ordenDeCompraInventario, this.ordenDeCompra);
      if (!respuesta.sucess) {
        let mensaje = this.errorService.displayError(respuesta.errors);
        this.carritoComponent.setMensaje('Error al confirmar el pedido para importación', mensaje, true);
        this.verMensaje();
      }else{
        // Actualiza la orden de inventario
        this.ordenDeCompraInventario = respuesta.data;
        // Actualiza los detalles de ambas
        await this.getCarritoDetailsImportacion();
        await this.getCarritoDetailsInventario();
      }
      this.spinnerHide();
    }
  }

  /**
   * Función que solicita una orden de compra en estado borrador
   * @author msemeraro
   */
  async getOrdenDeCompraBorrador() {
    let ordenDeCompra = await this.carritoComponent.getNuevaOrdenDeCompra();
    if (!ordenDeCompra.sucess) {
      let mensaje = this.errorService.displayError(ordenDeCompra.errors);
      this.carritoComponent.setMensaje('Error al obtener el carrito', mensaje, true);
      this.verMensaje();
    }
    else {
      if(ordenDeCompra.data[0].estadoOrdenInventario.estado == EnumEstadoOrdenInventario.IMPORTACION.nombre) {
        this.carritoComponent.setOrdenDeCompraImportacion(ordenDeCompra.data[0]);
        this.carritoComponent.setOrdenDeCompraInventario(ordenDeCompra.data[1]);
      } else {
        this.carritoComponent.setOrdenDeCompraInventario(ordenDeCompra.data[0]);
        this.carritoComponent.setOrdenDeCompraImportacion(ordenDeCompra.data[1]);
      }
    }
  }

  /**
   * Función que selecciona o deselecciona todos los productos del carrito
   * @author msemeraro
   */
  seleccionarTodo() {
    var checkboxes = document.getElementsByTagName('input');
    let check = checkboxes[0].checked;
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].type == 'checkbox') {
        checkboxes[i].checked = check;
      }
    }
  }

  /**
   * Función que selecciona o deselecciona todos los productos de una marca
   * @author msemeraro
   */
  seleccionarMarca(nombreMarca: string) {
    var checkboxes = document.getElementsByTagName('input');
    let check = false;
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].id == nombreMarca) {
        check = checkboxes[i].checked;
      }
      if (checkboxes[i].className == nombreMarca || checkboxes[i].name == nombreMarca) {
        checkboxes[i].checked = check;
      }
    }
  }

  /**
  * Función que redondea un número
  * @author msemeraro
  */
  getRoundNumber(numero: number) {
    return this.carritoComponent.getRoundNumber(numero);
  }

  /**
  * Función que formatea un número
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
  getFormatNumber(numero: number) {
    return this.carritoComponent.getFormatNumber(numero);
  }

  /**
  * Función que retorna el porcentaje de ganancia de una orden de compra
  * @author msemeraro
  */
  getPorcentajeGanancia(ganancia: number, totalOrden: number): number {
    let porcentaje = this.carritoComponent.getPorcentajeGanancia(ganancia, totalOrden);
    return this.getRoundNumber(porcentaje);
  }

  /**
   * Indica si la orden tiene líneas de detalle y tiene fecha de primer pedido
   * @returns 
   */
  tieneFechaPrimerPedidoCarritoInventario():boolean{
    if(this.detallesCarritoInventario){
      if(this.ordenDeCompraInventario){
        if(this.ordenDeCompraInventario.fechaPrimerPedido){
          return this.detallesCarritoInventario.length > 0;
        }
      }
    }
    return false;
  }

  /**
   * Obtiene la fecha límite de pago para las órdenes de inventario
   */
  getFechaLimitePagoInventario(){
    if(this.cantidadDiasPagoOrden && this.fechaLimitePagoOrden){
      if(this.ordenDeCompraInventario.fechaPrimerPedido) {
        let fechaLimite = new Date(this.ordenDeCompraInventario.fechaPrimerPedido);
        fechaLimite.setDate(fechaLimite.getDate() + this.cantidadDiasPagoOrden);

        
        this.fechaLimiteRealizarPedidoInventario = fechaLimite.toLocaleDateString('es-ES', this.opcionesFormatoFecha);
      }
    }
  }
  

  spinnerShow() {
    this.spinner.show();
  }

  spinnerHide() {
    this.spinner.hide();
  }

  /**
  * Función que muestra un mensaje
  * @author msemeraro
  */
  verMensaje() {
    this.tituloMensaje = this.carritoComponent.getTituloMensaje();
    this.contenidoMensaje = this.carritoComponent.getContenidoMensaje();
    this.esError = this.carritoComponent.getEsError();
    var element = document.getElementById("btnAbrirMensaje") as any;
    element.click();
  }

  clickModalMensaje() { }

}
