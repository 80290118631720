import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ErrorService } from 'src/services/error.service';
import { PageEvent } from '@angular/material/paginator';
import { AdminMantenimientoMarcasController } from './admin-mantenimiento-marcas.component.controller';
import { Marca } from 'src/models/com.enersol.directshopping.dal.entidad/Marca';

@Component({
  selector: 'app-admin-mantenimiento-marcas',
  templateUrl: './admin-mantenimiento-marcas.component.html',
  styleUrls: ['./admin-mantenimiento-marcas.component.scss'],
  providers: [AdminMantenimientoMarcasController]
})
export class AdminMantenimientoMarcasBackview implements OnInit {

  /* Variables para controlar los roles del usuario */
  isAdmin: Boolean;

  listaMarcas: Marca[] = null;
  nuevaMarca: Marca = new Marca();

  /* Variable para los mensajes */
  contenidoMensaje: String;
  tituloMensaje: String;
  esError: Boolean = false;

  /* Variables para la paginacion */
  page_size: number = 10;
  page_number: number = 1;
  pageSizeOptions = [5, 10, 20, 50, 100];


  constructor(
    private router: Router,
    public adminMantenimientosComponent: AdminMantenimientoMarcasController,
    private errorService: ErrorService,
    private spinner: NgxSpinnerService
  ) {
  }

  async ngOnInit() {
    this.isAdmin = await this.adminMantenimientosComponent.isAdminEnersol();
    await this.getMarcas();
  }


  /**
  * Función que obtiene las marcas registradas
  * @author msemeraro
  */
  async getMarcas() {
    let marcas = await this.adminMantenimientosComponent.getMarcas();
    if (!marcas.sucess) {
      let mensaje = this.errorService.displayError(marcas.errors);
      this.adminMantenimientosComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }
    else {
      this.listaMarcas = marcas.data;
    }
  }

  async eliminarMarca(marca: Marca) {
    var confirmacion = confirm("¿Desea eliminar la marca " + marca.nombre + "?");
    if (confirmacion) {
      this.spinnerShow();
      let respuesta = await this.adminMantenimientosComponent.eliminarMarca(marca);
      if (!respuesta.sucess) {
        let mensaje = this.errorService.displayError(respuesta.errors);
        this.adminMantenimientosComponent.setMensaje('Error al eliminar la marca', mensaje, true);
        this.verMensaje();
      }
      else {
        await this.getMarcas();
      }
      this.spinnerHide();
    }
  }

  async actualizarMarca(marca: Marca) {
    this.spinnerShow();
    let respuesta = await this.adminMantenimientosComponent.actualizarMarca(marca);
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al actualizar la marca', mensaje, true);
      this.verMensaje();
    }
    else {
      await this.getMarcas();
    }
    this.spinnerHide();
  }

  async agregarMarca() {
    this.nuevaMarca.icono = "";
    if (!this.adminMantenimientosComponent.validarNombre(this.nuevaMarca.nombre)) {
      this.adminMantenimientosComponent.setMensaje('Error', "Debe ingresar un nombre válido", true);
      this.verMensaje();
      return;
    }
    this.spinnerShow();
    let respuesta = await this.adminMantenimientosComponent.agregarMarca(this.nuevaMarca);
    if (!respuesta.sucess) {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminMantenimientosComponent.setMensaje('Error al agregar la marca', mensaje, true);
      this.verMensaje();
    }
    else {
      $(".close").click();
      this.nuevaMarca.nombre = "";
      await this.getMarcas();
    }
    this.spinnerHide();
  }

  /**
  * Función que navega a otra pagina
  * @author msemeraro
  */
  irPagina(nombre: string) {
    this.router.navigate([nombre]);
  }

  spinnerShow() {
    this.spinner.show();
  }

  spinnerHide() {
    this.spinner.hide();
  }

  /**
  * Función que muestra un mensaje
  * @author msemeraro
  */
  verMensaje() {
    this.tituloMensaje = this.adminMantenimientosComponent.getTituloMensaje();
    this.contenidoMensaje = this.adminMantenimientosComponent.getContenidoMensaje();
    this.esError = this.adminMantenimientosComponent.getEsError();
    var element = document.getElementById("btnAbrirMensaje") as any;
    element.click();
  }

  clickModalMensaje() { }

  clickModalCambioEstado() { }

  handlePage(e: PageEvent) {
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }
}
