import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusquedaBackview } from './busqueda.component.backview';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PipeModule } from 'src/app/pipes/pipe-module';
import { NgxSpinnerModule } from "ngx-spinner";
import { HeaderModule } from '../header/header.module';
import { FooterModule } from '../footer/footer.module';
import { ViewportScroller } from '@angular/common';

@NgModule({
  declarations: [BusquedaBackview],
  imports: [
    CommonModule,
    MatPaginatorModule,
    PipeModule,
    NgxSpinnerModule,
    HeaderModule,
    FooterModule,
    FormsModule
  ],
  exports: [
    BusquedaBackview
  ]
})
export class BusquedaModule { }
