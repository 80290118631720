import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
/* SERVICES */
import { ErrorService } from 'src/services/error.service';
import { PrincipalController } from './principal.component.controller';
/* REST MODEL */
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
/* MODELS */
import { Familia } from 'src/models/com.enersol.directshopping.dal.entidad/Familia';
import { ProductoPresentacion } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoPresentacion';
import { ProductoSugerido } from 'src/models/com.enersol.directshopping.dal.entidad/ProductoSugerido';

import { Event as NavigationEvent } from "@angular/router";
import { filter } from "rxjs/operators"; 

import { OwlOptions } from 'ngx-owl-carousel-o';
import { GlobalService } from 'src/services/global';
import { EnumEstadoInventarioProducto } from 'src/models/com.enersol.directshopping.dal.enums/EnumEstadoInventarioProducto';
import { Parametro } from 'src/models/com.enersol.directshopping.dal.entidad/Parametro';
import { EnumFormaInventario } from 'src/models/com.enersol.directshopping.dal.enums/EnumFormaInventario';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.scss'],
  providers: [PrincipalController]
})
export class PrincipalBackview implements OnInit {
  
  urlImagenNoDisponible = "../assets/img/no-disponible.png"

  productosNuevos: ListResponse<ProductoPresentacion> = new ListResponse<ProductoPresentacion>(false);
  productosPromociones: ListResponse<ProductoPresentacion> = new ListResponse<ProductoPresentacion>(false);
  productosSugeridos: ListResponse<ProductoSugerido> = new ListResponse<ProductoSugerido>(false);
  productosFamilias: ListResponse<ProductoPresentacion> = new ListResponse<ProductoPresentacion>(false);

  familias: ListResponse<Familia> = new ListResponse<Familia>(false);
  familiasProductoPresentacion: Familia[] = [];

  urlEnersol: string = "";
  mySlideOptions: {};
  myCarouselOptions: {};

  marcas;

  formaInventario: Parametro;

  /* Variable para los mensajes */
  contenidoMensaje: String;
  tituloMensaje: String;
  esError: Boolean;

  customOptions: OwlOptions = {
    // loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    items: 6,
    autoWidth: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      500: {
        items: 3
      },
      740: {
        items: 6
      },
      940: {
        items: 6
      }
    }
    // nav: true
  }

  logosOptions: OwlOptions = {
    // loop: true,
    autoplay: true,
    autoplaySpeed: 600,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    items: 2,
    autoWidth: true,
    navSpeed: 650,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      500: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      }
    }
    // nav: true
  }



  constructor(
    private router: Router,
    private principalComponent: PrincipalController,
    private errorService: ErrorService,
    private spinner: NgxSpinnerService,
    private globalEnersolService: GlobalService
  ) {

    this.myCarouselOptions = { items: 3, dots: true, nav: true };
    // router.events
    //         .pipe(
    //             // The "events" stream contains all the navigation events. For this demo,
    //             // though, we only care about the NavigationStart event as it contains
    //             // information about what initiated the navigation sequence.
    //             filter(
    //                 ( event: NavigationEvent ) => {
 
    //                     return( event instanceof NavigationStart );
 
    //                 }
    //             )
    //         )
    //         .subscribe(
    //             ( event: NavigationStart ) => {
    //               console.log(event.navigationTrigger == 'popstate')
    //               if(event.navigationTrigger == 'popstate') {
    //                 return;
    //                 this.router.navigate(['principal']);
    //               }
    //                 console.group( "NavigationStart Event" );
    //                 // Every navigation sequence is given a unique ID. Even "popstate"
    //                 // navigations are really just "roll forward" navigations that get
    //                 // a new, unique ID.
    //                 console.log( "navigation id:", event.id );
    //                 console.log( "route:", event.url );
    //                 // The "navigationTrigger" will be one of:
    //                 // --
    //                 // - imperative (ie, user clicked a link).
    //                 // - popstate (ie, browser controlled change such as Back button).
    //                 // - hashchange
    //                 // --
    //                 // NOTE: I am not sure what triggers the "hashchange" type.
    //                 console.log( "trigger:", event.navigationTrigger );
 
    //                 // This "restoredState" property is defined when the navigation
    //                 // event is triggered by a "popstate" event (ex, back / forward
    //                 // buttons). It will contain the ID of the earlier navigation event
    //                 // to which the browser is returning.
    //                 // --
    //                 // CAUTION: This ID may not be part of the current page rendering.
    //                 // This value is pulled out of the browser; and, may exist across
    //                 // page refreshes.
    //                 if ( event.restoredState ) {
 
    //                     console.warn(
    //                         "restoring navigation id:",
    //                         event.restoredState.navigationId
    //                     );
 
    //                 }
 
    //                 console.groupEnd();
 
    //             }
    //         )
    //     ;



  }

  async ngOnInit() {
    await this.spinnerShow();
    this.urlEnersol = await this.principalComponent.urlEnersol;
    await this.getMarcas();
    await this.getProductosSugeridos();
    await this.getProductosNuevos();
    await this.getProductosPromociones();
    await this.getFamilias();
    this.globalEnersolService.getCantidadProductosCarrito();
    this.formaInventario = await this.globalEnersolService.getFormaInventario();
    await this.spinnerHide();
  }


  /**
   * Función que obtiene los productos sugeridos a desplegar
   * @author msemeraro
   */
  async getProductosSugeridos() {
    this.productosSugeridos = await this.principalComponent.getProductosSugeridos();
    if (!this.productosSugeridos.sucess) {
      let mensaje = this.errorService.displayError(this.productosSugeridos.errors);
      this.principalComponent.setMensaje('Error al obtener los productos', mensaje, true);
      this.verMensaje();
    }
  }


  /**
   * Función que obtiene los productos nuevos a desplegar
   * @author msemeraro
   */
  async getProductosNuevos() {
    this.productosNuevos = await this.principalComponent.getProductosNuevos();
    if (!this.productosNuevos.sucess) {
      let mensaje = this.errorService.displayError(this.productosNuevos.errors);
      this.principalComponent.setMensaje('Error al obtener los productos', mensaje, true);
      this.verMensaje();
    }
  }

  /**
   * Función que obtiene los productos en promocion a desplegar
   * @author msemeraro
   */
  async getProductosPromociones() {
    this.productosPromociones = await this.principalComponent.getProductosPromociones();
    if (!this.productosPromociones.sucess) {
      let mensaje = this.errorService.displayError(this.productosNuevos.errors);
      this.principalComponent.setMensaje('Error al obtener los productos', mensaje, true);
      this.verMensaje();
    }
  }

  /**
   * Función que obtiene los productos por familia a desplegar
   * @author msemeraro
   */
  async getFamilias() {
    this.familias = await this.principalComponent.getFamilias();
    if (!this.familias.sucess) {
      let mensaje = this.errorService.displayError(this.productosNuevos.errors);
      this.principalComponent.setMensaje('Error al obtener los productos', mensaje, true);
      this.verMensaje();
    }
    // Carga las productos por familia
    else {
      let familia: Familia;
      this.familiasProductoPresentacion = [];
      for (familia of this.familias.data) {
        await this.getProductosFamilia(familia.codigoFamilia);
        familia.productosPresentacion = this.productosFamilias.data;
        if (familia.productosPresentacion.length > 0)
          this.familiasProductoPresentacion.push(familia);
      }
    }
  }

  /**
   * Función que obtiene los productos de una familia
   * @param codFamilia
   * @author msemeraro
   */
  async getProductosFamilia(codFamilia: number) {
    this.productosFamilias = await this.principalComponent.getProductosFamilia(codFamilia);
    if (!this.productosFamilias.sucess) {
      let mensaje = this.errorService.displayError(this.productosNuevos.errors);
      this.principalComponent.setMensaje('Error al obtener los productos', mensaje, true);
      this.verMensaje();
    }
  }

  /**
   * Función que muestra todos los productos de una seccion
   * @param nombreLista
   * @param codigo
   * @param titulo
   * @author msemeraro
   */
  verMas(nombreLista: string, codigo: number, titulo: string) {
    if (nombreLista === 'productosSugeridos')
      this.router.navigate(['orden-sugerida']);
    else {
      this.principalComponent.verListadoProductos(nombreLista, codigo, titulo);
      this.router.navigate(['busqueda']);
    }
  }

  /**
   * Función que despliega el detalle del producto seleccionado
   * @param producto
   * @author msemeraro
   */
  verDetalle(producto: ProductoPresentacion, cantidad: number) {
    this.principalComponent.verDetalleProducto(producto, cantidad);
    this.router.navigate(['detalle-producto']);
  }
  /**
   * Función que añade un producto al carrito
   * @param producto
   * @author msemeraro
   */
  async addCarrito(producto: ProductoPresentacion, cantidad: number) {
    try{
      let respuesta = await this.principalComponent.addCarrito(producto, cantidad);
      if (!respuesta.sucess) {
        //TODO DCHINCHILLA REVISAR ESTE ERROR
        let mensaje = this.errorService.displayError(respuesta.errors);
        this.principalComponent.setMensaje('Error al añadir al carrito', mensaje, true);
        this.verMensaje();
      }
      else {
        if (cantidad == 1)
          this.principalComponent.setMensaje('Producto añadido al carrito', 'Se añadió una cantidad del producto: ' + producto.producto.nombre, false);
        else
          this.principalComponent.setMensaje('Producto añadido al carrito', 'Se añadieron ' + cantidad + ' cantidades del producto: ' + producto.producto.nombre, false);
  
        this.verMensaje();
      }
    }
    catch(error){
      let mensaje = this.errorService.getErrorMessage(error.message);
      this.principalComponent.setMensaje('Error al añadir al carrito', mensaje, true);
      this.verMensaje();
    }
  }

  isEstadoInventario(producto: ProductoPresentacion) {
    return this.isEstado(producto, EnumEstadoInventarioProducto.INVENTARIO.codigoEstado);
  }

  isEstadoProximamente(producto: ProductoPresentacion) {
    return this.isEstado(producto, EnumEstadoInventarioProducto.PROXIMAMENTE.codigoEstado);
  }

  isEstadoFueraInventario(producto: ProductoPresentacion) {
    return this.isEstado(producto, EnumEstadoInventarioProducto.FUERA_INVENTARIO.codigoEstado);
  }

  isEstadoProduccionSuspendida(producto: ProductoPresentacion) {
    return this.isEstado(producto, EnumEstadoInventarioProducto.PRODUCCION_SUSPENDIDA.codigoEstado);
  }

  isEstado(producto: ProductoPresentacion, codigoEstado: number){
    if(producto) {
      return producto.estadoInventarioProducto.id == codigoEstado;
    }
  }

  /**
  * Función que formatea un número
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
  getFormatNumber(numero: number) {
    return this.principalComponent.getFormatNumber(numero);
  }

  spinnerShow() {
    this.spinner.show();
  }

  spinnerHide() {
    this.spinner.hide();
  }

  /**
  * Función que muestra un mensaje
  * @author msemeraro
  */
  verMensaje() {
    this.tituloMensaje = this.principalComponent.getTituloMensaje();
    this.contenidoMensaje = this.principalComponent.getContenidoMensaje();
    this.esError = this.principalComponent.getEsError();
    var element = document.getElementById("btnAbrirMensaje") as any;
    element.click();
  }

  clickModalMensaje() { }

  /**
   * Indica si una presentacion de producto está en estado No Disponible
   * @param producto 
   * @author gberrocal
   */
  isProductoNoDisponible(producto: ProductoPresentacion):boolean {
    if(producto){
      return producto.estadoInventarioProducto.id == EnumEstadoInventarioProducto.PRODUCCION_SUSPENDIDA.codigoEstado;
    }
    return false;
  }

  /**
   * Indica si tiene que mostrar tanto la cantidad de inventario como de importación
   * @returns 
   */
  mostrarInventarioImportacion(producto: ProductoPresentacion): boolean{
    // Se muestra solo si la modalidad no es inventario
    if(this.formaInventario){
      if(this.formaInventario.valor != EnumFormaInventario.INVENTARIO.nombre){
        // Si el producto esta en inventario o fuera de invnetario
        if(this.isEstadoInventario(producto) || this.isEstadoFueraInventario(producto)){
          var cantidadImportacion = this.getCantidadImportacionDisponible(producto);
          return (cantidadImportacion && cantidadImportacion > 0);
        }
      }
    }
    return false;
  }

  /**
   * Indica si se muestra el estado normal del producto
   * @param producto 
   * @returns 
   */
  mostrarEstadoNormal(producto: ProductoPresentacion): boolean{
    if(this.isModalidad(EnumFormaInventario.MIXTO.nombre) 
      || this.isModalidad(EnumFormaInventario.INVENTARIO.nombre)){
      return true;
    }
    else if(this.isModalidad(EnumFormaInventario.IMPORTACION.nombre)){
      if(this.isEstadoProximamente(producto)){
        return true;
      }
    }
    return false;
  }

  /**
   * Indica si la modalidad es mixta
   */
  isModalidadMixta(): boolean{
    return this.isModalidad(EnumFormaInventario.MIXTO.nombre);
  }

  /**
   * Indica si la modalidad de la plataforma es de un tipo determinado
   * @param modalidad 
   * @returns 
   */
  private isModalidad(modalidad: string): boolean{
    if(this.formaInventario){
      return this.formaInventario.valor == modalidad;
    }
    return false;
  }

  getNombreEstadoProximamente(): string{
    return EnumEstadoInventarioProducto.PROXIMAMENTE.nombre;
  }

  /**
   * Obtiene la cantidad disponible para importación
   * @returns 
   */
  getCantidadImportacionDisponible(producto: ProductoPresentacion): number{
    if(producto){
      return producto.cantidadImportacion - producto.importacionTransito;
    }
    return null;
  }

  /**
   * Función que obtiene todas las marcas importadas
   */
  async getMarcas() {
    try {
      let response;
      // Obtiene los datos de las marcas
      await this.principalComponent.getMarcas()
        .then(data => {           // Procesa los datos
          response = data;
        });
      if (response.sucess) {
        this.marcas = response.data;    // Asigna los datos obtenidos al atributo marcas
      }
    } catch (error) {
      console.log('Error al obtener los datos');
    }
  }
}
