import { Injectable } from '@angular/core';

import { ConstantsService } from './constants.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(
    private constantsService: ConstantsService
  ) {

  }

  log(message: string) {
    if (this.constantsService.getDebugMode()) {
      console.log(message);
    }
  }

}