import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
/* SERVICES */
import { ErrorService } from 'src/services/error.service';
import { AdminDetalleOrdenController } from './admin-detalle-orden.component.controller';
/* REST MODEL */
import { ListResponse } from 'src/models/com.enersol.directshopping.ws.rest.model/ListResponse';
/* MODELS */
import { OrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/OrdenDeCompra';
import { EstadoOrden } from 'src/models/com.enersol.directshopping.dal.entidad/EstadoOrden';
import { DetalleOrdenCompraPorMarca } from 'src/models/com.enersol.directshopping.dal.entidad/DetalleOrdenCompraPorMarca';
import { ComprobantePagoOrdenDeCompra } from 'src/models/com.enersol.directshopping.dal.entidad/ComprobantePagoOrdenDeCompra';
import { EnumEstadoOrdenInventario } from 'src/models/com.enersol.directshopping.dal.enums/EnumEstadoOrdenInventario';
import { EstadoOrdenInventario } from 'src/models/com.enersol.directshopping.dal.entidad/EstadoOrdenInventario';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { style } from '@angular/animations';
import { Content } from '@angular/compiler/src/render3/r3_ast';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-admin-detalle-orden',
  templateUrl: './admin-detalle-orden.component.html',
  styleUrls: ['./admin-detalle-orden.component.scss'],
  providers: [AdminDetalleOrdenController]
})
export class AdminDetalleOrdenBackview implements OnInit {

  /* Variables para controlar los roles del usuario */
  isAdmin: Boolean;

  /* Orden de compra que se muestra */
  ordenDeCompra: OrdenDeCompra = null;

  /* Montos de la orden */
  subTotalOrden = 0;
  totalOrden = 0;
  impuestoOrden = 0;

  /* Variables para el modal del cambio de estado */
  detalleOrdenSeleccionado: DetalleOrdenCompraPorMarca;

  posiblesEstados: ListResponse<EstadoOrden> = new ListResponse<EstadoOrden>(false);
  codigoEstadoSeleccionado = 0;

  comentarioCambioEstado = "";
  /* Variables para el modal del historial de los detalles */
  historialEstadosDetalle = [];

  /* Variable para los mensajes */
  contenidoMensaje: String;
  tituloMensaje: String;
  esError: Boolean;

  /* Variables para el comprobante de pago */
  comprobante: ComprobantePagoOrdenDeCompra = null;

//Variable que tendra el enlace a la url del icono de enersol para utilizar en la impresion de documentos
  logoDataUrl: string;
  estadosOrdenInventario: EstadoOrdenInventario[] = [];

  constructor(
    private router: Router,
    private adminOrdenesComponent: AdminDetalleOrdenController,
    private errorService: ErrorService,
    private spinner: NgxSpinnerService
  ) {
  }

  async ngOnInit() {
    this.isAdmin = await this.adminOrdenesComponent.isAdminEnersol();
    this.spinnerShow();
    this.ordenDeCompra = await this.adminOrdenesComponent.getOrdenDeCompra();
    this.ordenDeCompra.detallesOrden = await this.getDetailProducts(this.ordenDeCompra.detallesOrden);
    this.inicializarOrdenMarcaComo();

    // Obtiene la lista de estados orden inventario
    let resultEstadosOrdenInventario = await this.adminOrdenesComponent.getEstadosOrdenInventario();
    if (resultEstadosOrdenInventario.sucess) {
      this.estadosOrdenInventario = resultEstadosOrdenInventario.data;
    } else {
      let mensaje = this.errorService.displayError(resultEstadosOrdenInventario.errors);
      this.adminOrdenesComponent.setMensaje('Error al obtener los datos', mensaje, true);
      this.verMensaje();
    }

    this.getSubtotal();
    await this.obtenerComprobante();
    this.spinnerHide();


    this.getImageDataUrlFromLocalPath1('assets/img/Enersol Logo Rojo.png').then(
      result => this.logoDataUrl = result
    )
  }

  /**
   * Si la orden no tiene el campo inicializado, lo inicializa
   */
  inicializarOrdenMarcaComo(){
    // Si no tiene el campo inicializado, lo inicializa
    if(!this.ordenDeCompra.marcadaComo){
      this.ordenDeCompra.marcadaComo = new EstadoOrdenInventario();
      this.ordenDeCompra.marcadaComo.id = 0;
    }
  }

  /**
  * Función que redondea un número
  * @author msemeraro
  */
  getRoundNumber(numero: number) {
    return this.adminOrdenesComponent.getRoundNumber(numero);
  }

  /**
  * Función que formatea un número
  * @param numero
  * @returns number formateado
  * @author msemeraro
  */
  getFormatNumber(numero: number) {
    return this.adminOrdenesComponent.getFormatNumber(numero);
  }

  /**
  * Función que retorna el porcentaje de ganancia de una orden de compra
  * @author msemeraro
  */
  getPorcentajeGanancia(ganancia: number, totalOrden: number): number {
    let porcentaje = this.adminOrdenesComponent.getPorcentajeGanancia(ganancia, totalOrden);
    return this.getRoundNumber(porcentaje);
  }

  /**
  * Función que obtiene los productos de un detalle de una orden de compra
  * @author msemeraro
  */
  async getDetailProducts(listaDetalle) {
    try {
      let subtotal;
      for (let element of listaDetalle) {
        subtotal = 0;
        await this.adminOrdenesComponent.getDetailProducts(element.codigoDetalleOrden).then(data => {
          element.productos = data.data;
          /*for (let producto of element.productos) {
            subtotal += producto.precioUnitario * producto.cantidad;
          }
          element.subTotal = subtotal;*/
        });
      }
    } catch (error) {
      console.log(error);
      console.log("Error al obtener los datos");
    }
    return listaDetalle;
  }
  

  /**
  * Función que obtiene el subtotal de la orden de compra
  * @author msemeraro
  */
  getSubtotal() {
    this.subTotalOrden = 0;
    for (let element of this.ordenDeCompra.detallesOrden) {
      this.subTotalOrden = this.subTotalOrden + element.subTotal;
      this.subTotalOrden = this.subTotalOrden;
    }
    this.totalOrden = this.adminOrdenesComponent.getTotalOrden(this.subTotalOrden);
    this.impuestoOrden = this.adminOrdenesComponent.getImpuestoOrden(this.subTotalOrden);
  }

/**
  * Función que crea el PDF de la orden
  * @author dchinchilla
  */
  async crearPDF() {
    let array = [];
    this.ordenDeCompra = await this.adminOrdenesComponent.getOrdenDeCompra();
     array.push([
      'Marca', 
      'Código Enersol', 
      'Producto', 
      'Presentación', 
      'Unidad de Empaque',
      'Cantidad', 
      'Precio por Unidad de Empaque'
    ],);


    for(let detalleOrden of this.ordenDeCompra.detallesOrden) {
      let detalleOrden2 = await this.getDetailProducts(detalleOrden);
      for(let productoDetalle of detalleOrden2.productos) {
        array.push([
         detalleOrden.marca.nombre , 
         productoDetalle.productoPresentacion.codigoEnersol , 
         productoDetalle.descripcion ,
         productoDetalle.productoPresentacion.presentacion.descripcion , 
         productoDetalle.productoPresentacion.unidadEmpaque , 
         productoDetalle.cantidad , 
         '$' + this.getFormatNumber(productoDetalle.precioUnitario)  ,
        ]);
      }
    }
            
     var documentDefinition = {
        content: [
          {
            columns: [
              [
                    'Orden No: ' + this.ordenDeCompra.codigoOrden,
                    {text: 'Cliente: ' + this.ordenDeCompra.estacion.nombre, style: 'subheader'},
                    {text: 'Realizado el: ' + this.ordenDeCompra.fecha, style: 'subheader'},
              ],
                    {
                        image: this.logoDataUrl, style: 'imagenDerecha'
                    }
                  ],
                  style: 'header'
          },
                {
                  style: 'tableExample',
                  table: {
                    body: array 
                  }
                }, 
                {
                stack: [
                  { 
                    alignment: 'right',
                    text: 'Sub-total: $' + this.getFormatNumber(this.subTotalOrden),
                    style:'footer'
                  },
                  { 
                    alignment: 'right',
                    text: 'Impuesto ( ' + this.ordenDeCompra.impuesto + '%): $' + this.getFormatNumber(this.impuestoOrden)
                  },
                  { 
                    alignment: 'right',
                    text: 'Total: $' + this.getFormatNumber(this.subTotalOrden + this.impuestoOrden)
                  }  
                ]
              } 
        ],
        styles: {
              header: {
                fontSize: 18,
                bold: true,
                alignment: 'left',
                margin: [0, 0, 0, 80]
              },
              subheader: {
                fontSize: 14
              },
              imagenDerecha: {
                alignment: 'right'
              },
              footer: {
                margin: [0, 30, 0, 0]
              }

            }
      };

    
    
        pdfMake.createPdf(documentDefinition).download('Orden No: ' + this.ordenDeCompra.codigoOrden);
  }
  /**
  * Función que obtiene el historial de los detalles de la orden
  * @author msemeraro
  */
  async verHistorialEstados() {
    this.spinnerShow();
    let historialDetalles = await this.adminOrdenesComponent.getHistorialEstadosDetalles(this.ordenDeCompra.codigoOrden);
    if (!historialDetalles.sucess) {
      let mensaje = this.errorService.displayError(historialDetalles.errors);
      this.adminOrdenesComponent.setMensaje('Error al cargar el historial', mensaje, true);
      this.verMensaje();
    }
    else {
      // Ordena la lista por fecha 
      this.historialEstadosDetalle = historialDetalles.data;
      this.getFormatoFechas();
      this.historialEstadosDetalle = this.adminOrdenesComponent.orderByFecha(this.historialEstadosDetalle);
    }
    this.spinnerHide();
  }


  async selectDetalleOrden(detalleOrden) {
    this.codigoEstadoSeleccionado = 0;
    this.comentarioCambioEstado = "";
    this.detalleOrdenSeleccionado = detalleOrden;
    await this.getPosiblesEstados();
    if (!this.posiblesEstados.sucess) {
      this.adminOrdenesComponent.setMensaje('Error', 'Los productos de la marca ' + detalleOrden.marca.nombre + ' se encuentran en un estado no modificable por el administrador', true);
      this.verMensaje();
    }
  }

  /**
* Función que le da formato a una fecha
* @author msemeraro
*/
  getFormatoFechas() {
    this.historialEstadosDetalle = this.adminOrdenesComponent.getFormatoFechas(this.historialEstadosDetalle);
  }

  /**
   * Función que carga los posibles estados de una marca de un detalle de orden seleccionada
   * @author msemeraro
   */
  async getPosiblesEstados() {
    this.posiblesEstados = await this.adminOrdenesComponent.getPosiblesEstados(this.detalleOrdenSeleccionado.estadoOrden.codigoEstado);
    /*if (!this.posiblesEstados.sucess) {
      this.errorService.displayError(this.posiblesEstados.errors);
    }*/
    return this.posiblesEstados;
  }

  /**
  * Función que controla el cambio de estado
  * @param codigoEstado 
  * @author msemeraro
  */
  onChangeEstado(codigoEstado) {
    this.codigoEstadoSeleccionado = codigoEstado;
  }

  /**
  * Función que hace la peticion del cambio de estado a los detalles
  * @author msemeraro
  */
  async modificarEstado() {
    let respuesta;
    if (this.codigoEstadoSeleccionado > 0) {
      if (!this.adminOrdenesComponent.validateComentario(this.comentarioCambioEstado)) {
        this.adminOrdenesComponent.setMensaje('Error', 'Debe ingresar un comentario válido', true);
        this.verMensaje();
        return;
      }
      else {
        this.spinnerShow();
        respuesta = await this.adminOrdenesComponent.updateEstadoDetalleOrden(this.detalleOrdenSeleccionado.codigoDetalleOrden, this.codigoEstadoSeleccionado, this.comentarioCambioEstado);
        if (respuesta.sucess) {
          this.spinnerHide();
          this.actualizarOrden();
        }
        else {
          this.spinnerHide();
          let mensaje = this.errorService.displayError(respuesta.errors);
          this.adminOrdenesComponent.setMensaje('Error al modificar el estado', mensaje, true);
          this.verMensaje();
          return;
        }
      }
      this.spinnerHide();
    }
  }

  /**
  * Función que carga los detalles de la orden actualizados
  * @author msemeraro
  */
  async getDetallesActualizados() {
    let respuesta = await this.adminOrdenesComponent.getDetallesOrden(this.ordenDeCompra.codigoOrden);
    if (respuesta.sucess) {
      this.ordenDeCompra.detallesOrden = respuesta.data;
      this.adminOrdenesComponent.setOrdenDeCompraImportacion(this.ordenDeCompra);
      this.ordenDeCompra = this.adminOrdenesComponent.getOrdenDeCompra();
      this.inicializarOrdenMarcaComo();
    }
    else {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminOrdenesComponent.setMensaje('Error al actualizar', mensaje, true);
      this.verMensaje();
    }
  }

  /**
  * Función que acepta o rechaza el comprobante
  * @author msemeraro
  */
  async validarComprobante(valido: boolean) {
    if (!this.adminOrdenesComponent.validateComentario(this.comentarioCambioEstado)) {
      this.adminOrdenesComponent.setMensaje('Error', 'Debe ingresar un comentario válido', true);
      this.verMensaje();
      return;
    }
    
    this.spinnerShow();
    let respuesta;
    let codProximoEstado = 3;
    if (valido) {
      codProximoEstado = 5;
    }
    respuesta = await this.adminOrdenesComponent.verificarComprobanteOrden(this.ordenDeCompra.codigoOrden, codProximoEstado, this.comentarioCambioEstado);
    if (respuesta.sucess) {
      this.ordenDeCompra.estadoOrden.codigoEstado = codProximoEstado;
      // Actualiza el estado del comprobante
      if (valido)
        await this.adminOrdenesComponent.updateEstadoComprobantePago(this.comprobante.codigoComprobante, 2);
      else
        await this.adminOrdenesComponent.updateEstadoComprobantePago(this.comprobante.codigoComprobante, 3);
      await this.actualizarOrden();
    }
    else {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminOrdenesComponent.setMensaje('Error al validar el comprobante de pago', mensaje, true);
      this.verMensaje();
    }
    this.spinnerHide();
  }

  /**
  * Función que actualiza la orden que se muestra
  * @author msemeraro
  */
  async actualizarOrden() {
    $("#estadosSiguienteEstado").val(0);
    $(".close").click();
    await this.getOrdenDeCompraUpdate();
    this.ordenDeCompra = await this.adminOrdenesComponent.getOrdenDeCompra();
    this.inicializarOrdenMarcaComo();
    await this.getDetallesActualizados();
    this.getDetailProducts(this.ordenDeCompra.detallesOrden);
    this.getSubtotal();
  }

  /**
  * Función que hace la peticion de obtener la orden actualizada
  * @author msemeraro
  */
  async getOrdenDeCompraUpdate() {
    let respuesta = await this.adminOrdenesComponent.getOrdenDeCompraUpdate(this.ordenDeCompra.codigoOrden);

    if (respuesta.sucess) {
      this.adminOrdenesComponent.setOrdenDeCompraImportacion(respuesta.data);
    }
    else {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminOrdenesComponent.setMensaje('Error al actualizar', mensaje, true);
      this.verMensaje();
    }
  }

  /**
  * Función que obtiene el ultimo comprobante de pago adjunto de una orden
  * @author msemeraro
  */
  async obtenerComprobante() {
    let respuesta = await this.adminOrdenesComponent.obtenerUltimoComprobante(this.ordenDeCompra.codigoOrden);
    if (respuesta.sucess) {
      this.comprobante = respuesta.data;
    }
    else {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminOrdenesComponent.setMensaje('Error al obtener el comprobante', mensaje, true);
      this.verMensaje();
    }
  }

  /**
   * Indica si la orden de compra es de importación o no
   * @returns True si es importación, false si no
   * @author gberrocal
   */
  isOrdenTipoImportacion(): boolean{
    if(this.ordenDeCompra && this.ordenDeCompra.estadoOrdenInventario.id === EnumEstadoOrdenInventario.IMPORTACION.codigoEstado){
      return true;
    }
    return false;
  }

  /**
   * Actualiza la marca de estado de una orden de compra
   */
  async actualizarMarcaOrden(){
    this.spinnerShow();
    // Actualiza el estado
    if(this.ordenDeCompra.marcadaComo.id != 0){
      for(let estado of this.estadosOrdenInventario){
        if(estado.id == this.ordenDeCompra.marcadaComo.id){
          this.ordenDeCompra.marcadaComo.estado = estado.estado;
        }
      }
    }else{
      this.ordenDeCompra.marcadaComo = undefined;
    }

    // Salva temporalmente el detalle de las ordenes para volverlo a colocar luego del update
    let detallesOrden = this.ordenDeCompra.detallesOrden;

    // Actualiza la marca de la orden
    let respuesta = await this.adminOrdenesComponent.marcarOrdenComo(this.ordenDeCompra);
    if (respuesta.sucess) {
      this.ordenDeCompra = respuesta.data;
      this.ordenDeCompra.detallesOrden = detallesOrden;
      this.adminOrdenesComponent.setOrdenDeCompraImportacion(this.ordenDeCompra);
      this.inicializarOrdenMarcaComo();
    }
    else {
      let mensaje = this.errorService.displayError(respuesta.errors);
      this.adminOrdenesComponent.setMensaje('Error al actualizar', mensaje, true);
      this.verMensaje();
    }

    this.spinnerHide();
  }

  /**
  * Función que obtiene el ultimo comprobante de pago adjunto de una orden
  * @author msemeraro
  */
  async descargarComprobante() {
    this.descargaArchivo(this.comprobante.comprobantePago);
  }

  /**
  * Función que muestra un documento
  * @author msemeraro
  */
  descargaArchivo(url) {
    this.adminOrdenesComponent.abrirArchivo(url);
  }

  spinnerShow() {
    this.spinner.show();
  }

  spinnerHide() {
    this.spinner.hide();
  }

  /**
  * Función que muestra un mensaje
  * @author msemeraro
  */
  verMensaje() {
    this.tituloMensaje = this.adminOrdenesComponent.getTituloMensaje();
    this.contenidoMensaje = this.adminOrdenesComponent.getContenidoMensaje();
    this.esError = this.adminOrdenesComponent.getEsError();
    var element = document.getElementById("btnAbrirMensaje") as any;
    element.click();
  }

  clickModalMensaje() { }

  clickModalCambioEstado() { }



  getImageDataUrlFromLocalPath1(localPath: string): Promise<string> {
    return new Promise((resolve, reject) => {
        let canvas = document.createElement('canvas');
        let img = new Image();
        img.onload = () => {
            canvas.height = img.height;
            canvas.width = img.width;
            canvas.getContext("2d").drawImage(img, 0, 0);
            resolve(canvas.toDataURL('image/png'));
        }
        img.onerror = () => reject('Imagen no disponible')
        img.src = localPath;
    })

}
}
